import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Switch } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { MdOutlineDelete } from "react-icons/md";
import DeleteConfirm from "../snapchatMarketing/DeleteConfirmation";

import { toast } from "react-toastify";
import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import CustomSwitch from "./customSwitch";

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&.Mui-checked": {
    color: theme.palette.success.main,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20, // Adjust size for smaller checkboxes
  },
}));

const token = localStorage?.getItem("auth-token");

const deliveryStatus = [
  {
    code: "INVALID_NOT_EFFECTIVE_ACTIVE",
    label: "Campaign is inactive",
  },
  {
    code: "INVALID_NOT_ACTIVE",
    label: "Not Devlivering",
  },
];

// Columns definition

const CompaignsTable = ({
  setIsSingleCampaign,
  setCampaignDetails,
  oldObjectives,
  compaigns,
  setComapaigns,
  getAllCompaigns,
  selectedRows = { selectedRows },
  setSelectedRows = { setSelectedRows },
  loading,
  setLoading,
  fb_token,
  searchQuery,
  objectives,
}) => {
  // const [compaigns, setComapaigns] = useState([]);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    DataGridStyles,
    fbCredentials,
  } = useStateContext();
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);

  async function deleteCampaign(id) {
    window.FB.api(
      `/${id}`,
      "DELETE",
      {
        access_token: fbCredentials?.access_token,
        // access_token: fb_token,
      },
      (response) => {
        console.log("delete camp res: ", response);
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error ? response.error.message : "Error delete campaign."
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          toast.success(`Campaign Deleted.`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          getAllCompaigns();

          setLoading(false);
        }
      }
    );
  }
  async function updateCampaign(data) {
    window.FB.api(
      `/${data?.id}`,
      "POST",
      {
        access_token: fbCredentials?.access_token,
        // access_token: fb_token,
        status: data?.status == "PAUSED" ? "ACTIVE" : "PAUSED",
      },
      (response) => {
        console.log("updated camp res: ", response);
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error
                ? response.error.message
                : "Error updating campaign."
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          toast.success(`Campaign Status Updated.`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          getAllCompaigns();

          setLoading(false);
        }
      }
    );
  }
  // async function updateCampaign(campaignDetails, status) {
  //   console.log(campaignDetails, "details");
  //   try {
  //     await axios.post(
  //       `${BACKEND_URL}/snapchat/update/campaign`,
  //       {
  //         campaign_id: campaignDetails?.id,
  //         access_token: snapchatCredentials?.access_token,
  //         name: campaignDetails?.name,
  //         ad_account_id: snapchatCredentials?.currentAdAccount?.id,
  //         status: status ? "ACTIVE" : "PAUSED",
  //         start_time: campaignDetails?.start_time,
  //         objective: campaignDetails?.objective,
  //         ...(campaignDetails?.end_time && {
  //           end_time: campaignDetails.end_time,
  //         }),
  //         ...(campaignDetails?.daily_budget_micro && {
  //           daily_budget_micro: campaignDetails?.daily_budget_micro / 1000000,
  //         }),
  //         ...(campaignDetails?.lifetime_spend_cap_micro && {
  //           lifetime_spend_cap_micro:
  //             campaignDetails?.lifetime_spend_cap_micro / 1000000,
  //         }),
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );

  //     toast.success(" Campaign is Successfully Updated", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     getAllCompaigns();
  //   } catch (error) {
  //     toast.error("Unable to update campaign", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     console.log(error, "this is an error");
  //     if (error.response?.data?.errors) {
  //       Object.values(error.response?.data?.errors)
  //         .flat()
  //         .map((err) =>
  //           toast.error(err, {
  //             position: "top-right",
  //             autoClose: 3000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //           })
  //         );
  //     }
  //   }
  // }

  const columns = [
    {
      field: "status",
      headerName: "Off/On",
      headerAlign: "center",
      minWidth: 150,
      // cellClassName: "border-r-[2px] border-gray-300",
      renderHeader: (params) => {
        return <div className="font-semibold">{params.colDef.headerName}</div>; // Apply padding manually
      },
      renderCell: (params) => (
        <CustomSwitch
          checked={params.row.status == "PAUSED" ? false : true}
          onChange={(e) => updateCampaign(params?.row, e?.target?.checked)}
        />
      ),
    },
    {
      field: "name",
      headerName: "Campaign",
      headerAlign: "left",
      headerClassName: "pl-5",
      renderHeader: (params) => {
        return (
          <div className="pl-12 font-semibold">{params.colDef.headerName}</div>
        ); // Apply padding manually
      },
      renderCell: (params) => (
        <Name
          value={params?.value}
          onEdit={() => {
            setIsSingleCampaign(
              objectives?.find((obj) => obj.value == params?.row?.objective)
              // ||
              //   objectives?.consideration?.find(
              //     (obj) => obj.identifier == params?.row?.objective
              //   ) ||
              //   objectives?.conversions?.find(
              //     (obj) => obj.identifier == params?.row?.objective
              //   )
            );
            setCampaignDetails(params?.row);
          }}
          onDelete={() => {
            // deleteCampaign(params?.row?.id,params?.value);
            setOpenDeleteConfirm({ id: params?.row?.id, name: params?.value });
          }}
        />
      ),
      minWidth: 250,
    },

    // {
    //   field: "delivery_status",
    //   headerName: "Delivery",
    //   headerAlign: "center",
    //   minWidth: 200,
    //   renderHeader: (params) => {
    //     return <div className=" font-semibold">{params.colDef.headerName}</div>; // Apply padding manually
    //   },
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params?.value?.map(
    //           (val) =>
    //             deliveryStatus?.find((status) => status?.code == val)
    //               ?.label && (
    //               <div>
    //                 {
    //                   deliveryStatus?.find((status) => status?.code == val)
    //                     ?.label
    //                 }
    //               </div>
    //             )
    //         )}
    //       </div>
    //     );
    //   },
    // },

    {
      field: "account_currency",
      headerName: "Currency",
      headerAlign: "center",
      minWidth: 200,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div>{params?.value}</div>;
      },
    },
    {
      field: "budget_remaining",
      headerName: "Remaining Budget",
      headerAlign: "center",
      minWidth: 200,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div>{params?.value}</div>;
      },
    },
    {
      field: "objective",
      headerName: "Objective",
      headerAlign: "center",
      minWidth: 200,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div>{params?.value}</div>;
      },
    },
    {
      field: "spend",
      headerName: "Spend",
      headerAlign: "center",
      minWidth: 200,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div>{params?.value}</div>;
      },
    },
    {
      field: "clicks",
      headerName: "Clicks",
      headerAlign: "center",
      minWidth: 200,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div>{params?.value}</div>;
      },
    },
    {
      field: "buying_type",
      headerName: "Buying Type",
      headerAlign: "center",
      minWidth: 200,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div>{params?.value}</div>;
      },
    },
  ];

  const filteredCampaigns = compaigns?.filter((row) =>
    searchQuery === ""
      ? true
      : Object.values(row).some(
          (value) =>
            value?.toString()?.toLowerCase() == searchQuery?.toLowerCase()
        )
  );

  return (
    <>
      <div>
        <Box
          className={`closed-datatable ${currentMode}-mode-datatable`}
          sx={{
            // ...DataGridStyles,
            ...DataGridStyles,
            "& .MuiDataGrid-columnHeaders": {
              ...DataGridStyles["& .MuiDataGrid-columnHeaders"],
              backgroundColor: "transparent",
              color: currentMode === "dark" ? "white" : "black",
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
              borderTop: "1px solid rgba(224, 224, 224, 1)",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            },
            "& .MuiButtonBase-root": {
              ...DataGridStyles["& .MuiButtonBase-root"],
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
            },
            "& .MuiDataGrid-virtualScroller": {
              ...DataGridStyles["& .MuiDataGrid-virtualScroller"],
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
            },
            "& .MuiDataGrid-cell": {
              borderRight: "1px solid #e5e7eb",
            },
            "& .MuiDataGrid-columnHeader": {
              borderRight: "1px solid #e5e7eb",
            },
            ".MuiDataGrid-columnHeaders": {
              paddingLeft: "3px", // Apply padding here
            },
            position: "relative",
            marginBottom: "50px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <DataGrid
            // rows={compaigns}
            rows={filteredCampaigns}
            columns={columns}
            loading={loading}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            selectionModel={selectedRows?.campaigns}
            onSelectionModelChange={(rows) => {
              setSelectedRows((pre) => ({ ...pre, campaigns: rows }));
              console.log(rows, "rows");
            }}
          />
        </Box>
      </div>
      <DeleteConfirm
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        message={` Are you sure you want to delete campaign ${openDeleteConfirm?.name}? `}
        onDelete={deleteCampaign}
        title="Confirm Delete Campaign"
      />
    </>
  );
};

export default CompaignsTable;

const Name = ({ value, onEdit, onDelete }) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const { currentMode } = useStateContext();
  return (
    <>
      <div
        className="pl-11 w-full text-left"
        onMouseEnter={() => setIsMouseEnter(true)}
        onMouseLeave={() => setIsMouseEnter(false)}
      >
        <p>{value}</p>
        {isMouseEnter && (
          <div className="flex gap-3">
            <button
              className={`mt-2 ${
                currentMode === "dark"
                  ? "text-white hover:text-gray-600"
                  : "text-gray-600 hover:text-gray-900"
              } hover:text-gray-900`}
              onClick={onEdit}
            >
              Edit
            </button>
            <button
              className={`mt-2 ${
                currentMode === "dark"
                  ? "text-white hover:text-gray-600"
                  : "text-gray-600 hover:text-gray-900"
              }  flex gap-2 items-center`}
              onClick={onDelete}
            >
              Delete <MdOutlineDelete size={16} />
            </button>
          </div>
        )}
      </div>
    </>
  );
};
