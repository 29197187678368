import React from "react";

const BackgroundWithBlurBalls = ({ children }) => {
  return (
    <div className="relative ">
      <div className="absolute w-full h-full z-[1] inset-0 bg-[#0a1b15] rounded-md">
        <div className="w-52 h-52 rounded-full bg-gray-200"></div>
        <div className="w-[300px] h-[300px] rounded-full bg-white right-[10%] absolute top-[15%]"></div>
      </div>

      <div className="w-full h-full z-[10] relative backdrop-blur-[100px] rounded-md">
        {children}
      </div>
    </div>
  );
};

export default BackgroundWithBlurBalls;
