import React, { useEffect, useState } from "react";
import { BsMegaphone } from "react-icons/bs";
import { MdAdd, MdFilterList } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { PiGridFourLight, PiSquaresFour } from "react-icons/pi";
import { LuRectangleVertical } from "react-icons/lu";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { FaRegFolder } from "react-icons/fa";
import CreateCampaignModal from "./CreateCampaign";
import SingleCampaign from "./SingleCampaign";
import CompaignsTable from "./CompaignsTable";
import AdSetsTable from "./adSetTable";
import AdsTable from "./AdsTable";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";

import SingleAdSet from "./SingleAdSet";
import CreateAdSetModal from "./createAdSet";
import SingleAd from "./singleAd";
import CreateAdModal from "./createAd";
import SearchBar from "./search";
import { HiMiniViewColumns } from "react-icons/hi2";
import { CircularProgress, Menu } from "@mui/material";
import { TiArrowSortedDown } from "react-icons/ti";
import { IoAdd } from "react-icons/io5";
import { IoIosImages } from "react-icons/io";
import ReportCreateUI from "./Report";
import Filters from "./filters";
import { MdOutlineCampaign } from "react-icons/md";
import { BsCart4 } from "react-icons/bs";
import { PiCursorFill } from "react-icons/pi";
import { IoFilterOutline } from "react-icons/io5";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";

const token = localStorage?.getItem("auth-token");
const fb_token =
  "EAAPVZATIw8YIBO3wETrXvkWSUI6581s2R9hUVdqzKtXH8O4J6WkZAzntHqOlJcNj4KX2cAxJhTcREIQRJrAuZBj8H1U3eSgmrmfrHdcZA4O838gUApiZBGK7baoMEeWzY27hHioisaMrtNiEhasrz4OUmWDB9pUVJuIXYm0mzvGmxu9zQ5m5wqJHzOZAC9XatFJVZCP07ffub7rA7QE6wZDZD";
const objectives = [
  {
    label: "Awareness",
    icon: <MdOutlineCampaign size={16} />,
    banner:
      "https://release-manager.sc-cdn.net/218e1a0c4b913ae4d17c6404d31ae915.svg",
    value: "OUTCOME_AWARENESS",
    type: "reserved",
    content: {
      para: "Show your ads to people who are most likely to remember them.",
      Outcomes: {
        label: "good for Awareness & Engagement objective.",
        points: [
          "Reach",
          "Brand Awarenes",
          "Video Views",
          "Store location awareness",
          "Ad Engagement",
          "Calls & Texts",
          "Place Promotion",
        ],
      },
      AdFormats: {
        label: " available for Awareness & Engagement objective.",
        points: [
          "Signle Image or Video",
          "Story Ad",
          "Collection Ad",
          "AR Ads",
          "Commercial",
        ],
      },
    },
  },
  {
    label: "Traffic",
    icon: <PiCursorFill size={16} />,
    banner: "/assets/website_traffic.png",
    value: "OUTCOME_TRAFFIC",
    content: {
      para: "Send people to a destination, such as your website, app, Instagram profile or Facebook event",
      Outcomes: {
        label: "good for Traffic objective.",
        points: [
          "Link Clicks",
          "Landing Page Views",
          "Instagram profile visits",
          "Messenger, Instagram and WhatsApp",
          "Calls",
        ],
      },
    },
  },
  {
    label: "Engagement",
    icon: <PiCursorFill size={16} />,
    banner: "/assets/engagement.png",
    value: "OUTCOME_ENGAGEMENT",
    type: "reserved",
    content: {
      para: "Get more messages, purchases through messaging, video views, post engagement, Page likes or event responses.",
      Outcomes: {
        points: [
          "Messenger, Instagram and WhatsApp",
          "Video views",
          "Post engagement",
          "Conversions",
          "Calls",
        ],
      },
    },
  },
  {
    label: "Leads",
    icon: <IoFilterOutline size={16} />,
    banner: "/assets/leads.png",
    value: "OUTCOME_LEADS",
    content: {
      para: "Collect leads for your business or brand.",
      Outcomes: {
        points: [
          "Instant Forms",
          "Messenger and Instagram",
          "Conversions",
          "Calls",
        ],
      },
    },
  },
  {
    label: "App promotion",
    icon: <HiOutlineDevicePhoneMobile size={16} />,
    banner: "/assets/app_promotion.png",
    value: "OUTCOME_APP_PROMOTION",
    content: {
      para: "Find new people to install your app and continue using it",
      Outcomes: {
        points: ["App installs", "App events"],
      },
    },
  },
  {
    label: "Sales",
    icon: <BsCart4 size={16} />,
    banner: "/assets/sales.png",
    value: "OUTCOME_SALES",
    content: {
      para: "Find people who are likely to purchase your product or service",
      Outcomes: {
        points: [
          "Conversions",
          "Catalogue sales",
          "Messenger, Instagram and WhatsApp",
          "Calls",
        ],
      },
    },
  },
];

const oldObjectives = {
  awareness: [
    {
      label: "Awareness",
      banner:
        "https://release-manager.sc-cdn.net/218e1a0c4b913ae4d17c6404d31ae915.svg",
      content: {
        para: "Reach Snapchatters and create interest in your brand",
      },
      identifier: "BRAND_AWARENESS",
    },
    {
      label: "Promote Places",
      banner: "/assets/promote_places.png",
      content: {
        para: "Promote your places to Snapchatters",
      },
      identifier: "PROMOTE_PLACES",
    },
  ],
  consideration: [
    {
      label: "App Installs",
      banner: "/assets/app_installs.png",
      content: {
        para: "Send Snapchatters to the app store to download your app",
      },
      identifier: "APP_INSTALL",
    },
    {
      label: "Drive Traffic to Website",

      banner: "/assets/website_traffic.png",
      content: {
        para: "Send Snapchatters directly to your website",
      },
      identifier: "WEB_VIEW",
    },
    {
      label: "Drive Traffic to App",
      banner: "/assets/app_traffic.png",
      content: {
        para: "Send Snapchatters to your app or a third-party app",
      },
      identifier: "APP_REENGAGEMENT",
    },
    {
      label: "Engagement",

      banner: "/assets/engagement.png",
      content: {
        para: "Get more Snapchatters to engage with your ad",
      },
      identifier: "ENGAGEMENT",
    },
    {
      label: "Video Views",

      banner: "/assets/video_views.png",
      content: {
        para: "Promote your brand or product to Snapchatters through video. Learn more.",
      },
      identifier: "VIDEO_VIEW",
    },
    {
      label: "Lead Generation",

      banner: "/assets/leads.png",
      content: {
        para: "Generate leads for your business",
      },
      identifier: "LEAD_GENERATION",
    },
  ],
  conversions: [
    {
      label: "App Conversions",

      banner: "/assets/app_promotion.png",
      content: {
        para: "Drive specific actions within your app",
      },
      identifier: "APP_CONVERSION",
    },
    {
      label: "Website Conversions",

      banner: "/assets/app_promotion.png",
      content: {
        para: "Drive specific actions on your website",
      },
      identifier: "WEB_CONVERSION",
    },
  ],
};

const Compaigns = () => {
  const {
    currentMode,
    themeBgImg,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    fbCredentials,
    setFbCredentials,
  } = useStateContext();
  const [currentTable, setCurrentTable] = useState("campaigns");
  const [isCampaignModal, setIsCampaignModal] = useState(false);
  const [isSingleCampaign, setIsSingleCampaign] = useState(false);
  console.log("is single camp: ", isSingleCampaign);
  const [campaignDetails, setCampaignDetails] = useState(null);
  console.log("camp details: ", campaignDetails);
  const [isSingleAdSet, setIsSingleAdSet] = useState(false);
  const [isSingleAd, setIsSingleAd] = useState(false);
  const [createAdSetModal, setCreateAdSetModal] = useState(false);
  const [adSetDetails, setAdSetDetails] = useState(null);
  const [adDetails, setAdDetails] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [adSets, setAdSets] = useState([]);
  const [ads, setAds] = useState([]);
  const [createAdModal, setCreateAdModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountLoading, setAccountLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState({
    campaigns: [],
    adSets: [],
  });
  const [filterEl, setFilterEl] = useState(null);
  const [query, setQuery] = useState("");
  const [columnsEl, setColumnsEl] = useState(null);
  const [breakDownEl, setBreakDownEl] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState("Video engagement");

  const [campData, setCampData] = useState({
    buying_type: "AUCTION",
    objective: "",
    name: "",
    special_ad_categories: "",
    status: "PAUSED",
    bid_strategy: "LOWEST_COST_WITHOUT_CAP",
    spend_cap: "",
    lifetime_budget: "",
    daily_budget: "",
    budget_schedule_specs: "",
    start_time: "",
    end_time: "",
  });
  console.log("campData: ", campData);

  const [singleCampData, setSingleCampData] = useState(null);
  console.log("single camp data:: ", singleCampData);
  const [adsetData, setAdsetData] = useState({
    campaign_id: "",
    name: "",
    daily_budget: "",
    bid_amount: "",
    billing_event: "",
    optimization_goal: "",
    daily_min_spend_target: "",
    start_time: "",
    end_time: "",
    status: "PAUSED",
    targeting: {},
  });

  console.log("adset: ", adsetData);

  const [adData, setAdData] = useState({
    campaign_id: "",
    name: "",
    status: "PAUSED",
    creative: {
      degrees_of_freedom_spec: {
        creative_features_spec: {
          standard_enhancements: {
            enroll_status: "OPT_OUT",
          },
        },
      },
    },
  });

  console.log("adData: ", adData);

  const getAccountDetails = async () => {
    setAccountLoading(true);
    toast.success(`${"Fetching ad account details...."}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    window.FB.api(
      `/${fbCredentials?.currentAdAccount}`,
      "GET",
      {
        // access_token: fbCredentials?.access_token,
        access_token: fbCredentials?.access_token,
        fields: "id,name,currency",
      },
      (response) => {
        if (!response || response?.error) {
          setAccountLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error
                ? response.error.message
                : "Error fetching ad account"
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          console.log("ad account details: ", response);
          const accountData = response;
          setFbCredentials({
            ...fbCredentials,
            id: accountData?.id,
            name: accountData?.name,
            currency: accountData?.currency,
          });

          setAccountLoading(false);
        }
      }
    );
  };
  const getAllCompaigns = async () => {
    setLoading(true);
    window.FB.api(
      `/${fbCredentials?.currentAdAccount}/campaigns`,
      "GET",
      {
        // access_token: fbCredentials?.access_token,
        access_token: fbCredentials?.access_token,
        fields:
          // "name,status,effective_status,insights{account_currency,buying_type,clicks,objective,spend},budget_remaining,daily_budget,lifetime_budget,is_budget_schedule_enabled",
          "id,name,insights{cpc,clicks,spend,account_currency,buying_type},status,budget_remaining,objective",
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error
                ? response.error.message
                : "Error fetching campaigns"
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          const campsData = response?.data?.map((camps) => ({
            id: camps?.id,
            status: camps?.status,
            name: camps?.name || "-",
            account_currency: camps?.insights?.data[0]?.account_currency || "-",
            budget_remaining: camps?.budget_remaining || "-",
            daily_budget: camps?.daily_budget || "-",
            lifetime_budget: camps?.lifetime_budget || "-",
            is_budget_schedule_enabled:
              camps?.is_budget_schedule_enabled || "-",
            objective: camps?.objective || "-",
            spend: camps?.insights?.data[0]?.spend || "-",
            clicks: camps?.insights?.data[0]?.clicks || "-",
            buying_type: camps?.insights?.data[0]?.buying_type || "-",
            optimization_goal:
              camps?.insights?.data[0]?.optimization_goal || "-",
          }));
          setCampaigns(campsData);
          setLoading(false);
        }
      }
    );
  };

  const getAllAdSets = async () => {
    setLoading(true);

    window.FB.api(
      `/${fbCredentials?.currentAdAccount}/adsets`,
      "GET",
      {
        access_token: fbCredentials?.access_token,
        // access_token: fb_token,
        fields:
          "id,name,status,insights{objective,optimization_goal,reach,spend,impressions,clicks,account_currency,cpc},bid_amount,bid_strategy,budget_remaining,daily_budget,lifetime_budget",
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error ? response.error.message : "Error fetching Adsets"
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          const adsetData = response?.data?.map((adset) => ({
            id: adset?.id,
            status: adset?.status,
            name: adset?.name || "-",
            budget_remaining: adset?.budget_remaining || "-",
            bid_strategy: adset?.bid_strategy || "-",
            daily_budget: adset?.daily_budget || "-",
            lifetime_budget: adset?.lifetime_budget || "-",
            clicks: adset?.insights?.data[0]?.clicks || "-",
            cpc: adset?.insights?.data[0]?.cpc || "-",
            spend: adset?.insights?.data[0]?.spend || "-",
            reach: adset?.insights?.data[0]?.reach || "-",
            impressions: adset?.insights?.data[0]?.impressions || "-",
            account_currency: adset?.insights?.data[0]?.account_currency || "-",
            optimization_goal:
              adset?.insights?.data[0]?.optimization_goal || "-",
          }));
          setAdSets(adsetData);
          setLoading(false);
        }
      }
    );
  };

  const getAllAds = async () => {
    setLoading(true);

    window.FB.api(
      `/${fbCredentials?.currentAdAccount}/ads`,
      "GET",
      {
        access_token: fbCredentials?.access_token,
        // access_token: fb_token,
        fields: [
          "id",
          "name",
          "status",
          "effective_status",
          "insights{impressions,spend,cpc,reach,optimization_goal}",
        ].join(","),
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${response.error ? response.error.message : "Error fetching Ads"}`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          if (response?.data) {
            const adsData = response?.data?.map((ad) => ({
              id: ad?.id,
              status: ad?.status,
              name: ad?.name || "-",
              cpc: ad?.insights?.data[0]?.cpc || "-",
              spend: ad?.insights?.data[0]?.spend || "-",
              reach: ad?.insights?.data[0]?.reach || "-",
              impressions: ad?.insights?.data[0]?.impressions || "-",
              optimization_goal:
                ad?.insights?.data[0]?.optimization_goal || "-",
            }));
            setAds(adsData);
            setLoading(false);
          }
          setLoading(false);
        }
      }
    );
  };

  async function getAllAdSetsUnderCampaign(id) {
    console.log("single camp id: ", id);
    setLoading(true);

    window.FB.api(
      `/${id}/adsets`,
      "GET",
      {
        access_token: fbCredentials?.access_token,
        // access_token: fb_token,
        fields:
          "id,name,status,insights{objective,optimization_goal,reach,spend,impressions,clicks,account_currency,cpc},bid_amount,bid_strategy,budget_remaining,daily_budget,lifetime_budget",
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${response.error ? response.error.message : "Error fetching Ads"}`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          console.log("adsets list : ", response);
          // if (response?.data) {
          const adsetData = response?.data?.map((adset) => ({
            id: adset?.id,
            status: adset?.status,
            name: adset?.name || "-",
            budget_remaining: adset?.budget_remaining || "-",
            bid_strategy: adset?.bid_strategy || "-",
            daily_budget: adset?.daily_budget || "-",
            lifetime_budget: adset?.lifetime_budget || "-",
            clicks: adset?.insights?.data[0]?.clicks || "-",
            cpc: adset?.insights?.data[0]?.cpc || "-",
            spend: adset?.insights?.data[0]?.spend || "-",
            reach: adset?.insights?.data[0]?.reach || "-",
            impressions: adset?.insights?.data[0]?.impressions || "-",
            account_currency: adset?.insights?.data[0]?.account_currency || "-",
            optimization_goal:
              adset?.insights?.data[0]?.optimization_goal || "-",
          }));
          setAdSets(adsetData);
          setLoading(false);
          // }
          setLoading(false);
        }
      }
    );
  }

  async function getAllAdsUnderCampaign(id) {
    setLoading(true);

    window.FB.api(
      `/${id}/ads`,
      "GET",
      {
        access_token: fbCredentials?.access_token,
        // access_token: fb_token,
        fields: [
          "id",
          "name",
          "status",
          "effective_status",
          "insights{impressions,spend,cpc,reach,optimization_goal}",
        ].join(","),
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error
                ? response.error.message
                : "Error fetching relevant ads."
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          if (response?.data) {
            const adsData = response?.data?.map((ad) => ({
              id: ad?.id,
              status: ad?.status,
              name: ad?.name || "-",
              cpc: ad?.insights?.data[0]?.cpc || "-",
              spend: ad?.insights?.data[0]?.spend || "-",
              reach: ad?.insights?.data[0]?.reach || "-",
              impressions: ad?.insights?.data[0]?.impressions || "-",
              optimization_goal:
                ad?.insights?.data[0]?.optimization_goal || "-",
            }));
            setAds(adsData);
            setLoading(false);
          }
          setLoading(false);
        }
      }
    );
  }

  async function getAllAdsUnderAdSet(id) {
    setLoading(true);

    window.FB.api(
      `/${id}/ads`,
      "GET",
      {
        access_token: fbCredentials?.access_token,
        // access_token: fb_token,
        fields: [
          "id",
          "name",
          "status",
          "effective_status",
          "insights{impressions,spend,cpc,reach,optimization_goal}",
        ].join(","),
        effective_status: [
          "ACTIVE",
          "PAUSED",
          "ARCHIVED",
          "IN_PROCESS",
          "WITH_ISSUES",
          "PENDING_REVIEW",
          "PREAPPROVED",
          "PENDING_BILLING_INFO",
          "CAMPAIGN_PAUSED",
          "ADSET_PAUSED",
        ],
      },
      (response) => {
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error
                ? response.error.message
                : "Error fetching relevant ads."
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          if (response?.data) {
            const adsData = response?.data?.map((ad) => ({
              id: ad?.id,
              status: ad?.status,
              name: ad?.name || "-",
              cpc: ad?.insights?.data[0]?.cpc || "-",
              spend: ad?.insights?.data[0]?.spend || "-",
              reach: ad?.insights?.data[0]?.reach || "-",
              impressions: ad?.insights?.data[0]?.impressions || "-",
              optimization_goal:
                ad?.insights?.data[0]?.optimization_goal || "-",
            }));
            setAds(adsData);
            setLoading(false);
          }
          setLoading(false);
        }
      }
    );
  }

  async function createCampaign() {
    setBtnLoading(true);

    const filteredCampData = Object.entries(campData).filter(
      ([key, value]) => value !== "" && value !== null && value !== undefined
    );

    console.log("filtered camp params: ", filteredCampData);

    try {
      const response = await new Promise((resolve, reject) => {
        window.FB.api(
          `/${fbCredentials?.currentAdAccount}/campaigns`,
          "POST",
          {
            access_token: fbCredentials?.access_token,
            // access_token: fb_token,
            ...campData,
          },
          (response) => {
            console.log("error in creating campaing: ", response);
            if (!response || response.error) {
              reject(
                response?.error?.error_user_msg ||
                  response?.error?.message ||
                  new Error("Unknown error occurred")
              );
            } else {
              resolve(response);
            }
          }
        );
      });

      console.log("Campaign created successfully:", response);

      toast.success("Campaign created successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setBtnLoading(false);
      setIsSingleCampaign(false);
      getAllCompaigns();
      return response; // Return the campaign details
    } catch (error) {
      setBtnLoading(false);

      console.error("Error creating campaign:", error);
      toast.error(
        `Error creating campaign: ${error || "Unknown error occurred"}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

      throw error; // Handle the error in the calling function
    }
  }

  async function createAdSet() {
    setBtnLoading(true);

    // Filter out empty or invalid values
    const filteredAdsetData = Object.fromEntries(
      Object.entries(adsetData).filter(
        ([key, value]) => value !== "" && value !== null && value !== undefined
      )
    );

    console.log("Filtered ad set params:", filteredAdsetData);

    try {
      const response = await new Promise((resolve, reject) => {
        window.FB.api(
          `/${fbCredentials?.currentAdAccount}/adsets`,
          "POST",
          {
            access_token: fbCredentials?.access_token,
            // access_token: fb_token,
            ...filteredAdsetData,
          },
          (response) => {
            if (!response || response.error) {
              console.error("Error in adset response:", response.error);
              reject(
                response?.error?.error_user_msg ||
                  new Error("Unknown error occurred")
              );
            } else {
              resolve(response);
            }
          }
        );
      });

      console.log("Ad set created successfully:", response);

      // Show success toast
      toast.success("Ad set created successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setBtnLoading(false);
      setIsSingleAdSet(false);
      setAdsetData({
        campaign_id: "",
        name: "",
        daily_budget: "",
        bid_amount: "",
        billing_event: "",
        optimization_goal: "",
        daily_min_spend_target: "",
        start_time: "",
        end_time: "",
        status: "PAUSED",
        targeting: {},
      });
      getAllAdSets();
      return response; // Return the ad set details
    } catch (error) {
      setBtnLoading(false);

      console.error("Error creating ad set:", error);

      // Show error toast
      toast.error(
        `Error creating ad set: ${
          error?.error_user_msg ||
          error?.message ||
          error ||
          "Unknown error occurred"
        }`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

      throw error; // Propagate the error for further handling if needed
    }
  }

  async function createAd() {
    setBtnLoading(true);

    // Filter out empty or invalid values
    const filteredAdData = Object.fromEntries(
      Object.entries(adData).filter(
        ([key, value]) => value !== "" && value !== null && value !== undefined
      )
    );

    console.log("Filtered ad set params:", filteredAdData);

    try {
      const response = await new Promise((resolve, reject) => {
        window.FB.api(
          `/${fbCredentials?.currentAdAccount}/ads`,
          "POST",
          {
            access_token: fbCredentials?.access_token,
            // access_token: fb_token,
            ...filteredAdData,
          },
          (response) => {
            if (!response || response.error) {
              console.error("Error in ad response:", response.error);
              reject(
                response?.error?.error_user_msg ||
                  response?.error?.message ||
                  new Error("Unknown error occurred")
              );
            } else {
              resolve(response);
            }
          }
        );
      });

      console.log("Ad  created successfully:", response);

      // Show success toast
      toast.success("Ad created successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAdData({});

      setBtnLoading(false);
      setIsSingleAd(false);
      getAllAds();
      return response; // Return the ad set details
    } catch (error) {
      setBtnLoading(false);

      console.error("Error creating ad :", error);

      // Show error toast
      toast.error(
        `Error creating ad : ${
          error?.error_user_msg ||
          error?.message ||
          error ||
          "Unknown error occurred"
        }`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

      throw error; // Propagate the error for further handling if needed
    }
  }

  useEffect(() => {
    if (fbCredentials?.access_token && !fbCredentials?.id) {
      getAccountDetails();
    }
  }, []);

  useEffect(() => {
    if (fbCredentials?.access_token) {
      getAllCompaigns();
    }
  }, [fbCredentials]);

  useEffect(() => {
    if (currentTable === "adSets" && selectedRows?.campaigns?.length > 0) {
      fetchAdSetsOfDifferentCampaings();
    } else if (fbCredentials?.access_token && currentTable === "adSets") {
      getAllAdSets();
    } else if (currentTable === "ads" && selectedRows?.adSets?.length > 0) {
      fetchAdsOfDifferentAdSets();
    } else if (currentTable === "ads" && selectedRows?.campaigns?.length > 0) {
      fetchAdsOfDifferentCampaings();
    } else if (fbCredentials?.access_token && currentTable === "ads") {
      getAllAds();
    }
  }, [currentTable]);

  async function fetchAdSetsOfDifferentCampaings() {
    try {
      const adSetsPromises = selectedRows?.campaigns?.map(async (id) => {
        console.log("selected camp id: ", id);
        try {
          return await getAllAdSetsUnderCampaign(id);
        } catch (error) {
          console.log(error);
          toast.error("Error fetching relevant adsets", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return [];
        }
      });

      const adSets = await Promise.all(adSetsPromises);
      // setAdSets(adSets.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
      toast.error("Error fetching relevant adsets", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  async function fetchAdsOfDifferentCampaings() {
    try {
      const adsPromises = selectedRows?.campaigns?.map(async (id) => {
        try {
          return await getAllAdsUnderCampaign(id);
        } catch (error) {
          console.log(error);
          return [];
        }
      });

      const ads = await Promise.all(adsPromises);
      // setAds(ads.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
    }
  }
  async function fetchAdsOfDifferentAdSets() {
    try {
      const adsPromises = selectedRows?.adSets?.map(async (id) => {
        try {
          return await getAllAdsUnderAdSet(id);
        } catch (error) {
          console.log(error);
          return [];
        }
      });

      const ads = await Promise.all(adsPromises);
      // setAds(ads.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
    }
  }
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  function selectedCampaignsRemove() {
    setSelectedRows((pre) => ({ ...pre, campaigns: [] }));
  }
  function selectedAdSetsRemove() {
    setSelectedRows((pre) => ({ ...pre, adSets: [] }));
  }

  const columnsTypes = [
    { label: "Video engagement", value: "Video engagement" },
    { label: "Delivery", value: "Delivery" },
    { label: "Performance", value: "Performance" },
    { label: "Setup", value: "Setup" },
    { label: "Engagement", value: "Engagement" },
    { label: "Performance and clicks", value: "Performance and clicks" },
  ];

  return (
    <>
      {/* <ReportCreateUI /> */}
      <div className={`px-6 ${getSummaryBgClass()}`}>
        {/* <div className="flex gap-1 py-9">
       
          <div
            className={`flex items-center  gap-8 py-6  px-5  ${getSummaryBgClass()} flex-1 rounded-md shadow-md`}
          >
            <div>
              <FiSearch size={16} />
            </div>
            <input
              type="text"
              className={`focus:outline-none flex-1 ${getSummaryBgClass()}`}
              placeholder="Search name or ID"
            />
          </div>
        </div> */}
        {accountLoading ? (
          <div className="h-[500px] w-full flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="shadow-lg">
            <div
              className={`flex flex-col ${
                currentMode === "dark" ? "" : "bg-[#F6F0F0]"
              } justify-between  pt-5 px-5 border-b  border-b-gray-400 ${
                currentMode === "dark"
                  ? ""
                  : "bg-gradient-to-br from-[#F3EEF0] to-[#E7F0F9]"
              } ${getSummaryBgClass()}`}
            >
              {/* <SearchBar
              query={query}
              setQuery={setQuery}
              filterEl={filterEl}
              setFilterEl={setFilterEl}
            /> */}
              {/* <Filters filterEl={filterEl} setFilterEl={setFilterEl} /> */}

              <div className="mb-5 text-right">
                <h3>
                  Account Name:{" "}
                  <span className="font-bold">{fbCredentials?.name}</span>
                </h3>
              </div>

              <div className="flex gap-4 items-end select-none">
                <div
                  onClick={() => setCurrentTable("campaigns")}
                  className={`flex  items-center  ${
                    currentMode === "dark"
                      ? currentTable === "campaigns"
                        ? "bg-dark-neu"
                        : "bg-dark"
                      : currentTable === "campaigns"
                      ? "bg-[#FFFFFF]"
                      : "bg-[rgba(255,255,255,0.6)]"
                  } rounded-t-lg py-3 ${
                    currentTable === "campaigns"
                      ? "h-[95%] text-[#0A78BE]"
                      : "h-[80%]"
                  }  px-3 min-w-[220px] cursor-pointer justify-between`}
                >
                  <div className="flex items-center gap-3">
                    {/* <FaRegFolder size={18} /> */}
                    {currentTable === "campaigns" ? (
                      <svg
                        viewBox="0 0 48 48"
                        width="2em"
                        height="2em"
                        fill="currentColor"
                        class="x1qsmy5i xxk0z11 xvy4d1p"
                      >
                        <path d="M40.5 10H23.74c-1.08 0-2.03-.69-2.37-1.71s-.18-.53-.18-.53A5.496 5.496 0 0 0 15.97 4H6.5C4.02 4 2 6.02 2 8.5v30C2 41.53 4.47 44 7.5 44h33c3.03 0 5.5-2.47 5.5-5.5v-23c0-3.03-2.47-5.5-5.5-5.5zm-9.83 23.73c-.2.18-.46.27-.72.27-.17 0-.35-.04-.51-.13L24 30.98l-5.44 2.89c-.4.21-.89.15-1.23-.14a.98.98 0 0 1-.23-1.16l5.95-12c.17-.35.54-.57.95-.57s.77.22.95.57l5.95 12c.19.39.1.86-.23 1.16z"></path>
                      </svg>
                    ) : (
                      <svg
                        viewBox="0 0 48 48"
                        width="1.5em"
                        height="1.5em"
                        fill="currentColor"
                        class="x4s1yf2 x1qx5ct2 xw4jnvo"
                      >
                        <path
                          d="m19.95 8.76-.18-.53a4 4 0 0 0-3.79-2.74H6.5c-1.66 0-3 1.34-3 3v30c0 2.21 1.79 4 4 4h33c2.21 0 4-1.79 4-4V15.5c0-2.21-1.79-4-4-4H23.74c-1.72 0-3.25-1.1-3.79-2.74z"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3px"
                          fill="none"
                        ></path>
                        <path d="m30.9 32.57-5.95-12c-.17-.35-.54-.57-.95-.57s-.77.22-.95.57l-5.95 12c-.19.39-.1.86.23 1.16.33.3.83.36 1.23.14L24 30.98l5.44 2.89a1.075 1.075 0 0 0 1.23-.14c.33-.3.42-.76.23-1.16z"></path>
                      </svg>
                    )}
                    <span
                      className={`font-medium text-[14px]  ${
                        currentTable === "campaigns" ? "text-[#0A78BE]" : ""
                      }`}
                    >
                      Campaigns
                    </span>
                  </div>

                  {selectedRows?.campaigns?.length > 0 && (
                    <div
                      onClick={selectedCampaignsRemove}
                      className="rounded-[5px] py-1 px-2 text-white bg-[#0A78BE] border flex items-center justify-between"
                    >
                      {selectedRows?.campaigns?.length} Selected
                      <IoMdClose size={16} />
                    </div>
                  )}
                </div>
                <div
                  onClick={() => setCurrentTable("adSets")}
                  // className={`flex gap-3 items-center  bg-[#FFFFFF] rounded-t-lg py-3 px-3 min-w-[220px] cursor-pointer border-b-[3px]`}
                  className={`flex justify-between items-center gap-3 ${
                    currentMode === "dark"
                      ? currentTable === "adSets"
                        ? "bg-dark-neu"
                        : "bg-dark"
                      : currentTable === "adSets"
                      ? "bg-[#FFFFFF]"
                      : "bg-[rgba(255,255,255,0.6)]"
                  } rounded-t-lg py-3 ${
                    currentTable === "adSets"
                      ? "h-[95%] text-[#0A78BE]"
                      : "h-[80%]"
                  }  px-3 min-w-[220px] justify-between cursor-pointer`}
                >
                  <div className="flex items-center gap-3">
                    {/* <PiSquaresFour size={18} /> */}
                    {currentTable === "adSets" ? (
                      <svg
                        viewBox="0 0 48 48"
                        width="2em"
                        height="2em"
                        fill="currentColor"
                        class="x1qsmy5i xxk0z11 xvy4d1p"
                      >
                        <rect
                          x="26"
                          y="2"
                          width="20"
                          height="20"
                          rx="4.5"
                          ry="4.5"
                        ></rect>
                        <rect
                          x="2"
                          y="26"
                          width="20"
                          height="20"
                          rx="4.5"
                          ry="4.5"
                        ></rect>
                        <path d="M17.5 2h-11C4.02 2 2 4.02 2 6.5v11C2 19.98 4.02 22 6.5 22h11c2.48 0 4.5-2.02 4.5-4.5v-11C22 4.02 19.98 2 17.5 2zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zM41.5 26h-11c-2.48 0-4.5 2.02-4.5 4.5v11c0 2.48 2.02 4.5 4.5 4.5h11c2.48 0 4.5-2.02 4.5-4.5v-11c0-2.48-2.02-4.5-4.5-4.5zM36 40c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"></path>
                      </svg>
                    ) : (
                      <svg
                        viewBox="0 0 48 48"
                        width="1.5em"
                        height="1.5em"
                        fill="currentColor"
                        class="x1qsmy5i xxk0z11 xvy4d1p"
                      >
                        <rect
                          x="26"
                          y="2"
                          width="20"
                          height="20"
                          rx="4.5"
                          ry="4.5"
                        ></rect>
                        <rect
                          x="2"
                          y="26"
                          width="20"
                          height="20"
                          rx="4.5"
                          ry="4.5"
                        ></rect>
                        <path d="M17.5 2h-11C4.02 2 2 4.02 2 6.5v11C2 19.98 4.02 22 6.5 22h11c2.48 0 4.5-2.02 4.5-4.5v-11C22 4.02 19.98 2 17.5 2zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zM41.5 26h-11c-2.48 0-4.5 2.02-4.5 4.5v11c0 2.48 2.02 4.5 4.5 4.5h11c2.48 0 4.5-2.02 4.5-4.5v-11c0-2.48-2.02-4.5-4.5-4.5zM36 40c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"></path>
                      </svg>
                    )}
                    <span
                      className={`font-medium text-[14px] ${
                        currentTable === "adSets" ? "text-[#0A78BE]" : ""
                      }`}
                    >
                      Ad set{" "}
                      {selectedRows?.campaigns?.length > 0 && (
                        <>for {selectedRows?.campaigns?.length} Campaigns</>
                      )}
                    </span>
                  </div>
                  {selectedRows?.adSets?.length > 0 && (
                    <div
                      onClick={selectedAdSetsRemove}
                      className="rounded-[5px] px-2 py-1 text-white bg-[#0A78BE] border flex items-center justify-center "
                    >
                      {selectedRows?.adSets?.length} Selected
                      <IoMdClose size={16} />
                    </div>
                  )}
                </div>
                <div
                  onClick={() => setCurrentTable("ads")}
                  className={`flex gap-3 items-center  ${
                    currentMode === "dark"
                      ? currentTable === "ads"
                        ? "bg-dark-neu"
                        : "bg-dark"
                      : currentTable === "ads"
                      ? "bg-[#FFFFFF]"
                      : "bg-[rgba(255,255,255,0.6)]"
                  } rounded-t-lg py-3 ${
                    currentTable === "ads"
                      ? "h-[95%] text-[#0A78BE]"
                      : "h-[80%]"
                  }  px-3 min-w-[220px] cursor-pointer `}
                >
                  {/* <LuRectangleVertical size={18} /> */}
                  {currentTable === "ads" ? (
                    <svg
                      viewBox="0 0 16 16"
                      width="2em"
                      height="2em"
                      fill="currentColor"
                      class="x1qsmy5i xxk0z11 xvy4d1p"
                    >
                      <g data-name="Layer 2">
                        <path
                          d="M13.25 1H2.75A1.76 1.76 0 0 0 1 2.75v10.5A1.76 1.76 0 0 0 2.75 15h10.5A1.76 1.76 0 0 0 15 13.25V2.75A1.76 1.76 0 0 0 13.25 1zM4.5 5.5a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm8-.5h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1z"
                          data-name="16"
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      viewBox="0 0 16 16"
                      width="1.5em"
                      height="1.5em"
                      fill="currentColor"
                      class="x4s1yf2 x1qx5ct2 xw4jnvo"
                    >
                      <g data-name="Layer 2">
                        <g data-name="16">
                          <rect
                            x="1.5"
                            y="1.5"
                            width="13"
                            height="13"
                            rx="1.25"
                            stroke="currentColor"
                            fill="none"
                          ></rect>
                          <circle cx="4.5" cy="4.5" r="1"></circle>
                          <path
                            stroke-linecap="round"
                            stroke="currentColor"
                            fill="none"
                            d="M7.5 4.5 12.5 4.5"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  )}
                  <div>
                    <span
                      className={`font-medium text-[14px] ${
                        currentTable === "ads" ? "text-[#0A78BE]" : ""
                      }`}
                    >
                      {" "}
                      Ads{" "}
                      {selectedRows?.adSets?.length > 0 ? (
                        <>for {selectedRows?.adSets?.length} ad set</>
                      ) : (
                        selectedRows?.campaigns?.length > 0 && (
                          <>for {selectedRows?.campaigns?.length} Campaigns</>
                        )
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`flex gap-3 items-center justify-between ${getSummaryBgClass()} py-3 px-3`}
              >
                <>
                  <button
                    onClick={() => {
                      if (currentTable == "campaigns") {
                        setIsCampaignModal(true);
                      }
                      if (currentTable == "adSets") {
                        setCreateAdSetModal(true);
                        setAdDetails(null);
                      }
                      if (currentTable == "ads") {
                        setCreateAdModal(true);
                      }
                    }}
                    className="flex gap-2 items-center rounded-lg px-5 bg-[#006B4E] text-white p-3"
                  >
                    <MdAdd size={20} />
                    Create
                  </button>
                  {/* <div className="flex gap-3 items-center">
                  <div>
                    <button
                      onClick={(e) => setColumnsEl(e?.currentTarget)}
                      className="flex items-center px-5 gap-3 p-3 hover:bg-[#EDF0F2] border-[1px] border-gray-400 rounded-lg"
                    >
                      <HiMiniViewColumns size={18} />{" "}
                      <span className="text-[14px]">Columns</span>
                      <TiArrowSortedDown size={18} />
                    </button>
                    <Menu
                      id="basic-menu"
                      anchorEl={columnsEl}
                      open={Boolean(columnsEl)}
                      onClose={() => setColumnsEl(null)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      sx={{
                        "& .MuiList-root": {
                          padding: "0px !important", // Modify border radius
                        },
                        padding: "0px",
                      }}
                    >
                      <div className="px-2 py-3">
                        <label
                          htmlFor=""
                          className="text-[14px] font-semibold mb-1 pl-3 "
                        >
                          Recently used
                        </label>
                        <ul className="pl-4  px-4">
                          {columnsTypes?.map((objective) => {
                            return (
                              <li
                                className={`flex gap-4 items-center mb-1 group cursor-pointer px-2 py-3 rounded-lg ${
                                  selectedColumn?.label === objective?.label
                                    ? "bg-[#ECF3FF]"
                                    : ""
                                } `}
                                onClick={() => setSelectedColumn(objective)}
                              >
                                {" "}
                                <div
                                  className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                                    selectedColumn?.label === objective?.label
                                      ? "border-[5px] bg-[#ECF3FF]"
                                      : "group-hover:border-[3px]"
                                  }  `}
                                ></div>
                                <div className="flex-1">
                                  <h3
                                    className={`text-[14px] ${
                                      currentMode === "dark"
                                        ? "text-white"
                                        : "text-black"
                                    }`}
                                  >
                                    {objective?.label}
                                  </h3>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Menu>
                  </div>
                  <div>
                    <button
                      onClick={(e) => setBreakDownEl(e?.currentTarget)}
                      className="flex items-center px-5 gap-3 p-3 hover:bg-[#EDF0F2] border-[1px] border-gray-400 rounded-lg"
                    >
                      <span className="text-[14px]">Reports</span>
                      <TiArrowSortedDown size={18} />
                    </button>
                    <Menu
                      id="basic-menu"
                      anchorEl={breakDownEl}
                      open={Boolean(breakDownEl)}
                      onClose={() => setBreakDownEl(null)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      sx={{
                        "& .MuiList-root": {
                          padding: "0px !important", // Modify border radius
                        },
                        padding: "0px",
                      }}
                    >
                      <div className="px-2 py-3">
                        <ul className="pl-4  px-4">
                          <li className="flex items-center gap-3 py-3 cursor-pointer">
                            <IoAdd size={20} /> Create new report
                          </li>
                          <li className="flex items-center gap-3 py-3 cursor-pointer">
                            <IoIosImages size={20} /> View ad creative
                            performance
                          </li>
                        </ul>
                      </div>
                    </Menu>
                  </div>
                </div> */}
                </>
              </div>
            </div>
            {currentTable === "campaigns" && (
              <CompaignsTable
                setIsSingleCampaign={setIsSingleCampaign}
                setCampaignDetails={setCampaignDetails}
                oldObjectives={oldObjectives}
                getAllCompaigns={getAllCompaigns}
                compaigns={campaigns}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                loading={loading}
                setLoading={setLoading}
                fb_token={fb_token}
                searchQuery={query}
                objectives={objectives}
              />
            )}
            {currentTable === "adSets" && (
              <AdSetsTable
                setAdSetDetails={setAdSetDetails}
                setIsSingleAdSet={setIsSingleAdSet}
                getAllAdSets={getAllAdSets}
                adSets={adSets}
                setAdSets={setAdSets}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                loading={loading}
                setLoading={setLoading}
                fb_token={fb_token}
                compaigns={campaigns}
                searchQuery={query}
              />
            )}
            {currentTable === "ads" && (
              <AdsTable
                setAdDetails={setAdDetails}
                setIsSingleAd={setIsSingleAd}
                getAllAds={getAllAds}
                ads={ads}
                setAds={setAds}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                loading={loading}
                setLoading={setLoading}
                fb_token={fb_token}
                searchQuery={query}
              />
            )}
          </div>
        )}
      </div>
      <CreateCampaignModal
        setCreateCampaignModal={setIsCampaignModal}
        createCampaignModal={isCampaignModal}
        setIsSingleCampaign={setIsSingleCampaign}
        oldObjectives={oldObjectives}
        setCurrentCampaignDetails={setCampaignDetails}
        campData={campData}
        setCampData={setCampData}
        objectives={objectives}
      />
      <SingleCampaign
        isSingleCampaign={isSingleCampaign}
        setIsSingleCampaign={setIsSingleCampaign}
        setCreateCampaignModal={setIsCampaignModal}
        currentCampaignDetails={campaignDetails}
        setCurrentCampaignDetails={setCampaignDetails}
        getAllCompaigns={getAllCompaigns}
        campData={campData}
        setCampData={setCampData}
        createCampaign={createCampaign}
        btnLoading={btnLoading}
        setBtnLoading={setBtnLoading}
        fb_token={fb_token}
      />
      <CreateAdSetModal
        setCreateAdSetModal={setCreateAdSetModal}
        createAdSetModal={createAdSetModal}
        setIsSingleAdSet={setIsSingleAdSet}
        setAdSetDetails={setAdSetDetails}
        campaigns={campaigns}
        setAdsetData={setAdsetData}
        adsetData={adsetData}
        singleCampData={singleCampData}
        setSingleCampData={setSingleCampData}
      />
      <SingleAdSet
        isSingleAdSet={isSingleAdSet}
        setIsSingleAdSet={setIsSingleAdSet}
        currentAdSetDetails={adSetDetails}
        getAllAdSets={getAllAdSets}
        adsetData={adsetData}
        setAdsetData={setAdsetData}
        singleCampData={singleCampData}
        setSingleCampData={setSingleCampData}
        fb_token={fb_token}
        createAdSet={createAdSet}
        btnLoading={btnLoading}
      />
      <SingleAd
        isSingleAd={isSingleAd}
        setIsSingleAd={setIsSingleAd}
        currentAdDetails={adDetails}
        getAllAds={getAllAds}
        adData={adData}
        setAdData={setAdData}
        fb_token={fb_token}
        createAd={createAd}
        btnLoading={btnLoading}
      />
      <CreateAdModal
        setCreateAdModal={setCreateAdModal}
        createAdModal={createAdModal}
        setIsSingleAd={setIsSingleAd}
        setAdDetails={setAdDetails}
        adData={adData}
        setAdData={setAdData}
        campaigns={campaigns}
        adSets={adSets}
        getAllAdSets={getAllAdSets}
      />
    </>
  );
};

export default Compaigns;
