import React, { useState, useEffect } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { selectStyles } from "../_elements/SelectStyles";
import Select from "react-select";
import axios from "../../axoisConfig";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import Menu from "@mui/material/Menu";
import { IoMdClose } from "react-icons/io";

import {
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
  Backdrop,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
} from "@mui/material";
import DeviceMakes from "./deviceMakes";
import CustomCheckbox from "./CustomCheckbox";

const token = localStorage?.getItem("auth-token");

const Audiences = ({ adSetDetails, setAdSetDetails }) => {
  const [selectedPlacementType, setSelectedPlacementType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLifeStyleOpened, setIsLifeStyleOpened] = useState(false);

  const {
    currentMode,
    themeBgImg,
    t,
    primaryColor,
    BACKEND_URL,
    snapchatCredentials,
  } = useStateContext();
  const [isMore, setIsMore] = useState(false);
  const [checked, setChecked] = useState(false);
  const [osTypes, setOsTypes] = useState([]);
  const [openedCountry, setOpenedCountry] = useState(null);

  const [lifeStyles, setLifeStyles] = useState([]);
  const open = Boolean(anchorEl);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function getOsTypes() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/targeting/device/os_type`,
        {
          access_token: snapchatCredentials?.access_token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setOsTypes(
        res?.data?.data?.targeting_dimensions?.map((obj) => ({
          label: obj.os_type?.name,
          value: obj?.os_type?.id,
        }))
      );
    } catch (error) {
      console.log(error, "this is an error");
    }
  }

  useEffect(() => {
    getOsTypes();
    getLifeStyles();
  }, []);
  const data = {
    targeting_dimensions: [
      {
        sub_request_status: "SUCCESS",
        scls: {
          id: "SLC_1",
          name: "Adventure Seekers",
          path: "/Adventure Seekers",
          parentId: "SLC_0",
          source: "SNAPCHAT",
        },
      },
      // ... Add other elements as needed
    ],
  };

  function buildTree(items) {
    let tree = [];
    let lookup = {};

    items.forEach((item) => {
      lookup[item.id] = { ...item, children: [] };
    });

    items.forEach((item) => {
      const node = lookup[item.id];
      if (item.parentId !== "SLC_0") {
        lookup[item.parentId].children.push(node);
      } else {
        tree.push(node);
      }
    });

    return tree;
  }

  async function getLifeStyles() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/interests/scls`,
        {
          access_token: snapchatCredentials?.access_token,
          country_code: "us",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setLifeStyles(
        buildTree(
          res?.data?.data?.targeting_dimensions?.map((item) => item.scls)
        )
      );
    } catch (error) {
      console.log(error, "this is an error");
    }
  }

  //   const treeStructure = buildTree(data.targeting_dimensions);
  //   console.log(treeStructure);

  return (
    <div className={``}>
      <h2 className="font-bold text-[16px] mb-5">Audiences</h2>
      <div
        className={`${getSummaryBgClass()} rounded-3xl px-8 py-4 shadow-md mb-9`}
      >
        <label htmlFor="" className="text-semibold">
          Predefined Audiences
        </label>
        {lifeStyles.filter((obj) =>
          adSetDetails?.interests[0]?.category_id.includes(obj.id)
        )?.length > 0 && (
          <ul className="border border-gray-200 rounded-lg max-h-[300px] overflow-auto">
            {lifeStyles
              .filter((obj) =>
                adSetDetails?.interests[0]?.category_id.includes(obj.id)
              )
              ?.map((interest, index) => {
                return (
                  <li key={index} className="flex  flex-col px-4 py-1 ">
                    <div
                      className={`flex items-center justify-between px-4 py-3 group cursor-pointer hover:bg-[#F9F9FA] ${
                        currentMode == "dark"
                          ? "hover:bg-gray-800"
                          : "hover:bg-[#F9F9FA]"
                      }`}
                    >
                      <div className="flex items-center gap-2">
                        <span>{interest.name}</span>
                      </div>

                      <span
                        onClick={(e) => {
                          setAdSetDetails((pre) => {
                            pre.interests[0].category_id =
                              pre?.interests[0]?.category_id.filter(
                                (ls) => ls != interest.id
                              );
                            return { ...pre };
                          });
                        }}
                        className="group-hover:block hidden text-sky-500 cursor-pointer"
                      >
                        <IoMdClose />
                      </span>
                    </div>
                  </li>
                );
              })}
          </ul>
        )}
        <div>
          <div className="relative">
            <input
              type="text"
              onClick={(e) => setAnchorEl(e?.currentTarget)}
              // className="p-2 py-3 mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
              className={`p-2 mt-4 ${
                currentMode != "dark" && "!bg-[#F9F9FA]"
              } ${getSummaryBgClass()} rounded-lg focus:outline-none w-full  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
              // value={adSetDetails?.name}
              placeholder="Select Category"
              // onChange={(e) =>
              //   setAdSetDetails((pre) => ({
              //     ...pre,
              //     name: e?.target?.value,
              //   }))
              // }
            />
          </div>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: { width: anchorEl && anchorEl.offsetWidth }, // <-- The line that does all
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          sx={{
            "& .MuiList-root": {
              padding: "0px !important", // Modify border radius
            },
            padding: "0px",
            zIndex: 9999, // Remove any max width that might be applied by default
          }}
        >
          <div className={`${getSummaryBgClass()} max-h-[300px] overflow-auto`}>
            <ul className="">
              <div
                onClick={(e) => {
                  setIsLifeStyleOpened((pre) => !pre);
                  e.stopPropagation();
                }}
                className="flex items-center w-full gap-2 py-2 px-2"
              >
                {!isLifeStyleOpened ? (
                  <span>
                    <MdKeyboardArrowRight size={16} />
                  </span>
                ) : (
                  <span>
                    <MdKeyboardArrowDown size={16} />
                  </span>
                )}
                <span>Lifestyles</span>
              </div>
              {isLifeStyleOpened && (
                <div className="pl-6">
                  {lifeStyles?.map((lifeStyle, index) => {
                    return (
                      // <li
                      //   key={index}
                      //   className="flex flex-col px-4 py-2  group cursor-pointer"
                      //   // onClick={() => {
                      //   //   if (country?.code === openedCountry?.code) {
                      //   //     setOpenedCountry(null);
                      //   //     return;
                      //   //   }
                      //   //   setOpenedCountry(country);
                      //   // }}
                      // >
                      //   <div className="flex items-center justify-between">
                      //     <div className="flex items-center gap-2">
                      //       {true ? (
                      //         <span>
                      //           <MdKeyboardArrowRight size={16} />
                      //         </span>
                      //       ) : (
                      //         <span>
                      //           <MdKeyboardArrowDown size={16} />
                      //         </span>
                      //       )}
                      //       <span>{lifeStyle?.name}</span>
                      //       <div>{lifeStyle?.children?.length}</div>
                      //     </div>
                      //   </div>
                      // </li>
                      <LifeStyleRow
                        key={index}
                        lifeStyle={lifeStyle}
                        adSetDetails={adSetDetails}
                        setAdSetDetails={setAdSetDetails}
                      />
                    );
                  })}
                </div>
              )}
            </ul>
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default Audiences;

const LifeStyleRow = ({ lifeStyle, adSetDetails, setAdSetDetails }) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <li
      className="flex flex-col px-4 py-2  group cursor-pointer"
      onClick={(e) => {
        // if (country?.code === openedCountry?.code) {
        //   setOpenedCountry(null);
        //   return;
        // }
        e.stopPropagation();
        setIsOpened((pre) => !pre);
      }}
    >
      <div className="flex flex-col pl-4">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center  gap-2">
            {lifeStyle?.children?.length > 0 && (
              <div>
                {!isOpened ? (
                  <span>
                    <MdKeyboardArrowRight size={16} />
                  </span>
                ) : (
                  <span>
                    <MdKeyboardArrowDown size={16} />
                  </span>
                )}
              </div>
            )}
            <div className="flex flex-col">
              <span>{lifeStyle?.name}</span>
            </div>
          </div>
          <div>
            <CustomCheckbox
              value={
                adSetDetails?.interests[0]?.category_id?.find(
                  (interest) => interest == lifeStyle?.id
                )
                  ? 2
                  : 0
              }
              onChange={(e) => {
                setAdSetDetails((pre) => {
                  if (e == 2) {
                    pre?.interests[0]?.category_id.push(lifeStyle?.id);
                  } else if (e == 0) {
                    pre.interests[0].category_id =
                      pre?.interests[0]?.category_id.filter(
                        (ls) => ls != lifeStyle.id
                      );
                  }
                  return { ...pre };
                });
              }}
            />
          </div>
        </div>
        <div className="flex flex-col">
          {isOpened &&
            lifeStyle?.children?.map((item, index) => {
              return (
                <LifeStyleRow
                  key={index}
                  lifeStyle={item}
                  adSetDetails={adSetDetails}
                  setAdSetDetails={setAdSetDetails}
                />
              );
            })}
        </div>
      </div>
    </li>
  );
};
