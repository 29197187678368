import React, { useEffect, useState, useRef } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { selectStyles } from "../../../Components/_elements/SelectStyles";
import Select from "react-select";
import { toast } from "react-toastify";
import axios from "../../../axoisConfig";
import ListingDataGrid from "../ListingDataGrid";
import { BsTrash } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import ListingLocation from "../../Leads/listings/ListingLocation";
import { currencies, listing_options } from "../../_elements/SelectOptions";

const AddListingAttrType = ({
  data,
  setData,
  loading,
  setLoading,
  page,
  pageSize,
  total,
  setPage,
  setPageSize,
  FetchData,
  listingIds,
  setListingIDs,
  handleNext,
  edit,
  fetchSingleListing,
  handleClose,
  listData,
  setListingData,
  listingData,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    isArabic,
    primaryColor,
    t,
    isLangRTL,
    i18n,
    fontFam,
  } = useStateContext();
  const token = localStorage.getItem("auth-token");

  const [btnLoading, setBtnLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);

  const [country, setCountry] = useState("");
  const [countryLoading, setCountryLoading] = useState(false);
  const [city, setCity] = useState("");
  const isFirstRender = useRef(true);

  console.log("listing attr type:: ", listingData);
  const latLong = listingData?.latlong?.split(",").map(Number);
  const [listingLocation, setListingLocation] = useState({
    lat: latLong[0] || 0,
    lng: latLong[1] || 0,
    addressText: listingData?.location,
    city: listingData?.city,
    country: listingData?.country,
  });

  const handleChange = (e) => {
    setListingData((prevListingAttr) => ({
      ...prevListingAttr,
      [e.target.id]: e.target.value,
    }));
  };

  const fetchCountries = () => {
    setCountryLoading(true);
    axios
      .get(`${BACKEND_URL}/countries`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("countries list : ", result);
        setCountryList(result?.data?.data);
        setCountryLoading(false);
      })
      .catch((err) => {
        setCountryLoading(false);

        console.error(err);
        setBtnLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Unable to fetch countries", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setListingData({
      ...listingData,
      latlong: `${listingLocation?.lat},${listingLocation?.lng}`,
      location: listingLocation?.addressText,
      city: listingLocation?.city,
      country: listingLocation?.country,
    });
  }, [listingLocation]);

  return (
    <div className="my-4">
      <h4 className={`text-primary text-center font-semibold pb-5`}>
        {t("heading_lising_location")}
      </h4>

      {countryLoading ? (
        <div className="w-full h-[400px] flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="grid sm:grid-cols-1   md:grid-cols-2 lg:grid-cols-2 gap-3">
            <Box
              sx={{
                ...darkModeColors,
                "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                  {
                    right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                    transformOrigin: isLangRTL(i18n.language)
                      ? "right"
                      : "left",
                  },
                "& legend": {
                  textAlign: isLangRTL(i18n.language) ? "right" : "left",
                },
              }}
            >
              {/* <TextField
            id="name"
            type={"text"}
            label={t("name")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.name}
            name="projectLocation"
            onChange={handleChange}
            required
          /> */}
              {/* <TextField
            id="type"
            type={"text"}
            label={t("type")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.type}
            name="area"
            onChange={handleChange}
            required
          /> */}

              {/* <Select
            id="type"
            value={listing_options(t)?.find(
              (list_type) => list_type.value === listingData?.type
            )}
            onChange={(e) => {
              setListingData({
                ...listingData,
                type: e.value,
              });
            }}
            options={listing_options(t)?.map((list_attr_type) => ({
              value: list_attr_type.value,
              label: list_attr_type.label,
            }))}
            className="w-full"
            placeholder={t("label_listing_type")}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          /> */}

              <TextField
                id="area"
                type={"text"}
                label={t("form_project_area")}
                className="w-full"
                sx={{
                  marginBottom: "20px !important",
                }}
                variant="outlined"
                size="small"
                value={listingData?.area}
                name="area"
                onChange={handleChange}
                required
              />
              <TextField
                id="address"
                type={"text"}
                label={t("label_address")}
                className="w-full"
                sx={{
                  marginBottom: "20px !important",
                }}
                variant="outlined"
                size="small"
                value={listingData?.address}
                name="area"
                onChange={handleChange}
                required
              />

              {/* <TextField
            id="latitude"
            type={"text"}
            label={t("form_label_lat")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.latitude}
            name="tourLink"
            onChange={handleChange}
          /> */}
            </Box>
            <Box
              sx={{
                ...darkModeColors,
                "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                  {
                    right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                    transformOrigin: isLangRTL(i18n.language)
                      ? "right"
                      : "left",
                  },
                "& legend": {
                  textAlign: isLangRTL(i18n.language) ? "right" : "left",
                },
              }}
            >
              {/* <Select
              id="currency_type"
              options={currencies(t)?.map((curr) => ({
                value: curr.value,
                label: curr.label,
              }))}
              value={currencies(t)?.filter(
                (curr) => curr?.value === listingData?.currency_type
              )}
              onChange={(e) => {
                setListingData({
                  ...listingData,
                  currency_type: e.value,
                });
              }}
              placeholder={t("label_currency")}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />*/}
              <TextField
                id="city"
                // id="city"
                type={"text"}
                label={t("label_city")}
                className="w-full"
                sx={{
                  marginBottom: "20px !important",
                }}
                variant="outlined"
                size="small"
                value={listingData?.city}
                name="projectLocation"
                onChange={handleChange}
                // slotProps={{
                //   input: {
                //     readOnly: true,
                //   },
                // }}
                required
              />

              <Select
                id="country_id"
                value={{
                  value: listingData?.country,
                  label: listingData?.country
                    ? countryList?.find(
                        (cont) =>
                          cont?.name?.toLowerCase() ==
                          listingData?.country?.toLowerCase()
                      )?.name || ""
                    : t("label_country"),
                }}
                onChange={(e) => {
                  setListingData({
                    ...listingData,
                    country: e.value,
                  });
                }}
                options={countryList?.map((cont) => ({
                  value: cont.name,
                  label: cont.name,
                }))}
                isLoading={countryLoading}
                className="w-full"
                placeholder={t("label_country")}
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />
            </Box>
          </div>
          <Box
            sx={darkModeColors}
            className="w-full grid grid-cols-1 gap-5 my-5"
          >
            <ListingLocation
              listingLocation={listingLocation}
              currLocByDefault={true}
              setListingLocation={setListingLocation}
              city={city}
              setCity={setCity}
              country={country}
              setCountry={setCountry}
              required
            />
          </Box>
        </>
      )}

      {/* <Button
        className={`w-full text-center text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none`}
        ripple={true}
        style={{
          fontFamily: fontFam,
          background: `${primaryColor}`,
        }}
        size="lg"
        type="submit"
        disabled={btnLoading ? true : false}
        onClick={AddListAttrType}
      >
        {btnLoading ? (
          <CircularProgress
            size={20}
            sx={{ color: "white" }}
            className="text-white"
          />
        ) : (
          <span className="text-white">{t("submit")}</span>
        )}
      </Button> */}
      {/* <div className=" mt-5">
        <ListingDataGrid
          data={data}
          setData={setData}
          column={columns}
          // setColumn={setColumn}
          type="list_attr_type"
          loading={loading}
          setLoading={setLoading}
          page={page}
          pageSize={pageSize}
          total={total}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </div> */}
    </div>
  );
};

export default AddListingAttrType;
