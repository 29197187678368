import React, { useState, useEffect } from "react";
import { Backdrop, Checkbox, CircularProgress, Modal } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig.js";
import ImagePicker from "./ImagePicker";

import { toast } from "react-toastify";
import { FaSquare } from "react-icons/fa";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineClose } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { GoAlert } from "react-icons/go";

const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const token = localStorage?.getItem("auth-token");

const LeadForm = ({
  setIsSingleLeadForm,
  isSingleLeadForm,
  fetchLeadForms,
  isView,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,
    fbCredentials,
  } = useStateContext();
  const tabs = ["Form Settings", "Questions", "Disclosures"];
  // "Disclosures", "Ending"

  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    headerImage: null,
    privacy_policy_url: "",
    form_fields: [
      {
        value: "FIRST_NAME",
        label: "First Name",
      },
      {
        value: "LAST_NAME",
        label: "Last Name",
      },
      {
        value: "PHONE_NUMBER",
        label: "Phone Number",
      },
    ],
  });

  useEffect(() => {
    if (isView) {
      const newFields = isSingleLeadForm?.form_fields?.map((i) => ({
        value: i?.type,
        label: i?.type
          ?.toLowerCase()
          .split("_")
          .map((word, index) =>
            index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join(" "),
      }));
      setFormData({
        ...isSingleLeadForm,
        form_fields: newFields,
      });
    } else {
      setFormData({
        name: "",
        description: "",
        headerImage: null,
        privacy_policy_url: "",
        form_fields: [
          {
            value: "FIRST_NAME",
            label: "First Name",
          },
          {
            value: "LAST_NAME",
            label: "Last Name",
          },
          {
            value: "PHONE_NUMBER",
            label: "Phone Number",
          },
        ],
      });
    }
  }, [isView]);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  async function createLeadForm() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/create_lead_form`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          lead_generation_forms: [
            {
              privacy_policy_url: formData?.privacy_policy_url,
              name: formData?.name,
              description: formData?.description,
              form_fields: formData?.form_fields?.map((i) => ({
                type: i?.value,
              })),
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      toast.success("Form is successfully created", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchLeadForms();
      setIsSingleLeadForm(false);
    } catch (error) {
      console.log(error, "this is an error");
      toast.error(error?.response?.data?.error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (Object?.keys(error?.response?.data?.errors)?.length > 0) {
        Object?.values(error?.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    }
  }

  return (
    <Modal
      //   keepMounted
      open={isSingleLeadForm}
      onClose={() => setIsSingleLeadForm(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[70%] h-[600px] ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2  rounded-xl flex flex-col items-start`}
      >
        <h2 className="py-4 font-semibold text-[16px] px-4">
          Design Your Form
        </h2>
        <div className="w-full flex flex-1 overflow-y-scroll">
          <div
            className={`w-[20%]  ${
              currentMode !== "dark"
                ? "!bg-gradient-to-br !from-[#F3EEF0] !to-[#E7F0F9]"
                : "!bg-gradient-to-br !from-[#2D2A32] !to-[#1F2937]"
            } h-full rounded-l-xl pl-4 pt-4`}
          >
            <ul className="mt-4">
              {tabs?.map((tab) => {
                return (
                  <li
                    onClick={() => setCurrentTab(tab)}
                    className={`py-3 rounded-l-full pl-3 flex items-center gap-1 cursor-pointer ${
                      currentMode !== "dark"
                        ? currentTab == tab
                          ? "bg-white"
                          : ""
                        : currentTab == tab
                        ? "bg-black"
                        : ""
                    }`}
                  >
                    {currentTab == tab && (
                      <span>
                        <FaSquare size={4} />
                      </span>
                    )}
                    {tab}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="px-5 pt-5 flex flex-col flex-1 pb-5">
            <div className="flex-1">
              {currentTab === "Form Settings" && (
                <FormSetting
                  formData={formData}
                  setFormData={setFormData}
                  isView={isView}
                />
              )}
              {currentTab === "Questions" && (
                <Questions
                  formData={formData}
                  setFormData={setFormData}
                  isView={isView}
                />
              )}
              {currentTab === "Disclosures" && (
                <Disclosures
                  formData={formData}
                  setFormData={setFormData}
                  getSummaryBgClass={getSummaryBgClass}
                  isView={isView}
                />
              )}
            </div>
            <div className="flex items-center justify-end gap-3 w-full pt-3 border-t-[2px]">
              <button
                onClick={() => setIsSingleLeadForm(false)}
                // className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
                //   currentMode == "dark" ? "bg-dark text-white" : "bg-white"
                // } `}
                className="p-3 px-7 disabled:cursor-not-allowed rounded-[20px] bg-black text-white  border hover:border-[#F7D901]"
              >
                Cancel
              </button>
              {currentTab != "Disclosures" ? (
                <button
                  onClick={() => {
                    setCurrentTab(
                      tabs[tabs.findIndex((i) => i == currentTab) + 1]
                    );
                  }}
                  // className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
                  className="p-3 px-7 disabled:cursor-not-allowed rounded-[20px] bg-black text-white  border hover:border-[#F7D901]"
                >
                  Continue
                </button>
              ) : (
                <button
                  disabled={isView}
                  onClick={() => {
                    createLeadForm();
                  }}
                  // className="p-3 px-7 disabled:cursor-not-allowed rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
                  className="p-3 px-7 disabled:cursor-not-allowed rounded-[20px] bg-black text-white  border hover:border-[#F7D901]"
                >
                  Publish
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const FormSetting = ({ formData, setFormData, isView }) => {
  const { currentMode, themeBgImg } = useStateContext();
  const [imagePickerModal, setImagePickerModal] = useState(false);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  return (
    <>
      <div>
        <div className="flex flex-col">
          <label htmlFor="" className="font-medium">
            Form Name
          </label>
          <div className="w-full relative">
            <input
              disabled={isView}
              type="text"
              className={`p-2 pr-[50px] mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none w-full  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
              value={formData?.name}
              placeholder="Enter Your form name (required)"
              onChange={(e) => {
                if (e?.target?.value?.length <= 374) {
                  setFormData((pre) => ({
                    ...pre,
                    name: e?.target?.value,
                  }));
                }
              }}
            />
            <span className="absolute right-3 top-1/2 -translate-y-1/2">
              {375 - (formData?.name?.length || 0)}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <label htmlFor="" className="font-medium">
            Lead Form Description
          </label>
          <div className="w-full relative">
            <textarea
              onChange={(e) => {
                if (e?.target?.value?.length <= 179) {
                  setFormData((pre) => ({
                    ...pre,
                    description: e?.target?.value,
                  }));
                }
              }}
              placeholder="Describe Your offering"
              disabled={isView}
              name=""
              id=""
              rows={3}
              className={`p-2 resize-none  pr-[50px] mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none w-full  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
            ></textarea>
            <span className="absolute right-3 top-1/2 -translate-y-1/2">
              {180 - (formData?.name?.length || 0)}
            </span>
          </div>
        </div>
        {/* <div className="flex gap-3">
          {!formData?.header_image ? (
            <label
              className={` cursor-pointer ${
                currentMode === "dark"
                  ? "bg-black text-white"
                  : "text-[#444546] bg-white"
              } p-3 px-5 hover:border-[#F7D901]  rounded-[20px] border font-semibold `}
              htmlFor="top-snap-media"
              onClick={() => setImagePickerModal(true)}
            >
              Upload
            </label>
          ) : (
            <div className="flex gap-3">
              <img
                src={URL.createObjectURL(formData?.header_image)}
                alt="form header image"
                className="w-[100px]"
              />
              <div className="flex-col flex items-start">
                <span>{formData?.header_image?.name}</span>
                <span
                  className={` cursor-pointer ${
                    currentMode === "dark"
                      ? "bg-black text-white"
                      : "text-[#444546] bg-white"
                  } p-3 px-5 hover:border-[#F7D901]  rounded-full border font-semibold `}
                >
                  <img
                    src="data:image/svg+xml,%3csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e %3c!-- Generator: Sketch 52.6 (67491) - http://www.bohemiancoding.com/sketch --%3e %3ctitle%3eIcon/SDS/Trash%3c/title%3e %3cdesc%3eCreated with Sketch.%3c/desc%3e %3cg id='Icon/SDS/Trash' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e %3cpath d='M11,14 L12,14 L12,5 L11,5 L11,14 Z M7.5,14 L8.5,14 L8.5,5 L7.5,5 L7.5,14 Z M4,14 L5,14 L5,5 L4,5 L4,14 Z M14,3 C14.553,3 15,3.448 15,4 C15,4.552 14.553,5 14,5 L14,14 C14,15.104 13.104,16 12,16 L4,16 C2.896,16 2,15.104 2,14 L2,5 C1.448,5 1,4.552 1,4 C1,3.448 1.448,3 2,3 L14,3 Z M3,2 C2.448,2 2,1.552 2,1 C2,0.448 2.448,0 3,0 L13,0 C13.553,0 14,0.448 14,1 C14,1.552 13.553,2 13,2 L3,2 Z' id='🎨-Fill-Color' fill='%2316191C'%3e%3c/path%3e %3c/g%3e %3c/svg%3e"
                    alt=""
                  />
                </span>
              </div>
            </div>
          )}
         
        </div> */}
      </div>
      <ImagePicker
        imagePickerModal={imagePickerModal}
        setImagePickerModal={setImagePickerModal}
        setData={(file) => {
          setFormData((pre) => ({
            ...pre,
            header_image: file,
          }));
        }}
        imageProperties={{
          width: 10,
          height: 10,
          aspectRatio: 16 / 9,
        }}
        isOnlyImage
      />
    </>
  );
};

const Disclosures = ({ formData, setFormData, getSummaryBgClass, isView }) => {
  return (
    <div>
      <div className="flex flex-col">
        <label htmlFor="" className="font-medium">
          Privacy Policy URL
        </label>
        <div className="w-full ">
          <input
            disabled={isView}
            type="text"
            className={`p-2 pr-[50px] mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none w-full  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
            value={formData?.privacy_policy_url}
            placeholder="https://example.com"
            onChange={(e) => {
              if (e?.target?.value?.length <= 374) {
                setFormData((pre) => ({
                  ...pre,
                  privacy_policy_url: e?.target?.value,
                }));
              }
            }}
          />
        </div>
        {/* <label htmlFor="" className="font-medium">
          Disclosures
        </label>
        <p>Add a custom legal disclaimer or marketing opt-in.</p>
        <div className="flex flex-col">
          <label htmlFor="" className="font-medium">
            Tile
          </label>
          <div className="w-full relative">
            <input
              type="text"
              className={`p-2 pr-[50px] mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none w-full  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
              value={formData?.name}
              placeholder="Enter Your form name (required)"
              onChange={(e) => {
                if (e?.target?.value?.length <= 34) {
                  setFormData((pre) => ({
                    ...pre,
                    name: e?.target?.value,
                  }));
                }
              }}
            />
            <span className="absolute right-3 top-1/2 -translate-y-1/2">
              {35 - (formData?.name?.length || 0)}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <label htmlFor="" className="font-medium">
            Description
          </label>
          <div className="w-full relative">
            <textarea
              onChange={(e) => {
                if (e?.target?.value?.length <= 179) {
                  setFormData((pre) => ({
                    ...pre,
                    description: e?.target?.value,
                  }));
                }
              }}
              placeholder="Enter a marketing or legal disclaimer"
              name=""
              id=""
              rows={3}
              className={`p-2 resize-none  pr-[50px] mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none w-full  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
            ></textarea>
          
          </div>
        </div> */}
      </div>
    </div>
  );
};
const Questions = ({ formData, setFormData, isView }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isQuestionsCollapsed, setIsQuestionsCollapsed] = useState(false);
  const { currentMode, themeBgImg } = useStateContext();
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  const [menuItems, setMenuItems] = useState([
    { label: "Email", value: "EMAIL" },
    { label: "Phone Number", value: "PHONE_NUMBER" },
    { label: "Address", value: "ADDRESS" },
    { label: "Postal code", value: "POSTAL_CODE" },
    { label: "Birthday Date", value: "BIRTHDAY_DATE" },
    { label: "Job Title", value: "JOB_TITLE" },
    { label: "Company Name", value: "COMPANY_NAME" },
  ]);
  // const [fieldData, setFieldData] = useState([]);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMenuClose = (item, index) => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };
  const RemovingField = (index, item) => {
    const newFieldData = [...formData?.form_fields];
    newFieldData.splice(index, 1);
    setFormData((pre) => ({ ...pre, form_fields: newFieldData }));
    // setMenuItems([...menuItems, item]);
  };
  return (
    <div className="flex justify-center">
      <div className="w-full h-full p-[10px] flex flex-col">
        <div
          onClick={() => setIsQuestionsCollapsed((pre) => !pre)}
          className="flex items-center gap-4 p-4 border-b-[1px] cursor-pointer "
        >
          <div className="p-3 text-gray-600 border rounded-full ">
            {isQuestionsCollapsed ? <IoIosArrowForward /> : <IoIosArrowDown />}
          </div>
          <div className=" flex flex-col gap-[-4px] ">
            <h5 className="text-gray-600 font-medium -gap-1">
              Personal Information
            </h5>
            <p className=" text-gray-600 text-[14px]">
              Ask for user information. This will be prefilled from their
              account.
            </p>
          </div>
        </div>
        {!isQuestionsCollapsed && (
          <div className=" flex p-5 gap-3 flex-col w-[450px] ">
            <h3 className="  font-medium text-gray-800">Fields</h3>
            {/* <div className="flex gap-4 items-center ">
              <IoMdCheckmark color="green" size={16} />
              <p className="text-gray-800 text-[14px]">First Name</p>
            </div>
            <div className="flex gap-4 items-center ">
              <IoMdCheckmark color="green" size={16} />
              <p className="text-gray-800 text-[14px]">Last Name</p>
            </div> */}
            {formData?.form_fields.map((item, index) => (
              <div className=" flex  justify-between items-center ">
                <div key={index} className="flex gap-4 items-center ">
                  <IoMdCheckmark
                    // cursor={PointerEvents.none}
                    color="green"
                    size={16}
                  />
                  <p className={`${getSummaryBgClass()} text-[14px]`}>
                    {item?.label}
                  </p>
                </div>
                {item?.value !== "FIRST_NAME" &&
                  item?.value !== "LAST_NAME" && (
                    <span>
                      <MdOutlineClose
                        size={16}
                        className={`  ${
                          isView ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() => {
                          if (!isView) {
                            RemovingField(index, item);
                          }
                        }}
                      />
                    </span>
                  )}
              </div>
            ))}
            <div className="flex flex-col items-start">
              {formData?.form_fields?.filter(
                (i) => i.value == "EMAIL" || i.value == "PHONE_NUMBER"
              )?.length == 0 && (
                <p className="flex items-center gap-2">
                  <GoAlert color="red" /> You need to include either phone or
                  email in your Lead Form.
                </p>
              )}
              <div
                className={`flex gap-2 py-2 px-3 items-center rounded-full border justify-start ${
                  isView ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={(e) => {
                  if (!isView) {
                    handleMenuOpen(e);
                  }
                }}
              >
                <FaPlus /> Add field
                <IoIosArrowDown />
              </div>

              <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                PaperProps={{
                  sx: {
                    boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.1)", // Shadow for the menu
                    // width: "100%", // Set the width to match the parent div
                    maxWidth: "none", // Add some margin from the button
                    borderRadius: "8px",
                    border: "1px solid #E5E5E5", // Optional: rounded corners for the menu
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom", // Position the menu below the button
                  horizontal: "left", // Align the menu to the left of the button
                }}
                transformOrigin={{
                  vertical: "top", // Align the menu's top edge to the button's bottom edge
                  horizontal: "left", // Align the menu to the left of the button
                }}
                sx={{
                  zIndex: 1500,
                }}
              >
                {menuItems
                  ?.filter(
                    (item) =>
                      !formData?.form_fields?.find(
                        (i) => i?.value === item?.value
                      )
                  )
                  .map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleMenuClose(item, index);
                        // let menudata = [...menuItems];
                        // menudata.splice(index, 1);
                        // setMenuItems(menudata);
                        setFormData((pre) => ({
                          ...pre,
                          form_fields: [...pre?.form_fields, item],
                        }));
                      }}
                      sx={{
                        color: item?.Style?.textColor || "black",
                        "&:hover": {
                          color: item?.Style?.textColor || "black",
                        },
                      }}
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
              </Menu>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default LeadForm;
