import React, { useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { selectStyles } from "../_elements/SelectStyles";
import Select from "react-select";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
const PlacementOptions = ({ adSetDetails, setAdSetDetails }) => {
  const [selectedPlacementType, setSelectedPlacementType] = useState(null);
  const { currentMode, themeBgImg, t, primaryColor } = useStateContext();
  const [isMore, setIsMore] = useState(false);

  const placementTypes = [
    {
      label: "Automatic Placement (Recommended)",
      para: "Run your ads across all of Snapchat. This gives more flexibility to improve the results of your campaign and maximize your reach.",
    },
    // {
    //   label: "Edit Placement",
    //   para: "",
    // },
  ];
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  const betweenContentTypes = [
    {
      label: "User Stories",
      value: 1,
    },
    {
      label: "Publisher & Creator Stories",
      value: 2,
    },
    {
      label: "Spotlight",
      value: 3,
    },
  ];
  const filterTypes = [
    {
      label: "Include",
      value: "include",
    },
    {
      label: "Exclude",
      value: "exclude",
    },
  ];

  return (
    <div className={``}>
      <h2 className="font-bold text-[16px] mb-5">Placements</h2>
      <div
        className={`${getSummaryBgClass()} rounded-3xl px-8 py-4 shadow-md mb-9`}
      >
        <h2 className="text-lg font-semibold mb-4">Placement Option</h2>
        <ul className="pl-4 pt-4 px-4 space-y-3">
          {placementTypes?.map((type, index) => {
            return (
              <li
                className={`flex gap-4 items-center  group cursor-pointer px-2 py-2 rounded-lg  `}
                onClick={() => setSelectedPlacementType(type)}
              >
                {" "}
                <div
                  className={`w-[16px] h-[16px] rounded-full border border-gray-700 ${
                    // selectedPlacementType?.label === type?.label
                    true ? "border-[5px]" : "group-hover:border-[3px]"
                  }  `}
                ></div>
                <div className="flex-1">
                  <h3
                    className={`text-[14px] ${
                      currentMode === "dark" ? "text-white" : "text-black"
                    }`}
                  >
                    {type?.label}
                  </h3>
                  <p>{type?.para}</p>
                </div>
              </li>
            );
          })}
          {selectedPlacementType?.label == "Edit Placement" && (
            <ul className="pl-4 !mt-0  px-4">
              <li
                className={`flex gap-4 items-center mb-3 group cursor-pointer px-2 py-2 rounded-lg  `}
              >
                {" "}
                <div
                  className={`w-[16px] h-[16px] rounded-full  border-gray-700 border-[5px]  `}
                ></div>
                <div className="flex-1">
                  <h3
                    className={`text-[14px] ${
                      currentMode === "dark" ? "text-white" : "text-black"
                    }`}
                  >
                    Content
                  </h3>
                </div>
              </li>
              <div className="pl-5 space-y-3">
                <li className="flex items-center gap-2">
                  <input type="checkbox" />
                  <label htmlFor="">
                    Between Content(Ads watched between stories and professional
                    content)
                  </label>
                </li>

                <ul className="pl-4 flex flex-col gap-2 mt-3">
                  {betweenContentTypes?.map((type) => {
                    return (
                      <li className="flex items-center gap-2">
                        <input type="checkbox" />
                        <label htmlFor="">{type?.label}</label>{" "}
                      </li>
                    );
                  })}
                </ul>
                <li className="flex items-center gap-2">
                  <input type="checkbox" />
                  <label htmlFor="">
                    Within Content(Ads watched within professional, creator &
                    curated content)
                  </label>
                </li>
                <ul className="pl-4 flex flex-col gap-2 mt-3">
                  <li className="flex items-center gap-2">
                    <input type="checkbox" />
                    <label htmlFor="">Publisher Stories</label>
                  </li>
                  <div>
                    <label htmlFor="">Categories</label>
                    <div className="flex gap-4">
                      <div className="w-[17%]">
                        <Select
                          id=""
                          options={filterTypes}
                          // value={adSetDetails?.goal}
                          // onChange={(e) => {
                          //   setAdSetDetails((pre) => ({ ...pre, goal: e }));
                          // }}
                          placeholder={t("Select a goal")}
                          menuPortalTarget={document.body}
                          styles={{
                            ...selectStyles(currentMode, primaryColor),
                            control: (provided) => ({
                              ...provided,
                              ...selectStyles(currentMode, primaryColor)
                                .control,
                              marginBottom: 0,
                              marginTop: "3px",
                            }),
                          }}
                        />
                      </div>
                      <div className="flex-1">
                        <Select
                          id=""
                          options={[]}
                          // value={adSetDetails?.goal}
                          // onChange={(e) => {
                          //   setAdSetDetails((pre) => ({ ...pre, goal: e }));
                          // }}
                          placeholder={t("Select a goal")}
                          menuPortalTarget={document.body}
                          styles={{
                            ...selectStyles(currentMode, primaryColor),
                            control: (provided) => ({
                              ...provided,
                              ...selectStyles(currentMode, primaryColor)
                                .control,
                              marginBottom: 0,
                              marginTop: "3px",
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <li className="flex items-center gap-2">
                    <input type="checkbox" />
                    <label htmlFor="">Creator Stories</label>
                  </li>
                  <div>
                    <label htmlFor="">Categories</label>
                    <div className="flex gap-4">
                      <div className="w-[17%]">
                        <Select
                          id=""
                          options={filterTypes}
                          // value={adSetDetails?.goal}
                          // onChange={(e) => {
                          //   setAdSetDetails((pre) => ({ ...pre, goal: e }));
                          // }}
                          placeholder={t("Select a goal")}
                          menuPortalTarget={document.body}
                          styles={{
                            ...selectStyles(currentMode, primaryColor),
                            control: (provided) => ({
                              ...provided,
                              ...selectStyles(currentMode, primaryColor)
                                .control,
                              marginBottom: 0,
                              marginTop: "3px",
                            }),
                          }}
                        />
                      </div>
                      <div className="flex-1">
                        <Select
                          id=""
                          options={[]}
                          // value={adSetDetails?.goal}
                          // onChange={(e) => {
                          //   setAdSetDetails((pre) => ({ ...pre, goal: e }));
                          // }}
                          placeholder={t("Select a goal")}
                          menuPortalTarget={document.body}
                          styles={{
                            ...selectStyles(currentMode, primaryColor),
                            control: (provided) => ({
                              ...provided,
                              ...selectStyles(currentMode, primaryColor)
                                .control,
                              marginBottom: 0,
                              marginTop: "3px",
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ul>
                <li className="flex items-center gap-2">
                  <input type="checkbox" />
                  <label htmlFor="">Discover Feed</label>
                </li>
              </div>
              <ul></ul>
            </ul>
          )}
        </ul>
        <div onClick={() => setIsMore((pre) => !pre)}>
          {!isMore ? (
            <button className="text-sky-600 hover:underline flex items-center gap-2">
              <IoIosArrowDown /> More
            </button>
          ) : (
            <button className="text-sky-600 hover:underline flex items-center gap-2">
              <IoIosArrowUp /> Less
            </button>
          )}
        </div>
        {isMore && (
          <div className="mt-4">
            <label htmlFor="" className="font-semibold mb-1">
              Brand Safety Filter
            </label>
            <ul>
              <li
                onClick={() =>
                  setAdSetDetails((pre) => ({
                    ...pre,
                    brandSafetyFilter: "Full Inventory",
                  }))
                }
                className={`group flex gap-3 items-center border border-gray-300 cursor-pointer ${
                  currentMode === "dark"
                    ? "bg-dark-neu text-white"
                    : "bg-[#F9F9FA]"
                } rounded-lg py-4 px-3 mb-3`}
              >
                <div
                  className={`w-[16px] h-[16px] rounded-full border border-gray-700 ${
                    // adSetDetails?.brandSafetyFilter === "Full Inventory"
                    true ? "border-[5px]" : "group-hover:border-[3px]"
                  }  `}
                ></div>
                <div>
                  <h4>Full Inventory</h4>
                  <p>
                    Show ads near any Snapchat content to maximize reach.
                    Content still adheres to Snap's Community Guidelines and
                    content moderation rules.
                  </p>
                </div>
              </li>
              {/* <li
                onClick={() =>
                  setAdSetDetails((pre) => ({
                    ...pre,
                    brandSafetyFilter: "Limited Inventory",
                  }))
                }
                className={`${
                  currentMode === "dark"
                    ? "bg-dark-neu text-white"
                    : "bg-[#F9F9FA]"
                } border border-gray-300  rounded-lg py-4 px-3 cursor-pointer`}
              >
                <div className="group flex gap-3 items-center">
                  <div
                    className={`w-[16px] h-[16px] rounded-full border border-gray-700 ${
                      adSetDetails?.brandSafetyFilter === "Limited Inventory"
                        ? "border-[5px]"
                        : "group-hover:border-[3px]"
                    }  `}
                  ></div>
                  <div className="w-full">
                    <h4>Limited Inventory</h4>
                    <p>
                      Show ads near content that has passed additional
                      moderation filters. This may decrease reach and increase
                      costs. Only available for Creator Stories right now.
                    </p>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlacementOptions;
