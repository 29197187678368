// import React, { useState } from "react";
// import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
// import { IoSearchOutline } from "react-icons/io5";
// import { MdOutlineKeyboardArrowDown } from "react-icons/md";
// import { IoIosArrowDroprightCircle } from "react-icons/io";
// import { useStateContext } from "../../context/ContextProvider";
// import { IoMdArrowUp, IoMdArrowDown } from "react-icons/io";
// import { TiArrowSortedDown } from "react-icons/ti";

// export default function CustomSelect({
//   options,
//   selectedOption,
//   setSelectedOption,
//   placeholder,
//   label,
// }) {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const open = Boolean(anchorEl);
//   const {
//     currentMode,
//     primaryColor,
//     t,
//     BACKEND_URL,
//     snapchatCredentials,
//     setSnapchatCredentials,
//   } = useStateContext();

//   const getSummaryBgClass = () => {
//     return currentMode === "dark"
//       ? "bg-dark text-white"
//       : "bg-white text-black";
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <div className="w-full">
//       <label htmlFor="" className="text-[12px] font-semibold mb-4">
//         {label}
//       </label>
//       <div
//         className="flex items-center border p-2 w-full border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px]"
//         onClick={(e) => setAnchorEl(e?.currentTarget)}
//       >
//         <div className="h-full w-[30px] ">{selectedOption?.icon}</div>

//         <div
//           className={`p-2  ${getSummaryBgClass()} rounded-lg flex-1 focus:outline-none outline-none !outline-offset-0  `}
//         >
//           {selectedOption?.label || ""}
//         </div>
//         <span>
//           <TiArrowSortedDown size={16} />
//         </span>
//       </div>
//       <Menu
//         id="basic-menu"
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         MenuListProps={{
//           "aria-labelledby": "basic-button",
//         }}
//         transformOrigin={{ horizontal: "left", vertical: "top" }}
//         anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
//         sx={{
//           "& .MuiList-root": {
//             padding: "0px !important", // Modify border radius
//           },
//           padding: "0px",
//           zIndex: 9999,
//           width: "100%",
//         }}
//       >
//         <div className={` ${getSummaryBgClass()} py-4`}>
//           <div className="flex items-center gap-3 px-4 pt-3 mb-2">
//             <IoSearchOutline size={16} />
//             <input
//               type="text"
//               className={`focus:outline-none border-none ${getSummaryBgClass()}`}
//               placeholder={`Filter ...`}
//             />
//           </div>
//           <ul className="px-3">
//             {options?.map((item) => {
//               return (
//                 <li
//                   className={`flex gap-4 items-center mb-4 group cursor-pointer px-2 py-2 rounded-lg select-none ${
//                     selectedOption?.label === item?.label ? "bg-[#C3DCF5]" : ""
//                   }`}
//                   onClick={() => setSelectedOption(item)}
//                 >
//                   <div
//                     className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
//                       selectedOption?.label === item?.label
//                         ? "border-[5px]"
//                         : "group-hover:border-[3px]"
//                     }  `}
//                   ></div>
//                   <div className="h-full w-[30px]">
//                     {item?.icon && item?.icon}
//                   </div>
//                   <div className="flex-1">
//                     <h3
//                       className={`text-[14px] ${
//                         currentMode === "dark" ? "text-white" : "text-black"
//                       }`}
//                     >
//                       {item?.label}
//                     </h3>
//                     <p>{item?.para}</p>
//                   </div>
//                 </li>
//               );
//             })}
//           </ul>
//         </div>
//       </Menu>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { IoSearchOutline } from "react-icons/io5";
import { TiArrowSortedDown } from "react-icons/ti";
import { useStateContext } from "../../context/ContextProvider";

export default function CustomSelect({
  options,
  selectedOption,
  setSelectedOption,
  placeholder,
  label,
  accountPages,
  identity,
  adData,
  setAdData,
}) {
  console.log("identity: ", identity);
  console.log("selectedOption: ", selectedOption);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    setSnapchatCredentials,
  } = useStateContext();

  let selectedPage =
    identity == "second"
      ? selectedOption?.secondIdentity
      : selectedOption?.firstIdentity;

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? "bg-dark text-white"
      : "bg-white text-black";
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageSelect = (item) => {
    if (identity) {
      const pageKey =
        identity === "second" ? "secondIdentity" : "firstIdentity";
      console.log("page::::: ", pageKey);
      setSelectedOption((prev) => ({
        ...prev,
        [pageKey]: item,
      }));
    } else {
      setSelectedOption((prev) => ({
        ...prev,
        firstIdentity: item,
      }));
    }
    handleClose();
  };

  useEffect(() => {
    if (identity) {
      let page_id =
        identity == "first" ? "page_id" : "branded_content_sponsor_page_id";

      setAdData({
        ...adData,
        [page_id]: selectedPage?.id,
      });
    } else {
      setAdData((prevAdData) => ({
        ...prevAdData,
        creative: {
          ...prevAdData?.creative,
          object_story_spec: {
            ...prevAdData?.creative?.object_story_spec,
            page_id: selectedOption?.firstIdentity?.id, // Set the selected Page ID
          },
        },
      }));
    }
  }, [selectedOption]);

  return (
    <div className="w-full">
      <label htmlFor="" className="text-[12px] font-semibold mb-4">
        {label}
      </label>
      <div
        className="flex mt-2 items-center border px-2 py-1 w-full border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px]"
        onClick={(e) => setAnchorEl(e?.currentTarget)}
      >
        {selectedPage?.picture?.data?.url ? (
          <div className="h-full w-[30px]">
            <img src={selectedPage?.picture?.data?.url} />
          </div>
        ) : null}

        <div
          className={`p-2  ${getSummaryBgClass()} rounded-lg flex-1 focus:outline-none outline-none !outline-offset-0  `}
        >
          {selectedPage?.name || ""}
        </div>
        <span>
          <TiArrowSortedDown size={16} />
        </span>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { width: anchorEl && anchorEl.offsetWidth }, // <-- The line that does all
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": {
            padding: "0px !important", // Modify border radius
          },
          padding: "0px",
          zIndex: 9999, // Remove any max width that might be applied by default
        }}
      >
        <div className={`${getSummaryBgClass()} py-4`}>
          <div className="flex items-center gap-3 px-4 pt-3 mb-2">
            <IoSearchOutline size={16} />
            <input
              type="text"
              className={`focus:outline-none border-none ${getSummaryBgClass()}`}
              placeholder="Filter ..."
            />
          </div>
          <ul className="px-3">
            {accountPages?.map((item) => {
              return (
                <li
                  className={`flex gap-4 items-center mb-4 group cursor-pointer px-2 py-2 rounded-lg select-none ${
                    selectedPage?.id == item?.id ? "bg-[#C3DCF5]" : ""
                  }`}
                  onClick={() => handlePageSelect(item)}
                  // onClick={() => {
                  //   let page =
                  //     identity == "first" ? "firstIdentity" : "secondIdentity";
                  //   setSelectedOption({
                  //     ...selectedOption,
                  //     [page]: item,
                  //   });
                  // }}
                >
                  <div
                    className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                      selectedPage?.id === item?.id
                        ? "border-[5px]"
                        : "group-hover:border-[3px]"
                    }`}
                  ></div>
                  {item?.picture?.data?.url ? (
                    <div className="h-full w-[30px]">
                      <img src={item?.picture?.data?.url} />
                    </div>
                  ) : null}
                  <div className="flex-1">
                    <h3
                      className={`text-[14px] ${
                        currentMode === "dark" ? "text-white" : "text-black"
                      }`}
                    >
                      {item?.name}
                    </h3>
                    <p>{item?.id}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Menu>
    </div>
  );
}
