import { useState, useRef } from "react";
import { useStateContext } from "../../context/ContextProvider";
import imageCompression from "browser-image-compression";

import axios from "../../axoisConfig";
import {
  Modal,
  Backdrop,
  IconButton,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { BiImageAdd } from "react-icons/bi";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

const ImagePicker = ({
  imagePickerModal,
  setImagePickerModal,
  setData,
  isOnlyImage,
  imageProperties,
}) => {
  const { BACKEND_URL, currentMode, setUser, ReFetchProfile } =
    useStateContext();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [btnloading, setbtnloading] = useState(false);
  const pickerRef = useRef();

  const cropperRef = useRef();

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    console.log(e.target.files[0]);

    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
      setSelectedFile(files[0]);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleClickUpload = () => {
    pickerRef.current.click();
  };

  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }

  const getCropData = async () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const src = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL(selectedFile.type, 0.7);
      const file = await srcToFile(src, selectedFile.name, selectedFile.type);
      setData(file);
      setImagePickerModal(false);
    }
    setImagePickerModal(false);
    setSelectedFile(null);
    setSelectedImage(null);
  };

  return (
    <div>
      <Modal
        // keepMounted
        sx={{ zIndex: 1400 }} // Adjust z-index as needed
        open={imagePickerModal}
        onClose={() => {
          setImagePickerModal(false);
          setSelectedFile(null);
          setSelectedImage(null);
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          style={style}
          className={`w-[calc(100%-20px)] image-picker flex flex-col items-center justify-center outline-4 outline-white outline-dashed md:w-[60%] md:h-[70%]  ${
            currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          } absolute top-1/2 left-1/2 p-5 rounded-md`}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: 5,
              top: 2,
              zIndex: 10000,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => {
              setImagePickerModal(false);
              setSelectedFile(null);
              setSelectedImage(null);
            }}
          >
            <IoMdClose size={18} />
          </IconButton>
          {btnloading && (
            <div className="progress-bar-wrapper">
              <div
                className="progress-bar"
                style={{
                  background: `radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(hotpink ${uploadProgress}%, pink 0)`,
                }}
              >
                {uploadProgress}%
              </div>
            </div>
          )}
          {!selectedImage && (
            <>
              <BiImageAdd size={70} color="grey" />
              <div className="flex gap-3 items-center">
                {!isOnlyImage && (
                  <input
                    type="file"
                    accept=".mp4, .mov"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const allowedTypes = ["video/mp4", "video/quicktime"];
                      const maxSize = 32 * 1024 * 1024; // 32 MB in bytes

                      // Check if file exists, is an allowed type, and within the size limit
                      if (
                        file &&
                        allowedTypes.includes(file.type) &&
                        file.size <= maxSize
                      ) {
                        const video = document.createElement("video");
                        video.onloadedmetadata = () => {
                          if (
                            video.videoWidth < 1080 ||
                            video.videoHeight < 1920
                          ) {
                            toast.error(
                              "Please upload a video of at least 1080 * 1920 resolution.",
                              {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                              }
                            );
                          } else {
                            setData(file);
                            setImagePickerModal(false);
                            setSelectedFile(null);
                            setSelectedImage(null);
                          }
                        };
                        video.src = URL.createObjectURL(file);
                      } else {
                        // Show appropriate error message
                        if (file && file.size > maxSize) {
                          toast.error(
                            "The video size should be a maximum of 32 MB.",
                            {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            }
                          );
                        } else {
                          toast.error(
                            "Please upload a valid file ( MP4, MOV).",
                            {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            }
                          );
                        }
                        e.target.value = null; // Reset input if the file is not valid or too large
                      }
                    }}
                    style={{ display: "none" }}
                    id="top-snap-media"
                  />
                )}

                <Button
                  onClick={handleClickUpload}
                  variant="contained"
                  sx={{ py: 1 }}
                  color="info"
                >
                  Upload Image
                </Button>

                {!isOnlyImage && (
                  <label
                    className=" cursor-pointer p-3 px-5 bg-[#0288d1] text-white hover:border-[#F7D901]  rounded-[5px] border "
                    htmlFor="top-snap-media"
                  >
                    Upload Video
                  </label>
                )}
              </div>

              <input
                ref={pickerRef}
                type="file"
                accept=".png, .jpg, .jpeg"
                id="pick-image"
                hidden
                onInput={(e) => {
                  const file = e.target.files[0];
                  const allowedTypes = ["image/png", "image/jpeg"];

                  // Check if file exists and is an allowed type
                  if (file && allowedTypes.includes(file.type)) {
                    const img = new Image();
                    img.onload = () => {
                      if (
                        img.width < imageProperties?.width ||
                        img.height < imageProperties?.height
                      ) {
                        toast.error(
                          `Please upload a ${imageProperties?.width} * ${imageProperties?.height} image`,
                          {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          }
                        );
                      } else {
                        onChange(e);
                      }
                    };
                    img.src = URL.createObjectURL(file);
                  } else {
                    toast.error("Please upload a valid file ( PNG, JPG).", {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    e.target.value = null; // Reset input if the file is not valid
                  }
                }}
              />
            </>
          )}
          {selectedImage && (
            <Box className="relative w-[90%] h-[80%] mt-2 flex flex-col items-center">
              <Cropper
                ref={cropperRef}
                style={{ width: "100%", height: "80%" }}
                // zoomTo={0.5}
                // initialAspectRatio={9 / 16}
                src={selectedImage}
                aspectRatio={imageProperties?.aspectRatio || 9 / 16}
                viewMode={1}
                minCropBoxHeight={50}
                minCropBoxWidth={50}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
              />

              <Button
                onClick={getCropData}
                sx={{ mt: 5 }}
                size="large"
                variant="contained"
              >
                {btnloading ? (
                  <CircularProgress size={18} sx={{ color: "white" }} />
                ) : (
                  <span>done</span>
                )}
              </Button>
            </Box>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ImagePicker;
