import React, { useState, useEffect } from "react";
import { Backdrop, Checkbox, Modal } from "@mui/material";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import { selectStyles } from "../_elements/SelectStyles.jsx";
import axios from "../../axoisConfig.js";
import { CiFolderOn } from "react-icons/ci";
import { GoPlusCircle } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";
import { TiArrowSortedDown } from "react-icons/ti";
import Menu from "@mui/material/Menu";
import { IoIosImages } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { RiPagesLine } from "react-icons/ri";
import { FaMinus } from "react-icons/fa6";
import { IoIosCrop } from "react-icons/io";
import { LiaExchangeAltSolid } from "react-icons/lia";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { width } from "@mui/system";

const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const token = localStorage?.getItem("auth-token");

const PartnerShipModal = ({
  setPartnerShipModal,
  partnerShipModal,
  adData,
  setAdData,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,
  } = useStateContext();

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <Modal
      //   keepMounted
      open={partnerShipModal}
      onClose={() => setPartnerShipModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[70%] h-[600px] ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2  rounded-xl flex flex-col items-start`}
      >
        <div className="w-full flex flex-1 overflow-y-scroll">
          <div
            className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4 `}
          >
            <label htmlFor="" className="text-[14px] font-semibold mb-3">
              Enter partnership ad code, post ID or post URL
            </label>
            <p>
              This will set the first identity of the partnership ad and will
              use the media associated with the code or post info. For
              partnership ad code, contact the post's creator and request that
              they share it with you. For post ID or post URL, make sure that
              you have account-level permissions from the creator to run your ad
              with the associated media
            </p>
            <input
              type="text"
              className={`p-2 mt-4 ${getSummaryBgClass()} rounded-lg w-full focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
              value={adData?.branded_content_sponsor_page_id}
              placeholder="Enter ad code provided by the creator, or post ID or post URL"
              onChange={(e) =>
                setAdData((pre) => ({
                  ...pre,
                  branded_content_sponsor_page_id: e?.target?.value,
                }))
              }
            />
          </div>
        </div>
        <div className="flex items-center justify-end gap-3 w-full pt-3 border-t-[2px] py-2 px-4">
          <button
            onClick={() => setPartnerShipModal(false)}
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
              currentMode == "dark" ? "bg-dark text-white" : "bg-white"
            } `}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setPartnerShipModal(false);
              // setIsSingleAdSet(selectedCampaign?.value);
              //   setIsSingleAdSet(1);
              //   setAdSetDetails(null);
            }}
            className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
            // disabled={!selectedCampaign}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PartnerShipModal;
