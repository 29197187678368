import React, { useState, useEffect } from "react";
import { Backdrop, Checkbox, CircularProgress, Modal } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import CustomSelect from "./CustomSelect.js";
import { TbSwitchVertical } from "react-icons/tb";

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { width } from "@mui/system";

const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const token = localStorage?.getItem("auth-token");

const facebookPages = [
  {
    label: "Marketing of shoe products",
    para: "ID : 4328932094032493",
    icon: (
      <img
        src="/person.png"
        className="w-full h-full rounded-full object-cover"
      />
    ),
  },
  {
    label: "Second Page of Marketing",
    para: "ID : 320982398239898",
    icon: (
      <img
        src="/person.png"
        className="w-full h-full rounded-full object-cover"
      />
    ),
  },
];

const IdentityModal = ({
  setIdentityModal,
  identityModal,
  accountPages,
  accountLoading,
  adData,
  setAdData,
  setSelectedAccount,
  selectedAccount,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,
  } = useStateContext();

  const [selectedFbPage, setSelectedFbPage] = useState(facebookPages[0]);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <Modal
      //   keepMounted
      open={identityModal}
      onClose={() => setIdentityModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[70%] h-[700px] ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2  rounded-xl flex flex-col items-start`}
      >
        {accountLoading ? (
          <div className="w-full h-[700px] flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="w-full flex flex-1 overflow-y-scroll">
              <div
                className={`flex flex-col w-full ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4 `}
              >
                <label htmlFor="" className="text-[14px] font-semibold mb-3">
                  Select identities
                </label>
                <label htmlFor="" className="text-[14px] font-semibold mb-3">
                  First Identity
                </label>
                <CustomSelect
                  placeholder="Select facebook page"
                  label={"Choose a Facebook Page"}
                  options={facebookPages}
                  accountPages={accountPages}
                  setSelectedOption={setSelectedAccount}
                  selectedOption={selectedAccount}
                  identity={"first"}
                  adData={adData}
                  setAdData={setAdData}
                />
                <div className="flex items-end gap-3 mt-4">
                  <div className="flex-1">
                    {/* <CustomSelect
                      placeholder="Select facebook page"
                      label={"Choose an Instagram account"}
                      options={facebookPages}
                      setSelectedOption={setSelectedFbPage}
                      selectedOption={selectedFbPage}
                      accountPages={accountPages}
                    /> */}
                  </div>

                  {/* <div className="flex items-center gap-2">
                    <span>or</span>
                    <button
                      // onClick={(event) => setAnchorEl(event.currentTarget)}
                      className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium  flex items-center w-fit gap-3   ${
                        currentMode == "dark"
                          ? "bg-dark text-white"
                          : "bg-white"
                      } `}
                    >
                      Connect Account
                    </button>
                  </div> */}
                </div>
                {/* <div class="bg-white rounded-md shadow-md p-4 border-l-[2px] border-[#0A78BE]">
                  <div class="flex items-center mb-4">
                    <div class="bg-blue-500 text-white rounded-full p-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M11 5.882V19.24a1.75 1.75 0 01-3.5 0V5.882c0-1.003.87-1.75 1.75-1.75h.894c.69.003 1.402.538 1.607.976l.894 2.538a1.75 1.75 0 01-.157 2.923l-.894 2.538c-.205.638.157 1.402.976 1.607h.894c.88 0 1.75-.747 1.75-1.75V5.882zM18 8.75a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        />
                      </svg>
                    </div>
                    <h2 class="ml-2 font-bold text-lg">
                      Instagram account selection
                    </h2>
                  </div>
                  <p class="text-gray-600">
                    You can connect an Instagram account to represent this
                    identity on Instagram. If you don't connect one, this ad
                    will appear on Instagram with the name, profile picture and
                    other details from your Facebook Page.
                  </p>
                </div> */}
                <button
                  // onClick={(event) => setAnchorEl(event.currentTarget)}
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium  flex items-center w-fit gap-3 mt-3  ${
                    currentMode == "dark" ? "bg-dark text-white" : "bg-white"
                  } `}
                >
                  <TbSwitchVertical size={16} />
                  Switch Identities
                </button>
                <label htmlFor="" className="text-[14px] font-semibold mb-3">
                  Second Identity
                </label>
                <CustomSelect
                  placeholder="Choose a Facebook Page"
                  label={"Choose a Facebook Page"}
                  options={facebookPages}
                  setSelectedOption={setSelectedFbPage}
                  selectedOption={selectedAccount}
                  accountPages={accountPages}
                  identity={"second"}
                  adData={adData}
                  setAdData={setAdData}
                />
                {/* <CustomSelect
                  placeholder="Choose an Instagram account"
                  label={"Choose an Instagram account"}
                  options={facebookPages}
                  setSelectedOption={setSelectedFbPage}
                  selectedOption={selectedFbPage}
                /> */}
                <p>
                  You can connect new accounts by adding them as an ad partner
                  in Meta Business Suite.
                </p>
              </div>
            </div>
            <div className="flex items-center justify-end gap-3 w-full pt-3 border-t-[2px] py-2 px-4">
              <button
                onClick={() => setIdentityModal(false)}
                className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
                  currentMode == "dark" ? "bg-dark text-white" : "bg-white"
                } `}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setIdentityModal(false);
                  // setIsSingleAdSet(selectedCampaign?.value);
                  //   setIsSingleAdSet(1);
                  //   setAdSetDetails(null);
                }}
                className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
                // disabled={!selectedCampaign}
              >
                Continue
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default IdentityModal;
