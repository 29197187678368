import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Switch } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { MdOutlineDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import DeleteConfirm from "../snapchatMarketing/DeleteConfirmation";
import CustomSwitch from "./customSwitch";
const token = localStorage?.getItem("auth-token");

// Columns definition
const deliveryStatus = [
  {
    code: "INVALID_NOT_EFFECTIVE_ACTIVE",
    label: "Ad Set is inactive",
  },
  {
    code: "INVALID_NOT_ACTIVE",
    label: "Not Devlivering",
  },
];

const AdSetsTable = ({
  setAdSetDetails,
  setIsSingleAdSet,
  adSets,
  setAdSets,
  getAllAdSets,
  selectedRows,
  setSelectedRows,
  loading,
  searchQuery,
  setLoading,
  fb_token,
}) => {
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    DataGridStyles,
    fbCredentials,
  } = useStateContext();
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);

  console.log("adsets:: ", adSets);

  async function deleteAdSet(id) {
    window.FB.api(
      `/${id}`,
      "DELETE",
      {
        access_token: fbCredentials?.access_token,
        // access_token: fb_token,
      },
      (response) => {
        console.log("delete adset res: ", response);
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${response.error ? response.error.message : "Error delete ad."}`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          toast.success(`Adset Deleted.`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          getAllAdSets();

          setLoading(false);
        }
      }
    );
  }

  async function updateAdSet(data) {
    window.FB.api(
      `/${data?.id}`,
      "POST",
      {
        access_token: fbCredentials?.access_token,
        // access_token: fb_token,
        status: data?.status == "PAUSED" ? "ACTIVE" : "PAUSED",
      },
      (response) => {
        console.log("updated adset res: ", response);
        if (!response || response?.error) {
          setLoading(false);

          console.log("error response: ", response);
          toast.error(
            `${
              response.error
                ? response?.error?.error_user_msg
                : "Error updating adset."
            }`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          toast.success(`Adset Status Updated.`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          getAllAdSets();

          setLoading(false);
        }
      }
    );
  }

  // useEffect(() => {
  //   if (snapchatCredentials?.access_token) {
  //     getAllAdSets();
  //   }
  // }, [snapchatCredentials]);

  const columns = [
    {
      field: "status",
      headerName: "Off/On",
      headerAlign: "center",
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      minWidth: 150,
      renderCell: (params) => (
        <CustomSwitch
          checked={params.row.status == "PAUSED" ? false : true}
          onChange={(e) => updateAdSet(params?.row, e?.target?.checked)}
        />
      ),
    },
    {
      field: "name",
      headerName: "Ad set",
      headerAlign: "left",
      headerClassName: "pl-5",
      renderHeader: (params) => {
        return (
          <div className="pl-12 font-semibold">{params.colDef.headerName}</div>
        );
      },
      renderCell: (params) => (
        <Name
          value={params?.value}
          onEdit={() => {
            setAdSetDetails(params?.row);
            setIsSingleAdSet(true);
          }}
          onDelete={() => {
            // deleteAdSet(params?.row?.id);
            setOpenDeleteConfirm({ id: params?.row?.id, name: params?.value });
          }}
        />
      ),
      minWidth: 210,
    },

    // {
    //   field: "delivery_status",
    //   headerName: "Delivery",
    //   headerAlign: "center",
    //   minWidth: 200,
    //   renderHeader: (params) => {
    //     return <div className=" font-semibold">{params.colDef.headerName}</div>;
    //   },
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params?.value?.map(
    //           (val) =>
    //             deliveryStatus?.find((status) => status?.code == val)
    //               ?.label && (
    //               <div>
    //                 {
    //                   deliveryStatus?.find((status) => status?.code == val)
    //                     ?.label
    //                 }
    //               </div>
    //             )
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "daily_budget",
      headerName: "Daily Budget",
      headerAlign: "center",
      minWidth: 190,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return (
          <div className="w-full text-center">
            <span>
              {params?.value ??
                `${Number(params?.value)?.toFixed(2)} ${
                  params?.row?.account_currency || ""
                }`}
            </span>
          </div>
        );
      },
    },

    {
      field: "budget_remaining",
      headerName: "Budget Remaining",
      headerAlign: "center",
      minWidth: 180,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return (
          <div className="w-full text-center">
            {params?.value ? (
              <>
                <span>
                  {params?.value ?? Number(params?.value)?.toFixed(2)}
                </span>
              </>
            ) : (
              "_"
            )}
          </div>
        );
      },
    },
    {
      field: "lifetime_budget",
      headerName: "Lifetime Budget",
      headerAlign: "center",
      minWidth: 180,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return (
          <div className="w-full text-center">
            {params?.value ? (
              <>
                <span>
                  {params?.value ?? Number(params?.value)?.toFixed(2)}
                </span>
              </>
            ) : (
              "_"
            )}
          </div>
        );
      },
    },

    {
      field: "optimization_goal",
      headerName: "Goal",
      headerAlign: "center",
      minWidth: 170,
      renderHeader: (params) => {
        return (
          <div className="pl-7 font-semibold">{params.colDef.headerName}</div>
        );
      },
      renderCell: (params) => {
        return <div className="pl-7 w-full text-center">{params?.value}</div>;
      },
    },
    {
      field: "clicks",
      headerName: "Clicks",
      headerAlign: "center",
      minWidth: 170,
      renderHeader: (params) => {
        return (
          <div className="pl-7 font-semibold">{params.colDef.headerName}</div>
        );
      },
      renderCell: (params) => {
        return (
          <div className="pl-7 w-full text-center">
            {params?.value ?? Number(params?.value)?.toFixed(2)}
          </div>
        );
      },
    },
    {
      field: "cpc",
      headerName: "CPC",
      headerAlign: "center",
      minWidth: 170,
      renderHeader: (params) => {
        return (
          <div className="pl-7 font-semibold">{params.colDef.headerName}</div>
        );
      },
      renderCell: (params) => {
        return (
          <div className="pl-7 w-full text-center">
            {params?.value ?? Number(params?.value)?.toFixed(2)}
          </div>
        );
      },
    },
    {
      field: "spend",
      headerName: "Spend",
      headerAlign: "center",
      minWidth: 170,
      renderHeader: (params) => {
        return (
          <div className="pl-7 font-semibold">{params.colDef.headerName}</div>
        );
      },
      renderCell: (params) => {
        return (
          <div className="pl-7 w-full text-center">
            {params?.value ?? Number(params?.value)?.toFixed(2)}
          </div>
        );
      },
    },
    {
      field: "reach",
      headerName: "Reach",
      headerAlign: "center",
      minWidth: 170,
      renderHeader: (params) => {
        return (
          <div className="pl-7 font-semibold">{params.colDef.headerName}</div>
        );
      },
      renderCell: (params) => {
        return (
          <div className="pl-7 w-full text-center">
            {params?.value ?? Number(params?.value)?.toFixed(2)}
          </div>
        );
      },
    },
    {
      field: "impressions",
      headerName: "Impressions",
      headerAlign: "center",
      minWidth: 170,
      renderHeader: (params) => {
        return (
          <div className="pl-7 font-semibold">{params.colDef.headerName}</div>
        );
      },
      renderCell: (params) => {
        return (
          <div className="pl-7 w-full text-center">
            {params?.value ?? Number(params?.value)?.toFixed(2)}
          </div>
        );
      },
    },
  ];

  const filteredAdSets = adSets?.filter((row) =>
    searchQuery === ""
      ? true
      : Object.values(row).some(
          (value) =>
            value?.toString()?.toLowerCase() == searchQuery?.toLowerCase()
        )
  );

  return (
    <>
      <div>
        <Box
          className={`closed-datatable ${currentMode}-mode-datatable`}
          sx={{
            // ...DataGridStyles,
            ...DataGridStyles,
            "& .MuiDataGrid-columnHeaders": {
              ...DataGridStyles["& .MuiDataGrid-columnHeaders"],
              backgroundColor: "transparent",
              color: currentMode === "dark" ? "white" : "black",
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
              borderTop: "1px solid rgba(224, 224, 224, 1)",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            },
            "& .MuiButtonBase-root": {
              ...DataGridStyles["& .MuiButtonBase-root"],
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
            },
            "& .MuiDataGrid-virtualScroller": {
              ...DataGridStyles["& .MuiDataGrid-virtualScroller"],
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
            },
            "& .MuiDataGrid-cell": {
              borderRight: "1px solid #e5e7eb",
            },
            "& .MuiDataGrid-columnHeader": {
              borderRight: "1px solid #e5e7eb",
            },
            ".MuiDataGrid-columnHeaders": {
              paddingLeft: "3px", // Apply padding here
            },
            position: "relative",
            marginBottom: "50px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <DataGrid
            // rows={adSets}
            rows={filteredAdSets}
            loading={loading}
            columns={columns}
            pageSize={5}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            selectionModel={selectedRows?.adSets}
            onSelectionModelChange={(rows) =>
              setSelectedRows((pre) => ({ ...pre, adSets: rows }))
            }
          />
        </Box>
      </div>
      <DeleteConfirm
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        message={` Are you sure you want to delete AdSet ${openDeleteConfirm?.name}? `}
        onDelete={deleteAdSet}
        title="Confirm Delete AdSet"
      />
    </>
  );
};

export default AdSetsTable;

const Name = ({ value, onEdit, onDelete }) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const { currentMode } = useStateContext();
  return (
    <>
      <div
        className="pl-11 w-full text-left"
        onMouseEnter={() => setIsMouseEnter(true)}
        onMouseLeave={() => setIsMouseEnter(false)}
      >
        <p>{value}</p>
        {isMouseEnter && (
          <div className="flex gap-3">
            {/* <button
              // className="mt-2 text-gray-600 hover:text-gray-900"
              className={`mt-2 ${
                currentMode === "dark"
                  ? "text-white hover:text-gray-600"
                  : "text-gray-600 hover:text-gray-900"
              } `}
              onClick={onEdit}
            >
              Edit
            </button> */}
            <button
              // className="mt-2 text-gray-600 hover:text-gray-900 flex gap-2 items-center"
              className={`mt-2 ${
                currentMode === "dark"
                  ? "text-white hover:text-gray-600"
                  : "text-gray-600 hover:text-gray-900"
              }  flex gap-2 items-center`}
              onClick={onDelete}
            >
              Delete <MdOutlineDelete size={16} />
            </button>
          </div>
        )}
      </div>
    </>
  );
};
