import React, { useEffect, useState, useRef } from "react";

import { TiArrowSortedDown } from "react-icons/ti";
import {
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
  Backdrop,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox,
  Typography,
} from "@mui/material";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import classNames from "classnames";

import { useStateContext } from "../../context/ContextProvider";
import { MdClose } from "react-icons/md";
import { selectStyles } from "../_elements/SelectStyles.jsx";
import Select from "react-select";
import TimeScheduleView from "./TimeScheduleView.js";
import { GoPlusCircle } from "react-icons/go";
import { FaCheck, FaWhatsapp } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import { IoAddSharp } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CreatePost from "./createPost.js";
import AdCreativeModal from "./AdCreative.js";
import SelectPost from "./SelectPost.js.js";
import PartnerShipModal from "./PartnerShipModal.js";
import { MdOutlineDelete } from "react-icons/md";
import { SlArrowRight } from "react-icons/sl";
import { RiFacebookLine } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa6";
import { TbSwitchVertical } from "react-icons/tb";
import IdentityModal from "./IdentityModal.js";
import { toast } from "react-toastify";
import CustomSelect from "./CustomSelect.js";
import CarouselCard from "./CarouselCard.js";
import BuildUrlParams from "./BuildUrlParams.js";
const style = {
  transform: "translate(0%, 0%)",
  boxShadow: 24,
};
const stripeStyle = {
  backgroundColor: "#e5e7eb", // Light gray background
  backgroundImage:
    "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
  backgroundSize: "20px 20px", // Size of the stripes
};
const token = localStorage?.getItem("auth-token");
const categories = [
  {
    label: "Financial products and services (formerly Credit)",
    value: "FINANCIAL_PRODUCTS_SERVICES",
  },
  {
    label: "Employment",
    value: "EMPLOYMENT",
  },
  {
    label: "Housing",
    value: "HOUSING",
  },
  {
    label: "Social issues, elections or politics",
    value: "ISSUES_ELECTIONS_POLITICS",
  },
];
const budgetTypes = [
  {
    label: "Daily Budget",
    value: "daily_budget",
  },
  {
    label: "Lifetime Budget",
    value: "lifetime_budget",
  },
];
const bidStrategies = [
  {
    label: "Highest volume",
    value: "LOWEST_COST_WITHOUT_CAP", // Aligning with the enum for automatic bidding
    description:
      "Get the most results for your budget without limiting your bid amount. Best for cost efficiency but might lack stable average costs.",
  },
  {
    label: "Cost per result goal",
    value: "COST_CAP", // Aligning with the enum for cost cap
    description:
      "Control your cost per optimization event while aiming for results. May require careful cost target settings.",
  },
  {
    label: "Bid Cap",
    value: "LOWEST_COST_WITH_BID_CAP", // Aligning with the enum for manual maximum-cost bidding
    description:
      "Limit actual bids to your specified amount. Offers control over costs but may reduce ad delivery if limits are too low.",
  },
];

const dailyBudgetTypes = [
  {
    label: "Increase daily budget by value amount (Rs) ",
    value: "ABSOLUTE",
  },
  {
    label: "Increase daily budget by value percentage (%)",
    value: "MULTIPLIER",
  },
];
const adCreateTypes = [
  {
    label: "Create Ad",
    value: 1,
  },
  // {
  //   label: "Use existing post",
  //   value: 2,
  // },
  // {
  //   label: "Use Creative Hub mockup",
  //   value: 3,
  // },
];
const formates = [
  {
    label: "Single image or video",
    value: 1,
    para: "One image or video, or a slideshow with multiple images",
  },
  // {
  //   label: "Carousel",
  //   value: 2,
  //   para: "Two or more scrollable images or videos",
  // },
  // {
  //   label: "Collection",
  //   value: 3,
  //   para: "A group of items that opens into a full-screen mobile experience",
  // },
];
const creativeSources = [
  {
    label: "Manual upload",
    value: 1,
    para: "Manually upload images or videos.",
  },
  // {
  //   label: "Advantage+ catalogue ads",
  //   value: 2,
  //   para: "Automatically use media from your catalogue. We'll show each person the catalogue items that they're most likely to engage with.",
  // },
];

const adTypes = [
  {
    label: "Image ad",
    value: "image_ad",
  },
  {
    label: "Video ad",
    value: "video_ad",
  },
];
const carouselCards = [
  {
    label: "Add image cards",
    value: 1,
  },
  {
    label: "Add video cards",
    value: 2,
  },
];

const buttons = [
  { label: "No button", value: "" }, // No button means no action
  { label: "See menu", value: "SEE_MENU" },
  { label: "Shop now", value: "SHOP_NOW" },
  { label: "Sign up", value: "SIGN_UP" },
  { label: "Subscribe", value: "SUBSCRIBE" },
  { label: "Watch more", value: "WATCH_MORE" },
  { label: "Send WhatsApp message", value: "WHATSAPP_MESSAGE" },
  { label: "Learn more", value: "LEARN_MORE" },
  { label: "Apply now", value: "APPLY_NOW" },
  { label: "Book now", value: "BOOK_NOW" },
  { label: "Contact us", value: "CONTACT_US" },
  { label: "Download", value: "DOWNLOAD" },
  { label: "Get directions", value: "GET_DIRECTIONS" },
  { label: "Get quote", value: "GET_QUOTE" },
  { label: "Get showtimes", value: "GET_SHOWTIMES" },
  { label: "Listen now", value: "LISTEN_NOW" },
  { label: "Send message", value: "SEND_MESSAGE" },
  { label: "Order now", value: "ORDER_NOW" },
  { label: "Request time", value: "REQUEST_TIME" },
  { label: "Save", value: "SAVE" },
];

const destinations = [
  // {
  //   label: "Instant Experience",
  //   value: 1,
  //   para: "Send people to a fast-loading, mobile-optimised experience.",
  // },
  {
    label: "Website",
    value: 2,
    para: "Send people to your website.",
  },
  // {
  //   label: "Call",
  //   value: 3,
  //   para: "Let people call you directly.",
  // },
  // {
  //   label: "Messaging apps",
  //   value: 4,
  //   para: "Send people to Messenger, Instagram and WhatsApp.",
  // },
];
const pageEvents = [
  {
    label: "Use the app as the ad sponsor and destination when",
    value: 1,
    para: "people click on your profile.",
  },
  {
    label: "Use this Page as the ad sponsor and destination when",
    value: 2,
    para: "people click on your profile.",
  },
];

const SingleAd = ({
  isSingleAd,
  setIsSingleAd,
  adData,
  setAdData,
  fb_token,
  createAd,
  btnLoading,
}) => {
  const {
    isLangRTL,
    BACKEND_URL,
    i18n,
    currentMode,
    t,
    snapchatCredentials,
    themeBgImg,
    darkModeColors,
    primaryColor,
    fbCredentials,
  } = useStateContext();
  const [selectedPage, setSelectedPage] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isChangesOccured, setIsChangesOccured] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [adCreateType, setAdCreateType] = useState(adCreateTypes[0]);
  const [selectedFormate, setSelectedFormate] = useState(formates[0]);
  const [createPostModal, setCreatePostModal] = useState(false);
  const [selectedCreativeSource, setSelectedCreativeSource] = useState(
    creativeSources[0]
  );
  const [selectedPageEvent, setSelectedPageEvent] = useState(pageEvents[0]);
  const [selectedAdType, setSelectedAdType] = useState(null);
  const [selectedCarouselCard, setSelectedCarouselCard] = useState(null);
  const [selectedBtn, setSelectedBtn] = useState(buttons[0]);
  const [adCreativeModal, setAdCreativeModal] = useState(false);
  const [selectPostModal, setSelectPostModal] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(
    destinations[0]
  );
  const [selectedApp, setSelectedApp] = useState();
  const [partnerShipAd, setPartnerShipAd] = useState(false);
  const [partnerShipModal, setPartnerShipModal] = useState(false);
  const [identityModal, setIdentityModal] = useState(false);
  const [accountPages, setAccountPages] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({
    firstIdentity: null,
    secondIdentity: null,
  });
  const [accountLoading, setAccountLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [postList, setPostList] = useState([]);
  const [singlePost, setSinglePost] = useState(null);
  console.log("single post:: ", singlePost);

  const [value, setValue] = useState(null);
  const [error, setError] = useState(false);

  const [ctaUrl, setCtaUrl] = useState("");

  const handleContact = (isAlt) => {
    setError(false);

    const inputValue = value;
    console.log("Phone: ", inputValue);
    if (inputValue && isPossiblePhoneNumber(inputValue)) {
      console.log("Possible: ", inputValue);
      if (isValidPhoneNumber(inputValue)) {
        console.log("Valid input: ", inputValue);

        setAdData((pre) => ({
          ...pre,
          creative: {
            ...pre.creative,

            object_story_spec: {
              link_data: {
                link: formatPhoneNumberIntl(inputValue),
                call_to_action: {
                  type: "CALL_NOW",
                  value: {
                    link: formatPhoneNumberIntl(inputValue),
                  },
                },
              },
              // page_id: "<PAGE_ID>",
            },
          },
        }));

        setError(false);
      } else {
        setError("Not a valid number.");
      }
    } else {
      setError("Not a valid number.");
    }
  };

  const handleDestinationChange = (destination, e) => {
    let adCreative = {
      ...adData?.creative,
      object_story_spec: {
        ...adData?.creative?.object_story_spec,
        link_data: {
          ...adData?.creative?.object_story_spec?.link_data, // Keep previous state data
        },
      },
    };

    switch (destination) {
      case "Website":
        adCreative.object_story_spec.link_data = {
          ...adCreative.object_story_spec.link_data,
          link: e.target.value,
        };
        break;

      case "Call":
        adCreative.object_story_spec.link_data = {
          ...adCreative.object_story_spec.link_data,
          link: e.target.value,
          call_to_action: {
            type: "CALL_NOW",
            value: {
              link: e.target.value,
            },
          },
        };
        break;

      case "Messaging apps":
        adCreative.object_story_spec.link_data = {
          ...adCreative.object_story_spec.link_data,
          link: "https://m.me/<PAGE_ID>",
          call_to_action: {
            type: "MESSAGE_PAGE",
            value: {
              link: "https://m.me/<PAGE_ID>",
            },
          },
        };
        break;

      case "Instant Experience":
        adCreative.object_story_spec.link_data = {
          ...adCreative.object_story_spec.link_data,
          link: "https://www.example.com",
          call_to_action: {
            type: "USE_APP",
            value: {
              link: "https://www.example.com",
            },
          },
        };
        adCreative.object_story_spec.template = {
          elements: [
            {
              type: "button",
              button_text: "Learn More",
              button_link: "https://www.example.com",
            },
          ],
        };
        break;

      default:
        break;
    }

    // Update adData state with merged adCreative
    setAdData({ ...adData, creative: adCreative });
  };

  const handleCTAChange = (selectedOption) => {
    setSelectedBtn(selectedOption);

    setAdData((prevAdData) => ({
      ...prevAdData,
      creative: {
        ...prevAdData?.creative,
        object_story_spec: {
          ...prevAdData?.creative?.object_story_spec,
          call_to_action: {
            ...prevAdData?.creative?.object_story_spec?.call_to_action,
            type: selectedOption?.value, // Set the Call-to-Action type
            value: {
              link: ctaUrl || "", // Keep current URL or empty if not set
            },
          },
        },
      },
    }));
  };

  // Handle URL input change
  const handleURLChange = (e) => {
    const url = e.target.value;
    setCtaUrl(url);

    setAdData((prevAdData) => ({
      ...prevAdData,
      creative: {
        ...prevAdData?.creative,
        object_story_spec: {
          ...prevAdData?.creative?.object_story_spec,
          call_to_action: {
            ...prevAdData?.creative?.object_story_spec?.call_to_action,
            value: {
              link: url,
            },
          },
        },
      },
    }));
  };

  const fetchUserPages = async () => {
    console.log("Access Token:", fbCredentials?.access_token);

    setAccountLoading(true);
    try {
      // Call the Graph API to get pages
      const response = await new Promise((resolve, reject) => {
        window.FB.api(
          "/me/accounts",
          "GET",
          {
            access_token: fbCredentials?.access_token,
            // access_token: fb_token,
            fields:
              "id,name,category,access_token,picture,instagram_business_account{id,username,profile_picture_url},page_token",
          },
          (response) => {
            if (response?.error) {
              reject(response.error);
            } else {
              resolve(response);
            }
          }
        );
      });

      // Log the pages for debugging
      console.log("Fetched Pages:", response?.data);

      // Check if response.data is valid before setting state
      if (response?.data) {
        setAccountPages(response?.data);
      } else {
        console.warn("No data received from API response.");
        setAccountPages([]);
      }

      setAccountLoading(false);
    } catch (error) {
      setAccountLoading(false);
      console.error("Error fetching Facebook Pages:", error);
      toast.error(
        `${error?.message ? error?.message : "Error fetching Facebook pages."}`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      throw error;
    }
  };

  function fetchBrandedContentPosts(pageId, pageAccessToken) {
    return new Promise((resolve, reject) => {
      setPostLoading(true);
      window.FB.api(
        `/${pageId}/posts`,
        "GET",
        {
          access_token: pageAccessToken,
          fields:
            "fields=full_picture,attachments{media,title,subattachments,media_type},message",
        },
        (response) => {
          if (response?.error) {
            setPostLoading(false);
            console.error(
              "Error fetching branded content posts:",
              response?.error
            );
            toast.error(
              `${
                response?.error
                  ? response?.error?.message
                  : "Error fetching brand content posts."
              }`,
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
            reject(response?.error);
          } else {
            console.log("Branded Content Posts:", response?.data);

            const postsData = response?.data?.map((post) => ({
              id: post?.id,
              post_id: post?.id,
              message: post?.message,
              media: post?.attachments?.data[0]?.media_type,
              attachments: post?.attachments,
              full_picture: post?.full_picture,
              dateCreated: post?.created_time,
            }));

            setPostList(postsData);
            setPostLoading(false);
            resolve(response.data);
          }
        }
      );
    });
  }
  const [carouselCard, setCarouselCard] = useState(false);
  const [urlParams, setUrlParams] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setAdData({
      ...adData,
      multi_advertiser_optimization: event.target.checked,
    });
  };
  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      setIsSingleAd(false);
    }, 1000);
  };

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  function DestinationComponent() {
    if (selectedDestination?.label === "Messaging apps") {
      return (
        <MessagingApps
          selectedApp={selectedApp}
          setSelectedApp={setSelectedApp}
        />
      );
    }
  }

  useEffect(() => {
    fetchUserPages();
  }, []);

  return (
    <>
      <Modal
        // keepMounted
        open={isSingleAd}
        onClose={() => setIsSingleAd(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
        w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            // onClick={handleCloseTimelineModel}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>

          <div
            style={style}
            className={` ${getSummaryBgClass()} ${
              currentMode === "dark"
                ? "bg-gradient-to-br from-[#3A3F47] to-[#20272C]"
                : "bg-gradient-to-br from-[#F3EEF0] to-[#E7F0F9]"
            } ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && " border-primary border-r-2"
                : currentMode === "dark" && " border-primary border-l-2"
            } 
             pt-4 h-[100vh] w-[80vw] overflow-y-scroll border-primary px-7 flex flex-col 
            `}
          >
            <h2 className="font-bold text-[20px] mb-5">Build Your Ad</h2>
            <div className={`  rounded-3xl px-8 py-4 flex-1`}>
              <div
                className={`flex flex-col  ${getSummaryBgClass()} rounded-lg py-3 px-4`}
              >
                <label htmlFor="" className="text-[14px] font-semibold mb-3">
                  Ad Name
                </label>
                <input
                  type="text"
                  className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                  value={adData?.name}
                  placeholder="Ad Name"
                  onChange={(e) =>
                    setAdData((pre) => ({
                      ...pre,
                      name: e?.target?.value,
                    }))
                  }
                />
              </div>
              {/* <div
                className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4`}
              >
                <div className="flex items-center justify-between ">
                  <label
                    htmlFor=""
                    className="text-[14px] font-semibold mb-1 flex gap-2 items-center"
                  >
                    Partnership ad
                  </label>
                  <div>
                    <FormControlLabel
                      value=""
                      control={
                        <Switch
                          color="primary"
                          size="medium"
                          checked={partnerShipAd}
                          onChange={(e) => {
                            setPartnerShipAd((pre) => !pre);
                            if (partnerShipAd == false) {
                              setAdData((prevAdData) => ({
                                ...prevAdData,
                                creative: {
                                  ...prevAdData?.creative,
                                  object_story_spec: {
                                    ...prevAdData?.creative?.object_story_spec,
                                    page_id: null, // Set the selected Page ID
                                  },
                                },
                              }));
                            }
                          }}
                        />
                      }
                      label={"Off"}
                      labelPlacement="start"
                      sx={{
                        margin: "0px",
                        marginTop: `10px`,
                      }}
                    />
                  </div>
                </div>
                <p>
                  Run ads with creators, brands and other businesses. These ads
                  will feature both identities in the header.
                </p>
                {partnerShipAd && (
                  <button
                    onClick={(e) => setPartnerShipModal(true)}
                    className={`p-3 px-5 justify-center  hover:border-[#0A78BE] rounded-[5px] border font-medium mb-4 flex items-center w-full gap-3 mt-3  ${
                      currentMode == "dark" ? "bg-dark text-white" : "bg-white"
                    } `}
                  >
                    Enter ad code or post info
                  </button>
                )}
              </div> */}
              {partnerShipAd ? (
                <div
                  className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4`}
                >
                  <div className="flex justify-between items-center">
                    <label
                      htmlFor=""
                      className="text-[14px] font-semibold mb-3"
                    >
                      Identity
                    </label>
                    <div className="flex gap-3 items-center">
                      <button
                        className={`p-3 px-5 justify-center  hover:border-[#0A78BE] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                          currentMode == "dark"
                            ? "bg-dark text-white"
                            : "bg-white"
                        } `}
                        onClick={() => {
                          setIdentityModal(true);
                          fetchUserPages();
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className={`p-3 px-5 justify-center  hover:border-[#0A78BE] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                          currentMode == "dark"
                            ? "bg-dark text-white"
                            : "bg-white"
                        } `}
                      >
                        <MdOutlineDelete size={18} />
                      </button>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setIdentityModal(true);
                      fetchUserPages();
                    }}
                    className="cursor-pointer"
                  >
                    {selectedAccount?.firstIdentity ? (
                      <IdentityCard
                        firstIcon={selectedAccount?.firstIdentity}
                        // secondIcon={<FaInstagram size={18} />}
                        title={selectedAccount?.firstIdentity?.name}
                        defaultTitle={"First Identity"}
                        accountLoading={accountLoading}
                        accountPages={accountPages}
                      />
                    ) : (
                      <h1 className="text-[#0A78BE]">First Identity.</h1>
                    )}
                    <div>
                      <div className="w-full bg-gray-200 h-[1px] relative px-[100px] my-4">
                        <div
                          className={`p-2 ${getSummaryBgClass()} absolute top-0 left-1/2 -translate-y-1/2 -translate-x-1/2`}
                        >
                          <TbSwitchVertical size={16} />
                        </div>
                      </div>
                    </div>
                    {selectedAccount?.secondIdentity ? (
                      <IdentityCard
                        firstIcon={selectedAccount?.secondIdentity}
                        secondIcon={<FaInstagram size={18} />}
                        defaultTitle={selectedAccount?.secondIdentity?.name}
                        accountLoading={accountLoading}
                        accountPages={accountPages}
                      />
                    ) : (
                      <h1 className="text-[#0A78BE]">Second Identity</h1>
                    )}
                  </div>

                  {/* <label htmlFor="" className="text-[14px] font-semibold mb-1">
                    * Facebook Page
                  </label>
                  <div className="w-full ">
                    <Select
                      id="public-Profiles"
                      options={carouselCards}
                      value={selectedCarouselCard}
                      onChange={(e) => {
                        setSelectedCarouselCard(e);
                      }}
                      placeholder={"+ Add cards"}
                      className={`mt-1`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>
                  ​
                  <ul className="pl-4  w-1/2 px-4">
                    {pageEvents?.map((objective) => {
                      return (
                        <li
                          className={`flex gap-4 items-center mb-8 group cursor-pointer px-2 py-2 rounded-lg  `}
                          onClick={() => setSelectedPageEvent(objective)}
                        >
                          {" "}
                          <div
                            className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                              selectedPageEvent?.label === objective?.label
                                ? "border-[5px]"
                                : "group-hover:border-[3px]"
                            }  `}
                          ></div>
                          <div className="flex-1">
                            <h3
                              className={`text-[14px] ${
                                currentMode === "dark"
                                  ? "text-white"
                                  : "text-black"
                              }`}
                            >
                              {objective?.label}
                            </h3>
                            <p>{objective?.para}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <label htmlFor="" className="text-[14px] font-semibold mb-3">
                    Instagram account
                  </label>
                  <div className="w-full flex items-center  gap-2">
                    <Select
                      id="public-Profiles"
                      options={adCreateTypes}
                      value={adCreateType}
                      onChange={(e) => {
                        setAdCreateType(e);
                      }}
                      placeholder={""}
                      className={`mt-1`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                    <div className="flex items-center gap-2 mb-4">
                      <span>or</span>
                      <button
                        // onClick={(event) => setAnchorEl(event.currentTarget)}
                        className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                          currentMode == "dark"
                            ? "bg-dark text-white"
                            : "bg-white"
                        } `}
                      >
                        Connect Account
                      </button>
                    </div>
                  </div> */}
                </div>
              ) : (
                <div
                  className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4`}
                >
                  <label htmlFor="" className="text-[14px] font-semibold mb-3">
                    Identity
                  </label>
                  <label htmlFor="" className="text-[14px] font-semibold mb-1">
                    * Facebook Page
                  </label>
                  <div className="w-full ">
                    <CustomSelect
                      placeholder="Select facebook page"
                      label={"Choose a Facebook Page"}
                      accountPages={accountPages}
                      setSelectedOption={setSelectedAccount}
                      selectedOption={selectedAccount}
                      adData={adData}
                      setAdData={setAdData}
                    />
                  </div>
                  ​
                  {/* <ul className="pl-4  w-1/2 px-4">
                    {pageEvents?.map((objective) => {
                      return (
                        <li
                          className={`flex gap-4 items-center mb-8 group cursor-pointer px-2 py-2 rounded-lg  `}
                          onClick={() => setSelectedPageEvent(objective)}
                        >
                          {" "}
                          <div
                            className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                              selectedPageEvent?.label === objective?.label
                                ? "border-[5px]"
                                : "group-hover:border-[3px]"
                            }  `}
                          ></div>
                          <div className="flex-1">
                            <h3
                              className={`text-[14px] ${
                                currentMode === "dark"
                                  ? "text-white"
                                  : "text-black"
                              }`}
                            >
                              {objective?.label}
                            </h3>
                            <p>{objective?.para}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <label htmlFor="" className="text-[14px] font-semibold mb-3">
                    Instagram account
                  </label>
                  <div className="w-full flex items-center  gap-2">
                    <Select
                      id="public-Profiles"
                      options={adCreateTypes}
                      value={adCreateType}
                      onChange={(e) => {
                        setAdCreateType(e);
                      }}
                      placeholder={""}
                      className={`mt-1`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                    <div className="flex items-center gap-2 mb-4">
                      <span>or</span>
                      <button
                        // onClick={(event) => setAnchorEl(event.currentTarget)}
                        className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                          currentMode == "dark"
                            ? "bg-dark text-white"
                            : "bg-white"
                        } `}
                      >
                        Connect Account
                      </button>
                    </div>
                  </div> */}
                </div>
              )}
              <div
                className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4`}
              >
                <label htmlFor="" className="text-[14px] font-semibold mb-3">
                  Ad setup
                </label>
                <div className="w-full ">
                  <Select
                    id="public-Profiles"
                    options={adCreateTypes}
                    value={adCreateType}
                    onChange={(e) => {
                      setAdCreateType(e);
                      if (e.value == 2) {
                        setSelectedPage(
                          adData?.creative?.object_story_spec?.page_id
                        );
                        setAdData((prevAdData) => ({
                          ...prevAdData,
                          creative: {
                            ...prevAdData?.creative,
                            object_story_spec: null,
                          },
                        }));
                      } else if (e.value == 1) {
                        setAdData((prevAdData) => ({
                          ...prevAdData,
                          creative: {
                            ...prevAdData?.creative,
                            object_story_id: null,
                          },
                        }));
                      }
                    }}
                    placeholder={""}
                    className={`mt-1`}
                    menuPortalTarget={document.body}
                    styles={selectStyles(currentMode, primaryColor)}
                  />
                </div>
                {adCreateType?.label === "Create Ad" && (
                  <>
                    <label
                      htmlFor=""
                      className="text-[14px] font-semibold mb-3"
                    >
                      Creative source
                    </label>
                    <p>
                      Choose how you'd like to provide the media for your ad.
                    </p>
                    <ul className="pl-4 pt-4 w-1/2 px-4">
                      {creativeSources?.map((objective) => {
                        return (
                          <li
                            className={`flex gap-4 items-center mb-8 group cursor-pointer px-2 py-2 rounded-lg  `}
                            onClick={() => setSelectedCreativeSource(objective)}
                          >
                            {" "}
                            <div
                              className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                                selectedCreativeSource?.label ===
                                objective?.label
                                  ? "border-[5px]"
                                  : "group-hover:border-[3px]"
                              }  `}
                            ></div>
                            <div className="flex-1">
                              <h3
                                className={`text-[14px] ${
                                  currentMode === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                {objective?.label}
                              </h3>
                              <p>{objective?.para}</p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}

                {adCreateType?.label === "Create Ad" && (
                  <>
                    <label
                      htmlFor=""
                      className="text-[14px] font-semibold mb-3"
                    >
                      Format
                    </label>
                    <p>Choose how you'd like to structure your ad.</p>

                    <ul className="pl-4 pt-4 w-1/2 px-4">
                      {formates?.map((objective) => {
                        return (
                          <li
                            className={`flex gap-4 items-center mb-8 group cursor-pointer px-2 py-2 rounded-lg  `}
                            onClick={() => setSelectedFormate(objective)}
                          >
                            {" "}
                            <div
                              className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                                selectedFormate?.label === objective?.label
                                  ? "border-[5px]"
                                  : "group-hover:border-[3px]"
                              }  `}
                            ></div>
                            <div className="flex-1">
                              <h3
                                className={`text-[14px] ${
                                  currentMode === "dark"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                {objective?.label}
                              </h3>
                              <p>{objective?.para}</p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
                {/* <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={adData?.multi_advertiser_optimization}
                        onChange={handleChange}
                      />
                    }
                    label="Multi-advertiser ads"
                  />
                  <p className="pl-9">
                    Your ads can appear alongside other ads in the same ad unit
                    to help people discover products and services from
                    businesses that are personalised to them. Your ad creative
                    may be resized or cropped to fit the ad unit
                  </p>
                </div> */}
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setAdData((pre) => ({
                            ...pre,
                            creative: {
                              ...pre?.creative,
                              degrees_of_freedom_spec: {
                                creative_features_spec: {
                                  standard_enhancements: {
                                    enroll_status: e.target.checked
                                      ? "OPT_IN"
                                      : "OPT_OUT",
                                  },
                                },
                              },
                            },
                          }));
                        }}
                      />
                    }
                    label="Standard Enhancements"
                  />
                  <p className="pl-9">
                    Standard enhancements is for ads using a single image,
                    video, or carousel. It automatically creates multiple
                    variations of your ad and shows a personalized variation to
                    each Account Center account based on what they're most
                    likely to respond to.
                  </p>
                </div>
              </div>
              <div
                className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4`}
              >
                <label htmlFor="" className="text-[14px] font-semibold mb-3">
                  Ad creative
                </label>
                <p>Select and optimise your ad text, media and enhancements.</p>
                {adCreateType?.label === "Create Ad" &&
                  selectedFormate?.label === "Single image or video" && (
                    <div className="w-full ">
                      <Select
                        id=""
                        options={adTypes}
                        value={selectedAdType}
                        onChange={(e) => {
                          setSelectedAdType(e);
                          setAdCreativeModal(true);
                        }}
                        placeholder={""}
                        className={`mt-1`}
                        menuPortalTarget={document.body}
                        styles={selectStyles(currentMode, primaryColor)}
                      />
                    </div>
                  )}
                {adCreateType?.label === "Create Ad" &&
                  selectedFormate?.label === "Carousel" && (
                    <>
                      <label
                        htmlFor=""
                        className="text-[14px] font-semibold mb-1"
                      >
                        Carousel cards
                      </label>
                      <p>0 of 10 cards added</p>​
                      <p className="py-3 px-2 bg-[#E1EDF7] rounded-lg mb-2">
                        Add at least two cards.
                      </p>
                      <div className="w-[200px] ">
                        <Select
                          id="public-Profiles"
                          options={carouselCards}
                          value={selectedCarouselCard}
                          onChange={(e) => {
                            setSelectedCarouselCard(e);
                            setCarouselCard(true);
                          }}
                          placeholder={"+ Add cards"}
                          className={`mt-1`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                        />
                      </div>
                      <label
                        htmlFor=""
                        className="text-[14px] font-semibold mb-1"
                      >
                        Edit placement
                      </label>
                      <div className="w-full ">
                        <Select
                          id="public-Profiles"
                          options={carouselCards}
                          value={selectedCarouselCard}
                          onChange={(e) => {
                            setSelectedCarouselCard(e);
                          }}
                          placeholder={"+ Add cards"}
                          className={`mt-1`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                        />
                      </div>
                      <label
                        htmlFor=""
                        className="text-[14px] font-semibold mb-3"
                      >
                        Primary text (1 of 5)
                      </label>
                      <input
                        type="text"
                        className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                        //   value={campData?.name}
                        placeholder="Tell people what your ad is about"
                        //   onChange={(e) =>
                        //     setCampData((pre) => ({
                        //       ...pre,
                        //       name: e?.target?.value,
                        //     }))
                        //   }
                      />
                      {/* <button
                        // onClick={(event) => setAnchorEl(event.currentTarget)}
                        className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                          currentMode == "dark"
                            ? "bg-dark text-white"
                            : "bg-white"
                        } `}
                      >
                        <GoPlusCircle size={16} /> Add text option
                      </button> */}
                      <label
                        htmlFor=""
                        className="text-[14px] font-semibold mb-1"
                      >
                        Call to action
                      </label>
                      <div className="w-full ">
                        <Select
                          id="public-Profiles"
                          options={buttons}
                          value={selectedBtn}
                          onChange={(e) => {
                            setSelectedBtn(e);
                          }}
                          placeholder={"+ Add cards"}
                          className={`mt-1`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                        />
                      </div>
                    </>
                  )}
                {adCreateType?.label === "Create Ad" &&
                  selectedFormate?.label === "Collection" && (
                    <>
                      <label
                        htmlFor=""
                        className="text-[14px] font-semibold mb-3"
                      >
                        Primary text
                      </label>
                      <input
                        type="text"
                        className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                        //   value={campData?.name}
                        placeholder="Tell people what your ad is about"
                        //   onChange={(e) =>
                        //     setCampData((pre) => ({
                        //       ...pre,
                        //       name: e?.target?.value,
                        //     }))
                        //   }
                      />
                      <label
                        htmlFor=""
                        className="text-[14px] font-semibold mb-3"
                      >
                        Headline
                      </label>
                      <input
                        type="text"
                        className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                        //   value={campData?.name}
                        placeholder="Write a short headline"
                        //   onChange={(e) =>
                        //     setCampData((pre) => ({
                        //       ...pre,
                        //       name: e?.target?.value,
                        //     }))
                        //   }
                      />
                    </>
                  )}
                {adCreateType?.label === "Use existing post" && (
                  <>
                    {singlePost && (
                      <div className="flex items-center  space-x-3 my-3">
                        <div className="h-[40px] w-[50px] mb-4">
                          <img
                            src={
                              singlePost?.attachments?.data[0]?.media?.image
                                ?.src
                            }
                            alt="post thumbnail"
                          />
                        </div>

                        <div>
                          <h4>Facebook Post</h4>
                          <h4>{singlePost?.id}</h4>
                        </div>
                      </div>
                    )}

                    <div className="flex gap-3 items-center">
                      <button
                        onClick={(event) => {
                          setSelectPostModal(true);
                          if (selectedAccount?.firstIdentity?.id) {
                            fetchBrandedContentPosts(
                              selectedAccount?.firstIdentity?.id ||
                                selectedPage,
                              selectedAccount?.firstIdentity?.access_token
                            );
                          }
                        }}
                        className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                          currentMode == "dark"
                            ? "bg-dark text-white"
                            : "bg-white"
                        } `}
                      >
                        Select Post
                      </button>
                      {/* <button
                        onClick={() => {
                          setCreatePostModal(true);
                        }}
                        className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                          currentMode == "dark"
                            ? "bg-dark text-white"
                            : "bg-white"
                        } `}
                      >
                        <GoPlusCircle size={16} /> Create Post
                      </button> */}
                    </div>
                    <label
                      htmlFor=""
                      className="text-[14px] font-semibold mb-1"
                    >
                      Enter Post ID
                    </label>
                    <div className="flex items-center gap-3">
                      <input
                        type="text"
                        className={`p-2 mt-[2px] ${getSummaryBgClass()} flex-1 rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                        value={adData?.object_story_id}
                        placeholder="Tell people what your ad is about"
                        onChange={(e) =>
                          setAdData({
                            ...adData,
                            object_story_id: e.target.value,
                          })
                        }
                      />
                      {/* <button className="p-3  rounded-lg border">
                        <IoMdCheckmark />
                      </button>
                      <button className="p-3 rounded-lg border">
                        <IoMdClose />
                      </button> */}
                    </div>

                    <label
                      htmlFor=""
                      className="text-[14px] font-semibold mb-1 mt-3"
                    >
                      Call to action
                    </label>
                    <label
                      htmlFor=""
                      className="text-[14px] font-semibold mb-1 mt-3"
                    >
                      Selected post's call to action will apply. Edit the post
                      to update.
                    </label>
                    {/* <div>
                      <label className="block text-sm font-medium mb-2">
                        Call to Action
                      </label>
                      <Select
                        id="public-Profiles"
                        options={buttons} // Call-to-Action options
                        value={selectedBtn}
                        onChange={handleCTAChange}
                        placeholder={"+ Add cards"}
                        className="mt-1"
                        menuPortalTarget={document.body}
                        styles={selectStyles(currentMode, primaryColor)}
                      />

                      <label className="block text-sm font-medium mt-4 mb-2">
                        URL
                      </label>
                      <input
                        type="text"
                        value={ctaUrl}
                        onChange={handleURLChange}
                        placeholder="Enter a link"
                        className={`p-2 mt-[2px] ${
                          currentMode === "dark"
                            ? "bg-dark text-white"
                            : "bg-white text-black"
                        } rounded-lg focus:outline-none border border-gray-200 focus:border-[#0A78BE]`}
                      />
                    </div> */}
                  </>
                )}
              </div>
              {adCreateType?.label === "Create Ad" &&
                selectedFormate?.label === "Collection" && (
                  <div
                    className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4`}
                  >
                    <label
                      htmlFor=""
                      className="text-[14px] font-semibold mb-3"
                    >
                      Destination
                    </label>
                    <p>
                      Tell us where to send people immediately after they tap or
                      click your ad.
                    </p>
                    <label
                      htmlFor=""
                      className="text-[14px] font-semibold mb-3"
                    >
                      Instant Experience
                    </label>
                    <div className="w-full ">
                      <Select
                        id="public-Profiles"
                        options={adCreateTypes}
                        // value={adCreateType}
                        onChange={(e) => {
                          setAdCreateType(e);
                        }}
                        placeholder={
                          "Search for an existing Instant Experience"
                        }
                        className={`mt-1`}
                        menuPortalTarget={document.body}
                        styles={selectStyles(currentMode, primaryColor)}
                      />
                    </div>
                    <button
                      // onClick={(event) => setAnchorEl(event.currentTarget)}
                      className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3   ${
                        currentMode == "dark"
                          ? "bg-dark text-white"
                          : "bg-white"
                      } `}
                    >
                      <IoAddSharp size={16} />
                      Create New
                    </button>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox checked={checked} onChange={handleChange} />
                        }
                        label="Override catalogue deep links"
                      />
                      <p className="pl-9">
                        Override catalogue deep links ​ This allows you to
                        override any website deep links for items in the
                        selected catalogue.
                      </p>
                    </div>
                  </div>
                )}
              {(selectedFormate?.label === "Carousel" ||
                selectedFormate?.label === "Single image or video") && (
                <div
                  className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4`}
                >
                  <label htmlFor="" className="text-[14px] font-semibold mb-3">
                    Destination
                  </label>
                  <p>
                    Tell us where to send people immediately after they tap or
                    click your ad.
                  </p>

                  {destinations?.map((objective) => {
                    return (
                      <>
                        {" "}
                        <li
                          className={`flex gap-4 items-center mb-4 group cursor-pointer px-2 py-2 rounded-lg select-none `}
                          onClick={() => setSelectedDestination(objective)}
                          // onClick={() => handleDestinationChange(objective)}
                        >
                          <div
                            className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                              selectedDestination?.label === objective?.label
                                ? "border-[5px]"
                                : "group-hover:border-[3px]"
                            }  `}
                          ></div>
                          <div className="flex-1">
                            <h3
                              className={`text-[14px] ${
                                currentMode === "dark"
                                  ? "text-white"
                                  : "text-black"
                              }`}
                            >
                              {objective?.label}
                            </h3>
                            <p>{objective?.para}</p>
                          </div>
                        </li>
                        <div className="pl-5">
                          {selectedDestination?.label === "Messaging apps" &&
                            objective?.label === "Messaging apps" && (
                              <MessagingApps
                                selectedApp={selectedApp}
                                setSelectedApp={setSelectedApp}
                              />
                            )}
                          {selectedDestination?.label === "Call" &&
                            objective?.label === "Call" && (
                              <div>
                                <label
                                  htmlFor=""
                                  className="text-[14px] font-semibold mb-3"
                                >
                                  * Phone number
                                </label>
                                <div className="flex items-center gap-3">
                                  <div className="w-full ">
                                    <PhoneInput
                                      placeholder={t("label_contact_number")}
                                      aria-label="contact number"
                                      value={value}
                                      onChange={(value) => setValue(value)}
                                      onKeyUp={() => handleContact(false)}
                                      error={error}
                                      className={` ${classNames({
                                        "dark-mode": currentMode === "dark",
                                        "phone-input-light":
                                          currentMode !== "dark",
                                        "phone-input-dark":
                                          currentMode === "dark",
                                      })} mb-5`}
                                      size="small"
                                      style={{
                                        background: `${
                                          !themeBgImg
                                            ? currentMode === "dark"
                                              ? "#000000"
                                              : "#FFFFFF"
                                            : "transparent"
                                          // : (currentMode === "dark" ? blurDarkColor : blurLightColor)
                                        }`,
                                        "& .PhoneInputCountryIconImg": {
                                          color: "#fff",
                                        },
                                        color:
                                          currentMode === "dark"
                                            ? "white"
                                            : "black",
                                        border: `1px solid ${
                                          currentMode === "dark"
                                            ? "#EEEEEE"
                                            : "#666666"
                                        }`,
                                        borderRadius: "5px",
                                        outline: "none",
                                      }}
                                      inputStyle={{
                                        outline: "none !important",
                                      }}
                                      required
                                    />

                                    {error && (
                                      <Typography variant="body2" color="error">
                                        {error}
                                      </Typography>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          {selectedDestination?.label === "Website" &&
                            objective?.label === "Website" && (
                              <Website
                                selectedApp={selectedApp}
                                setSelectedApp={setSelectedApp}
                                handleDestinationChange={
                                  handleDestinationChange
                                }
                                adData={adData}
                                setAdData={setAdData}
                              />
                            )}
                          {selectedDestination?.label ===
                            "Instant Experience" &&
                            objective?.label === "Instant Experience" && (
                              <>
                                <div className="w-full ">
                                  <Select
                                    id="public-Profiles"
                                    options={adCreateTypes}
                                    value={adCreateType}
                                    onChange={(e) => {
                                      setAdCreateType(e);
                                    }}
                                    placeholder={""}
                                    className={`mt-1`}
                                    menuPortalTarget={document.body}
                                    styles={selectStyles(
                                      currentMode,
                                      primaryColor
                                    )}
                                  />
                                </div>
                                <button
                                  // onClick={(event) => setAnchorEl(event.currentTarget)}
                                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                                    currentMode == "dark"
                                      ? "bg-dark text-white"
                                      : "bg-white"
                                  } `}
                                >
                                  <GoPlusCircle size={16} /> Create New
                                </button>
                              </>
                            )}
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
              <div
                className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4`}
              >
                {/* <div className="flex items-center justify-between ">
                  <label htmlFor="" className="text-[14px] font-semibold mb-3">
                    Languages
                  </label>
                  <div>
                    <FormControlLabel
                      value=""
                      control={
                        <Switch
                          color="primary"
                          size="medium"
                          // checked={advantageCamp}
                          // onChange={(e) => setAdvantageCamp(!advantageCamp)}
                        />
                      }
                      label={"Off"}
                      labelPlacement="start"
                      sx={{
                        margin: "0px",
                        marginTop: `10px`,
                      }}
                    />
                  </div>
                </div> */}

                {/* <p>
                  Add your own translations or automatically translate your ad
                  to reach people in more languages
                </p>

                <button
                  // onClick={(event) => setAnchorEl(event.currentTarget)}
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                    currentMode == "dark" ? "bg-dark text-white" : "bg-white"
                  } `}
                >
                  Add Languages
                </button> */}
              </div>
              <div
                className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4`}
              >
                {/* <label htmlFor="" className="text-[14px] font-semibold mb-3">
                  Tracking
                </label>
                <p>
                  Track event data sets that contain the conversions your ad
                  might motivate. The dataset that contains the conversion
                  selected for the ad account will be tracked by default.
                </p>
                <label htmlFor="" className="text-[14px] font-semibold mb-2">
                  Website events
                </label>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleChange} />
                  }
                  label="App events"
                />
                <label htmlFor="" className="text-[14px] font-semibold mb-3">
                  Offline events
                </label> */}
                <label htmlFor="" className="text-[14px] font-semibold mb-3">
                  URL parameters∙
                </label>
                <input
                  type="text"
                  className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                  placeholder="key1=value1&key2=value2"
                  value={adData?.creative?.link_data?.url_tags}
                  onChange={(e) => {
                    const urlTags = e.target.value;
                    setAdData((prevData) => ({
                      ...prevData,
                      creative: {
                        ...prevData?.creative,
                        link_data: {
                          ...prevData?.creative?.link_data,
                          url_tags: urlTags,
                        },
                      },
                    }));
                  }}
                />
                {/* <button className="text-[#0A78BE] w-fit py-3">
                  Build a url parameter
                </button> */}
              </div>
              {/* <div
                className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 mt-4 px-4`}
              >
                <label htmlFor="" className="text-[14px] font-semibold mb-1">
                  Ad Sources
                </label>
                <p>
                  Connect ad sources to include more information in your ad that
                  can help inspire action. Learn more about ad sources.
                </p>
                <label htmlFor="" className="text-[14px] font-semibold mb-1">
                  Source URL
                </label>
                <p>
                  Enter a URL to automatically find site links you can choose to
                  add. By default, we'll use your destination Website URL.
                </p>
                <input
                  type="text"
                  className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                  //   value={campData?.name}
                  placeholder="https:/example.com/page"
                  //   onChange={(e) =>
                  //     setCampData((pre) => ({
                  //       ...pre,
                  //       name: e?.target?.value,
                  //     }))
                  //   }
                />
                <label
                  htmlFor=""
                  className="text-[14px] font-semibold mb-2 mt-4"
                >
                  Site Links
                </label>
                <div className="flex justify-between items-center">
                  <span>0 site links added</span>
                  <button className="text-blue-400">Add</button>
                </div>
              </div> */}
            </div>

            <div
              className={`flex items-center justify-between gap-3 w-full pt-3 border-t-[2px] ${getSummaryBgClass()} py-3 px-3 mx-4`}
            >
              <button
                onClick={() => createAd()}
                className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
                disabled={btnLoading}
              >
                {btnLoading ? <CircularProgress /> : <span>Create</span>}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <AdCreativeModal
        setAdCreativeModal={setAdCreativeModal}
        adCreativeModal={adCreativeModal}
        adData={adData}
        setAdData={setAdData}
        fb_token={fb_token}
        selectedAdType={selectedAdType}
      />
      <SelectPost
        selectPostModal={selectPostModal}
        setSelectPostModal={setSelectPostModal}
        postLoading={postLoading}
        postList={postList}
        singlePost={singlePost}
        setSinglePost={setSinglePost}
        adData={adData}
        setAdData={setAdData}
      />
      <CreatePost
        setCreatePostModal={setCreatePostModal}
        createPostModal={createPostModal}
      />
      <PartnerShipModal
        setPartnerShipModal={setPartnerShipModal}
        partnerShipModal={partnerShipModal}
        adData={adData}
        setAdData={setAdData}
      />
      <IdentityModal
        identityModal={identityModal}
        setIdentityModal={setIdentityModal}
        adData={adData}
        setAdData={setAdData}
      />
      <CarouselCard
        setCarouselCardModal={setCarouselCard}
        carouselCardModal={carouselCard}
      />
      <BuildUrlParams urlParams={urlParams} setUrlParams={setUrlParams} />
    </>
  );
};

export default SingleAd;

const MessagingApps = ({ selectedApp, setSelectedApp }) => {
  const { currentMode } = useStateContext();
  const Apps = [
    {
      label: "Messenger",
      value: 1,
      para: "Page Name",
      link: "https://z-p3-scontent.fisb6-2.fna.fbcdn.net/v/t39.30808-1/454386068_122100349556458813_818153095054095266_n.png?stp=cp0_dst-png_s50x50&_nc_cat=108&ccb=1-7&_nc_sid=6738e8&_nc_eui2=AeFUZkypqMhAfh29_DsykNKxu3LuQ9ch49y7cu5D1yHj3M_Pf47dU3FSwtbGzwrwZmjI-Crd3D2owDg2x-oe4MgZ&_nc_ohc=Jhd5iBlWOG8Q7kNvgG5I_Kn&_nc_zt=24&_nc_ht=z-p3-scontent.fisb6-2.fna&_nc_gid=A_vT1RuBq0qt2ok4WY3AY5D&oh=00_AYBoIgNmY3f6PlVA9sPeYt9NbG7UeZx5hh11nMYTCTSc0g&oe=674207A8",
    },
    {
      label: "Instagram",
      value: 2,
      para: "",
      link: "https://static.xx.fbcdn.net/rsrc.php/yM/r/gXv36GX8I6t.svg?_nc_eui2=AeH96tiyCgw3XWSMtFjvuzsrlAqehfJLq16UCp6F8kurXgZaDvPTcqRyo3jAcONWSoIoRghAwVIwGB9nHUkBxU7D",
    },
    {
      label: "WhatsApp",
      value: 3,
      para: "",
      link: "https://static.xx.fbcdn.net/rsrc.php/yd/r/RIwqg3B0HgO.svg?_nc_eui2=AeFnphpn_xoXqq8m5xoFQOUpDEThPlbFLnoMROE-VsUuejq-X_zg0qx_HDQ-u8DgLrxmZ8TL35NGnOijz4U3CO9E",
    },
  ];
  return (
    <>
      {Apps?.map((objective) => {
        return (
          <li
            className={`flex gap-4 items-center mb-3 group cursor-pointer px-2 py-2 rounded-lg `}
            onClick={() => setSelectedApp(objective)}
          >
            {" "}
            <div
              className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                selectedApp?.label === objective?.label
                  ? "border-[5px]"
                  : "group-hover:border-[3px]"
              }  `}
            ></div>
            <div className="flex-1 flex items-center">
              <div className="w-[50px] h-[50px] rounded-full p-4 bg-[#F1F4F7]">
                <img src={objective?.link} alt="" className="w-full h-full" />
              </div>
              <h3
                className={`text-[14px] ${
                  currentMode === "dark" ? "text-white" : "text-black"
                }`}
              >
                {objective?.label}
              </h3>
              <p>{objective?.para}</p>
            </div>
            <button
              // onClick={(event) => setAnchorEl(event.currentTarget)}
              className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3   ${
                currentMode == "dark" ? "bg-dark text-white" : "bg-white"
              } `}
            >
              Connect
            </button>
          </li>
        );
      })}
    </>
  );
};

const Website = ({
  selectedApp,
  setSelectedApp,
  setUrlParams,
  handleDestinationChange,
  adData,
  setAdData,
}) => {
  const { currentMode, themeBgImg } = useStateContext();

  const Apps = [
    {
      label: "None",
      value: 1,
      para: "Don't add a button",
      link: <IoIosCloseCircleOutline size={16} />,
    },

    {
      label: "WhatsApp",
      value: 3,
      para: "Add a WhatsApp button on your website.",
      link: <FaWhatsapp size={16} />,
    },
  ];
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  return (
    <div className="flex flex-col ">
      <label htmlFor="" className="text-[14px] font-semibold mb-3">
        * See more URL
      </label>
      <input
        type="text"
        className={`p-2 mb-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
        // value={adData?.creative?.link_data?.call_to_action?.value?.link}
        value={adData?.creative?.object_story_spec?.link_data?.link}
        placeholder="http://www.example.com/page"
        onChange={(e) => handleDestinationChange("Website", e)}
      />
      <p>Enter the website URL field for your ad.</p>
      {/* <button className="text-[#3367D1] w-fit py-3">
        Build a URL parameter
      </button> */}
      {/* <label htmlFor="" className="text-[14px] font-semibold mb-3">
        "See More" display link
      </label>
      <input
        type="text"
        className={`p-2 mb-4 ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
        //   value={campData?.name}
        placeholder="Enter Link to show on last caurosel card"
        onChange={(e) => handleDestinationChange("Website", e)}
      /> */}
      {/* <label htmlFor="" className="text-[14px] font-semibold mb-1">
        Browser add-ons
      </label>
      <p>
        People will see your website when they tap on your ad. You can add an
        additional contact method in the browser to help people connect with
        you..
      </p> */}
      {/* <div className="pl-4">
        {Apps?.map((objective) => {
          return (
            <li
              className={`flex gap-4 items-center mb-3 group cursor-pointer px-2 py-2 rounded-lg  $`}
              onClick={() => setSelectedApp(objective)}
            >
              {" "}
              <div
                className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                  selectedApp?.label === objective?.label
                    ? "border-[5px]"
                    : "group-hover:border-[3px]"
                }  `}
              ></div>
              <div className="flex items-center justify-between w-full">
                <div className="flex-1 flex items-center gap-3 ">
                 
                  {objective?.link}
                
                  <div>
                    <h3
                      className={`text-[14px] ${
                        currentMode === "dark" ? "text-white" : "text-black"
                      }`}
                    >
                      {objective?.label}
                    </h3>
                    <p>{objective?.para}</p>
                  </div>
                </div>
              </div>
              {objective?.label === "WhatsApp" && (
                <button
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3   ${
                    currentMode == "dark" ? "bg-dark text-white" : "bg-white"
                  } `}
                >
                  Connect
                </button>
              )}
            </li>
          );
        })}
      </div> */}
    </div>
  );
};

const IdentityCard = ({
  firstIcon,
  secondIcon,
  defaultTitle,
  title,
  accountLoading,
}) => {
  console.log("firstIcon: ", firstIcon);
  return accountLoading ? (
    <CircularProgress />
  ) : (
    <div className="flex px-3 items-center gap-3">
      <div className="flex gap-2">
        {firstIcon?.picture?.data?.url && (
          <div className="p-7 rounded-full ">
            <img src={firstIcon?.picture?.data?.url} className="rounded-full" />
          </div>
        )}
        {/* <div className="p-2 rounded-full bg-[#F0F2F5]">{secondIcon}</div> */}
      </div>
      <div className="flex-1">
        {title ? (
          <>
            {" "}
            <div className="text-[14px]"> {title} </div>{" "}
            <div className="text-[12px]">{defaultTitle}</div>
          </>
        ) : (
          <div className="text-[14px] text-[#0A78BE]">{defaultTitle}</div>
        )}
      </div>
      <div>
        <SlArrowRight />
      </div>
    </div>
  );
};
