import React, { useState, useEffect } from "react";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { Checkbox, Switch } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineDelete } from "react-icons/md";
import DeleteConfirm from "./DeleteConfirmation";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import CustomSwitch from "./customSwitch";
// import { deliveryStatus } from "./deliveryStatuses";
const token = localStorage?.getItem("auth-token");

// Columns definition
const deliveryStatus = [
  {
    code: "INVALID_NOT_EFFECTIVE_ACTIVE",
    label: "Ad Set is inactive",
  },
  {
    code: "INVALID_NOT_ACTIVE",
    label: "Not Devlivering",
  },
];
const goalOptions = [
  {
    label: "Clicks",
    value: "SWIPES",
  },
  {
    label: "Leads",
    value: "LEAD_FORM_SUBMISSIONS",
  },

  {
    label: "Story Opens",
    value: "STORY_OPENS",
  },
];

const AdSetsTable = ({
  setAdSetDetails,
  setIsSingleAdSet,
  adSets,
  setAdSets,
  getAllAdSets,
  selectedRows,
  setSelectedRows,
  loading,
  searchQuery,
}) => {
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    DataGridStyles,
  } = useStateContext();
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
  const filteredAdSets = adSets.filter((row) =>
    Object.values(row).some((value) =>
      value.toString().toLowerCase()?.includes(searchQuery.toLowerCase())
    )
  );

  async function deleteAdSet(id) {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/delete-adsquad`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_squad_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      getAllAdSets();
      toast.success("AdSet is successfully deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error, "error");
      toast.error("AdSet can't be deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  async function updateAdSet(adSetDetails, status) {
    try {
      await axios.post(
        `${BACKEND_URL}/snapchat/update-adsquad`,
        {
          access_token: snapchatCredentials?.access_token,
          campaign_id: adSetDetails?.campaign_id,
          ad_squad_id: adSetDetails?.id,
          name: adSetDetails?.name,
          status: status ? "ACTIVE" : "PAUSED",
          start_time: adSetDetails?.start_time,
          ...(adSetDetails?.end_time && {
            end_time: adSetDetails?.end_time,
          }),
          daily_budget_micro: adSetDetails?.daily_budget_micro / 1000000,
          ...(adSetDetails?.bid_strategy !== "AUTO_BID" && {
            bid_micro: adSetDetails?.bid_micro / 1000000,
          }),
          bid_strategy: adSetDetails?.bid_strategy,
          optimization_goal: adSetDetails?.optimization_goal,
          geos: adSetDetails?.targeting.geos,
          demographics: adSetDetails?.targeting?.demographics,
          devices: adSetDetails?.targeting?.devices,
          pixel_id: adSetDetails?.pixel_id,
          ...(adSetDetails?.targeting?.interests &&
          adSetDetails?.targeting?.interests[0]?.category_id?.length > 0
            ? {
                interests: [
                  {
                    category_id:
                      adSetDetails?.targeting?.interests[0]?.category_id,
                    operation: "INCLUDE",
                  },
                ],
              }
            : {}),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      toast.success("Successfully Updated AdSet", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getAllAdSets();
    } catch (error) {
      console.log(error, "this is an error");
      toast.error("Unable to update adSet", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    }
  }

  // useEffect(() => {
  //   if (snapchatCredentials?.access_token) {
  //     getAllAdSets();
  //   }
  // }, [snapchatCredentials]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      headerClassName: "pl-5",
      renderHeader: (params) => {
        return (
          <div className="pl-12 font-semibold">{params.colDef.headerName}</div>
        );
      },
      renderCell: (params) => (
        <Name
          value={params?.value}
          onEdit={() => {
            setAdSetDetails(params?.row);
            setIsSingleAdSet(true);
          }}
          onDelete={() => {
            // deleteAdSet(params?.row?.id);
            setOpenDeleteConfirm({ id: params?.row?.id, name: params?.value });
          }}
        />
      ),
      minWidth: 250,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      minWidth: 150,
      renderCell: (params) => (
        <CustomSwitch
          checked={params.row.status == "PAUSED" ? false : true}
          onChange={(e) => updateAdSet(params?.row, e?.target?.checked)}
        />
      ),
    },
    // {
    //   field: "delivery_status",
    //   headerName: "Delivery Status",
    //   headerAlign: "center",
    //   minWidth: 200,
    //   renderHeader: (params) => {
    //     return <div className=" font-semibold">{params.colDef.headerName}</div>;
    //   },
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params?.value?.map(
    //           (val) =>
    //             deliveryStatus?.find((status) => status?.code == val)
    //               ?.label && (
    //               <div>
    //                 {
    //                   deliveryStatus?.find((status) => status?.code == val)
    //                     ?.label
    //                 }
    //               </div>
    //             )
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "delivery_status",
      headerName: "Delivery Status",
      headerAlign: "left",

      minWidth: 200,
      renderHeader: (params) => {
        return (
          <div className="text-left w-full font-semibold">
            {params.colDef.headerName}
          </div>
        ); // Apply padding manually
      },
      renderCell: (params) => {
        return (
          <div className="flex text-left w-full items-center gap-3">
            <div className="w-[8px] h-[8px] bg-[#848688] rounded-full"></div>
            <div className="flex flex-col items-start">
              {params?.value
                ?.slice(0, 2)
                ?.map((val) =>
                  deliveryStatus?.find((status) => status?.code == val)
                    ?.label ? (
                    <div>
                      {
                        deliveryStatus?.find((status) => status?.code == val)
                          ?.label
                      }
                    </div>
                  ) : (
                    <div>{val?.replace(/_/g, " ")}</div>
                  )
                )}
            </div>
          </div>
        );
      },
    },
    {
      field: "daily_budget_micro",
      headerName: "Daily Budget",
      headerAlign: "right",
      minWidth: 180,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return (
          <div className="w-full text-right">
            $<span>{params?.value / 1000000}.00</span>
          </div>
        );
      },
    },
    {
      field: "optimization_goal",
      headerName: "Goal",
      headerAlign: "left",
      minWidth: 170,
      renderHeader: (params) => {
        return (
          <div className="pl-7 font-semibold">{params.colDef.headerName}</div>
        );
      },
      renderCell: (params) => {
        return (
          <div className="pl-7 w-full text-left">
            {goalOptions?.find((item) => item?.value === params?.value)
              ?.label || params?.value}
          </div>
        );
      },
    },
    {
      field: "bid_micro",
      headerName: "Bid",
      headerAlign: "right",
      minWidth: 150,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return (
          <div className="w-full text-right">
            {params?.value ? (
              <>
                {"$"}
                <span>{(params?.value / 1000000).toFixed(2)}</span>
              </>
            ) : (
              "_"
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Box
          className={`closed-datatable ${currentMode}-mode-datatable`}
          sx={{
            ...DataGridStyles,
            "& .MuiDataGrid-columnHeaders": {
              ...DataGridStyles["& .MuiDataGrid-columnHeaders"],
              backgroundColor: "transparent",
              color: currentMode === "dark" ? "white" : "black",
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
              borderTop: "1px solid rgba(224, 224, 224, 1)",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            },
            "& .MuiButtonBase-root": {
              ...DataGridStyles["& .MuiButtonBase-root"],
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
            },
            "& .MuiDataGrid-virtualScroller": {
              ...DataGridStyles["& .MuiDataGrid-virtualScroller"],
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
            },
            position: "relative",
            marginBottom: "50px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <DataGrid
            loading={loading}
            rows={filteredAdSets}
            // rows={adSets}
            columns={columns}
            pageSize={5}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            selectionModel={selectedRows?.adSets}
            onSelectionModelChange={(rows) =>
              setSelectedRows((pre) => ({ ...pre, adSets: rows }))
            }
          />
        </Box>
      </div>
      <DeleteConfirm
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        message={` Are you sure you want to delete AdSet ${openDeleteConfirm?.name}? `}
        onDelete={deleteAdSet}
        title="Confirm Delete AdSet"
      />
    </>
  );
};

export default AdSetsTable;

const Name = ({ value, onEdit, onDelete }) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const { currentMode } = useStateContext();
  return (
    <>
      <div
        className="pl-11 w-full text-left h-full flex items-start justify-center flex-col"
        onMouseEnter={() => setIsMouseEnter(true)}
        onMouseLeave={() => setIsMouseEnter(false)}
      >
        <p>{value}</p>
        {
          <div
            className="flex gap-3 transition-all duration-[200ms] ease-in-out"
            style={{
              transform: isMouseEnter ? "translateY(0)" : "translateY(100%)",
              opacity: isMouseEnter ? 1 : 0,
            }}
          >
            <button
              className={`text-${
                currentMode === "dark" ? "white" : "gray-600"
              } hover:text-${currentMode === "dark" ? "gray-600" : "gray-900"}`}
              onClick={onEdit}
            >
              Edit
            </button>
            <button
              className={`text-${
                currentMode === "dark" ? "white" : "gray-600"
              } hover:text-${
                currentMode === "dark" ? "gray-600" : "gray-900"
              } flex gap-2 items-center`}
              onClick={onDelete}
            >
              Delete <MdOutlineDelete size={16} />
            </button>
          </div>
        }
      </div>
    </>
  );
};
