// import React, { useState, useEffect } from "react";
// import {
//   Menu,
//   MenuItem,
//   Button,
//   Checkbox,
//   ListItemText,
//   Collapse,
//   List,
//   ListItemButton,
// } from "@mui/material";
// import { FaChevronDown, FaChevronRight, FaChevronUp } from "react-icons/fa";
// import { RiArrowRightSFill, RiArrowDownSFill } from "react-icons/ri";
// import { useStateContext } from "../../context/ContextProvider";
// import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
// import { IoMdClose } from "react-icons/io";
// import axios from "../../axoisConfig";

// const token = localStorage?.getItem("auth-token");

// const DeviceMakes = ({ adSetDetails, setAdSetDetails }) => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedItems, setSelectedItems] = useState([]);
//   const [openParents, setOpenParents] = useState({});
//   const [deviceMakeCompanies, setDeviceMakeCompanies] = useState([]);
//   const { currentMode, themeBgImg, BACKEND_URL, snapchatCredentials } =
//     useStateContext();
//   const open = Boolean(anchorEl);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   // const handleToggle = (option) => {
//   //   setAdSetDetails((pre) => {
//   //     console.log(pre.devices[0], "something");
//   //     pre.devices[0].marketing_name = pre?.devices[0]?.marketing_name?.find(
//   //       (opt) => opt?.id == option?.id
//   //     )
//   //       ? pre?.devices[0]?.marketing_name?.filter(
//   //           (item) => item?.id !== option?.id
//   //         )
//   //       : [...pre?.devices[0]?.marketing_name, option];
//   //     return { ...pre };
//   //   });
//   // };
//   const handleToggle = (option) => {
//     setAdSetDetails((prev) => {
//       // Ensure marketing_name is an array
//       const marketingNames = prev.devices[0]?.marketing_name || [];

//       const exists = marketingNames.find((opt) => opt.id === option.id);

//       // Update marketing_name based on whether the option exists
//       const updatedMarketingNames = exists
//         ? marketingNames.filter((item) => item.id !== option.id)
//         : [...marketingNames, option];

//       // Update the previous state with the new array
//       const newDevices = {
//         ...prev.devices[0],
//         marketing_name: updatedMarketingNames,
//       };

//       return { ...prev, devices: [newDevices] };
//     });
//   };

//   const handleParentToggle = (label) => {
//     setOpenParents((prev) => ({
//       ...prev,
//       [label]: !prev[label],
//     }));
//   };
//   const getSummaryBgClass = () => {
//     return currentMode === "dark"
//       ? themeBgImg
//         ? "blur-bg-dark text-white"
//         : "bg-dark text-white"
//       : themeBgImg
//       ? "blur-bg-light text-gray-800"
//       : "bg-white text-gray-800";
//   };

//   async function getDeviceMakes() {
//     try {
//       const res = await axios.post(
//         `${BACKEND_URL}/snapchat/targeting/device/marketing_name`,
//         {
//           access_token: snapchatCredentials?.access_token,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + token,
//           },
//         }
//       );
//       setDeviceMakeCompanies(buildTree(res?.data?.data?.targeting_dimensions));
//     } catch (error) {
//       console.log(error, "this is an error");
//     }
//   }

//   function buildTree(data) {
//     const tree = {};

//     // Iterate over each item in the data
//     data.forEach((item) => {
//       const nameParts = item.marketing_name.name.split(" > "); // Split by '>' to create nested structure
//       let currentLevel = tree;

//       // Traverse through the tree structure
//       nameParts.forEach((part, index) => {
//         // If this level doesn't exist yet, create it
//         if (!currentLevel[part]) {
//           currentLevel[part] = {
//             name: part,
//             id: item.marketing_name.id,
//             children: {},
//           };
//         }

//         // Move to the next level (deeper in the tree)
//         currentLevel = currentLevel[part].children;
//       });
//     });

//     // Convert the tree into an array of objects
//     function convertTreeToArray(tree) {
//       return Object.keys(tree).map((key) => {
//         const node = tree[key];
//         return {
//           name: node.name,
//           id: node.id,
//           children: Object.keys(node.children).length
//             ? convertTreeToArray(node.children)
//             : [],
//         };
//       });
//     }

//     return convertTreeToArray(tree);
//   }

//   useEffect(() => {
//     getDeviceMakes();
//   }, []);

//   return (
//     <div className="">
//       <label htmlFor="" className="font-semibold mb-2">
//         Device Makes
//       </label>
//       {adSetDetails?.devices[0]?.marketing_name?.length > 0 && (
//         <ul className="border border-gray-200 rounded-lg">
//           {adSetDetails?.devices[0]?.marketing_name?.map((item, index) => {
//             return (
//               <li
//                 key={index}
//                 className="flex items-center justify-between px-4 py-3 group cursor-pointer"
//               >
//                 <div className="flex items-center gap-2">
//                   <span>{item?.name}</span>
//                 </div>

//                 <span className="group-hover:block hidden text-sky-500 cursor-pointer">
//                   <IoMdClose />
//                 </span>
//               </li>
//             );
//           })}
//         </ul>
//       )}
//       <div className="mb-3">
//         <div className="">
//           <input
//             type="text"
//             onClick={(e) => setAnchorEl(e?.currentTarget)}
//             // className="p-2 py-3 mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
//             className={`p-2 mt-4 ${getSummaryBgClass()} rounded-lg focus:outline-none w-full  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
//             // value={adSetDetails?.name}
//             placeholder="Select Device Makes..."
//             // onChange={(e) =>
//             //   setAdSetDetails((pre) => ({
//             //     ...pre,
//             //     name: e?.target?.value,
//             //   }))
//             // }
//           />
//         </div>
//       </div>
//       <Menu
//         id="basic-menu"
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         MenuListProps={{
//           "aria-labelledby": "basic-button",
//           sx: { width: "100%" }, // <-- The line that does all
//         }}
//         transformOrigin={{ horizontal: "left", vertical: "top" }}
//         anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
//         sx={{
//           "& .MuiList-root": {
//             padding: "0px !important", // Modify border radius
//           },
//           padding: "0px",
//           zIndex: 9999, // Remove any max width that might be applied by default
//         }}
//       >
//         <div className={`${getSummaryBgClass()} w-full`}>
//           {deviceMakeCompanies.map((option) => (
//             <div key={option.name}>
//               <MenuItem
//                 onClick={() => handleParentToggle(option.name)}
//                 className="w-full hover:bg-gray-100 flex !justify-between "
//               >
//                 <div className="flex gap-3 items-center ">
//                   {option.children &&
//                     (openParents[option.name] ? (
//                       <RiArrowDownSFill size={16} />
//                     ) : (
//                       <RiArrowRightSFill size={16} />
//                     ))}
//                   <ListItemText primary={option.name} />
//                 </div>

//                 <Checkbox
//                   checked={adSetDetails?.devices[0]?.marketing_name?.find(
//                     (item) => option?.id == item?.id
//                   )}
//                   onClick={() => handleToggle(option)}
//                   size="small"
//                   color="primary"
//                 />
//               </MenuItem>
//               {/* Render Child Options */}
//               {option.children && (
//                 <Collapse
//                   in={openParents[option.name]}
//                   timeout="auto"
//                   unmountOnExit
//                 >
//                   <List component="div" disablePadding>
//                     {option.children.map((child) => (
//                       <MenuItem
//                         key={child}
//                         onClick={() => handleToggle(child)}
//                         className="pl-8 hover:bg-gray-50"
//                       >
//                         <Checkbox
//                           checked={adSetDetails?.devices[0]?.marketing_name?.find(
//                             (item) => child?.id == item?.id
//                           )}
//                           onClick={() => handleToggle(child)}
//                           size="small"
//                           color="primary"
//                         />
//                         <ListItemText primary={child?.name} />
//                       </MenuItem>
//                     ))}
//                   </List>
//                 </Collapse>
//               )}
//             </div>
//           ))}
//         </div>
//       </Menu>
//     </div>
//   );
// };

// export default DeviceMakes;

import React, { useState, useEffect } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { selectStyles } from "../_elements/SelectStyles";
import Select from "react-select";
import axios from "../../axoisConfig";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import Menu from "@mui/material/Menu";
import { IoMdClose } from "react-icons/io";

import {
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
  Backdrop,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
} from "@mui/material";
import DeviceMakes from "./deviceMakes";
import CustomCheckbox from "./CustomCheckbox";

const token = localStorage?.getItem("auth-token");

const Audiences = ({ adSetDetails, setAdSetDetails }) => {
  const [selectedPlacementType, setSelectedPlacementType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLifeStyleOpened, setIsLifeStyleOpened] = useState(false);

  const {
    currentMode,
    themeBgImg,
    t,
    primaryColor,
    BACKEND_URL,
    snapchatCredentials,
  } = useStateContext();
  const [isMore, setIsMore] = useState(false);
  const [checked, setChecked] = useState(false);
  const [openedCountry, setOpenedCountry] = useState(null);

  const [deviceMakeCompanies, setDeviceMakeCompanies] = useState([]);
  const open = Boolean(anchorEl);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getDeviceMakes();
  }, []);

  // function buildTree(items) {
  //   let tree = [];
  //   let lookup = {};

  //   items.forEach((item) => {
  //     lookup[item.id] = { ...item, children: [] };
  //   });

  //   items.forEach((item) => {
  //     const node = lookup[item.id];
  //     if (item.parentId !== "SLC_0") {
  //       lookup[item.parentId].children.push(node);
  //     } else {
  //       tree.push(node);
  //     }
  //   });

  //   return tree;
  // }
  function buildTree(data) {
    const tree = {};

    // Iterate over each item in the data
    data.forEach((item) => {
      const nameParts = item.marketing_name.name.split(" > "); // Split by '>' to create nested structure
      let currentLevel = tree;

      // Traverse through the tree structure
      nameParts.forEach((part, index) => {
        // If this level doesn't exist yet, create it
        if (!currentLevel[part]) {
          currentLevel[part] = {
            name: part,
            id: item.marketing_name.id,
            children: {},
          };
        }

        // Move to the next level (deeper in the tree)
        currentLevel = currentLevel[part].children;
      });
    });

    // Convert the tree into an array of objects
    function convertTreeToArray(tree) {
      return Object.keys(tree).map((key) => {
        const node = tree[key];
        return {
          name: node.name,
          id: node.id,
          children: Object.keys(node.children).length
            ? convertTreeToArray(node.children)
            : [],
        };
      });
    }

    return convertTreeToArray(tree);
  }

  async function getDeviceMakes() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/targeting/device/marketing_name`,
        {
          access_token: snapchatCredentials?.access_token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setDeviceMakeCompanies(buildTree(res?.data?.data?.targeting_dimensions));
    } catch (error) {
      console.log(error, "this is an error");
    }
  }

  //   const treeStructure = buildTree(data.targeting_dimensions);
  //   console.log(treeStructure);
  console.log(adSetDetails, "adsetdetails");
  return (
    <div className={``}>
      {/* <h2 className="font-bold text-[16px] mb-5">Audiences</h2> */}
      <div
      // className={`${getSummaryBgClass()} rounded-3xl px-8 py-4 shadow-md mb-9`}
      >
        <label htmlFor="" className="font-semibold mb-2">
          Device Makes
        </label>
        {deviceMakeCompanies.filter((obj) =>
          adSetDetails?.devices[0]?.marketing_name?.includes(obj.id)
        )?.length > 0 && (
          <ul className="border border-gray-200 rounded-lg max-h-[300px] overflow-auto">
            {deviceMakeCompanies
              .filter((obj) =>
                adSetDetails?.devices[0]?.marketing_name?.includes(obj.id)
              )
              ?.map((device, index) => {
                return (
                  <li key={index} className="flex  flex-col px-4 py-1 ">
                    <div
                      className={`flex items-center justify-between px-4 py-3 group cursor-pointer hover:bg-[#F9F9FA] ${
                        currentMode == "dark"
                          ? "hover:bg-gray-800"
                          : "hover:bg-[#F9F9FA]"
                      }`}
                    >
                      <div className="flex items-center gap-2">
                        <span>{device.name}</span>
                      </div>

                      <span
                        onClick={(e) => {
                          setAdSetDetails((pre) => {
                            pre.devices[0].marketing_name =
                              pre?.devices[0]?.marketing_name.filter(
                                (ls) => ls != device.id
                              );
                            return { ...pre };
                          });
                        }}
                        className="group-hover:block hidden text-sky-500 cursor-pointer"
                      >
                        <IoMdClose />
                      </span>
                    </div>
                  </li>
                );
              })}
          </ul>
        )}
        <div>
          <div className="relative">
            <input
              type="text"
              onClick={(e) => setAnchorEl(e?.currentTarget)}
              // className="p-2 py-3 mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
              className={`p-2 mt-4 ${
                currentMode != "dark" && "!bg-[#F9F9FA]"
              } ${getSummaryBgClass()} rounded-lg focus:outline-none w-full  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
              // value={adSetDetails?.name}
              placeholder="Select Category"
              // onChange={(e) =>
              //   setAdSetDetails((pre) => ({
              //     ...pre,
              //     name: e?.target?.value,
              //   }))
              // }
            />
          </div>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: { width: anchorEl && anchorEl.offsetWidth }, // <-- The line that does all
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          sx={{
            "& .MuiList-root": {
              padding: "0px !important", // Modify border radius
            },
            padding: "0px",
            zIndex: 9999, // Remove any max width that might be applied by default
          }}
        >
          <div className={`${getSummaryBgClass()} max-h-[300px] overflow-auto`}>
            <ul className="">
              {
                <div className="pl-6">
                  {deviceMakeCompanies?.map((lifeStyle, index) => {
                    return (
                      <LifeStyleRow
                        key={index}
                        lifeStyle={lifeStyle}
                        adSetDetails={adSetDetails}
                        setAdSetDetails={setAdSetDetails}
                      />
                    );
                  })}
                </div>
              }
            </ul>
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default Audiences;

const LifeStyleRow = ({ lifeStyle, adSetDetails, setAdSetDetails }) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <li
      className="flex flex-col px-4 py-2  group cursor-pointer"
      onClick={(e) => {
        // if (country?.code === openedCountry?.code) {
        //   setOpenedCountry(null);
        //   return;
        // }
        e.stopPropagation();
        setIsOpened((pre) => !pre);
      }}
    >
      <div className="flex flex-col pl-4">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center  gap-2">
            {lifeStyle?.children?.length > 0 && (
              <div>
                {!isOpened ? (
                  <span>
                    <MdKeyboardArrowRight size={16} />
                  </span>
                ) : (
                  <span>
                    <MdKeyboardArrowDown size={16} />
                  </span>
                )}
              </div>
            )}
            <div className="flex flex-col">
              <span>{lifeStyle?.name}</span>
            </div>
          </div>
          <div>
            <CustomCheckbox
              value={
                adSetDetails?.devices[0]?.marketing_name?.find(
                  (interest) => interest == lifeStyle?.id
                )
                  ? 2
                  : 0
              }
              onChange={(e) => {
                setAdSetDetails((pre) => {
                  if (e == 2) {
                    pre.devices[0].marketing_name = [
                      ...(pre?.devices[0]?.marketing_name || []),
                      lifeStyle?.id,
                    ];
                  } else if (e == 0) {
                    pre.devices[0].marketing_name =
                      pre?.devices[0]?.marketing_name?.filter(
                        (ls) => ls != lifeStyle.id
                      );
                  }
                  return { ...pre };
                });
              }}
            />
          </div>
        </div>
        <div className="flex flex-col">
          {isOpened &&
            lifeStyle?.children?.map((item, index) => {
              return (
                <LifeStyleRow
                  key={index}
                  lifeStyle={item}
                  adSetDetails={adSetDetails}
                  setAdSetDetails={setAdSetDetails}
                />
              );
            })}
        </div>
      </div>
    </li>
  );
};
