import React, { useState } from "react";

const MoodEmojiUI = ({ goalsData, setGoalData }) => {
  const [selectedIndex, setSelectedIndex] = useState(2);

  const emojis = [
    { label: "Angry", emoji: "😡" },
    { label: "Sad", emoji: "😢" },
    { label: "Neutral", emoji: "😐" },
    { label: "Happy", emoji: "😊" },
    { label: "Love", emoji: "😍" },
  ];

  const handleSelect = (index, value) => {
    setSelectedIndex(index);
    setGoalData({
      ...goalsData,
      mood: value,
    });
  };

  return (
    <div className="flex flex-col items-center justify-center mt-5 ">
      <div className="flex items-center gap-4 p-4 rounded-full shadow-sm  relative bg-white">
        {emojis.map((item, index) => (
          <div
            key={index}
            className={`cursor-pointer flex items-center justify-center w-12 h-12 rounded-full text-2xl transition-transform duration-300 ${
              selectedIndex === index
                ? "scale-125 bg-yellow-200 shadow-lg"
                : "scale-100 bg-transparent"
            }`}
            onClick={() => handleSelect(index, item?.label)}
          >
            {item?.emoji}
          </div>
        ))}
      </div>
      <p className="mt-4 text-lg font-medium text-white z-50">
        {emojis[selectedIndex].label}
      </p>
    </div>
  );
};

export default MoodEmojiUI;
