import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import {
  categories,
  departments,
  priority_options,
  task_status,
  task_type,
} from "../_elements/SelectOptions";
import { selectStyles } from "../_elements/SelectStyles";
import Select from "react-select";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import moment from "moment";
import moment from "moment-timezone";

import dayjs from "dayjs";
import { toast } from "react-toastify";
import axios from "../../axoisConfig";
import { IoMdClose } from "react-icons/io";
import { socket } from "../../Pages/App";
import { IoCloseOutline } from "react-icons/io5";

const AddTaskForm = ({
  singleTask,
  fetchTasks,
  handleClose,
  setSingleTask,
}) => {
  const {
    currentMode,
    BACKEND_URL,
    darkModeColors,
    t,
    themeBgImg,
    primaryColor,
    blurDarkColor,
    blurLightColor,
    isLangRTL,
    i18n,
    fontFam,
    User,
  } = useStateContext();

  const theme = useTheme();

  const token = localStorage.getItem("auth-token");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const searchRef = useRef();

  const [loading, setLoading] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [agencyUsers, setAgencyUsers] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [Dialogue, setDialogue] = useState(false);
  const [notes, setNotes] = useState("");

  console.log("edit task data::", singleTask);

  const [taskData, setTaskData] = useState({
    department: singleTask?.department || null,
    category: singleTask?.category || "",
    agency: singleTask?.agency || User?.agency || null,
    country: singleTask?.country || null,
    task_title: singleTask?.task_title || null,
    description: singleTask?.description || null,
    task_type: singleTask?.task_type || "",
    priority: singleTask?.priority || "",
    start_date: singleTask?.start_date || null,
    deadline: singleTask?.deadline || null,
    assigned_to: Array.isArray(singleTask?.assigned_to)
      ? singleTask?.assigned_to?.join(",")
      : singleTask?.assigned_to || null,
    status: singleTask?.status || "New",
    note: null,
  });

  console.log("task data:: ", taskData);
  console.log(
    "selected agency: ",
    agencies?.find((curr) => curr.id === taskData?.agency)
  );

  const handleChange = (e) => {
    setTaskData(() => ({
      ...taskData,
      [e.target.id]: e.target.value,
    }));
  };

  const handleUser = (e) => {
    const selectedValues = e.target.value.map(String); // Ensure selected values are strings
    const latestValue = String(selectedValues[selectedValues.length - 1]);
    console.log("latest value: ", latestValue);

    // Convert the existing assigned_to string to an array of strings
    const currentValues = taskData?.assigned_to
      ? taskData?.assigned_to?.split(",")
      : [];

    // Create a Set from the current values for fast lookups
    const currentValuesSet = new Set(currentValues);

    console.log("current values set: ", currentValuesSet);

    let updatedValues;

    if (currentValuesSet.has(latestValue)) {
      updatedValues = currentValues?.filter((curr) => curr != latestValue);
    } else {
      updatedValues = [...currentValues, latestValue];
    }

    console.log("updated values:: ", updatedValues);

    // Update the state with the new values
    setTaskData({
      ...taskData,
      assigned_to: updatedValues.join(","), // Convert back to a comma-separated string
    });
  };

  const fetchAgencies = async () => {
    setLoading(true);

    try {
      const agenciesList = await axios.get(`${BACKEND_URL}/agencies`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("agencies ::: ", agenciesList);

      setAgencies(agenciesList?.data?.data?.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);

      toast.error("Unable to fetch the agencies", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchAgencyUsers = async () => {
    setUserLoading(true);

    try {
      const agencyUsers = await axios.get(
        `${BACKEND_URL}/allUsers?agency=${taskData?.agency}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("agency users::: ", agencyUsers);

      setAgencyUsers(agencyUsers?.data?.users);

      setUserLoading(false);
    } catch (error) {
      console.log(error);
      setUserLoading(false);

      toast.error(`Unable to fetch the users for selected agency. `, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const AddTask = () => {
    setBtnLoading(true);

    if (singleTask && !taskData?.note) {
      toast.error("Kindly add the note.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setBtnLoading(false);
      return;
    }

    if (!taskData?.assigned_to) {
      toast.error("Kindly assigned the task to someone.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setBtnLoading(false);
      return;
    }

    let taskDetails = taskData;

    if (taskData?.task_type == "Daily") {
      taskDetails = {
        ...taskData,
        status: "Daily",
      };
    }

    console.log("tasks details : ", taskDetails);

    let url = singleTask
      ? `${BACKEND_URL}/tasks/${singleTask?.t_id}`
      : `${BACKEND_URL}/tasks`;

    axios
      .post(url, taskDetails, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("Task Added: ", result);

        if (result?.data?.status === false) {
          toast.error(result?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setBtnLoading(false);
          return;
        }

        toast.success(
          `Task ${singleTask ? "Updated" : "Added"} Successfully.`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );

        if (singleTask) {
          setSingleTask({
            ...result?.data?.data,
            t_id: singleTask?.t_id,
          });
          handleClose();
          fetchTasks();
          return;
        }
        socket.emit("new_task_added", { assignedTo: taskData?.assigned_to });

        setTaskData({
          department: "",
          category: "",

          country: "",
          task_title: "",
          description: "",
          task_type: "",
          priority: "",
          start_date: null,
          deadline: null,
          assigned_to: "",
          note: "",
        });
        setBtnLoading(false);
      })

      .catch((err) => {
        setBtnLoading(false);

        console.log(err);
        toast.error("Something Went Wrong! Please Try Again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    fetchAgencies();
  }, []);
  useEffect(() => {
    if (taskData?.agency) {
      fetchAgencyUsers();
    }
  }, [taskData?.agency]);

  return (
    <>
      {loading ? (
        <div className="w-full h-[500px] flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            className={`grid md:grid-cols-2 sm:grid-cols-1 ${"lg:grid-cols-2 xl:grid-cols-3"} ${
              currentMode === "dark" ? "text-white" : "text-black"
            } gap-5 my-5`}
          >
            {/*DEPARTMENT DETAILS  */}
            <Box
              sx={{
                ...darkModeColors,
                "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                  {
                    right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                    transformOrigin: isLangRTL(i18n.language)
                      ? "right"
                      : "left",
                  },
                "& legend": {
                  textAlign: isLangRTL(i18n.language) ? "right" : "left",
                },
                "& .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root": {
                  color: currentMode === "dark" ? "#EEEEEE" : "#2B2830",
                },
              }}
              className={`${
                currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
              }
                  p-5`}
            >
              <h1 className="text-center text-primary py-2 mb-5 uppercase font-semibold border-b-2 border-primary">
                {t("department")?.toUpperCase()}
              </h1>
              <div className="w-full pt-5"></div>

              {/* AGENCY */}
              {/* <FormControl
            className={`${
              currentMode === "dark" ? "text-white" : "text-black"
            }`}
            sx={{
              minWidth: "100%",
              borderRadius: 1,
              marginBottom: "10px",
            }}
          >
            <TextField
              id="user_id"
              select
              value={commissionData?.user_id || "selected"}
              label={t("select_user")}
              onChange={(e) => {
                setCommissionData({
                  ...commissionData,

                  user_id: e.target.value,
                });
              }}
              size="small"
              className="w-full border border-gray-300 rounded"
              displayEmpty
              required
              sx={{
                height: "40px",
                "& .MuiSelect-select": {
                  fontSize: 11,
                },
              }}
            >
              <MenuItem selected value="selected">
                ---{t("select_user")}----
              </MenuItem>
              <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                <TextField
                  placeholder={t("search_users")}
                  ref={searchRef}
                  sx={{ "& input": { border: "0" } }}
                  variant="standard"
                  onChange={(e) => {
                    e.preventDefault();
                    const inputValue =
                      searchRef.current.querySelector("input").value;
                    if (inputValue) {
                      fetchUsers(inputValue, "user");
                    }
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              </MenuItem>
              {user?.map((user) => (
                <MenuItem key={user?.id} value={user?.id}>
                  {user?.userName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl> */}

              {/* AGENCIES  */}
              <Select
                id="agency"
                options={agencies?.map((agency) => ({
                  value: agency?.id,
                  label: agency?.agency_name,
                }))}
                value={
                  // taskData?.agency
                  //   ? agencies?.find((curr) => curr.value === taskData?.agency)
                  //       ?.agency_name
                  //   : t("vendor_type_agency")
                  {
                    value: taskData?.agency,
                    label: taskData?.agency
                      ? agencies?.find(
                          (agency) => agency.id === taskData?.agency
                        )?.agency_name || ""
                      : t("vendor_type_agency"),
                  }
                }
                onChange={(e) => {
                  const country = agencies?.find(
                    (agency) => agency?.id === e.value
                  );

                  console.log("country:: ", country);
                  setTaskData({
                    ...taskData,
                    agency: e.value,
                    country: country?.country,
                  });
                }}
                placeholder={t("vendor_type_agency")}
                className="mb-5"
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />

              {/* DEPARTMENT  */}
              <Select
                id="title"
                options={departments(t)}
                value={departments(t)?.filter(
                  (curr) => curr.value === taskData?.department
                )}
                onChange={(e) => {
                  setTaskData({
                    ...taskData,
                    category: "", // Reset the category when the department changes
                    department: e.value,
                  });
                }}
                placeholder={t("department")}
                className={`mb-5`}
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />

              {/* CATEGORY */}
              <Select
                id="category"
                options={
                  taskData?.department === "All"
                    ? categories(t)
                    : categories(t)?.filter(
                        (cat) => cat?.link === taskData?.department
                      )
                }
                value={
                  taskData?.category &&
                  categories(t)?.find((cat) => cat.value === taskData?.category)
                }
                onChange={(e) => {
                  console.log("e::::::::: user: ", e);
                  setTaskData({
                    ...taskData,
                    category: e.value,
                  });
                }}
                placeholder={t("select_category")}
                className={`mb-5`}
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />
            </Box>
            {/* TASK DETAILS  */}
            <Box
              sx={{
                ...darkModeColors,
                "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                  {
                    right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                    transformOrigin: isLangRTL(i18n.language)
                      ? "right"
                      : "left",
                  },
                "& legend": {
                  textAlign: isLangRTL(i18n.language) ? "right" : "left",
                },
                "& .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root": {
                  color: currentMode === "dark" ? "#EEEEEE" : "#2B2830",
                },
              }}
              className={`${
                currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
              }
                  p-5`}
            >
              <h1 className="text-center text-primary py-2 mb-5 uppercase font-semibold border-b-2 border-primary">
                {t("heading_task_detail")?.toUpperCase()}
              </h1>
              <div className="w-full pt-5"></div>

              {/* TASK TYPE  */}
              <Select
                id="title"
                options={task_type(t)}
                value={
                  taskData?.task_type
                    ? task_type(t)?.find(
                        (curr) => curr.value === taskData?.task_type
                      )
                    : null
                }
                onChange={(e) => {
                  setTaskData({
                    ...taskData,
                    task_type: e.value,
                  });
                }}
                placeholder={t("task_type")}
                className={`mb-5`}
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />

              {/* TASK PRIORITY  */}
              <Select
                id="priority"
                options={priority_options(t)}
                value={
                  taskData?.priority
                    ? priority_options(t)?.find(
                        (curr) => curr.value === taskData?.priority
                      )
                    : null
                }
                onChange={(e) => {
                  setTaskData({
                    ...taskData,
                    priority: e.value,
                  });
                }}
                placeholder={t("label_priority")}
                className={`mb-5`}
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />

              {/* TASKT TITLE */}
              <TextField
                id="task_title"
                type={"text"}
                label={t("task_title")}
                className="w-full col-span-2 mb-5"
                sx={{
                  "&": {
                    marginBottom: "1.25rem !important",
                    zIndex: 1,
                  },
                }}
                variant="outlined"
                size="small"
                value={taskData?.task_title}
                onChange={handleChange}
                required
              />

              {/* TASKT DESCRIPTION */}
              <TextField
                id="description"
                type={"text"}
                label={t("description")}
                className="w-full col-span-2"
                sx={{
                  "&": {
                    // marginBottom: "1.25rem !important",
                    zIndex: 1,
                  },
                }}
                variant="outlined"
                size="small"
                value={taskData?.description}
                onChange={handleChange}
                required
              />
            </Box>

            {/* DEADLINE  */}
            <Box
              sx={{
                ...darkModeColors,
                "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                  {
                    right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                    transformOrigin: isLangRTL(i18n.language)
                      ? "right"
                      : "left",
                  },
                "& legend": {
                  textAlign: isLangRTL(i18n.language) ? "right" : "left",
                },
                "& .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root": {
                  color: currentMode === "dark" ? "#EEEEEE" : "#2B2830",
                },
              }}
              className={`${
                currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
              }
                  p-5`}
            >
              <h1 className="text-center text-primary py-2 mb-5 uppercase font-semibold border-b-2 border-primary">
                {t("deadline")?.toUpperCase()}
              </h1>
              <div className="w-full pt-5"></div>

              {/* START DATE */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={taskData?.start_date}
                  label={t("start_date")}
                  onChange={(newValue) => {
                    const formattedDateTime = moment(newValue?.$d).format(
                      "YYYY-MM-DD HH:mm:ss"
                    );

                    setTaskData((prev) => ({
                      ...prev,
                      start_date: formattedDateTime,
                    }));
                  }}
                  // format="DD-MM-YYYY HH:mm:ss"
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        "& input": {
                          color: currentMode === "dark" ? "white" : "black",
                        },
                        "& .MuiSvgIcon-root": {
                          color: currentMode === "dark" ? "white" : "black",
                        },
                        marginBottom: "15px",
                      }}
                      fullWidth
                      size="small"
                      {...params}
                      onKeyDown={(e) => e.preventDefault()}
                      readOnly={true}
                    />
                  )}
                  // minDate={dayjs().startOf("day").toDate()}
                />
              </LocalizationProvider>

              {/* END DATE */}
              {taskData?.task_type == "Daily Task" ? null : (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={taskData?.deadline}
                    label={t("deadline")}
                    // views={["day", "month", "year"]}
                    onChange={(newValue) => {
                      const formattedDate = moment(newValue?.$d).format(
                        "YYYY-MM-DD HH:mm:ss"
                      );

                      setTaskData((prev) => ({
                        ...prev,
                        deadline: formattedDate,
                      }));
                    }}
                    // format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          "& input": {
                            color: currentMode === "dark" ? "white" : "black",
                          },
                          "& .MuiSvgIcon-root": {
                            color: currentMode === "dark" ? "white" : "black",
                          },
                          marginBottom: "15px",
                        }}
                        fullWidth
                        size="small"
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                        readOnly={true}
                      />
                    )}
                    // minDate={dayjs().startOf("day").toDate()}
                  />
                </LocalizationProvider>
              )}

              {/* AGENCY USERS */}
              {/* <FormControl
                className={`${
                  currentMode === "dark" ? "text-white" : "text-black"
                }`}
                sx={{
                  minWidth: "100%",
                  borderRadius: 1,
                  marginBottom: "10px",
                }}
              >
                <TextField
                  id="user_id"
                  select
                  value={taskData?.assigned_to || "selected"}
                  label={t("assigned_to")}
                  onChange={(e) => {
                    setTaskData({
                      ...taskData,

                      assigned_to: e.target.value,
                    });
                  }}
                  size="small"
                  className="w-full border border-gray-300 rounded"
                  displayEmpty
                  required
                  sx={{
                    height: "40px",
                    "& .MuiSelect-select": {
                      fontSize: 11,
                    },
                  }}
                >
                  <MenuItem selected value="selected">
                    ---{t("assigned_to")}----
                  </MenuItem>

                  {agencyUsers?.length > 0 ? (
                    agencyUsers?.map((user) => (
                      <MenuItem key={user?.id} value={user?.id}>
                        {user?.userName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{t("no_users")}</MenuItem>
                  )}
                </TextField>
              </FormControl> */}
              {/* AGENCY USERS */}
              {/* <FormControl
                className={`${
                  currentMode === "dark" ? "text-white" : "text-black"
                }`}
                sx={{
                  minWidth: "100%",
                  borderRadius: 1,
                  marginBottom: "10px",
                }}
              >
                <TextField
                  id="user_id"
                  select
                  value={
                    taskData?.assigned_to
                      ? taskData?.assigned_to?.split(",")
                      : []
                  } // Ensure it's an array
                  label={t("assigned_to")}
                  onChange={(e) => {
                    const selectedValues = e.target.value;
                    setTaskData({
                      ...taskData,
                      assigned_to: selectedValues.join(","), // Convert back to comma-separated string
                    });
                  }}
                  size="small"
                  className="w-full border border-gray-300 rounded"
                  displayEmpty
                  required
                  multiple
                  SelectProps={{
                    multiple: true, // Enable multiple selection
                    renderValue: (selected) => {
                      console.log("selected: ", selected);
                      console.log(
                        "selected map:: ",
                        selected
                          ?.map(
                            (id) =>
                              agencyUsers?.find((user) => user.id == id)
                                ?.userName
                          )
                          .filter(Boolean) // Filter out any undefined values
                          .join(", ")
                      );
                      return selected.length > 0
                        ? selected
                            ?.map(
                              (id) =>
                                agencyUsers?.find((user) => user.id == id)
                                  ?.userName
                            )
                            .filter(Boolean) // Filter out any undefined values
                            .join(", ")
                        : "---" + t("assigned_to") + "----";
                    },
                  }}
                  sx={{
                    height: "40px",
                    "& .MuiSelect-select": {
                      fontSize: 11,
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    ---{t("assigned_to")}----
                  </MenuItem>
                  {agencyUsers?.length > 0 ? (
                    agencyUsers.map((user) => (
                      <MenuItem key={user?.id} value={user?.id}>
                        {user?.userName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{t("no_users")}</MenuItem>
                  )}
                </TextField>
              </FormControl> */}
              <FormControl
                className={`${
                  currentMode === "dark" ? "text-white" : "text-black"
                }`}
                sx={{
                  minWidth: "100%",
                  borderRadius: 1,
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <TextField
                  id="user_id"
                  select
                  value={
                    taskData?.assigned_to
                      ? taskData?.assigned_to.split(",")
                      : []
                  }
                  label={t("assigned_to")}
                  onChange={(e) => handleUser(e)}
                  size="small"
                  className="w-full border border-gray-300 rounded"
                  displayEmpty
                  required
                  SelectProps={{
                    multiple: true,
                    open: isDropdownOpen, // Control open state
                    onClose: () => setDropdownOpen(false), // Close on normal dropdown close
                    onOpen: () => setDropdownOpen(true), // Open dropdown explicitly
                    renderValue: (selected) => {
                      const selectedValue = Array.isArray(taskData?.assigned_to)
                        ? taskData?.assigned_to
                        : taskData?.assigned_to?.split(",");

                      return selectedValue.length > 0
                        ? selectedValue
                            ?.map(
                              (id) =>
                                agencyUsers?.find((user) => user.id == id)
                                  ?.userName
                            )
                            .filter(Boolean)
                            .join(", ")
                        : "---" + t("assigned_to") + "----";
                    },
                  }}
                  sx={{
                    height: "40px",
                    "& .MuiSelect-select": {
                      fontSize: 11,
                    },
                    position: "relative",
                  }}
                >
                  <MenuItem disabled value="">
                    ---{t("assigned_to")}----
                  </MenuItem>
                  {agencyUsers?.length > 0 ? (
                    agencyUsers?.map((user) => (
                      <MenuItem
                        key={user?.id}
                        value={user?.id}
                        sx={{
                          // Apply primary color and white text if the item is selected
                          backgroundColor: taskData?.assigned_to
                            ?.split(",")
                            .includes(user.id.toString())
                            ? primaryColor
                            : "inherit",
                          color: taskData?.assigned_to
                            ?.split(",")
                            .includes(user.id.toString())
                            ? "#fff"
                            : "inherit",
                          "&.Mui-selected": {
                            backgroundColor: primaryColor + " !important",
                            color: "#fff !important", // Ensure text stays white on selection
                          },
                          "&:hover": {
                            backgroundColor: primaryColor + "22",
                          },
                        }}
                      >
                        {user?.userName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{t("no_users")}</MenuItem>
                  )}
                </TextField>
                {/* Fixed position button */}
                {/* {taskData?.assigned_to &&
                  taskData.assigned_to.split(",").length > 1 &&
                  isDropdownOpen && (
                    <Button
                      variant="contained"
                      onClick={() => setDropdownOpen(false)} // Close dropdown
                      sx={{
                        // position: "fixed",
                        position: "absolute",
                        bottom: 0,
                        right: 2,
                        zIndex: 1301,
                        backgroundColor: ` ${primaryColor}`,
                        borderRadius: "50px",
                      }}
                    >
                      {t("select_user")}
                    </Button>
                  )} */}
                {taskData?.assigned_to &&
                  taskData.assigned_to.split(",").length > 1 &&
                  isDropdownOpen && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 8, // Adjust to stay within dropdown bounds
                        right: 8,
                        zIndex: 1301,
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => setDropdownOpen(false)}
                        sx={{
                          backgroundColor: primaryColor,
                          borderRadius: "50px",
                          minWidth: "36px", // Adjust for small screens
                          padding: "6px 8px",
                          "& .MuiButton-startIcon": {
                            margin: 0,
                          },
                        }}
                      >
                        {t("select_user")}
                      </Button>
                    </Box>
                  )}
              </FormControl>
            </Box>
          </div>

          <button
            style={{
              color: "white",
              fontFamily: fontFam,
            }}
            className={`${
              currentMode === "dark"
                ? "bg-primary-dark-neu"
                : "bg-primary-light-neu"
            } w-full text-white p-3 font-semibold mb-3 `}
            onClick={singleTask ? () => setDialogue(true) : AddTask}
            disabled={btnLoading ? true : false}
          >
            {btnLoading ? (
              <CircularProgress
                size={23}
                sx={{ color: "white" }}
                className="text-white"
              />
            ) : (
              <span>{singleTask ? t("btn_update") : t("create")}</span>
            )}
          </button>
        </>
      )}

      {Dialogue && (
        <>
          <Dialog
            sx={{
              "& .MuiPaper-root": {
                boxShadow: "none !important",
              },
              "& .MuiBackdrop-root, & .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop":
                {
                  // backgroundColor: "rgba(0, 0, 0, 0.6) !important",
                },
            }}
            open={Dialogue}
            onClose={(e) => setDialogue(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <IconButton
              sx={{
                position: "absolute",
                right: 12,
                top: 10,
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={() => setDialogue(false)}
            >
              <IoMdClose size={18} />
            </IconButton>
            <div
              className={`px-10 py-5 ${
                currentMode === "dark"
                  ? "bg-[#1C1C1C] text-white"
                  : "bg-white text-black"
              }`}
            >
              <div className="flex flex-col justify-center items-center">
                <h1 className="font-semibold pt-3 text-lg text-center">
                  {t("ticket_add_note_label")}
                </h1>
              </div>
              <div className="action buttons mt-5 flex items-center justify-center space-x-2">
                <Box
                  sx={{
                    ...darkModeColors,
                    "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                      {
                        right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                        transformOrigin: isLangRTL(i18n.language)
                          ? "right"
                          : "left",
                      },
                    "& legend": {
                      textAlign: isLangRTL(i18n.language) ? "right" : "left",
                    },
                    "& .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root": {
                      color: currentMode === "dark" ? "#EEEEEE" : "#2B2830",
                    },
                  }}
                  className={`${
                    currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
                  }
                  p-5`}
                >
                  <div className="flex items-center space-x-2 ">
                    <TextField
                      id="note"
                      type={"text"}
                      label={t("notes")}
                      className="w-full col-span-2 mb-5"
                      sx={{
                        "&": {
                          //   marginBottom: "1.25rem !important",
                          zIndex: 1,
                        },
                      }}
                      variant="outlined"
                      size="small"
                      value={taskData?.note}
                      onChange={handleChange}
                      required
                    />
                    <button
                      style={{
                        color: "white",
                        fontFamily: fontFam,
                      }}
                      className={`${
                        currentMode === "dark"
                          ? "bg-primary-dark-neu"
                          : "bg-primary-light-neu"
                      } w-max text-white p-3 font-semibold  `}
                      onClick={() => {
                        setDialogue(false);
                        AddTask();
                      }}
                      disabled={btnLoading ? true : false}
                    >
                      <span>{t("ticket_add_note_label")}</span>
                    </button>
                  </div>
                </Box>
              </div>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
};

export default AddTaskForm;
