import { useState, useEffect } from "react";
import {
  IoCheckbox,
  IoSquareOutline,
  IoCheckboxOutline,
} from "react-icons/io5";

const CustomCheckbox = ({ value, onChange, className }) => {
  const [state, setState] = useState(value); // Initialize with external value

  // Effect to update local state when external value changes
  useEffect(() => {
    setState(value);
  }, [value]);

  const toggleCheckbox = () => {
    const newState = state === 2 ? 0 : 2;
    setState(newState);
    onChange(newState); // Notify parent component of the change
  };

  const renderIcon = () => {
    if (state === 0) return <IoSquareOutline className="text-xl" size={18} />;
    if (state === 1) return <IoCheckboxOutline className="text-xl" size={18} />;
    return <IoCheckbox className="text-xl" size={18} />;
  };

  return (
    <button onClick={toggleCheckbox} className={``}>
      {renderIcon()}
    </button>
  );
};

export default CustomCheckbox;
