import React, { useState, useEffect } from "react";
import { Backdrop, Modal } from "@mui/material";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import { selectStyles } from "../_elements/SelectStyles.jsx";
import axios from "../../axoisConfig.js";
import { CiFolderOn } from "react-icons/ci";
const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const token = localStorage?.getItem("auth-token");

const CreateAdModal = ({
  setCreateAdModal,
  createAdModal,
  setIsSingleAd,
  setAdDetails,
  campaigns,
  adSets,
  setAdData,
  adData,
  getAllAdSets,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
  } = useStateContext();
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [loading, setLoading] = useState({
    campaign: false,
    adSet: false,
  });

  useEffect(() => {
    if (adSets?.length == 0) getAllAdSets();
  }, []);

  return (
    <Modal
      //   keepMounted
      open={createAdModal}
      onClose={() => setCreateAdModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[30%]  ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2 p-5 rounded-md flex flex-col items-start`}
      >
        <h3 className="text-[20px] font-semibold mb-3">Campaign</h3>
        <div className="w-full ">
          <Select
            isLoading={loading?.campaign}
            id="adAccounts"
            options={
              campaigns
                ? campaigns?.map((campaign) => ({
                    label: campaign?.name,
                    value: campaign?.id,
                  }))
                : []
            }
            value={
              campaigns
                ? campaigns
                    ?.map((campaign) => ({
                      label: campaign?.name,
                      value: campaign?.id,
                    }))
                    .find((camp) => camp.value === adData?.campaign_id)
                : "Campaign"
            }
            onChange={(e) => {
              setAdData({
                ...adData,
                campaign_id: e.value,
              });
              // setSingleCampData(campaigns?.find((camp) => camp.id === e.value));
            }}
            placeholder={"Campaign"}
            // className={`mb-5`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div>
        <h3 className="text-[20px] font-semibold mb-3">Ad Set</h3>
        <div className="w-full ">
          <Select
            isLoading={loading?.adSet}
            id="adAccounts"
            options={
              adSets
                ? adSets?.map((campaign) => ({
                    label: campaign?.name,
                    value: campaign?.id,
                  }))
                : []
            }
            value={
              adSets
                ? adSets
                    .map((campaign) => ({
                      label: campaign?.name,
                      value: campaign?.id,
                    }))
                    .find((camp) => camp.value === adData?.campaign_id)
                : "Adset"
            }
            onChange={(e) => {
              setAdData({
                ...adData,
                adset_id: e.value,
              });
              // setSingleCampData(campaigns?.find((camp) => camp.id === e.value));
            }}
            placeholder={"Adset"}
            // className={`mb-5`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div>

        <div className="flex items-center justify-end gap-3 w-full pt-3 border-t-[2px]">
          <button
            onClick={() => setCreateAdModal(false)}
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
              currentMode == "dark" ? "bg-dark text-white" : "bg-white"
            } `}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setCreateAdModal(false);
              //   setIsSingleAd(false);
              setIsSingleAd(1);
              setAdDetails(null);
            }}
            className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
            // disabled={!selectedCampaign}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateAdModal;
