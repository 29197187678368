// import React, { useEffect, useState, useRef } from "react";

// import { toast } from "react-toastify";
// import { AiOutlineHistory } from "react-icons/ai";
// import {
//   TextField,
//   CircularProgress,
//   Tooltip,
//   Modal,
//   Backdrop,
//   InputAdornment,
//   FormControlLabel,
//   Switch,
//   Checkbox,
//   Box,
// } from "@mui/material";
// import moment from "moment/moment";

// import axios from "../../axoisConfig";
// import usePermission from "../../utils/usePermission";
// import { useStateContext } from "../../context/ContextProvider";
// import { MdClose } from "react-icons/md";
// import { MdModeEdit } from "react-icons/md";
// import {
//   DatePicker,
//   LocalizationProvider,
//   TimePicker,
// } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
// const style = {
//   transform: "translate(0%, 0%)",
//   boxShadow: 24,
// };
// const stripeStyle = {
//   backgroundColor: "#e5e7eb", // Light gray background
//   backgroundImage:
//     "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
//   backgroundSize: "20px 20px", // Size of the stripes
// };
// const token = localStorage?.getItem("auth-token");
// const SingleCreative = ({
//   isSingleCreative,
//   setIsSingleCreative,
//   creativeDetails: currentCreativeDetails,
//   setCreativeDetails: setCurrentCreativeDetails,
// }) => {
//   const {
//     isLangRTL,
//     BACKEND_URL,
//     i18n,
//     currentMode,
//     t,
//     snapchatCredentials,
//     themeBgImg,
//     darkModeColors,
//   } = useStateContext();
//   const [creativeDetails, setCreativeDetails] = useState();
//   const [isClosing, setIsClosing] = useState(false);
//   const [isChangesOccured, setIsChangesOccured] = useState(false);

//   const handleClose = () => {
//     setIsClosing(true);

//     setTimeout(() => {
//       setIsClosing(false);
//       setIsSingleCreative(false);
//     }, 1000);
//   };

//   useEffect(() => {
//     if (currentCreativeDetails) {
//       const { name, shareable, brand_name, top_snap_media_id, headline } =
//         currentCreativeDetails;

//       setCreativeDetails({
//         name,
//         shareable,
//         brandName: brand_name,
//         topSnapMediaId: top_snap_media_id,
//         headLine: headline,
//       });
//     } else {
//       setCreativeDetails({
//         name: "",
//         shareable: false,
//         brandName: "",
//         topSnapMediaId: null,
//         headLine: "",
//       });
//     }
//   }, [currentCreativeDetails]);

//   useEffect(() => {
//     if (currentCreativeDetails) {
//       const { name, shareable, brand_name, headline } = currentCreativeDetails;
//       const {
//         name: Name,
//         shareable: Shareable,
//         brandName,
//         headLine,
//       } = creativeDetails;

//       if (
//         Name != name ||
//         shareable != Shareable ||
//         brandName != brand_name ||
//         headLine != headline
//       ) {
//         setIsChangesOccured(true);
//       } else {
//         setIsChangesOccured(false);
//       }
//     }
//   }, [creativeDetails]);

//   const getSummaryBgClass = () => {
//     return currentMode === "dark"
//       ? themeBgImg
//         ? "blur-bg-dark text-white"
//         : "bg-dark text-white"
//       : themeBgImg
//       ? "blur-bg-light text-gray-800"
//       : "bg-white text-gray-800";
//   };

//   async function createAndUploadMedia() {
//     const type = creativeDetails?.file.type?.split("/")[0]?.toUpperCase();

//     try {
//       const res = await axios.post(
//         `${BACKEND_URL}/snapchat/create-media`,
//         {
//           access_token: snapchatCredentials?.access_token,
//           ad_account_id: snapchatCredentials?.currentAdAccount?.id,
//           name: creativeDetails?.file?.name,
//           type: type,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + token,
//           },
//         }
//       );
//       const uploadLink =
//         type == "VIDEO"
//           ? "/snapchat/upload-media-video"
//           : "/snapchat/upload-media-image";
//       const fileFormData = new FormData();
//       fileFormData.append("access_token", snapchatCredentials?.access_token);
//       fileFormData.append("media_id", res?.data?.data?.media[0]?.media?.id);
//       fileFormData.append("file_path", creativeDetails?.file);
//       await axios.post(`${BACKEND_URL}${uploadLink}`, fileFormData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: "Bearer " + token,
//         },
//       });
//       return res?.data?.data?.media[0]?.media?.id;
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   async function createCreative() {
//     try {
//       const top_snap_media_id = await createAndUploadMedia();
//       await axios.post(
//         `${BACKEND_URL}/snapchat/create-creative`,
//         {
//           access_token: snapchatCredentials?.access_token,
//           ad_account_id: snapchatCredentials?.currentAdAccount?.id,
//           name: creativeDetails?.name,
//           shareable: creativeDetails?.shareable,
//           profile_id: "69e1d633-a9ea-4b05-ba60-652da4edb8bb",
//           brand_name: creativeDetails?.brandName,
//           headline: creativeDetails?.brandName,
//           type: "SNAP_AD",
//           top_snap_media_id: top_snap_media_id,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + token,
//           },
//         }
//       );

//       toast.success("Successfully Created Creative", {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//       // getallC();
//     } catch (error) {
//       toast.error("Unable to create Creative", {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//       console.log(error, "this is an error");
//     }
//   }
//   return (
//     <>
//       <Modal
//         keepMounted
//         open={isSingleCreative}
//         onClose={() => setIsSingleCreative(false)}
//         aria-labelledby="keep-mounted-modal-title"
//         aria-describedby="keep-mounted-modal-description"
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//         }}
//       >
//         <div
//           className={`${
//             isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
//           } ${
//             isClosing
//               ? isLangRTL(i18n.language)
//                 ? "modal-close-left"
//                 : "modal-close-right"
//               : ""
//           }

//         w-[100vw] h-[100vh] flex items-start justify-end `}
//         >
//           <button
//             // onClick={handleCloseTimelineModel}
//             onClick={handleClose}
//             className={`${
//               isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
//             }
//             bg-primary w-fit h-fit p-3 my-4 z-10`}
//           >
//             <MdClose
//               size={18}
//               color={"white"}
//               className=" hover:border hover:border-white hover:rounded-full"
//             />
//           </button>
//           {/* ${
//               currentMode === "dark"
//                 ? "bg-[#000000] text-white"
//                 : "bg-[#F9F9FA] text-black"
//             } */}
//           <div
//             style={style}
//             className={`${getSummaryBgClass()}  ${
//               isLangRTL(i18n.language)
//                 ? currentMode === "dark" && " border-primary border-r-2"
//                 : currentMode === "dark" && " border-primary border-l-2"
//             }
//              pt-4 h-[100vh] w-[80vw] overflow-y-scroll border-primary px-7 flex flex-col relative
//             `}
//           >
//             <h2 className="font-bold text-[16px] mb-5">Ad Details</h2>
//             <Box
//               sx={{
//                 ...darkModeColors,
//                 "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
//                   {
//                     right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
//                     transformOrigin: isLangRTL(i18n.language)
//                       ? "right"
//                       : "left",
//                   },
//                 "& legend": {
//                   textAlign: isLangRTL(i18n.language) ? "right" : "left",
//                 },
//               }}
//             >
//               <div
//                 className={`${getSummaryBgClass()} rounded-3xl px-8 py-4 shadow-md mb-5 flex flex-col gap-8 `}
//               >
//                 <div className="flex flex-col">
//                   <label htmlFor="" className="font-medium">
//                     Name
//                   </label>
//                   <input
//                     type="text"
//                     className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
//                     value={creativeDetails?.name}
//                     placeholder="Name your ad"
//                     onChange={(e) =>
//                       setCreativeDetails((pre) => ({
//                         ...pre,
//                         name: e?.target?.value,
//                       }))
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col">
//                   <label htmlFor="" className="font-medium">
//                     Brand Name (Optional)
//                   </label>
//                   <input
//                     type="text"
//                     className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
//                     value={creativeDetails?.brandName}
//                     placeholder="Replace Profile name"
//                     onChange={(e) =>
//                       setCreativeDetails((pre) => ({
//                         ...pre,
//                         brandName: e?.target?.value,
//                       }))
//                     }
//                   />
//                 </div>
//                 <div className="flex flex-col">
//                   <label htmlFor="" className="font-medium">
//                     Headline
//                   </label>
//                   <input
//                     className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
//                     type="text"
//                     value={creativeDetails?.headLine}
//                     placeholder="Headline to display in ad"
//                     onChange={(e) =>
//                       setCreativeDetails((pre) => ({
//                         ...pre,
//                         headLine: e?.target?.value,
//                       }))
//                     }
//                   />
//                 </div>
//                 <div className="flex items-end">
//                   <FormControlLabel
//                     control={
//                       <Switch
//                         color="primary"
//                         size="medium"
//                         checked={creativeDetails?.shareable}
//                         onChange={(e) =>
//                           setCreativeDetails((pre) => ({
//                             ...pre,
//                             shareable: e?.target?.checked,
//                           }))
//                         }
//                       />
//                     }
//                     label="Shareable"
//                     labelPlacement="top"
//                     sx={{
//                       margin: "0px",
//                       marginTop: `10px`,
//                     }}
//                   />
//                   <span className="pb-[10px] pl-1">
//                     {creativeDetails?.shareable ? "Enabled" : "Disabled"}
//                   </span>
//                 </div>
//                 <div>
//                   <label>Topsnap Media</label>
//                   <div className="mt-3">
//                     {" "}
//                     {/* <input
//                       type="file"
//                       onChange={(e) =>
//                         setCreativeDetails((pre) => ({
//                           ...pre,
//                           file: e?.target?.files[0],
//                         }))
//                       }
//                       style={{ display: "none" }}
//                       id="top-snap-media"
//                     />
//                     <label htmlFor="top-snap-media">
//                       <button
//                         // onClick={() => setOpenDeleteConfirm(true)}
//                         className="p-3 px-5 hover:border-[#F7D901] text-[#444546] rounded-[20px] border font-semibold bg-white"
//                       >
//                         Upload
//                       </button>
//                     </label> */}
//                     <input
//                       type="file"
//                       accept=".png, .jpg, .jpeg, .mp4, .mov"
//                       onChange={(e) => {
//                         const file = e.target.files[0];
//                         const allowedTypes = [
//                           "image/png",
//                           "image/jpeg",
//                           "video/mp4",
//                           "video/quicktime",
//                         ];

//                         // Check if file exists and is an allowed type
//                         if (file && allowedTypes.includes(file.type)) {
//                           setCreativeDetails((prev) => ({
//                             ...prev,
//                             file,
//                           }));
//                         } else {
//                           toast.error(
//                             "Please upload a valid file (PNG, JPG, MP4, MOV).",
//                             {
//                               position: "top-right",
//                               autoClose: 3000,
//                               hideProgressBar: false,
//                               closeOnClick: true,
//                               pauseOnHover: true,
//                               draggable: true,
//                               progress: undefined,
//                               theme: "light",
//                             }
//                           );
//                           e.target.value = null; // Reset input if the file is not valid
//                         }
//                       }}
//                       style={{ display: "none" }}
//                       id="top-snap-media"
//                     />
//                     <label
//                       className=" cursor-pointer p-3 px-5 hover:border-[#F7D901] text-[#444546] rounded-[20px] border font-semibold bg-white"
//                       htmlFor="top-snap-media"
//                     >
//                       Upload {creativeDetails?.file?.name}
//                     </label>
//                     <button
//                       // onClick={() => setOpenDeleteConfirm(true)}
//                       className="p-3 px-5 hover:border-[#F7D901] text-[#444546] rounded-[20px] border font-semibold bg-white"
//                     >
//                       Browse
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </Box>
//             <div
//               className="flex justify-end py-5 bg-white shadow-md px-4 mt-3  "
//               // style={{ width: "calc(100% - 40px)" }}
//             >
//               {!currentCreativeDetails && (
//                 <button
//                   onClick={() => createCreative()}
//                   className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold bg-white"
//                   disabled={
//                     !creativeDetails?.name ||
//                     !creativeDetails?.headLine ||
//                     !creativeDetails?.file
//                   }
//                 >
//                   Next
//                 </button>
//               )}

//               {currentCreativeDetails && (
//                 <button
//                   style={
//                     isChangesOccured
//                       ? { backgroundColor: "black", color: "white" }
//                       : stripeStyle
//                   }
//                   className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold bg-white"
//                   disabled={!isChangesOccured}
//                   // onClick={() => updateAdSet()}
//                 >
//                   Save Changes
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default SingleCreative;

import React, { useEffect, useState, useRef } from "react";

import { toast } from "react-toastify";
import { AiOutlineHistory } from "react-icons/ai";
import {
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
  Backdrop,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
} from "@mui/material";
import moment from "moment/moment";
import { IoIosShareAlt } from "react-icons/io";
import Select from "react-select";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "../../axoisConfig";
import usePermission from "../../utils/usePermission";
import { useStateContext } from "../../context/ContextProvider";
import { MdClose } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ImagePicker from "./ImagePicker";
import { selectStyles } from "../_elements/SelectStyles";
import Loader from "../Loader";
import MediaFiles from "./mediaFiles";
import { FaBatteryFull, FaSignal } from "react-icons/fa";
import { IoIosWifi } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
const style = {
  transform: "translate(0%, 0%)",
  boxShadow: 24,
};
const stripeStyle = {
  backgroundColor: "#e5e7eb", // Light gray background
  backgroundImage:
    "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
  backgroundSize: "20px 20px", // Size of the stripes
};
const token = localStorage?.getItem("auth-token");

const publicProfiles = [
  { value: "69e1d633-a9ea-4b05-ba60-652da4edb8bb", label: "HIKAL REAL ESTATE" },
  {
    value: "8a0e4c35-89af-49ef-ba42-2a5dc9e7f647",
    label: "MARAHEB CLEANING SERVICES",
  },
  { value: "309c5bd8-e716-4d49-8729-028454221a47", label: "Tanatel UAE" },
];

const SingleCreative = ({
  isSingleCreative,
  setIsSingleCreative,
  creativeDetails: currentCreativeDetails,
  setCreativeDetails: setCurrentCreativeDetails,
  getAllCreatives,
}) => {
  const {
    isLangRTL,
    BACKEND_URL,
    i18n,
    currentMode,
    t,
    snapchatCredentials,
    themeBgImg,
    darkModeColors,
    primaryColor,
  } = useStateContext();
  const [creativeDetails, setCreativeDetails] = useState();
  const [isClosing, setIsClosing] = useState(false);
  const [isChangesOccured, setIsChangesOccured] = useState(false);
  const [imagePickerModal, setImagePickerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMediaFilesModal, setIsMediaFilesModal] = useState(false);

  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      setIsSingleCreative(false);
    }, 1000);
  };

  useEffect(() => {
    async function initializeState() {
      try {
        if (currentCreativeDetails) {
          const {
            name,
            shareable,
            brand_name,
            top_snap_media_id,
            headline,
            profile_properties,
            thumbnail,
            mediaType,
          } = currentCreativeDetails;

          setCreativeDetails({
            name,
            shareable,
            brandName: brand_name,
            topSnapMediaId: top_snap_media_id,
            headLine: headline,
            media_url: thumbnail,
            publicProfile: publicProfiles?.find(
              (profile) => profile?.value === profile_properties?.profile_id
            ),
            mediaType: mediaType,
          });
        } else {
          setCreativeDetails({
            name: "",
            shareable: false,
            brandName: "",
            topSnapMediaId: null,
            headLine: "",
            media_url: null,
            publicProfile: null,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    initializeState();
  }, [currentCreativeDetails]);

  // async function fetchMediaDetails(id) {
  //   try {
  //     const res = await axios.post(
  //       `${BACKEND_URL}/snapchat/preview-specific-media`,
  //       {
  //         access_token: snapchatCredentials?.access_token,
  //         media_id: id,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );

  //     return res?.data?.data?.link;
  //   } catch (error) {
  //     toast.error("Unable to load ad details", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     console.log(error, "this is an error");
  //   }
  // }

  useEffect(() => {
    if (currentCreativeDetails) {
      const {
        name,
        shareable,
        brand_name,
        headline,
        profile_properties,
        top_snap_media_id,
      } = currentCreativeDetails;
      const {
        name: Name,
        shareable: Shareable,
        brandName,
        headLine,
        publicProfile,
        topSnapMediaId,
      } = creativeDetails;
      console.log(
        top_snap_media_id,
        creativeDetails?.topMedia?.id,
        creativeDetails?.file?.id,
        creativeDetails?.topSnapMediaId,
        "details"
      );
      if (
        Name != name ||
        shareable != Shareable ||
        brandName != brand_name ||
        headLine != headline ||
        publicProfile?.value != profile_properties?.profile_id ||
        (top_snap_media_id != creativeDetails?.topMedia?.id &&
          top_snap_media_id != creativeDetails?.file?.id &&
          top_snap_media_id != creativeDetails?.topSnapMediaId)
      ) {
        setIsChangesOccured(true);
      } else {
        setIsChangesOccured(false);
      }
    }
  }, [creativeDetails]);

  // async function fetchCreativeDetails(id) {
  //   setLoading(true);
  //   try {
  //     const res = await axios.post(
  //       `${BACKEND_URL}/snapchat/get-specific-creative`,
  //       {
  //         access_token: snapchatCredentials?.access_token,
  //         creative_id: id,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );

  //     const {
  //       headline,
  //       brand_name,
  //       shareable,
  //       review_status_details,
  //       review_status,
  //       top_snap_media_id,
  //       profile_properties,
  //     } = res?.data?.data?.creatives[0]?.creative;
  //     const url = await fetchMediaDetails(top_snap_media_id);
  //     setCreativeDetails((pre) => ({
  //       ...pre,
  //       shareable: shareable,
  //       brandName: brand_name,
  //       topSnapMediaId: top_snap_media_id,
  //       headLine: headline,
  //       media_url: url,
  //       publicProfile: publicProfiles?.find(
  //         (profile) => profile?.value === profile_properties?.profile_id
  //       ),
  //     }));
  //   } catch (error) {
  //     toast.error("Unable to load ad details", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     console.log(error, "this is an error");
  //   } finally {
  //     setLoading(false);
  //   }
  // }
  // async function fetchMediaDetails(id) {
  //   try {
  //     const res = await axios.post(
  //       `${BACKEND_URL}/snapchat/preview-specific-media`,
  //       {
  //         access_token: snapchatCredentials?.access_token,
  //         media_id: id,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );

  //     return res?.data?.data?.link;
  //   } catch (error) {
  //     toast.error("Unable to load ad details", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     console.log(error, "this is an error");
  //   }
  // }

  async function createAndUploadMedia() {
    const type = creativeDetails?.file.type?.split("/")[0]?.toUpperCase();

    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/create-media`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          name: creativeDetails?.file?.name,
          type: type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const uploadLink =
        type == "VIDEO"
          ? "/snapchat/upload-media-video"
          : "/snapchat/upload-media-image";
      const fileFormData = new FormData();
      fileFormData.append("access_token", snapchatCredentials?.access_token);
      fileFormData.append("media_id", res?.data?.data?.media[0]?.media?.id);
      fileFormData.append("file_path", creativeDetails?.file);
      await axios.post(`${BACKEND_URL}${uploadLink}`, fileFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });
      return res?.data?.data?.media[0]?.media?.id;
    } catch (error) {
      console.log(error);
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    }
  }

  async function createCreative() {
    setLoading(true);
    try {
      const top_snap_media_id = creativeDetails?.topMedia
        ? creativeDetails?.topMedia?.id
        : await createAndUploadMedia();
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/create-creative`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          name: creativeDetails?.name,
          shareable: `${creativeDetails?.shareable}`,
          profile_id: creativeDetails?.publicProfile?.value,
          brand_name: creativeDetails?.brandName,
          headline: creativeDetails?.headLine,
          type: "SNAP_AD",
          top_snap_media_id: top_snap_media_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      getAllCreatives();
      setIsSingleCreative(false);
      toast.success("Creative is successfully created", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // return res?.data?.data?.creatives[0]?.creative?.id;
    } catch (error) {
      console.log(error, "this is an error");
      toast.error("Unable to create creative", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      toast.error(error?.response?.data?.error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (Object?.keys > 0) {
        Object?.values(error?.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    } finally {
      setLoading(false);
    }
  }
  async function updateCreative() {
    setLoading(true);
    try {
      const top_snap_media_id = creativeDetails?.topSnapMediaId
        ? creativeDetails?.topSnapMediaId
        : creativeDetails?.topMedia
        ? creativeDetails?.topMedia?.id
        : await createAndUploadMedia();
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/update-creative`,
        {
          id: currentCreativeDetails?.id,
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          name: creativeDetails?.name,
          shareable: `${creativeDetails?.shareable}`,
          profile_id: creativeDetails?.publicProfile?.value,
          brand_name: creativeDetails?.brandName,
          headline: creativeDetails?.headLine,
          type: "SNAP_AD",
          top_snap_media_id: top_snap_media_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      getAllCreatives();
      toast.success("Creative updated successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsSingleCreative(false);
    } catch (error) {
      console.log(error, "this is an error");
      toast.error(error?.response?.data?.error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      toast.error("Can't update creative", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (Object?.keys > 0) {
        Object?.values(error?.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    } finally {
      setLoading(false);
    }
  }

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <>
      <Modal
        // keepMounted
        sx={{ zIndex: 1300 }} // Adjust z-index as needed
        open={isSingleCreative}
        onClose={() => setIsSingleCreative(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }

        w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            // onClick={handleCloseTimelineModel}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>
          {/* ${
              currentMode === "dark"
                ? "bg-[#000000] text-white"
                : "bg-[#F9F9FA] text-black"
            } */}

          <div
            style={style}
            className={`${getSummaryBgClass()}  ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && " border-primary border-r-2"
                : currentMode === "dark" && " border-primary border-l-2"
            }
             pt-4 h-[100vh] w-[80vw] overflow-y-scroll border-primary px-7 flex flex-col relative
            `}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                <h2 className="font-bold text-[16px] mb-5">
                  Ad Details (Creative)
                </h2>
                <div className="flex ">
                  <Box
                    sx={{
                      ...darkModeColors,
                      width: "70%",
                      "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                        {
                          right: isLangRTL(i18n.language)
                            ? "2.5rem"
                            : "inherit",
                          transformOrigin: isLangRTL(i18n.language)
                            ? "right"
                            : "left",
                        },
                      "& legend": {
                        textAlign: isLangRTL(i18n.language) ? "right" : "left",
                      },
                    }}
                  >
                    <div
                      className={`${getSummaryBgClass()} rounded-3xl px-8 py-4 shadow-md mb-5 flex flex-col gap-8 `}
                    >
                      <div className="flex flex-col">
                        <label htmlFor="" className="font-medium">
                          Name
                        </label>
                        <input
                          type="text"
                          className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                          value={creativeDetails?.name}
                          placeholder="Name your ad"
                          onChange={(e) =>
                            setCreativeDetails((pre) => ({
                              ...pre,
                              name: e?.target?.value,
                            }))
                          }
                        />
                      </div>
                      <h3 className="text-[16px] medium ">
                        Select Public Profile
                      </h3>
                      <div className="w-full ">
                        <Select
                          // isLoading={adSetLoading}
                          id="public-Profiles"
                          options={publicProfiles}
                          value={creativeDetails?.publicProfile}
                          onChange={(e) => {
                            setCreativeDetails((pre) => ({
                              ...pre,
                              publicProfile: e,
                            }));
                          }}
                          placeholder={"Select Public Profile"}
                          // className={`mb-5`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="font-medium">
                          Brand Name
                        </label>
                        <input
                          type="text"
                          className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                          value={creativeDetails?.brandName}
                          placeholder="Replace Profile name"
                          onChange={(e) =>
                            setCreativeDetails((pre) => ({
                              ...pre,
                              brandName: e?.target?.value,
                            }))
                          }
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="" className="font-medium">
                          Headline
                        </label>
                        <input
                          className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                          type="text"
                          value={creativeDetails?.headLine}
                          placeholder="Headline to display in ad"
                          onChange={(e) =>
                            setCreativeDetails((pre) => ({
                              ...pre,
                              headLine: e?.target?.value,
                            }))
                          }
                        />
                      </div>
                      <div className={"flex items-end"}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              size="medium"
                              checked={creativeDetails?.shareable}
                              onChange={(e) =>
                                setCreativeDetails((pre) => ({
                                  ...pre,
                                  shareable: e?.target?.checked,
                                }))
                              }
                            />
                          }
                          label="Shareable"
                          labelPlacement="top"
                          sx={{
                            margin: "0px",
                            marginTop: `10px`,
                          }}
                        />
                        <span className="pb-[10px] pl-1">
                          {creativeDetails?.shareable ? "Enabled" : "Disabled"}
                        </span>
                      </div>
                      <div>
                        <label>Topsnap Media</label>
                        <div className="mt-3 ">
                          {" "}
                          {/* <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .mp4, .mov"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const allowedTypes = [
                          "image/png",
                          "image/jpeg",
                          "video/mp4",
                          "video/quicktime",
                        ];

                        // Check if file exists and is an allowed type
                        if (file && allowedTypes.includes(file.type)) {
                          setCreativeDetails((prev) => ({
                            ...prev,
                            file,
                          }));
                        } else {
                          toast.error(
                            "Please upload a valid file (PNG, JPG, MP4, MOV).",
                            {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            }
                          );
                          e.target.value = null; // Reset input if the file is not valid
                        }
                      }}
                      style={{ display: "none" }}
                      id="top-snap-media"
                    /> */}
                          {creativeDetails?.media_url ? (
                            <div className="flex ">
                              <div>
                                {creativeDetails?.mediaType === "IMAGE" ? (
                                  <img
                                    src={creativeDetails?.media_url}
                                    className="w-[50px] h-[100px]"
                                    alt="creative media file"
                                  />
                                ) : (
                                  <video
                                    src={creativeDetails?.media_url}
                                    className="w-[50px] h-[100px]"
                                    alt="creative media file"
                                  ></video>
                                )}
                              </div>
                              {/* <div className="flex flex-col px-4 items-center justify-center">
                                <span>{creativeDetails?.topSnapMediaId}</span>
                              </div> */}
                              <div className="flex flex-col px-4 gap-4 justify-center">
                                <span>{creativeDetails?.topSnapMediaId}</span>
                                <div
                                  onClick={() =>
                                    setCreativeDetails((pre) => ({
                                      ...pre,
                                      media_url: null,
                                      topSnapMediaId: null,
                                    }))
                                  }
                                  className="cursor-pointer"
                                >
                                  <RiDeleteBin6Line size={20} />
                                </div>
                              </div>
                            </div>
                          ) : creativeDetails?.topMedia ? (
                            <>
                              <div className="flex gap-4">
                                <div>
                                  {creativeDetails?.topMedia?.type ===
                                  "IMAGE" ? (
                                    <img
                                      src={
                                        creativeDetails?.topMedia?.download_link
                                      }
                                      className="w-[50px] h-[100px] rounded-2xl"
                                      alt="creative media file"
                                    />
                                  ) : (
                                    <video
                                      src={
                                        creativeDetails?.topMedia?.download_link
                                      }
                                      className="w-[50px] h-[100px]"
                                    ></video>
                                  )}
                                </div>
                                <div className="flex flex-col px-4 gap-4 justify-center">
                                  <span>{creativeDetails?.topMedia?.name}</span>
                                  <div
                                    onClick={() =>
                                      setCreativeDetails((pre) => ({
                                        ...pre,
                                        topMedia: null,
                                      }))
                                    }
                                    className="cursor-pointer"
                                  >
                                    <RiDeleteBin6Line size={20} />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : creativeDetails?.file ? (
                            <>
                              <div className="flex gap-4">
                                <div>
                                  {creativeDetails?.file.type
                                    ?.split("/")[0]
                                    ?.toUpperCase() === "IMAGE" ? (
                                    <img
                                      src={URL.createObjectURL(
                                        creativeDetails?.file
                                      )}
                                      className="w-[50px] h-[100px] rounded-2xl"
                                      alt="creative media file"
                                    />
                                  ) : (
                                    <video
                                      src={URL.createObjectURL(
                                        creativeDetails?.file
                                      )}
                                      className="w-[50px] h-[100px]"
                                    ></video>
                                  )}
                                </div>
                                <div className="flex flex-col px-4 gap-4 justify-center">
                                  <span>{creativeDetails?.file?.name}</span>
                                  <div
                                    onClick={() =>
                                      setCreativeDetails((pre) => ({
                                        ...pre,
                                        file: null,
                                      }))
                                    }
                                    className="cursor-pointer"
                                  >
                                    <RiDeleteBin6Line size={20} />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex gap-3">
                                <label
                                  className={` cursor-pointer ${
                                    currentMode === "dark"
                                      ? "bg-black text-white"
                                      : "text-[#444546] bg-white"
                                  } p-3 px-5 hover:border-[#F7D901]  rounded-[20px] border font-semibold `}
                                  // htmlFor="top-snap-media"
                                  onClick={() => setImagePickerModal(true)}
                                >
                                  Upload {creativeDetails?.file?.name}
                                </label>
                                <button
                                  onClick={() => setIsMediaFilesModal(true)}
                                  // className="p-3 px-5 hover:border-[#F7D901] text-[#444546] rounded-[20px] border font-semibold bg-white"
                                  className={` cursor-pointer ${
                                    currentMode === "dark"
                                      ? "bg-black text-white"
                                      : "text-[#444546] bg-white"
                                  } p-3 px-5 hover:border-[#F7D901]  rounded-[20px] border font-semibold `}
                                >
                                  Browse
                                </button>
                              </div>
                              <p className="pl-2 pt-2">Min 1080 x 1920</p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Box>
                  <div className="w-[30%] px-[40px] h-full flex items-center justify-center">
                    <div className="w-full h-[660px] bg-black rounded-2xl relative  pb-5">
                      <div className="flex items-center justify-between px-5 py-3">
                        <span className="text-white">11:04</span>{" "}
                        <div className="flex items-center gap-2">
                          <FaSignal color="white" size={16} />
                          <IoIosWifi color="white" size={16} />
                          <FaBatteryFull color="white" size={16} />
                        </div>
                      </div>
                      <div className=" w-full flex items-center justify-between px-5 py-3 absolute top-[40px]">
                        <span className="text-white px-1 rounded-md bg-gray-700 bg-opacity-50">
                          Ad
                        </span>{" "}
                        <span>
                          <BsThreeDotsVertical color="white" size={16} />
                        </span>
                      </div>
                      {creativeDetails?.media_url ? (
                        creativeDetails?.mediaType === "IMAGE" ? (
                          <img
                            src={creativeDetails?.media_url}
                            className="w-full h-[80%]"
                            alt="creative media file"
                          />
                        ) : (
                          <video
                            controls
                            src={creativeDetails?.media_url}
                            className="w-full h-[80%]"
                            alt="creative media file"
                          ></video>
                        )
                      ) : creativeDetails?.topMedia ? (
                        <>
                          {creativeDetails?.topMedia?.type === "IMAGE" ? (
                            <img
                              src={creativeDetails?.topMedia?.download_link}
                              className="w-full flex-1 rounded-2xl"
                              alt="creative media file"
                            />
                          ) : (
                            <video
                              controls
                              src={creativeDetails?.topMedia?.download_link}
                              className="w-full h-[80%]"
                            ></video>
                          )}
                        </>
                      ) : creativeDetails?.file ? (
                        <>
                          {creativeDetails?.file.type
                            ?.split("/")[0]
                            ?.toUpperCase() === "IMAGE" ? (
                            <img
                              src={URL.createObjectURL(creativeDetails?.file)}
                              className="w-full h-[80%] rounded-2xl"
                              alt="creative media file"
                            />
                          ) : (
                            <video
                              controls
                              src={URL.createObjectURL(creativeDetails?.file)}
                              className="w-full h-[80%]"
                            ></video>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="w-full h-[80%] bg-black rounded-2xl"></div>
                        </>
                      )}
                      <div className="px-5 flex justify-between items-center gap-5 mt-3 h-[15%]">
                        <div className="bg-white text-center text-black rounded-[20px] w-[85%] py-4 font-medium ">
                          Install Now
                        </div>
                        <div className="bg-gray-700 bg-opacity-50 rounded-2xl w-[15%] py-4 flex items-center justify-center">
                          <IoIosShareAlt size={16} color="white" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`flex justify-end py-5 ${getSummaryBgClass()} shadow-md px-4 mt-3`}
                  // style={{ width: "calc(100% - 40px)" }}
                >
                  {!currentCreativeDetails && (
                    <button
                      onClick={() => createCreative()}
                      className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] disabled:cursor-not-allowed border font-semibold ${
                        currentMode === "dark"
                          ? "bg-black text-white"
                          : "bg-white"
                      }`}
                      // disabled={
                      //   !creativeDetails?.name ||
                      //   !creativeDetails?.headLine ||
                      //   (creativeDetails?.topMedia
                      //     ? !creativeDetails?.file
                      //     : !creativeDetails?.topMedia) ||
                      //   !creativeDetails?.publicProfile
                      // }
                      disabled={
                        !creativeDetails?.name || // true if name is not available
                        !creativeDetails?.headLine || // true if headLine is not available
                        (!creativeDetails?.topMedia // Check if topMedia is available
                          ? !creativeDetails?.file // If topMedia is available, check if file is not available
                          : !creativeDetails?.topMedia) || // If topMedia is not available, this will always be false
                        !creativeDetails?.publicProfile // true if publicProfile is not available
                      }
                    >
                      Next
                    </button>
                  )}

                  {currentCreativeDetails && (
                    <button
                      style={
                        isChangesOccured
                          ? { backgroundColor: "black", color: "white" }
                          : currentMode === "dark"
                          ? { backgroundColor: "#282b30", color: "white" }
                          : stripeStyle
                      }
                      className={`p-3 px-5 hover:border-[#F7D901] disabled:cursor-not-allowed rounded-[20px] border font-semibold ${
                        currentMode === "dark"
                          ? "bg-black text-white"
                          : "bg-white"
                      } `}
                      disabled={!isChangesOccured}
                      onClick={() => {
                        updateCreative();
                        // alert("it is called");
                      }}
                    >
                      Save Changes
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <ImagePicker
        imagePickerModal={imagePickerModal}
        setImagePickerModal={setImagePickerModal}
        setData={(file) => {
          setCreativeDetails((pre) => ({
            ...pre,
            file: file,
          }));
        }}
        imageProperties={{
          width: 1024,
          height: 1920,
          aspectRatio: 9 / 16,
        }}
      />
      <MediaFiles
        isMediaFilesModal={isMediaFilesModal}
        setIsMediaFilesModal={setIsMediaFilesModal}
        setCreativeDetails={setCreativeDetails}
      />
    </>
  );
};

export default SingleCreative;
