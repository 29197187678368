import React, { useEffect, useState } from "react";

const MIN_SPEED = 1.5;
const MAX_SPEED = 3.5;

function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

// class Blob {
//   constructor(el) {
//     this.el = el;
//     const boundingRect = this.el.getBoundingClientRect();
//     this.size = boundingRect.width;
//     this.initialX = randomNumber(0, window.innerWidth - this.size);
//     this.initialY = randomNumber(0, window.innerHeight - this.size);
//     this.el.style.top = `${this.initialY}px`;
//     this.el.style.left = `${this.initialX}px`;
//     this.vx =
//       randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
//     this.vy =
//       randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
//     this.x = this.initialX;
//     this.y = this.initialY;
//   }

//   update() {
//     this.x += this.vx;
//     this.y += this.vy;
//     if (this.x >= window.innerWidth - this.size) {
//       this.x = window.innerWidth - this.size;
//       this.vx *= -1;
//     }
//     if (this.y >= window.innerHeight - this.size) {
//       this.y = window.innerHeight - this.size;
//       this.vy *= -1;
//     }
//     if (this.x <= 0) {
//       this.x = 0;
//       this.vx *= -1;
//     }
//     if (this.y <= 0) {
//       this.y = 0;
//       this.vy *= -1;
//     }
//   }

//   move() {
//     this.el.style.transform = `translate(${this.x - this.initialX}px, ${
//       this.y - this.initialY
//     }px)`;
//   }
// }

// const NoiseBackground = () => {
//   useEffect(() => {
//     function initBlobs() {
//       const blobEls = document.querySelectorAll(".bouncing-blob");
//       const blobs = Array.from(blobEls).map((blobEl) => new Blob(blobEl));

//       function update() {
//         requestAnimationFrame(update);
//         blobs.forEach((blob) => {
//           blob.update();
//           blob.move();
//         });
//       }

//       requestAnimationFrame(update);
//     }

//     initBlobs();
//   }, []);

//   return (
//     <>
//       <div className="noise-container">
//         <div className="noise-glass"></div>
//         <div className="bouncing-blobs">
//           <div className="bouncing-blob bouncing-blob--1"></div>
//           <div className="bouncing-blob bouncing-blob--1"></div>
//           <div className="bouncing-blob bouncing-blob--1"></div>
//           <div className="bouncing-blob bouncing-blob--4"></div>
//           <div className="bouncing-blob bouncing-blob--2"></div>
//           <div className="bouncing-blob bouncing-blob--2"></div>
//           <div className="bouncing-blob bouncing-blob--3"></div>
//         </div>
//       </div>
//       {/* <div className="bouncing-blobs-container">
//                 <div className="bouncing-blobs-glass"></div>
//                 <div className="bouncing-blobs">
//                     <div className="bouncing-blob bouncing-blob--blue"></div>
//                     <div className="bouncing-blob bouncing-blob--blue"></div>
//                     <div className="bouncing-blob bouncing-blob--blue"></div>
//                     <div className="bouncing-blob bouncing-blob--white"></div>
//                     <div className="bouncing-blob bouncing-blob--purple"></div>
//                     <div className="bouncing-blob bouncing-blob--purple"></div>
//                     <div className="bouncing-blob bouncing-blob--pink"></div>
//                 </div>
//             </div> */}
//     </>
//   );
// };

// export default NoiseBackground;

class Blob {
  constructor(el, containerWidth, containerHeight) {
    this.el = el;
    const boundingRect = this.el.getBoundingClientRect();
    this.size = boundingRect.width;
    this.containerWidth = containerWidth;
    this.containerHeight = containerHeight;

    this.initialX = randomNumber(0, this.containerWidth - this.size);
    this.initialY = randomNumber(0, this.containerHeight - this.size);

    this.el.style.top = `${this.initialY}px`;
    this.el.style.left = `${this.initialX}px`;

    this.vx =
      randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
    this.vy =
      randomNumber(MIN_SPEED, MAX_SPEED) * (Math.random() > 0.5 ? 1 : -1);
    this.x = this.initialX;
    this.y = this.initialY;
  }

  update() {
    this.x += this.vx;
    this.y += this.vy;

    if (this.x >= this.containerWidth - this.size) {
      this.x = this.containerWidth - this.size;
      this.vx *= -1;
    }
    if (this.y >= this.containerHeight - this.size) {
      this.y = this.containerHeight - this.size;
      this.vy *= -1;
    }
    if (this.x <= 0) {
      this.x = 0;
      this.vx *= -1;
    }
    if (this.y <= 0) {
      this.y = 0;
      this.vy *= -1;
    }
  }

  move() {
    this.el.style.transform = `translate(${this.x - this.initialX}px, ${
      this.y - this.initialY
    }px)`;
  }
}

const NoiseBackground = ({ containerRef }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function initBlobs() {
      const blobEls = document.querySelectorAll(".bouncing-blob");
      const container = containerRef.current;
      const { width, height } = container.getBoundingClientRect();
      const blobs = Array.from(blobEls).map(
        (blobEl) => new Blob(blobEl, width, height)
      );

      function update() {
        requestAnimationFrame(update);
        blobs.forEach((blob) => {
          blob.update();
          blob.move();
        });
      }

      requestAnimationFrame(update);
    }

    initBlobs();
  }, [containerRef]);

  return (
    <div className="noise-container">
      <div className="noise-glass"></div>
      <div className="bouncing-blobs">
        <div className="bouncing-blob bouncing-blob--1"></div>
        <div className="bouncing-blob bouncing-blob--2"></div>
        <div className="bouncing-blob bouncing-blob--3"></div>
        <div className="bouncing-blob bouncing-blob--4"></div>
      </div>
    </div>
  );
};

export default NoiseBackground;

// const NoiseBackground = ({ containerRef }) => {
//   useEffect(() => {
//     function initBlobs() {
//       const blobEls = document.querySelectorAll(".bouncing-blob");
//       const container = containerRef.current;
//       const { width, height } = container.getBoundingClientRect();

//       const blobs = Array.from(blobEls).map(
//         (blobEl) => new Blob(blobEl, width, height)
//       );

//       function update() {
//         requestAnimationFrame(update);
//         blobs.forEach((blob) => {
//           blob.update();
//           blob.move();
//         });
//       }

//       requestAnimationFrame(update);
//     }

//     initBlobs();
//   }, [containerRef]);

//   return (
//     <div className="noise-container">
//       <div className="noise-glass"></div>
//       <div className="bouncing-blobs">
//         <div className="bouncing-blob bouncing-blob--1"></div>
//         <div className="bouncing-blob bouncing-blob--2"></div>
//         <div className="bouncing-blob bouncing-blob--3"></div>
//         <div className="bouncing-blob bouncing-blob--4"></div>
//       </div>
//     </div>
//   );
// };

// class Blob {
//   constructor(el, containerWidth, containerHeight) {
//     this.el = el;
//     this.containerWidth = containerWidth;
//     this.containerHeight = containerHeight;
//     this.scale = this.containerWidth < 768 ? 0.5 : 1; // Adjust for modal or smaller containers

//     this.x = Math.random() * this.containerWidth;
//     this.y = Math.random() * this.containerHeight;
//     this.speedX = (Math.random() - 0.5) * 2;
//     this.speedY = (Math.random() - 0.5) * 2;
//     this.rotation = Math.random() * 360;
//   }

//   update() {
//     this.x += this.speedX;
//     this.y += this.speedY;

//     // Bounce blobs off container edges
//     if (this.x < 0 || this.x > this.containerWidth) this.speedX *= -1;
//     if (this.y < 0 || this.y > this.containerHeight) this.speedY *= -1;
//   }

//   move() {
//     this.el.style.transform = `
//       translate(${this.x}px, ${this.y}px)
//       rotate(${this.rotation}deg)
//       scale(${this.scale})
//     `;
//   }
// }

// export default NoiseBackground;
