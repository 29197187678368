import React, { useState } from "react";
import { TfiClose } from "react-icons/tfi";
import { RiFacebookBoxLine } from "react-icons/ri";
import { SlSocialInstagram } from "react-icons/sl";
import { PiHandshake } from "react-icons/pi";
import { GoSearch } from "react-icons/go";
import { Backdrop, Checkbox, Modal } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import { LiaFacebookSquare } from "react-icons/lia";
import { TiArrowSortedDown } from "react-icons/ti";
import { FormControlLabel } from "@mui/material";
import SelectPostFilter from "./selectPostFilter";
import { DataGrid } from "@mui/x-data-grid";
import { FaRegImage } from "react-icons/fa6";
import { SlLike } from "react-icons/sl";
import { GoComment } from "react-icons/go";
import { PiShareFat } from "react-icons/pi";
import { FiPlus } from "react-icons/fi";
import { FaMinus } from "react-icons/fa6";

import { Box } from "@mui/material";
const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const CarouselCard = ({ carouselCardModal, setCarouselCardModal }) => {
  const {
    currentMode,
    themeBgImg,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    DataGridStyles,
  } = useStateContext();
  const [currentTab, setCurrentTab] = useState("facebook");
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  const postInSights = [
    {
      icon: <SlLike size={16} />,
      value: 0,
    },
    {
      icon: <GoComment size={16} />,
      value: 3,
    },
    {
      icon: <PiShareFat size={16} />,
      value: 5,
    },
  ];

  return (
    <Modal
      //   keepMounted
      open={carouselCardModal}
      onClose={() => setCarouselCardModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[60%] h-fit pb-5 px-2 ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2  rounded-lg flex flex-col items-start`}
      >
        <div className="w-full max-h-full  py-4 px-6 overflow-y-auto">
          <div className="flex justify-between">
            <div>
              <h3
                className={`text-[16px] font-semibold mb-1 tracking-wide ${
                  currentMode === "dark" ? "text-white" : "text-gray-900"
                } `}
              >
                Select up to 10 images
              </h3>
            </div>

            <div
              className="pt-1 cursor-pointer"
              onClick={() => setCarouselCardModal(false)}
            >
              <TfiClose />
            </div>
          </div>
          <div className="flex gap-4 pt-7 pb-4 ">
            <button
              onClick={() => setCurrentTab("all")}
              className={`p-2 px-3 rounded-md flex  items-center gap-1 cursor-pointer ${
                currentTab === "all" && "bg-[#C3DCF5] text-blue-800"
              }`}
            >
              {/* <LiaFacebookSquare size={20} color="#596774" /> */}
              <div
                className="bg-no-repeat inline-block w-[16px] h-[16px] "
                style={{
                  backgroundImage: `url('https://static.xx.fbcdn.net/rsrc.php/v3/yg/r/IkFqefhQFHd.png?_nc_eui2=AeHEJ05rlP_3RmPwLl8WJg__TWXz8SIAFRpNZfPxIgAVGkNdgfhAUPecrC0nfswIYkx624J1kSdbF67CaPgCZjx4&quot')`,
                  backgroundPosition: "-221px -1858px",
                  backgroundSize: "auto",
                }}
              ></div>
              <span className="text-[14px]">All</span>
            </button>
            <button
              onClick={() => setCurrentTab("account")}
              className={`p-2 px-3 rounded-md flex  items-center gap-3  cursor-pointer ${
                currentTab === "account" && "bg-[#C3DCF5] text-blue-800"
              }`}
            >
              <div
                className="bg-no-repeat inline-block w-[16px] h-[16px] "
                style={{
                  backgroundImage: `url('https://static.xx.fbcdn.net/rsrc.php/v3/yg/r/IkFqefhQFHd.png?_nc_eui2=AeHEJ05rlP_3RmPwLl8WJg__TWXz8SIAFRpNZfPxIgAVGkNdgfhAUPecrC0nfswIYkx624J1kSdbF67CaPgCZjx4&quot')`,
                  backgroundPosition: "0px -1909px",
                  backgroundSize: "auto",
                }}
              ></div>
              <span className="text-[14px]">Account</span>
            </button>
            <button
              onClick={() => setCurrentTab("instagram")}
              className={`p-2 px-3 rounded-md flex  items-center gap-3 cursor-pointer ${
                currentTab === "instagram" && "bg-[#C3DCF5] text-blue-800"
              }`}
            >
              <div
                className="bg-no-repeat inline-block w-[16px] h-[16px] "
                style={{
                  backgroundImage: `url('https://static.xx.fbcdn.net/rsrc.php/v3/yc/r/rgLKpsfRpuy.png?_nc_eui2=AeFbEKGaotwJgp9UxeiJz6AMmAypN-Hnj6qYDKk34eePqppAW9IGTXcj5djGAqD8l0XjUI1MDYHEfdKoEDWX7ZHc&quot')`,
                  backgroundPosition: "-251px -169px",
                  backgroundSize: "auto",
                }}
              ></div>

              <span className="text-[14px]">Instagram</span>
            </button>
            <button
              onClick={() => setCurrentTab("page")}
              className={`p-2 px-3 rounded-md flex  items-center gap-3 cursor-pointer ${
                currentTab === "page" && "bg-[#C3DCF5] text-blue-800"
              }`}
            >
              <div
                className="bg-no-repeat inline-block w-[16px] h-[16px] "
                style={{
                  backgroundImage: `url('https://static.xx.fbcdn.net/rsrc.php/v3/yg/r/IkFqefhQFHd.png?_nc_eui2=AeHEJ05rlP_3RmPwLl8WJg__TWXz8SIAFRpNZfPxIgAVGkNdgfhAUPecrC0nfswIYkx624J1kSdbF67CaPgCZjx4&quot')`,
                  backgroundPosition: "-323px -1824px",
                  backgroundSize: "auto",
                }}
              ></div>

              <span className="text-[14px]">Page</span>
            </button>
            <button
              onClick={() => setCurrentTab("video_url")}
              className={`p-2 px-3 rounded-md flex  items-center gap-3 cursor-pointer ${
                currentTab === "video_url" && "bg-[#C3DCF5] text-blue-800"
              }`}
            >
              <div
                className="bg-no-repeat inline-block w-[16px] h-[16px] "
                style={{
                  backgroundImage: `url('https://static.xx.fbcdn.net/rsrc.php/v4/yh/r/pR0H_cyD6Lw.png?_nc_eui2=AeFNZjtjx3do3ONEkwcKjf5Mf6piShBCYrV_qmJKEEJitdla5L6VdM4QjkLRy3NKXAtQSsxmAdywjIqPelgy_FEd&quot')`,
                  backgroundPosition: "-164px -251px",
                  backgroundSize: "auto",
                }}
              ></div>

              <span className="text-[14px]">Video URL</span>
            </button>
          </div>
          <hr className="p-0 m-0" />
          <div className="pt-4 px-5">
            {/* <p className="p-0 m-0">Filter by:</p> */}
            <div className="flex gap-1 items-start">
              {/* <select
                className="h-[34px] border-[1px] border-gray-400 rounded"
                name=""
                id=""
              >
                <option value="">All Posts</option>
              </select> */}
              {/*  */}

              <div className="flex pl-2 items-center border-[1px] border-gray-400 rounded flex-1">
                <GoSearch className="h-5 w-5 " />
                <input
                  type="text"
                  className={` outline-none rounded pl-2 py-3 flex-1 ${getSummaryBgClass()}`}
                  placeholder="Post image or video IDs, or other keywords"
                />
              </div>
              <SelectPostFilter
                options={[
                  "All post types",
                  "Published posts",
                  "Ads posts",
                  "Schedule posts",
                  "Available posts only",
                  "Option 3",
                ]}
                label={
                  <div
                    className="bg-no-repeat inline-block w-[16px] h-[16px] "
                    style={{
                      backgroundImage: `url('https://static.xx.fbcdn.net/rsrc.php/v3/ys/r/zQ7tm5y8cV3.png?_nc_eui2=AeEoNcQVP-t3R8EWa6ID8faeMC5265BCt40wLnbrkEK3jfw3iBLR9RmzcG_e0-dv0x2i5yMBp3vG20Q7Qj2DwG-1&quot')`,
                      backgroundPosition: "-17px -508px",
                      backgroundSize: "auto",
                    }}
                  ></div>
                }
              />
              <button
                // onClick={handleOpen}
                className={`p-3 px-3 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3  ${
                  currentMode == "dark" ? "bg-dark-neu text-white" : "bg-white"
                } `}
              >
                <FiPlus size={16} />

                <span>Upload</span>
              </button>
            </div>

            {currentTab !== "video_url" ? (
              <div>
                <div
                  className={`p-2 px-3 rounded-md flex  items-center gap-3 `}
                >
                  <div
                    className="bg-no-repeat inline-block w-[16px] h-[16px] "
                    style={{
                      backgroundImage: `url('https://static.xx.fbcdn.net/rsrc.php/v4/yg/r/IkFqefhQFHd.png?_nc_eui2=AeEpYR2m6RN2gvfiIIBJwxrQGMZrXZ0XcdQYxmtdnRdx1HNvLHwi6rKm6s0Rs3s6RbiZ1O16VtyCnq4hwGyHsNKI&quot')`,
                      backgroundPosition: "-187px -1858px",
                      backgroundSize: "auto",
                    }}
                  ></div>

                  <span className="text-[14px]">Account Pages</span>
                </div>
                <div className="flex gap-8 select-none">
                  {[1, 2, 3].map(() => {
                    return (
                      <div className="w-[100px] h-[150px]  rounded-lg flex flex-col relative">
                        <div className="w-full h-[60%] relative">
                          <img
                            src="/assets/female_character.png"
                            className="w-full object-contain h-full border-[2px] rounded-lg border-transparent hover:border-[#0A78BE] "
                            alt=""
                          />
                          <span className="p-[2px] text-white rounded-full bg-red-600 absolute bottom-2 right-2">
                            <FaMinus size={14} />
                          </span>
                        </div>

                        <div className="overflow-y-hidden truncate text-ellipsis break-words w-full">
                          female_character.png
                        </div>
                        <span>100 x 100</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-center flex-col">
                  <div>
                    <div className="flex flex-col items-center">
                      <div
                        className="bg-no-repeat inline-block w-[87px] h-[90px] "
                        style={{
                          backgroundImage: `url('https://static.xx.fbcdn.net/rsrc.php/v4/yh/r/pR0H_cyD6Lw.png?_nc_eui2=AeFNZjtjx3do3ONEkwcKjf5Mf6piShBCYrV_qmJKEEJitdla5L6VdM4QjkLRy3NKXAtQSsxmAdywjIqPelgy_FEd&quot')`,
                          backgroundPosition: "0px -251px",
                          backgroundSize: "auto",
                        }}
                      ></div>
                    </div>

                    <p className="py-4">
                      Quickly upload a video by pasting the link of a hosted
                      video file.
                    </p>
                    <div className="flex flex-col ">
                      <label
                        htmlFor=""
                        className="text-[14px] font-semibold mb-3"
                      >
                        Video URL
                      </label>
                      <input
                        type="text"
                        className={`p-2 mb-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                        //   value={campData?.name}
                        placeholder="paste the link of your video file"
                        //   onChange={(e) =>
                        //     setCampData((pre) => ({
                        //       ...pre,
                        //       name: e?.target?.value,
                        //     }))
                        //   }
                      />
                    </div>
                    <div className="flex flex-col ">
                      <label
                        htmlFor=""
                        className="text-[14px] font-semibold mb-3"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        className={`p-2 mb-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                        //   value={campData?.name}
                        placeholder="Name your video file"
                        //   onChange={(e) =>
                        //     setCampData((pre) => ({
                        //       ...pre,
                        //       name: e?.target?.value,
                        //     }))
                        //   }
                      />
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    // setCreateAdModal(false);
                    // //   setIsSingleAd(false);
                    // setIsSingleAd(1);
                    // setAdDetails(null);
                  }}
                  className="w-full mt-5 p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
                  // disabled={!selectedCampaign}
                >
                  Import
                </button>
              </>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end gap-3 w-full pt-3 px-4 border-t-[2px]">
          <button
            // onClick={() => setCreateAdModal(false)}
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
              currentMode == "dark" ? "bg-dark-neu text-white" : "bg-white"
            } `}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              // setCreateAdModal(false);
              // //   setIsSingleAd(false);
              // setIsSingleAd(1);
              // setAdDetails(null);
            }}
            className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
            // disabled={!selectedCampaign}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CarouselCard;
