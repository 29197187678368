import React, { useState, useEffect } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { selectStyles } from "../_elements/SelectStyles";
import Select from "react-select";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import axios from "../../axoisConfig";
import {
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
  Backdrop,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
} from "@mui/material";
let maxAges = [];
let minAges = [];

const token = localStorage?.getItem("auth-token");

const Demographics = ({ adSetDetails, setAdSetDetails }) => {
  const [selectedPlacementType, setSelectedPlacementType] = useState(null);
  const {
    currentMode,
    themeBgImg,
    t,
    primaryColor,
    snapchatCredentials,
    BACKEND_URL,
  } = useStateContext();
  const [isMore, setIsMore] = useState(false);
  const [checked, setChecked] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [genders, setGenders] = useState([]);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  async function getLanguages() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/demographics/languages`,
        {
          access_token: snapchatCredentials?.access_token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setLanguages(
        res?.data?.data?.targeting_dimensions?.map((obj) => ({
          label: obj.languages?.name,
          value: obj?.languages?.id,
        }))
      );
    } catch (error) {
      console.log(error, "this is an error");
    }
  }
  async function getGenders() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/demographics/gender`,
        {
          access_token: snapchatCredentials?.access_token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setGenders(
        res?.data?.data?.targeting_dimensions?.map((obj) => ({
          label: obj.gender?.name,
          value: obj?.gender?.id,
        }))
      );
    } catch (error) {
      console.log(error, "this is an error");
    }
  }

  useEffect(() => {
    getLanguages();
    getGenders();
    generateAges();
  }, []);

  function generateAges() {
    maxAges = Array.from({ length: 56 - 13 }, (v, i) => {
      const value = 13 + i;
      return { label: value.toString(), value: value }; // Format for react-select
    });
    minAges = Array.from({ length: 45 - 13 }, (v, i) => {
      const value = 13 + i;
      return { label: value.toString(), value: value }; // Format for react-select
    });
  }

  return (
    <div className={``}>
      <h2 className="font-bold text-[16px] mb-5">Demographics</h2>
      <div
        className={`${getSummaryBgClass()} rounded-3xl px-8 py-4 shadow-md mb-9`}
      >
        {/* <label htmlFor="" className="text-semibold">
          Regulated Content{" "}
        </label>
        <div>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="Age restrictions required"
          />
        </div> */}
        <label htmlFor="" className="text-semibold">
          Ages
        </label>
        {/* <div className="grid grid-cols-2 w-[250px] gap-3">
          <Select
            // isLoading={adSetLoading}
            id="min Age"
            options={minAges}
            value={minAges.find(
              (age) => age.value == adSetDetails?.demographics[0]?.min_age
            )}
            onChange={(e) => {
              setAdSetDetails((pre) => {
                pre.demographics = [
                  {
                    ...pre?.demographics[0],
                    min_age: e?.value,
                  },
                ];
                return { ...pre };
              });
            }}
            placeholder={"Min"}
            // className={`mb-5`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />

          <Select
            // isLoading={adSetLoading}
            id="max-ages"
            options={maxAges}
            value={maxAges.find(
              (age) => age.value == adSetDetails?.demographics[0]?.max_age
            )}
            onChange={(e) => {
              setAdSetDetails((pre) => {
                pre.demographics = [
                  {
                    ...pre?.demographics[0],
                    max_age: e?.value,
                  },
                ];
                return { ...pre };
              });
            }}
            placeholder={"Max"}
            // className={`mb-5`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div> */}
        <div className="grid grid-cols-2 w-[250px] gap-3">
          <Select
            id="min-age"
            options={minAges}
            value={minAges.find(
              (age) => age.value == adSetDetails?.demographics[0]?.min_age
            )}
            onChange={(e) => {
              setAdSetDetails((prev) => {
                const newMinAge = e?.value;
                // Prevent setting min_age greater than max_age
                const maxAge = prev.demographics[0]?.max_age;
                return {
                  ...prev,
                  demographics: [
                    {
                      ...prev?.demographics[0],
                      min_age: newMinAge,
                      max_age: maxAge < newMinAge ? newMinAge : maxAge, // Adjust max_age if needed
                    },
                  ],
                };
              });
            }}
            placeholder={"Min"}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />

          <Select
            id="max-age"
            options={maxAges}
            value={maxAges.find(
              (age) => age.value == adSetDetails?.demographics[0]?.max_age
            )}
            onChange={(e) => {
              setAdSetDetails((prev) => {
                const newMaxAge = e?.value;
                // Prevent setting max_age less than min_age
                const minAge = prev.demographics[0]?.min_age;
                return {
                  ...prev,
                  demographics: [
                    {
                      ...prev?.demographics[0],
                      max_age: newMaxAge,
                      min_age: minAge > newMaxAge ? newMaxAge : minAge, // Adjust min_age if needed
                    },
                  ],
                };
              });
            }}
            placeholder={"Max"}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div>

        <label htmlFor="" className="text-semibold mb-2">
          Genders
        </label>
        <div className="flex gap-2">
          <button
            onClick={() => {
              setAdSetDetails((pre) => ({
                ...pre,
                demographics: [
                  (({ gender, ...rest }) => rest)(pre.demographics[0]), // Removes gender key
                ],
              }));
            }}
            className={`p-3 px-5 ${
              !adSetDetails?.demographics[0]?.gender && "border-[#F7D901]"
            } rounded-[10px] border disabled:cursor-not-allowed font-semibold ${
              currentMode == "dark" ? "bg-black text-white" : "bg-white"
            } `}
          >
            All
          </button>
          {genders
            ?.filter((gen) => gen?.label !== "OTHER")
            ?.map((gender) => {
              return (
                <button
                  onClick={() => {
                    setAdSetDetails((pre) => ({
                      ...pre,
                      demographics: [
                        { ...pre?.demographics[0], gender: gender?.label },
                      ],
                    }));
                  }}
                  className={`p-3 px-5 ${
                    adSetDetails?.demographics[0]?.gender === gender?.label &&
                    "border-[#F7D901]"
                  } rounded-[10px] border disabled:cursor-not-allowed font-semibold ${
                    currentMode == "dark" ? "bg-black text-white" : "bg-white"
                  } `}
                >
                  {gender?.label}
                </button>
              );
            })}
        </div>
        <div className="flex flex-col py-3">
          <label htmlFor="" className="font-medium ">
            Languages
          </label>

          <Select
            id=""
            options={languages}
            value={languages?.filter((lang) =>
              adSetDetails?.demographics[0]?.languages?.includes(lang?.value)
            )}
            onChange={(e) => {
              setAdSetDetails((pre) => ({
                ...pre,
                demographics: [
                  {
                    ...pre?.demographics[0],
                    languages: e?.map((e) => e?.value),
                  },
                ],
              }));
            }}
            isMulti
            placeholder={t("Select a langauges")}
            menuPortalTarget={document.body}
            styles={{
              ...selectStyles(currentMode, primaryColor),
              control: (provided, state) => ({
                ...provided,
                ...(selectStyles(currentMode, primaryColor).control
                  ? selectStyles(currentMode, primaryColor).control(
                      provided,
                      state
                    )
                  : {}),
                marginBottom: 0,
                marginTop: "3px",
              }),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Demographics;
