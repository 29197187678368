import React, { useState, useEffect } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { selectStyles } from "../_elements/SelectStyles";
import Select from "react-select";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import Menu from "@mui/material/Menu";
import axios from "../../axoisConfig";
import CustomCheckbox from "./CustomCheckbox";
import { IoBanSharp } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
const continents = [
  { code: "af", name: "Africa" },
  { code: "as", name: "Asia" },
  { code: "eu", name: "Europe" },
  { code: "na", name: "North America" },
  { code: "oc", name: "Oceania" },
  { code: "sa", name: "South America" },
];

const Locations = ({
  adSetDetails,
  setAdSetDetails,
  // selectedCountries,
  setSelectedCountries,
  organizedData,
  setOrganizedData,
  targetingDimensions,
  setTargetingDimensions,
}) => {
  const {
    currentMode,
    themeBgImg,
    t,
    primaryColor,
    snapchatCredentials,
    BACKEND_URL,
  } = useStateContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorCountriesEl, setAnchorCountriesEl] = useState(null);
  const open = Boolean(anchorEl);
  const countriesOpen = Boolean(anchorCountriesEl);
  const token = localStorage?.getItem("auth-token");
  const [openedCountry, setOpenedCountry] = useState(null);
  const [isRegionOpened, setIsRegionOpened] = useState(false);
  const [isSelectedRegionOpened, setIsSelectedRegionOpened] = useState(false);
  const [regions, setRegions] = useState([]);
  const [openedSelectedCountry, setOpenedSelectedCountry] = useState(null);

  const selectedCountries = Object.values(organizedData)
    .flat()
    .filter((ctry) =>
      adSetDetails?.geos?.find((country) => country.country_code == ctry.code)
    );
  console.log(selectedCountries, "countries in pace");

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCountriesClose = () => {
    setAnchorCountriesEl(null);
  };

  async function getCountries() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/geolocation/country`,
        {
          access_token: snapchatCredentials?.access_token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setTargetingDimensions(res?.data?.data?.targeting_dimensions);
      setOrganizedData(
        res?.data?.data?.targeting_dimensions.reduce((acc, item) => {
          const continentName = item.country.continent.name;
          const country = item.country.country;

          if (!acc[continentName]) acc[continentName] = [];
          acc[continentName]?.push(country);

          return acc;
        }, {})
      );
    } catch (error) {
      console.log(error, "this is an error");
    }
  }
  // async function getRegions() {
  //   try {
  //     const res = await axios.post(
  //       `${BACKEND_URL}/snapchat/geo/country/region`,
  //       {
  //         access_token: snapchatCredentials?.access_token,
  //         country_code: openedCountry.code,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );
  //     setRegions((pre) => ({
  //       ...pre,
  //       [openedCountry.code]: res?.data?.data?.targeting_dimensions?.map(
  //         (obj) => obj.region?.region
  //       ),
  //     }));
  //   } catch (error) {
  //     console.log(error, "this is an error");
  //   }
  // }
  useEffect(() => {
    if (adSetDetails?.geos?.length > 0) {
      getRegionsForAllCountries();
    }
  }, [adSetDetails]);

  async function getRegionsForAllCountries() {
    // Extract unique country codes
    const uniqueCountryCodes = [
      ...new Set(adSetDetails.geos.map((geo) => geo.country_code)),
    ];

    // Filter out country codes that are already in regions
    const countryCodesToFetch = uniqueCountryCodes.filter(
      (code) => !regions[code]
    );

    // Fetch regions only for missing country codes
    const regionData = {};
    try {
      await Promise.all(
        countryCodesToFetch.map(async (code) => {
          const res = await axios.post(
            `${BACKEND_URL}/snapchat/geo/country/region`,
            {
              access_token: snapchatCredentials?.access_token,
              country_code: code,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          regionData[code] = res?.data?.data?.targeting_dimensions?.map(
            (obj) => obj.region?.region
          );
        })
      );

      // Update the regions state with new data
      setRegions((prevRegions) => ({
        ...prevRegions,
        ...regionData,
      }));
    } catch (error) {
      console.error(error, "Error fetching regions");
    }
  }

  console.log(regions, "regions");

  useEffect(() => {
    getCountries();
  }, []);

  console.log(adSetDetails?.geos, "geos locations");

  return (
    <div className={``}>
      <h2 className="font-bold text-[16px] mb-5">Locations</h2>
      <div
        className={`${getSummaryBgClass()} rounded-3xl px-8 py-4 shadow-md mb-9`}
      >
        <h3 className="font-semibold">Your audience is located in :</h3>
        <ul className="border border-gray-200 rounded-lg max-h-[300px] overflow-auto">
          {Object.values(organizedData)
            ?.flat()
            ?.filter((ctry) =>
              adSetDetails?.geos?.find((loc) => loc?.country_code == ctry?.code)
            )
            ?.map((country, index) => {
              return (
                <li key={index} className="flex  flex-col px-4 py-1 ">
                  <div
                    onClick={() => {
                      if (
                        adSetDetails?.geos?.filter(
                          (loc) =>
                            loc.region_id && loc.country_code === country?.code
                        ).length == 0
                      ) {
                        return;
                      }
                      if (country?.code === openedSelectedCountry?.code) {
                        setOpenedSelectedCountry(null);
                        return;
                      }
                      setOpenedSelectedCountry(country);
                    }}
                    className={`flex items-center justify-between px-4 py-3 group cursor-pointer hover:bg-[#F9F9FA] ${
                      currentMode == "dark"
                        ? "hover:bg-gray-800"
                        : "hover:bg-[#F9F9FA]"
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      {adSetDetails?.geos
                        ?.filter(
                          (loc) =>
                            loc?.region_id && loc.country_code === country?.code
                        )
                        ?.map((ctry) => ctry.region_id)
                        ?.flat().length > 0 && (
                        <span>
                          {openedSelectedCountry?.code === country?.code ? (
                            <MdKeyboardArrowDown size={16} />
                          ) : (
                            <MdKeyboardArrowRight size={16} />
                          )}
                        </span>
                      )}
                      <span>{country.name?.toUpperCase()}</span>
                    </div>

                    <span
                      onClick={(e) => {
                        setAdSetDetails((pre) => {
                          pre.geos = pre?.geos?.filter(
                            (loc) => loc.country_code !== country?.code
                          );
                          return { ...pre };
                        });
                      }}
                      className="group-hover:block hidden text-sky-500 cursor-pointer"
                    >
                      <IoMdClose />
                    </span>
                  </div>
                  <div>
                    {openedSelectedCountry?.code === country?.code && (
                      <ul className="pl-6">
                        <li>
                          <div
                            onClick={(e) => {
                              setIsSelectedRegionOpened((pre) => !pre);
                              e.stopPropagation();
                            }}
                            className="flex items-center  w-full gap-2 py-2"
                          >
                            {!isSelectedRegionOpened ? (
                              <span>
                                <MdKeyboardArrowRight size={16} />
                              </span>
                            ) : (
                              <span>
                                <MdKeyboardArrowDown size={16} />
                              </span>
                            )}
                            <span>REGRION</span>
                          </div>
                          {isSelectedRegionOpened && (
                            <ul className="pl-4">
                              {regions[country?.code]
                                ?.filter((region) =>
                                  adSetDetails?.geos
                                    ?.filter(
                                      (loc) =>
                                        loc.region_id &&
                                        loc.country_code === country?.code
                                    )
                                    ?.map((ctry) => ctry?.region_id)
                                    ?.flat()
                                    ?.includes(region?.id)
                                )
                                ?.map((region) => {
                                  return (
                                    <li className="flex justify-between group">
                                      <div className="flex items-center gap-2 py-3">
                                        <span>
                                          {adSetDetails?.geos
                                            ?.filter(
                                              (loc) =>
                                                loc.region_id &&
                                                loc.country_code ===
                                                  country?.code
                                            )
                                            ?.find((ctry) =>
                                              ctry?.region_id?.includes(
                                                region?.id
                                              )
                                            )?.operation === "EXCLUDE" ? (
                                            <IoBanSharp color="red" size={14} />
                                          ) : (
                                            <FaCheck color="green" size={14} />
                                          )}
                                        </span>
                                        <span>
                                          {region?.name?.toUpperCase()}
                                        </span>
                                      </div>

                                      <div className=" flex items-center gap-3 text-sky-500">
                                        {adSetDetails?.geos
                                          ?.filter(
                                            (loc) =>
                                              loc.region_id &&
                                              loc.country_code === country?.code
                                          )
                                          ?.find((ctry) =>
                                            ctry?.region_id?.includes(
                                              region?.id
                                            )
                                          )?.operation === "EXCLUDE" ? (
                                          <button
                                            onClick={() =>
                                              setAdSetDetails((pre) => {
                                                let cIndex =
                                                  pre.geos?.findIndex(
                                                    (loc) =>
                                                      loc.country_code ==
                                                        country?.code &&
                                                      loc.operation == "EXCLUDE"
                                                  );
                                                pre.geos[cIndex].region_id =
                                                  pre.geos[
                                                    cIndex
                                                  ].region_id?.filter(
                                                    (id) => id != region?.id
                                                  );
                                                cIndex = pre.geos?.findIndex(
                                                  (loc) =>
                                                    loc.country_code ==
                                                      country?.code &&
                                                    loc.operation == "INCLUDE"
                                                );
                                                if (cIndex !== -1) {
                                                  pre.geos[cIndex].region_id = [
                                                    ...(pre?.geos[cIndex]
                                                      ?.region_id || []),
                                                    region?.id,
                                                  ];
                                                } else {
                                                  pre?.geos?.push({
                                                    country_code: country?.code,
                                                    region_id: [region?.id],
                                                    operation: "EXCLUDE",
                                                  });
                                                }
                                                return { ...pre };
                                              })
                                            }
                                          >
                                            Include
                                          </button>
                                        ) : (
                                          <button
                                            onClick={() =>
                                              setAdSetDetails((pre) => {
                                                let cIndex =
                                                  pre.geos?.findIndex(
                                                    (loc) =>
                                                      loc.country_code ==
                                                        country?.code &&
                                                      loc.operation == "INCLUDE"
                                                  );
                                                pre.geos[cIndex].region_id =
                                                  pre.geos[
                                                    cIndex
                                                  ].region_id?.filter(
                                                    (id) => id != region?.id
                                                  );
                                                cIndex = pre.geos?.findIndex(
                                                  (loc) =>
                                                    loc.country_code ==
                                                      country?.code &&
                                                    loc.operation == "EXCLUDE"
                                                );
                                                if (cIndex !== -1) {
                                                  pre.geos[cIndex].region_id = [
                                                    ...(pre.geos[cIndex]
                                                      ?.region_id || []),
                                                    region?.id,
                                                  ];
                                                } else {
                                                  pre?.geos?.push({
                                                    country_code: country?.code,
                                                    region_id: [region?.id],
                                                    operation: "INCLUDE",
                                                  });
                                                }

                                                return { ...pre };
                                              })
                                            }
                                          >
                                            Exclude
                                          </button>
                                        )}
                                        <span
                                          onClick={(e) => {
                                            setAdSetDetails((pre) => {
                                              const cIndex =
                                                pre?.geos?.findIndex(
                                                  (ctry) =>
                                                    ctry?.country_code ==
                                                      country?.code &&
                                                    ctry.operation ==
                                                      adSetDetails?.geos
                                                        ?.filter(
                                                          (loc) =>
                                                            loc.region_id &&
                                                            loc.country_code ===
                                                              country?.code
                                                        )
                                                        ?.find((ctry) =>
                                                          ctry?.region_id?.includes(
                                                            region?.id
                                                          )
                                                        )?.operation
                                                );
                                              pre.geos[cIndex].region_id =
                                                pre.geos[
                                                  cIndex
                                                ]?.region_id?.filter(
                                                  (id) => id !== region?.id
                                                );

                                              return { ...pre };
                                            });
                                          }}
                                          className="group-hover:block hidden text-sky-500 cursor-pointer"
                                        >
                                          <IoMdClose />
                                        </span>
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </li>
                      </ul>
                    )}
                  </div>
                </li>
              );
            })}
        </ul>
        <div>
          <div className="relative">
            <input
              type="text"
              onClick={(e) => setAnchorEl(e?.currentTarget)}
              // className="p-2 py-3 mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
              className={`p-2 mt-4 ${
                currentMode != "dark" && "!bg-[#F9F9FA]"
              }  ${getSummaryBgClass()} rounded-lg focus:outline-none w-full  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
              // value={adSetDetails?.name}
              // onChange={(e) =>
              //   setAdSetDetails((pre) => ({
              //     ...pre,
              //     name: e?.target?.value,
              //   }))
              // }
            />
            <span
              onClick={(e) => setAnchorCountriesEl(e?.currentTarget)}
              className="text-sky-500 hover:underline absolute right-2 cursor-pointer top-1/2 -translate-y-[10%] "
            >
              Add Countries
            </span>
          </div>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: { width: anchorEl && anchorEl.offsetWidth }, // <-- The line that does all
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          sx={{
            "& .MuiList-root": {
              padding: "0px !important", // Modify border radius
            },
            padding: "0px",
            zIndex: 9999, // Remove any max width that might be applied by default
          }}
        >
          <div className={`${getSummaryBgClass()} max-h-[300px] overflow-auto`}>
            <ul className="">
              {selectedCountries?.map((country, index) => {
                return (
                  <li
                    key={index}
                    className="flex flex-col px-4 py-2  group cursor-pointer"
                    onClick={() => {
                      if (country?.code === openedCountry?.code) {
                        setOpenedCountry(null);
                        return;
                      }
                      setOpenedCountry(country);
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span>{country?.name?.toUpperCase()}</span>
                      </div>

                      {!(openedCountry?.code === country?.code) ? (
                        <span>
                          <MdKeyboardArrowRight size={16} />
                        </span>
                      ) : (
                        <span>
                          <MdKeyboardArrowDown size={16} />
                        </span>
                      )}
                    </div>

                    {openedCountry?.code === country?.code && (
                      <ul className="pl-4">
                        <li>
                          <div
                            onClick={(e) => {
                              setIsRegionOpened((pre) => !pre);
                              e.stopPropagation();
                            }}
                            className="flex items-center justify-between w-full gap-2 "
                          >
                            <span>REGION</span>

                            {!isRegionOpened ? (
                              <span>
                                <MdKeyboardArrowRight size={16} />
                              </span>
                            ) : (
                              <span>
                                <MdKeyboardArrowDown size={16} />
                              </span>
                            )}
                          </div>
                          {isRegionOpened && (
                            <ul className="pl-4">
                              {regions[openedCountry?.code]
                                ?.filter(
                                  (region) =>
                                    !adSetDetails?.geos
                                      ?.filter(
                                        (loc) =>
                                          loc.region_id &&
                                          loc.country_code == country?.code
                                      )
                                      ?.map((ctry) => ctry?.region_id)
                                      ?.flat()
                                      ?.includes(region?.id)
                                )
                                ?.map((region) => {
                                  return (
                                    <li className="flex justify-between">
                                      <div className="flex items-center gap-2 py-3">
                                        <span>
                                          {region?.name?.toUpperCase()}
                                        </span>
                                      </div>

                                      <div className=" flex items-center gap-3 text-sky-500">
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            setAdSetDetails((pre) => {
                                              const cIndex =
                                                pre?.geos?.findIndex(
                                                  (i) =>
                                                    i?.country_code ===
                                                      country?.code &&
                                                    i?.operation == "EXCLUDE"
                                                );
                                              if (cIndex !== -1) {
                                                pre.geos[cIndex].region_id = [
                                                  ...(pre?.geos[cIndex]
                                                    ?.region_id || []),
                                                  region?.id,
                                                ];
                                              } else {
                                                pre?.geos?.push({
                                                  country_code:
                                                    openedCountry?.code,
                                                  region_id: [region?.id],
                                                  operation: "EXCLUDE",
                                                });
                                              }
                                              return {
                                                ...pre,
                                              };
                                            });
                                          }}
                                        >
                                          Exclude
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setAdSetDetails((pre) => {
                                              const cIndex =
                                                pre?.geos?.findIndex(
                                                  (i) =>
                                                    i?.country_code ===
                                                      country?.code &&
                                                    i?.operation == "INCLUDE"
                                                );

                                              if (cIndex != -1) {
                                                pre.geos[cIndex].region_id = [
                                                  ...(pre?.geos[cIndex]
                                                    ?.region_id || []),
                                                  region?.id,
                                                ];
                                              } else {
                                                pre?.geos?.push({
                                                  country_code:
                                                    openedCountry?.code,
                                                  region_id: [region?.id],
                                                  operation: "INCLUDE",
                                                });
                                              }

                                              return {
                                                ...pre,
                                              };
                                            });
                                          }}
                                        >
                                          Include
                                        </button>
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </li>
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </Menu>
        <Menu
          id="basic-menu"
          anchorEl={anchorCountriesEl}
          open={countriesOpen}
          onClose={handleCountriesClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          sx={{
            "& .MuiList-root": {
              padding: "0px !important", // Modify border radius
            },
            padding: "0px",
            zIndex: 9999, // Remove any max width that might be applied by default
          }}
        >
          <div className={`${getSummaryBgClass()} `}>
            <Countries
              selectedCountries={selectedCountries}
              setSelectedCountries={setSelectedCountries}
              organizedData={organizedData}
              setOrganizedData={setOrganizedData}
              adSetDetails={adSetDetails}
              setAdSetDetails={setAdSetDetails}
              //   targetingDimensions={targetingDimensions}
            />
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default Locations;

const Countries = ({
  selectedCountries,
  setSelectedCountries,
  organizedData,
  adSetDetails,
  setAdSetDetails,
}) => {
  const [open, setOpen] = useState(false);
  const { currentMode } = useStateContext();
  const [selectedContent, setSelectedContent] = useState(continents[0].code);

  console.log("organizedData", organizedData);

  const handleCheckboxChange = (country) => {
    setSelectedCountries((prevSelected) =>
      prevSelected.find((ctry) => ctry.code === country.code)
        ? prevSelected.filter((item) => item.code !== country.code)
        : [...prevSelected, country]
    );
  };

  console.log(adSetDetails?.geos, "GEOS");

  return (
    <div className="">
      {/* <button
        className="w-full p-3 bg-white border border-gray-300 rounded-lg text-left flex justify-between items-center shadow-sm"
        onClick={() => setOpen(!open)}
      >
        <span className="text-gray-700">Select Countries</span>
        <span className="text-gray-500">{open ? "▲" : "▼"}</span>
      </button> */}
      <div className="   w-[400px] h-[180px] overflow-y-auto py-3">
        <div className="grid grid-cols-2 w-full pl-3 text-[13px] gap-y-3">
          <div>
            {Object.entries(organizedData)?.map(
              ([continent, countries], index) => (
                <div
                  className={`flex items-center justify-between  ${
                    currentMode == "dark"
                      ? "hover:bg-gray-800"
                      : "hover:bg-[#F9F9FA]"
                  } cursor-pointer`}
                  onClick={() => setSelectedContent(continent)}
                >
                  <div className="flex items-center gap-3 ">
                    {" "}
                    <div>
                      <CustomCheckbox
                        value={
                          adSetDetails?.geos?.filter((loc) =>
                            countries?.find(
                              (ctry) => ctry.code == loc.country_code
                            )
                          )?.length === countries?.length
                            ? 2
                            : adSetDetails?.geos?.filter((loc) =>
                                countries?.find(
                                  (ctry) => ctry.code == loc.country_code
                                )
                              )?.length >= 1
                            ? 1
                            : 0
                        }
                        onChange={(e) => {
                          setAdSetDetails((pre) => {
                            if (e == 2) {
                              countries?.map((country) => {
                                if (
                                  !pre?.geos?.find(
                                    (loc) => loc.country_code == country.code
                                  )
                                ) {
                                  console.log("It is called in loop of geos");

                                  pre?.geos?.push({
                                    country_code: country.code,
                                    operation: "INCLUDE",
                                  });
                                }
                              });
                            } else if (e == 0) {
                              countries?.map((country) => {
                                pre.geos = pre?.geos?.filter((loc) => {
                                  return loc.country_code != country.code;
                                });
                              });
                            }
                            return {
                              ...pre,
                            };
                          });
                        }}
                      />
                    </div>
                    <div>
                      {continents?.find((cnt) => cnt.code == continent)?.name}{" "}
                    </div>
                  </div>{" "}
                  <span>
                    <MdKeyboardArrowRight size={18} />
                  </span>
                </div>
              )
            )}
          </div>
          <div>
            {organizedData[selectedContent]?.map((country) => {
              return (
                <div
                  className={`flex items-center justify-between  cursor-pointer ${
                    currentMode == "dark"
                      ? "hover:bg-gray-800"
                      : "hover:bg-[#F9F9FA]"
                  }`}
                  // onClick={() => setSelectedContent(continent)}
                >
                  <div className="flex items-center gap-3 ">
                    {" "}
                    <div>
                      <CustomCheckbox
                        value={
                          adSetDetails?.geos?.find(
                            (loc) => loc.country_code == country?.code
                          )
                            ? 2
                            : 0
                        }
                        onChange={(e) => {
                          setAdSetDetails((pre) => {
                            if (e == 2) {
                              pre?.geos?.push({
                                country_code: country?.code,
                                operation: "INCLUDE",
                              });
                            } else if (e == 0) {
                              pre.geos = pre?.geos?.filter((loc) => {
                                return loc.country_code !== country?.code;
                              });
                            }
                            return {
                              ...pre,
                            };
                          });
                        }}
                      />
                    </div>
                    <div>{country?.name?.toUpperCase()}</div>
                  </div>{" "}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <div className=" mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-10">
<div className="grid grid-cols-2 gap-4 p-4">
  {/* Continents Section 
  <div>
    <h3 className="font-semibold mb-2 text-gray-700">Continents</h3>
    {continents.map((continent, index) => (
      <label
        key={index}
        className="flex items-center space-x-2 mb-2 cursor-pointer"
      >
        <input
          type="checkbox"
          className="form-checkbox h-4 w-4 text-blue-600"
        />
        <span className="text-gray-700">{continent}</span>
      </label>
    ))}
  </div>
  <div>
    <h3 className="font-semibold mb-2 text-gray-700">Countries</h3>
    {countries.map((country, index) => (
      <label
        key={index}
        className="flex items-center space-x-2 mb-2 cursor-pointer"
      >
        <input
          type="checkbox"
          className="form-checkbox h-4 w-4 text-blue-600"
        />
        <span className="text-gray-700">{country}</span>
      </label>
    ))}
  </div>
</div>
</div> */
}

// <div className="grid grid-cols-2 gap-4 p-4">
// {Object.entries(organizedData).map(
//   ([continent, countries], index) => (
//     <div key={index}>
//       <h3 className="font-semibold text-gray-700 mb-2">
//         {continent}
//       </h3>
//       {countries.map((country, idx) => (
//         <label key={idx} className="flex items-center space-x-2 mb-2">
//           <input
//             type="checkbox"
//             checked={selectedCountries.find(
//               (ctry) => country.code === ctry.code
//             )}
//             onChange={() => handleCheckboxChange(country)}
//             className="form-checkbox h-4 w-4 text-blue-600"
//           />
//           <span className="text-gray-700">{country.name}</span>
//         </label>
//       ))}
//     </div>
//   )
// )}
// </div>
