import React from "react";

import { TbFlag3Filled } from "react-icons/tb";

// OWNERS
export const boss = (t) => [
  {
    id: 102,
    title: "Mr.",
    name: "Mohamed Hikal",
    phone: "+971585556605",
    email: "hikal@hikalagency.ae",
  },
  {
    id: 132,
    title: "Mr.",
    name: "Belal Hikal",
    phone: "+971585550775",
    email: "belal.hikal@hikalproperties.ae",
  },
];

// LEAD CATEGORY
export const lead_category = (t) => [
  {
    name: t("menu_fresh"),
    value: "freshleads",
  },
  {
    name: t("menu_thirdparty"),
    value: "thirdpartyleads",
  },
  {
    name: t("menu_live_call"),
    value: "liveleads",
  },
  {
    name: t("cold"),
    value: "coldleads",
  },
  {
    name: t("menu_archived"),
    value: "archive",
  },
  {
    name: t("menu_personal"),
    value: "personalleads",
  },
  {
    name: t("menu_secondary"),
    value: "buyers",
  },
];

// FEEDBACK
export const feedback_options = (t) => [
  {
    value: "New",
    label: t("feedback_new"),
    bgColor: "#BEEDF1",
    color: "#000000",
  },
  {
    value: "Callback",
    label: t("feedback_callback"),
    bgColor: "#FFED9A",
    color: "#000000",
  },
  {
    value: "Follow Up (Short Term)",
    label: t("feedback_follow_up_short_term"),
    bgColor: "#FFF799",
    color: "#000000",
  },
  {
    value: "Follow Up (Long Term)",
    label: t("feedback_follow_up_long_term"),
    bgColor: "#FFDD99",
    color: "#000000",
  },
  {
    value: "Low Budget",
    label: t("feedback_low_budget"),
    bgColor: "#FDC68C",
    color: "#000000",
  },
  {
    value: "Meeting",
    label: t("feedback_meeting"),
    bgColor: "#C6DF9C",
    color: "#000000",
  },
  {
    value: "Booked",
    label: t("feedback_booked"),
    bgColor: "#81CA9D",
    color: "#000000",
  },
  {
    value: "No Answer",
    label: t("feedback_no_answer"),
    bgColor: "#FFBEBD",
    color: "#000000",
  },
  {
    value: "Switched Off",
    label: t("feedback_switched_off"),
    bgColor: "#F7977A",
    color: "#000000",
  },
  {
    value: "Not Interested",
    label: t("feedback_not_interested"),
    bgColor: "#F16C4D",
    color: "#000000",
  },
  {
    value: "Unreachable",
    label: t("feedback_unreachable"),
    bgColor: "#898989",
    color: "#000000",
  },
  {
    value: "Wrong Number",
    label: t("feedback_wrong_number"),
    bgColor: "#7D7D7D",
    color: "#000000",
  },
  {
    value: "Duplicate",
    label: t("feedback_duplicate"),
    bgColor: "#707070",
    color: "#000000",
  },
  // {
  //   value: "Dead",
  //   label: t("feedback_dead")
  // },
];

// LEAD CATEGORY
export const lead_options = (t) => [
  {
    value: "0",
    label: t("menu_fresh"),
  },
  {
    value: "5",
    label: t("secondary_buyers"),
  },
  {
    value: "3",
    label: t("menu_thirdparty"),
  },
  {
    value: "4",
    label: t("menu_archived"),
  },
  {
    value: "6",
    label: t("menu_live_call"),
  },
  {
    value: "1",
    label: t("menu_cold"),
  },
  {
    value: "2",
    label: t("menu_personal"),
  },
];

// LANGUAGE
export const language_options = [
  {
    value: "English",
    label: <div style={{ fontFamily: "Noto Sans" }}>English</div>,
  },
  {
    value: "Arabic",
    label: (
      <div style={{ fontFamily: "Noto Kufi Arabic" }}>
        عربي{" "}
        <span className="mx-2" style={{ fontFamily: "Noto Sans" }}>
          (Arabic)
        </span>
      </div>
    ),
  },
  {
    value: "Chinese",
    label: (
      <div style={{ fontFamily: "Noto Sans TC" }}>
        中国人{" "}
        <span className="mx-2" style={{ fontFamily: "Noto Sans" }}>
          (Chinese)
        </span>
      </div>
    ),
  },
  {
    value: "Farsi",
    label: (
      <div style={{ fontFamily: "Noto Kufi Arabic" }}>
        فارسی{" "}
        <span className="mx-2" style={{ fontFamily: "Noto Sans" }}>
          (Farsi/Persian)
        </span>
      </div>
    ),
  },
  {
    value: "French",
    label: (
      <div style={{ fontFamily: "Noto Sans" }}>
        Français <span className="mx-2">(French)</span>
      </div>
    ),
  },
  {
    value: "Hebrew",
    label: (
      <div style={{ fontFamily: "Noto Sans Hebrew" }}>
        עִברִית{" "}
        <span className="mx-2" style={{ fontFamily: "Noto Sans" }}>
          (Hebrew)
        </span>
      </div>
    ),
  },
  {
    value: "Hindi",
    label: (
      <div style={{ fontFamily: "Noto Sans Devanagari" }}>
        हिंदी{" "}
        <span className="mx-2" style={{ fontFamily: "Noto Sans" }}>
          (Hindi)
        </span>
      </div>
    ),
  },
  {
    value: "Russian",
    label: (
      <div style={{ fontFamily: "Noto Sans" }}>
        Русский <span className="mx-2">(Russian)</span>
      </div>
    ),
  },
  {
    value: "Spanish",
    label: (
      <div style={{ fontFamily: "Noto Sans" }}>
        Español <span className="mx-2">(Spanish)</span>
      </div>
    ),
  },
  {
    value: "Urdu",
    label: (
      <div style={{ fontFamily: "Noto Kufi Arabic" }}>
        اردو{" "}
        <span className="mx-2" style={{ fontFamily: "Noto Sans" }}>
          (Urdu)
        </span>
      </div>
    ),
  },
];

// LEAD SOURCE
export const source_options = (t) => [
  {
    value: "Facebook",
    label: `${t("source_facebook")}`,
  },
  {
    value: "Instagram",
    label: `${t("source_instagram")}`,
  },
  {
    value: "Snapchat",
    label: `${t("source_snapchat")}`,
  },
  {
    value: "TikTok",
    label: `${t("source_tiktok")}`,
  },
  {
    value: "GoogleAds",
    label: `${t("source_googleads")}`,
  },
  {
    value: "YouTube",
    label: `${t("source_youtube")}`,
  },
  {
    value: "Telegram",
    label: `${t("source_telegram")}`,
  },
  {
    value: "WeChat",
    label: `${t("source_wechat")}`,
  },
  {
    value: "Twitter",
    label: `${t("source_twitter")}`,
  },
  {
    value: "Campaign",
    label: `${t("source_campaign")}`,
  },
  {
    value: "WhatsApp",
    label: `${t("source_whatsapp")}`,
  },
  {
    value: "Comment",
    label: `${t("source_comment")}`,
  },
  {
    value: "Message",
    label: `${t("source_message")}`,
  },
  {
    value: "Website",
    label: `${t("source_website")}`,
  },
  // {
  //   value: "Secondary",
  //   label: `${t("source_secondary")}`,
  // },
  {
    value: "Property Finder",
    label: `${t("source_property_finder")}`,
  },
  {
    value: "Personal",
    label: `${t("source_personal")}`,
  },
];

// LEAD PURPOSE
export const purpose_options = (t) => [
  {
    value: "Investment",
    label: t("purpose_investment"),
  },
  {
    value: "End-user",
    label: t("purpose_end_user"),
  },
];

// PROPERTY TYPE
export const property_options = (t) => [
  {
    value: "Apartment",
    label: t("property_apartment"),
  },
  {
    value: "Villa",
    label: t("property_villa"),
  },
  {
    value: "Townhouse",
    label: t("property_townhouse"),
  },
  {
    value: "Penthouse",
    label: t("property_penthouse"),
  },
  {
    value: "Commercial",
    label: t("property_commercial"),
  },
];

// ENQUIRY TYPE
export const enquiry_options = (t) => [
  {
    value: "Studio",
    label: t("enquiry_studio"),
  },
  {
    value: "1 Bedroom",
    label: t("enquiry_1bed"),
  },
  {
    value: "2 Bedrooms",
    label: t("enquiry_2bed"),
  },
  {
    value: "3 Bedrooms",
    label: t("enquiry_3bed"),
  },
  {
    value: "4 Bedrooms",
    label: t("enquiry_4bed"),
  },
  {
    value: "5 Bedrooms",
    label: t("enquiry_5bed"),
  },
  {
    value: "6 Bedrooms",
    label: t("enquiry_6bed"),
  },
  {
    value: "7 Bedrooms",
    label: t("enquiry_7bed"),
  },
  {
    value: "8 Bedrooms",
    label: t("enquiry_8bed"),
  },
  {
    value: "9 Bedrooms",
    label: t("enquiry_9bed"),
  },
  {
    value: "10 Bedrooms",
    label: t("enquiry_10bed"),
  },
  {
    value: "Retail",
    label: t("enquiry_retail"),
  },
  {
    value: "Other",
    label: t("enquiry_others"),
  },
];

// BATHROOM
export const bathroom_options = (t) => [
  {
    value: "1 Bathroom",
    label: t("bathroom_1"),
  },
  {
    value: "2 Bathrooms",
    label: t("bathroom_2"),
  },
  {
    value: "3 Bathrooms",
    label: t("bathroom_3"),
  },
  {
    value: "4 Bathrooms",
    label: t("bathroom_4"),
  },
  {
    value: "5 Bathrooms",
    label: t("bathroom_5"),
  },
  {
    value: "6 Bathrooms",
    label: t("bathroom_6"),
  },
  {
    value: "7 Bathrooms",
    label: t("bathroom_7"),
  },
  {
    value: "8 Bathrooms",
    label: t("bathroom_8"),
  },
  {
    value: "9 Bathrooms",
    label: t("bathroom_9"),
  },
  {
    value: "10 Bathrooms",
    label: t("bathroom_10"),
  },
  {
    value: "Unavailabe",
    label: t("label_unavailable"),
  },
];

// LISTING TYPE
export const listing_options = (t) => [
  {
    value: "Off-plan",
    label: t("category_off_plan"),
  },
  {
    value: "Secondary",
    label: t("menu_secondary"),
  },
  {
    value: "Rent",
    label: t("listing_type_rent"),
  },
  {
    value: "Sell",
    label: t("listing_type_sell"),
  },
  {
    value: "Ready-to-move",
    label: t("listing_type_move"),
  },
];

// PROJECT STATUS
export const project_status_options = (t) => [
  {
    value: "Available",
    label: t("project_available"),
  },
  {
    value: "Sold Out",
    label: t("project_soldout"),
  },
];

// PRIORITY
export const priority_options = (t) => [
  {
    value: "High",
    label: t("priority_high"),
    icon: (
      <TbFlag3Filled size={16} className={`text-${priorityColors.High}-500`} />
    ),
  },
  {
    value: "Medium",
    label: t("priority_medium"),
    icon: (
      <TbFlag3Filled
        size={16}
        className={`text-${priorityColors.Medium}-500`}
      />
    ),
  },
  {
    value: "Low",
    label: t("priority_low"),
    icon: (
      <TbFlag3Filled size={16} className={`text-${priorityColors.Low}-500`} />
    ),
  },
];

// PRIORITY FLAGS
const priorityColors = {
  High: "red",
  Medium: "yellow",
  Low: "gray",
};
// PRIORITY ENDS

// INVENTORY STATUS
export const inventory_status = (t) => [
  {
    value: "Available",
    label: t("inventory_status_avail"),
  },
  {
    value: "Out Of Stock",
    label: t("inventory_status_stock"),
  },
];

// ORDER STATUS
export const order_status = (t) => [
  {
    value: "pending",
    label: t("order_pending"),
    // bgColor: "#b3b27d"
    bgColor: "#bfb202",
    color: "#ffffff",
  },
  {
    value: "preparing",
    label: t("order_status_preparing"),
    bgColor: "#bf6702",
    color: "#ffffff",
  },
  {
    value: "delivered",
    label: t("order_status_delivered"),
    // bgColor: "#75a99c"
    bgColor: "#28855a",
    color: "#ffffff",
  },
  {
    value: "out of stock",
    label: t("order_status_out_of_stock"),
    bgColor: "#852828",
    color: "#ffffff",
  },
  {
    value: "cancelled",
    label: t("order_cancel"),
    bgColor: "#852829",
    color: "#ffffff",
  },
];

// TICKET STATUS
export const ticket_status = (t) => [
  {
    value: "initiated",
    label: t("status_initiated"),
  },
  {
    value: "open",
    label: t("status_open"),
  },
  {
    value: "in process",
    label: t("status_in_process"),
  },
  {
    value: "closed",
    label: t("status_closed"),
  },
  {
    value: "resolved",
    label: t("status_resolved"),
  },
  {
    value: "transferred",
    label: t("status_transferred"),
  },
];

// TICKET SOURCE
export const ticket_source = (t) => [
  {
    value: "Email",
    label: t("support_via_email"),
  },
  {
    value: "Video Call",
    label: t("support_via_video_call"),
  },
  {
    value: "Phone Call",
    label: t("support_via_call"),
  },
  {
    value: "WhatsApp Chat",
    label: t("support_via_whatsapp"),
  },
];

// CURRENCIES
export const currencies = (t) => [
  {
    value: "AED",
    label: t("uae_currency"),
  },
  {
    value: "EGP",
    label: t("egp_currency"),
  },
  {
    value: "USD",
    label: t("usa_currency"),
  },
  {
    value: "PKR",
    label: t("pak_currency"),
  },
  // {
  //   value: "INR",
  //   label: t("india_currency"),
  // },
  // {
  //   value: "NPR",
  //   label: t("nepal_currency"),
  // },
  // {
  //   value: "GBP",
  //   label: t("uk_currency"),
  // },
  // {
  //   value: "JPY",
  //   label: t("japan_currency"),
  // },
  // {
  //   value: "CNY",
  //   label: t("china_currency"),
  // },
  // {
  //   value: "AUD",
  //   label: t("australia_currency"),
  // },
  // {
  //   value: "CAD",
  //   label: t("canada_currency"),
  // },
];

// CLAIM
export const claim = (t) => [
  {
    value: "First claim",
    label: t("claim_1"),
  },
  {
    value: "Second claim",
    label: t("claim_2"),
  },
  {
    value: "Third claim",
    label: t("claim_3"),
  },
  {
    value: "Full claim",
    label: t("full_claim"),
  },
];

// PAYMENT SOURCE
export const payment_source = (t) => [
  {
    value: "Bank transfer",
    label: t("form_account_bank"),
  },
  {
    value: "Cheque",
    label: t("payment_source_cheque"),
  },

  {
    value: "Cash",
    label: t("payment_source_cash"),
  },
];

// TRANSACTION TYPE
export const transaction_type = (t) => [
  {
    value: "Booking",
    label: t("booking"),
  },
  {
    value: "Payment",
    label: t("payment"),
  },
  // {
  //   value: "PDC",
  //   label: t("pdc"),
  // },
  {
    value: "SPA",
    label: t("spa"),
  },
];

// COMMISSION TYPE
export const commission_type = (t, commission) => [
  {
    value: "Income",
    label: commission ? t("income") : t("income_amount"),
  },
  {
    value: "Expense",
    label: commission ? t("expense") : t("expense_amount"),
  },
];

// PAYMENT STATUS
export const payment_status = (t) => [
  {
    value: "Paid",
    label: t("payment_paid"),
  },
  {
    value: "Unpaid",
    label: t("payment_unpaid"),
  },
];

//  INVOICES COUNTRIES
export const countries_list = (t) => [
  {
    value: "UAE",
    label: t("country_uae"),
  },
  {
    value: "Egypt",
    label: t("country_eqypt"),
  },
  {
    value: "Pakistan",
    label: t("country_pakistan"),
  },
];

//  INVOICES CATEGORIES
export const invoice_category = (t) => [
  {
    value: "Purchase",
    label: t("purchase"),
    income: false,
    expense: true,
  },
  {
    value: "Commission",
    label: t("commission"),
    income: true,
    expense: true,
  },
  {
    value: "Salary",
    label: t("label_salary"),
    income: false,
    expense: true,
  },
  {
    value: "Maintenance",
    label: t("maintenance"),
    income: false,
    expense: true,
  },
  {
    value: "Borrow",
    label: t("borrow"),
    income: true,
    expense: true,
  },
  {
    value: "Campaigns",
    label: t("menu_campaigns"),
    income: true,
    expense: true,
  },
  {
    value: "Visa",
    label: t("visa"),
    income: false,
    expense: true,
  },
];

//  VENDOR TYPES
export const vendor_type = (t) => [
  {
    value: "Developer",
    label: t("form_developer_name"),
  },
  {
    value: "Supermarket",
    label: t("vendor_type_market"),
  },
  {
    value: "Agency",
    label: t("vendor_type_agency"),
  },
  {
    value: "Supplier",
    label: t("vendor_type_supplier"),
  },
  {
    value: "Car wash",
    label: t("vendor_type_car_wash"),
  },
  {
    value: "Fuel Station",
    label: t("vendor_type_fuel"),
  },
];

// VENDORS SEARCH FILTERS

export const vendors_search_filter = (t) => [
  { value: "vendor_name", label: t("form_vendor_name") },
  { value: "person_to_contact", label: t("person_to_contact") },
  { value: "email", label: t("label_email") },
  { value: "contact", label: t("label_contact") },
];

// PRONOUNCE
export const title = () => [
  { value: "mr", label: "Mr" },
  { value: "ms", label: "Ms" },
  { value: "mrs", label: "Mrs" },
];

// STATUS
export const listing_status = (t) => [
  { value: 1, label: t("status_active") },
  { value: 0, label: t("status_inactive") },
];

// TASKS STATUS
export const task_status = (t) => [
  {
    value: "New",
    label: t("feedback_new"),
    bgColor: "#BEEDF1",
    color: "#000000",
  },
  {
    value: "Paused",
    label: t("task_status_paused"),
    bgColor: "#FDC68C",
    color: "#000000",
  },
  {
    value: "Cancelled",
    label: t("feedback_cancelled"),
    bgColor: "#F7977A",
    color: "#000000",
  },
  {
    value: "Ongoing",
    label: t("task_status_ongoing"),
    bgColor: "#FFF799",
    color: "#000000",
  },
  {
    value: "Completed",
    label: t("task_status_completed"),
    bgColor: "#81CA9D",
    color: "#000000",
  },
  {
    value: "Daily",
    label: t("tast_type_daily"),
    bgColor: "#FFED9A",
    color: "#000000",
  },
];

// TASK TYPE
export const task_type = (t) => [
  { value: "Deadline Task", label: t("tast_type_deadline") },
  { value: "Follow-up Task", label: t("tast_type_followup") },
  { value: "Urgent Task", label: t("tast_type_urgent") },
  { value: "Daily Task", label: t("tast_type_daily") },
];

// DEPARTMENTS
export const departments = (t) => [
  { value: "IT", label: t("department_it") },
  { value: "Marketing", label: t("menu_marketing") },
  { value: "Accounts", label: t("department_accounts") },
  { value: "Admin", label: t("department_admin") },
  { value: "Sales", label: t("department_sales") },
  { value: "HR", label: t("hr_department") },
  { value: "Others", label: t("enquiry_others") },
  { value: "All", label: t("all") },
];

// DEPARTMENT CATEGORIES
export const categories = (t) => [
  // IT
  { value: "Backend", label: t("category_backend"), link: "IT" },
  { value: "Frontend", label: t("category_frontend"), link: "IT" },
  {
    value: "API Integration",
    label: t("category_api_integration"),
    link: "IT",
  },
  { value: "UI/UX", label: t("category_ui_ux"), link: "IT" },
  { value: "API Testing", label: t("category_api_testing"), link: "IT" },
  { value: "App Testing", label: t("category_app_testing"), link: "IT" },
  { value: "Web Testing", label: t("category_web_testing"), link: "IT" },
  { value: "Android App", label: t("category_android_app"), link: "IT" },
  { value: "iOS App", label: t("category_ios_app"), link: "IT" },
  {
    value: "Cross Platform App",
    label: t("category_cross_platform_app"),
    link: "IT",
  },
  { value: "Research", label: t("category_research"), link: "IT" },
  {
    value: "Requirement Analysis",
    label: t("category_requirement_analysis"),
    link: "IT",
  },
  { value: "Documentation", label: t("category_documentation"), link: "IT" },
  { value: "Report", label: t("category_report"), link: "IT" },
  { value: "Others", label: t("category_others"), link: "IT" },
  // MARKETING
  {
    value: "Content",
    label: t("category_content"),
    link: "Marketing",
  },
  {
    value: "Media Buyer",
    label: t("category_media_buyer"),
    link: "Marketing",
  },
  {
    value: "Video Editing",
    label: t("category_video_editing"),
    link: "Marketing",
  },
  {
    value: "Graphic Design",
    label: t("category_graphic_design"),
    link: "Marketing",
  },
  {
    value: "Motion Graphic",
    label: t("category_motion_graphic"),
    link: "Marketing",
  },
  {
    value: "Social Media",
    label: t("category_social_media"),
    link: "Marketing",
  },
  {
    value: "Campaign",
    label: t("category_campaign"),
    link: "Marketing",
  },
  {
    value: "Photo Editing",
    label: t("category_photo_editing"),
    link: "Marketing",
  },
  {
    value: "Moderate",
    label: t("category_moderate"),
    link: "Marketing",
  },

  { value: "Others", label: t("category_others"), link: "Marketing" },
  // ACCOUNTS
  { value: "Payment", label: t("category_payment"), link: "Accounts" },
  { value: "Others", label: t("category_others"), link: "Accounts" },
  // ADMIN
  { value: "Others", label: t("category_others"), link: "Admin" },
  // HR
  { value: "Hirings", label: t("hiring_category"), link: "HR" },
  { value: "Evaluation", label: t("evaluation_category"), link: "HR" },
  { value: "Reports", label: t("menu_reports"), link: "HR" },
  { value: "Others", label: t("category_others"), link: "HR" },
  // OTHERS
  { value: "Others", label: t("category_others"), link: "Others" },
  // SALES
  { value: "Others", label: t("category_others"), link: "Sales" },
];

// SIZE UNIT
export const size_unit = (t) => [
  { value: "sq.ft", label: t("unit_size_sqft") },
  { value: "sq.m", label: t("unit_size_sqm") },
];

// BUYING TYPE
export const buying_type = (t) => [
  { value: "AUCTION", label: t("buying_type_auction") },
  { value: "RESERVED", label: t("buying_type_reserved") },
];

export const call_to_action = (t) => [
  { label: "No button", value: "" }, // No button means no action
  { label: "See menu", value: "SEE_MENU" },
  { label: "Shop now", value: "SHOP_NOW" },
  { label: "Sign up", value: "SIGN_UP" },
  { label: "Subscribe", value: "SUBSCRIBE" },
  { label: "Watch more", value: "WATCH_MORE" },
  { label: "Send WhatsApp message", value: "WHATSAPP_MESSAGE" },
  { label: "Learn more", value: "LEARN_MORE" },
  { label: "Apply now", value: "APPLY_NOW" },
  { label: "Book now", value: "BOOK_NOW" },
  { label: "Contact us", value: "CONTACT_US" },
  { label: "Download", value: "DOWNLOAD" },
  { label: "Get directions", value: "GET_DIRECTIONS" },
  { label: "Get quote", value: "GET_QUOTE" },
  { label: "Get showtimes", value: "GET_SHOWTIMES" },
  { label: "Listen now", value: "LISTEN_NOW" },
  { label: "Send message", value: "SEND_MESSAGE" },
  { label: "Order now", value: "ORDER_NOW" },
  { label: "Request time", value: "REQUEST_TIME" },
  { label: "Save", value: "SAVE" },
];

// categories => [
//   // IT
//   {
//     value: "Backend",
//     link: "IT" },
//   {
//     value: "Frontend",
//     link: "IT" },
//   {
//     value: "API Integration",
//     link: "IT",
//   },
//   {
//     value: "UI/UX",
//     link: "IT",
//   },
//   {
//     value: "API Testing",
//     link: "IT"
//   },
//   {
//     value: "App Testing",
//     link: "IT"
//   },
//   {
//     value: "Web Testing",
//     link: "IT"
//   },
//   {
//     value: "Android App",
//     link: "IT"
//   },
//   {
//     value: "iOS App",
//     link: "IT"
//   },
//   {
//     value: "Cross Platform App",
//     link: "IT",
//   },
//   {
//     value: "Research",
//     link: "IT"
//   },
//   {
//     value: "Requirement Analysis",
//     link: "IT",
//   },
//   {
//     value: "Documentation",
//     link: "IT"
//   },
//   {
//     value: "Report",
//     link: "IT"
//   },
//   {
//     value: "Others",
//     link: "IT"
//   },
//   // MARKETING
//   {
//     value: "Content",
//     link: "Marketing",
//   },
//   {
//     value: "Media Buyer",
//     link: "Marketing",
//   },
//   {
//     value: "Video Editing",
//     link: "Marketing",
//   },
//   {
//     value: "Graphic Design",
//     link: "Marketing",
//   },
//   {
//     value: "Motion Graphic",
//     link: "Marketing",
//   },
//   {
//     value: "Social Media",
//     link: "Marketing",
//   },
//   {
//     value: "Campaign",
//     link: "Marketing",
//   },
//   {
//     value: "Photo Editing",
//     link: "Marketing",
//   },
//   {
//     value: "Moderate",
//     link: "Marketing",
//   },
//   {
//     value: "Others",
//     link: "Marketing"
//   },
//   // ACCOUNTS
//   {
//     value: "Payment",
//     link: "Accounts"
//   },
//   {
//     value: "Others",
//     link: "Accounts"
//   },
//   // ADMIN
//   {
//     value: "Others",
//     link: "Admin"
//   },
//   // HR
//   {
//     value: "Hirings",
//     link: "HR"
//   },
//   {
//     value: "Evaluation",
//     link: "HR"
//   },
//   {
//     value: "Reports",
//     link: "HR"
//   },
//   {
//     value: "Others",
//     link: "HR"
//   },
//   // OTHERS
//   {
//     value: "Others",
//     link: "Others"
//   },
//   // SALES
//   {
//     value: "Others",
//     link: "Sales"
//   },
// ];
