import React, { useState, useEffect } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { selectStyles } from "../_elements/SelectStyles";
import Select from "react-select";
import axios from "../../axoisConfig";
import {
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
  Backdrop,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
} from "@mui/material";
import DeviceMakes from "./deviceMakes";

const token = localStorage?.getItem("auth-token");

const Devices = ({ adSetDetails, setAdSetDetails }) => {
  const [selectedPlacementType, setSelectedPlacementType] = useState(null);
  const {
    currentMode,
    themeBgImg,
    t,
    primaryColor,
    BACKEND_URL,
    snapchatCredentials,
  } = useStateContext();
  const [isMore, setIsMore] = useState(false);
  const [checked, setChecked] = useState(false);
  const [osTypes, setOsTypes] = useState([]);
  const [age, setAge] = useState({
    minAge: 20,
    maxAge: 55,
  });

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  async function getOsTypes() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/targeting/device/os_type`,
        {
          access_token: snapchatCredentials?.access_token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setOsTypes(
        res?.data?.data?.targeting_dimensions?.map((obj) => ({
          label: obj.os_type?.name,
          value: obj?.os_type?.id,
        }))
      );
    } catch (error) {
      console.log(error, "this is an error");
    }
  }

  useEffect(() => {
    getOsTypes();
  }, []);

  return (
    <div className={``}>
      <h2 className="font-bold text-[16px] mb-5">Devices</h2>
      <div
        className={`${getSummaryBgClass()} rounded-3xl px-8 py-4 shadow-md mb-9`}
      >
        <label htmlFor="" className="text-semibold">
          Operating Systems
        </label>
        {osTypes?.map((os) => {
          // const isDisabled =
          //   adSetDetails?.devices?.find(
          //     (device) =>
          //       device.os_type === os?.label &&
          //       (device.os_type === "iOS" || device.os_type === "ANDROID")
          //   ) &&
          //   adSetDetails?.devices?.filter((device) => device?.os_type !== "WEB")
          //     ?.length == 1;
          return (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adSetDetails?.devices?.find(
                      (device) => device.os_type === os?.label
                    )}
                    // disabled={isDisabled}
                    onChange={(e) => {
                      if (e?.target?.checked) {
                        setAdSetDetails((pre) => ({
                          ...pre,
                          devices: [
                            ...pre.devices,
                            {
                              os_type: os?.label,
                            },
                          ],
                        }));
                      } else {
                        setAdSetDetails((pre) => ({
                          ...pre,
                          devices: pre?.devices?.filter(
                            (device) => device?.os_type !== os?.label
                          ),
                        }));
                      }
                    }}
                  />
                }
                label={os?.label}
              />
            </div>
          );
        })}

        <DeviceMakes
          adSetDetails={adSetDetails}
          setAdSetDetails={setAdSetDetails}
        />

        {/* <label htmlFor="" className="font-semibold mb-3">
          Connection Type
        </label>
        <div className="flex gap-2">
          <button
            className={`p-3 px-5 border-[#F7D901] rounded-[10px] border disabled:cursor-not-allowed font-semibold ${
              currentMode == "dark" ? "bg-black text-white" : "bg-white"
            } `}
          >
            All
          </button>
          <button
            className={`p-3 px-5  rounded-[10px] border disabled:cursor-not-allowed font-semibold ${
              currentMode == "dark" ? "bg-black text-white" : "bg-white"
            } `}
          >
            Cell
          </button>
          <button
            className={`p-3 px-5  rounded-[10px] border disabled:cursor-not-allowed font-semibold ${
              currentMode == "dark" ? "bg-black text-white" : "bg-white"
            } `}
          >
            Wifi
          </button>
        </div> */}
        {/* <div className="flex flex-col py-3">
          <label htmlFor="" className="font-medium ">
            Carriers
          </label>

          <Select
            id=""
            options={[]}
            // value={adSetDetails?.goal}
            // onChange={(e) => {
            //   setAdSetDetails((pre) => ({ ...pre, goal: e }));
            // }}
            isMulti
            placeholder={t("Select Carrier...")}
            menuPortalTarget={document.body}
            styles={{
              ...selectStyles(currentMode, primaryColor),
              control: (provided) => ({
                ...provided,
                ...selectStyles(currentMode, primaryColor).control,
                marginBottom: 0,
                marginTop: "3px",
              }),
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Devices;
