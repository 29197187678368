import React, { useState, useEffect } from "react";
import { Backdrop, Checkbox, CircularProgress, Modal } from "@mui/material";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import { selectStyles } from "../_elements/SelectStyles.jsx";
import axios from "../../axoisConfig.js";
import { CiFolderOn } from "react-icons/ci";
import { GoPlusCircle } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";
import { TiArrowSortedDown } from "react-icons/ti";
import Menu from "@mui/material/Menu";
import { IoIosImages } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { RiPagesLine } from "react-icons/ri";
import { FaMinus } from "react-icons/fa6";
import { IoIosCrop } from "react-icons/io";
import { LiaExchangeAltSolid } from "react-icons/lia";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { width } from "@mui/system";
import { toast } from "react-toastify";
import { call_to_action } from "../_elements/SelectOptions.js";

const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const token = localStorage?.getItem("auth-token");

const imagesSources = [
  {
    label: "Acount Images",
    icon: <IoIosImages size={16} />,
  },
  {
    label: "Instagram Images",
    icon: <FaInstagram size={16} />,
  },
  {
    label: "Pages Images",
    icon: <RiPagesLine size={16} />,
  },
];

const AdCreativeModal = ({
  setAdCreativeModal,
  adCreativeModal,
  adData,
  setAdData,
  fb_token,
  selectedAdType,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,
    fbCredentials,
  } = useStateContext();
  const tabs = [
    {
      name: "Text",
      Component: (
        <Text adData={adData} setAdData={setAdData} fb_token={fb_token} />
      ),
    },
    {
      name: "Add media",
      Component: (
        <AddMedia
          adData={adData}
          setAdData={setAdData}
          fb_token={fb_token}
          selectedAdType={selectedAdType}
        />
      ),
    },
    // {
    //   name: "Crop",
    //   Component: (
    //     <Crop adData={adData} setAdData={setAdData} fb_token={fb_token} />
    //   ),
    // },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <Modal
      //   keepMounted
      open={adCreativeModal}
      onClose={() => setAdCreativeModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[70%] h-[600px] ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2  rounded-xl flex flex-col items-start`}
      >
        <div className="w-full flex flex-1 overflow-y-scroll">
          <div
            className={`w-[20%]  ${
              currentMode !== "dark"
                ? "!bg-gradient-to-br !from-[#F3EEF0] !to-[#E7F0F9]"
                : "!bg-gradient-to-br !from-[#2D2A32] !to-[#1F2937]"
            } h-full rounded-l-xl px-4 pt-4`}
          >
            {" "}
            <label htmlFor="" className="text-[14px] font-semibold mb-3 ">
              Set up your media
            </label>
            <ul className="mt-4">
              {tabs?.map((tab) => {
                return (
                  <li
                    className={` py-3 px-2 cursor-pointer  rounded-lg  ${
                      currentTab?.name === tab?.name
                        ? "text-[#0A78BE] bg-[#E1EDF7]"
                        : " text-black"
                    } flex gap-3 items-center text-[14px] font-semibold`}
                    onClick={() => setCurrentTab(tab)}
                  >
                    <div
                      className={`flex rounded-full w-[20px] h-[20px] border ${
                        currentTab?.name === tab?.name
                          ? "border-[#0A78BE]"
                          : "border-gray-700"
                      } `}
                    >
                      {currentTab?.name === tab?.name && (
                        <>
                          <div className="w-1/2 bg-[#F3EEF0] rounded-l-full"></div>
                          <div className="w-1/2 bg-[#0A78BE] rounded-r-full"></div>
                        </>
                      )}
                    </div>{" "}
                    {tab?.name}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="px-5 pt-5 flex flex-col flex-1 pb-5">
            <div className="flex-1">{currentTab?.Component}</div>

            <div className="flex items-center justify-end gap-3 w-full pt-3 border-t-[2px]">
              <button
                onClick={() => setAdCreativeModal(false)}
                className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
                  currentMode == "dark" ? "bg-dark text-white" : "bg-white"
                } `}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setAdCreativeModal(false);
                  // setIsSingleAdSet(selectedCampaign?.value);
                  //   setIsSingleAdSet(1);
                  //   setAdSetDetails(null);
                }}
                className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
                // disabled={!selectedCampaign}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AdCreativeModal;

const Text = ({ adData, setAdData }) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,
    fbCredentials,
  } = useStateContext();

  const [selectedBtn, setSelectedBtn] = useState(false);
  const [checked, setChecked] = useState(false);
  const [texts, setTexts] = useState({
    headline: adData?.creative?.object_story_spec?.link_data?.headline || null,
    message: adData?.creative?.object_story_spec?.link_data?.message || null,
    description:
      adData?.creative?.object_story_spec?.link_data?.description || null,
  });

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  const handleCallToActionChange = (selectedOption) => {
    setAdData((prevData) => ({
      ...prevData,
      creative: {
        ...prevData?.creative,
        object_story_spec: {
          ...prevData?.creative?.object_story_spec,
          link_data: {
            ...prevData?.creative?.object_story_spec?.link_data,
            call_to_action: selectedOption
              ? { type: selectedOption?.value }
              : null,
          },
        },
      },
    }));
  };

  return (
    <div className="flex flex-col">
      <label htmlFor="" className="text-[14px] font-semibold mb-3">
        Primary text (1 of 5)
      </label>
      <input
        type="text"
        className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
        value={texts?.message}
        placeholder="Tell people what your ad is about"
        onChange={(e) => {
          setTexts({
            ...texts,
            message: e.target.value,
          });
          setAdData((pre) => ({
            ...pre,
            creative: {
              ...pre?.creative,
              object_story_spec: {
                ...pre?.creative?.object_story_spec,
                link_data: {
                  ...pre?.creative?.object_story_spec?.link_data,
                  message: e.target.value,
                },
              },
            },
          }));
        }}
      />
      {/* <button
        // onClick={(event) => setAnchorEl(event.currentTarget)}
        className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
          currentMode == "dark" ? "bg-dark text-white" : "bg-white"
        } `}
      >
        <GoPlusCircle size={16} /> Add text option
      </button> */}

      {/* <label htmlFor="" className="text-[14px] font-semibold mb-3 mt-3">
        Headline
      </label>
      <input
        type="text"
        className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
        value={texts?.headline}
        placeholder="Tell people what your ad is about"
        onChange={(e) => {
          setTexts({
            ...texts,
            headline: e.target.value,
          });
          setAdData((pre) => ({
            ...pre,
            creative: {
              ...pre?.creative,
              object_story_spec: {
                ...pre?.creative?.object_story_spec,
                link_data: {
                  ...pre?.creative?.object_story_spec?.link_data,
                  headline: e.target.value,
                },
              },
            },
          }));
        }}
      /> */}

      <label htmlFor="" className="text-[14px] font-semibold mb-3 mt-3">
        Description
      </label>
      <input
        type="text"
        className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
        value={texts?.description}
        placeholder="Tell people what your ad is about"
        onChange={(e) => {
          setTexts({
            ...texts,
            description: e.target.value,
          });
          setAdData((pre) => ({
            ...pre,
            creative: {
              ...pre?.creative,
              object_story_spec: {
                ...pre?.creative?.object_story_spec,
                link_data: {
                  ...pre?.creative?.object_story_spec?.link_data,
                  description: e.target.value,
                },
              },
            },
          }));
        }}
      />

      <label htmlFor="" className="text-[14px] font-semibold mb-1">
        Call to action
      </label>
      <div className="w-full ">
        <Select
          id="public-Profiles"
          options={call_to_action(t)}
          value={selectedBtn}
          onChange={(e) => {
            setSelectedBtn(e);
            handleCallToActionChange(e);
          }}
          placeholder={"Call to action"}
          className={`mt-1`}
          menuPortalTarget={document.body}
          styles={selectStyles(currentMode, primaryColor)}
        />
      </div>
      {/* <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label="Add a destination"
      />
      <p className="pl-9">
        If you add a destination, you can send people immediately after they've
        tapped or clicked your ad to a website, a full-screen experience or a
        call. If you don't, they'll be sent to your Facebook Page or Instagram
        account.
      </p>
      {checked && (
        <>
          {" "}
          <label htmlFor="" className="text-[14px] font-semibold mb-3">
            Headline
          </label>
          <input
            type="text"
            className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
            placeholder="Write a short headline"
           
          />
          <div className="flex gap-3 items-center my-2 w-full">
            <input
              type="text"
              className={`p-2 flex-1 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
              placeholder="Write a short headline"
              
            />
            <div className="cursor-pointer">
              <IoCloseOutline size={16} />
            </div>
          </div>
          <button
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
              currentMode == "dark" ? "bg-dark text-white" : "bg-white"
            } `}
          >
            <GoPlusCircle size={16} /> Add headline option
          </button>
        </>
      )} */}
    </div>
  );
};
const AddMedia = ({ fb_token, selectedAdType, adData, setAdData }) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,

    fbCredentials,
  } = useStateContext();

  const [imagesSource, setImagesSource] = useState(imagesSources[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  console.log("selected File: ", selectedFile);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  async function uploadImage() {
    if (!selectedFile) {
      toast.error("Please select a file to upload.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setUploading(false);

    try {
      const response = await new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("filename", selectedFile);
        formData.append("access_token", fbCredentials?.access_token);
        window.FB.api(
          `/${fbCredentials?.currentAdAccount}/adimages`,
          "POST",
          formData,
          // {
          //   access_token: fb_token,

          //   file: selectedFile,
          // },
          (response) => {
            if (!response || response?.error) {
              console.error("Error in upload image response:", response.error);
              reject(
                response?.error?.message || new Error("Unknown error occurred")
              );
            } else {
              resolve(response);
            }
          }
        );
      });

      console.log("image uploaded successfully:", response);

      toast.success("Image uploaded successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setUploading(false);
      fetchMedia();
      return response; // Return the ad set details
    } catch (error) {
      setUploading(false);

      console.error("Error creating ad set:", error);

      // Show error toast
      toast.error(
        `Error creating ad set: ${
          error?.error_user_msg ||
          error?.message ||
          error ||
          "Unknown error occurred"
        }`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

      throw error; // Propagate the error for further handling if needed
    }
  }

  // const getAllImages = async () => {
  //   setImageLoading(true);
  //   window.FB.api(
  //     `/${fbCredentials?.currentAdAccount}/adimages`,
  //     "GET",
  //     {
  //       // access_token: fbCredentials?.access_token,
  //       access_token: fb_token,
  //       fields: "id,name,url,permalink_url,width,height",
  //     },
  //     (response) => {
  //       if (!response || response?.error) {
  //         setImageLoading(false);

  //         console.log("error response: ", response);
  //         toast.error(
  //           `${
  //             response.error
  //               ? response?.error?.message
  //               : "Error fetching Images"
  //           }`,
  //           {
  //             position: "top-right",
  //             autoClose: 3000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "light",
  //           }
  //         );
  //       } else {
  //         const imgData = response?.data?.map((imgs) => ({
  //           id: imgs?.id,
  //           name: imgs?.name || "-",
  //           url: imgs?.url || "-",
  //           permalink_url: imgs?.permalink_url || "-",
  //           width: imgs?.width || "-",
  //           height: imgs?.height || "-",
  //         }));
  //         setImages(imgData);
  //         setImageLoading(false);
  //       }
  //     }
  //   );
  // };

  const fetchMedia = async () => {
    setImageLoading(true);

    const isImageAd = selectedAdType?.value?.toLowerCase() === "image_ad";
    const endpoint = isImageAd ? "adimages" : "advideos"; // Choose API endpoint
    const fields = isImageAd
      ? "id,name,url,permalink_url,width,height,hash" // Fields for images
      : "id,title,thumbnails,permalink_url"; // Fields for videos

    window.FB.api(
      `/${fbCredentials?.currentAdAccount}/${endpoint}`,
      "GET",
      {
        access_token: fbCredentials?.access_token,
        // access_token: fb_token,
        fields: fields,
      },
      (response) => {
        if (!response || response?.error) {
          setImageLoading(false);

          console.error("Error response: ", response);
          toast.error(
            `${response?.error?.message || "Error fetching media files"}`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          const mediaData = response?.data?.map((media) => ({
            id: media?.id,
            name: isImageAd ? media?.name || "-" : media?.title || "-",
            url: isImageAd
              ? media?.url || "-"
              : media?.thumbnails?.data?.[0]?.uri || "-",
            permalink_url: media?.permalink_url || "-",
            width: isImageAd ? media?.width || "-" : null,
            height: isImageAd ? media?.height || "-" : null,
            hash: isImageAd ? media?.hash || "-" : null,
          }));

          setImages(mediaData);
          setImageLoading(false);
        }
      }
    );
  };

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  useEffect(() => {
    fetchMedia();
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <label htmlFor="" className="text-[14px] font-semibold mb-3">
          Select Media
        </label>
        {imageLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div className="flex gap-3 w-full items-center">
              {/* <div>
                <label
                  htmlFor="upload-img"
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                    currentMode == "dark" ? "bg-dark text-white" : "bg-white"
                  } `}
                >
                  <GoPlusCircle size={16} /> Upload
                </label>
                <input
                  type="file"
                  name=""
                  className="hidden"
                  id="upload-img"
                  onChange={handleFileChange}
                />
                <button
                  onClick={uploadImage}
                  className={`p-2 mt-2 bg-blue-500 text-white rounded-lg ${
                    uploading
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-blue-600"
                  }`}
                  disabled={uploading}
                >
                  {uploading ? "Uploading..." : "Upload Image"}
                </button>
              </div> */}
              {/* <div
                className={`   p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                  currentMode == "dark" ? "bg-dark text-white" : "bg-white"
                } `}
                onClick={(e) => setAnchorEl(e?.currentTarget)}
              >
                {imagesSource?.icon} {imagesSource?.label} <TiArrowSortedDown />
              </div>
              <input
                type="text"
                className={`p-3 px-5 flex-1 ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                //   value={campData?.name}
                placeholder="Tell people what your ad is about"
               
              /> */}
            </div>

            <div
              className={`grid grid-cols-2 md:grid-cols-4 gap-4 ${
                images?.length > 8 && "overflow-y-auto h-[400px]"
              } select-none`}
            >
              {images?.length > 0 ? (
                images?.map((img) => {
                  return (
                    <div
                      className="w-[100px] h-[150px]  rounded-lg flex flex-col relative cursor-pointer"
                      // onClick={(e) => {
                      //   let param =
                      //     selectedAdType?.value?.toLowerCase() == "image_ad"
                      //       ? "image_url"
                      //       : "video_url";
                      //   setAdData((prevData) => ({
                      //     ...prevData,
                      //     creative: {
                      //       ...prevData?.creative,
                      //       object_story_spec: {
                      //         ...prevData?.creative?.object_story_spec,
                      //         link_data: {
                      //           ...prevData?.creative?.object_story_spec
                      //             ?.link_data,

                      //           ...(selectedAdType?.value?.toLowerCase() ===
                      //           "image_ad"
                      //             ? {
                      //                 // Image ad specific params

                      //                 image_hash: img?.hash,
                      //               }
                      //             : {
                      //                 // Video ad specific params
                      //                 video_data: {
                      //                   video_id: img?.id,
                      //                 },
                      //               }),
                      //         },
                      //       },
                      //     },
                      //   }));
                      // }}
                      onClick={(e) => {
                        setAdData((prevData) => ({
                          ...prevData,
                          creative: {
                            ...prevData?.creative,
                            object_story_spec: {
                              ...prevData?.creative?.object_story_spec,
                              ...(selectedAdType?.value?.toLowerCase() ===
                              "image_ad"
                                ? {
                                    // Image ad specific params
                                    link_data: {
                                      ...prevData?.creative?.object_story_spec
                                        ?.link_data,
                                      image_hash: img?.hash, // Set image hash for image ads
                                    },
                                  }
                                : {
                                    // Video ad specific params
                                    video_data: {
                                      video_id: img?.id, // Set video ID for video ads
                                    },
                                  }),
                            },
                          },
                        }));
                      }}
                    >
                      <div className="w-full h-[60%] relative">
                        {/* <img
                          src="/assets/female_character.png"
                          className="w-full object-contain h-full border-[2px] rounded-lg border-transparent hover:border-[#0A78BE] "
                          alt=""
                        /> */}
                        <img
                          src={img?.url}
                          className="w-full object-contain h-full border-[2px] rounded-lg border-transparent hover:border-[#0A78BE] "
                          alt={img?.name || "Image"}
                        />
                        {/* <span className="p-[2px] text-white rounded-full bg-red-600 absolute bottom-2 right-2">
                          <FaMinus size={14} />
                        </span> */}
                      </div>

                      <div className="overflow-y-hidden truncate text-ellipsis break-words w-full">
                        {img?.name}
                      </div>
                      {selectedAdType?.value?.toLowerCase() == "image_ad" && (
                        <span>{`${img?.width} "x" ${img?.height}`}</span>
                      )}
                    </div>
                  );
                })
              ) : (
                <h1>
                  {selectedAdType?.value?.toLowerCase() == "image_ad"
                    ? "No Images Found."
                    : "No Videos Found."}
                </h1>
              )}
            </div>
          </>
        )}
      </div>
      {/* <ImageSources anchorEl={anchorEl} setAnchorEl={setAnchorEl} /> */}
    </>
  );
};
const Crop = () => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,
    fbCredentials,
  } = useStateContext();

  const [checked, setChecked] = useState(false);

  const [imagesSource, setImagesSource] = useState(imagesSources[0]);
  const [anchorEl, setAnchorEl] = useState(null);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const ImageCards = [
    {
      label: "Square",
      height: "100px",
      width: "100px",
      options: [
        {
          label: "Original",
          value: "original",
        },
        {
          label: "1:1",
          value: "1:1",
        },
      ],
    },
    {
      label: "Vertical",
      height: "300px",
      width: "100px",
      options: [
        {
          label: "Original",
          value: "original",
        },
        {
          label: "9:16",
          value: "9:16",
        },
      ],
    },
    {
      label: "Horizontal",
      height: "200px",
      width: "100px",
      options: [
        {
          label: "Original",
          value: "original",
        },
        {
          label: "1.91:1",
          value: "1:1",
        },
      ],
    },
  ];
  return (
    <>
      <div className="flex flex-col">
        <label htmlFor="" className="text-[14px] font-semibold mb-3">
          Select media crop for placements
        </label>
        <p>
          We evaluated your image and cropped it to fill the placement when we
          think it may perform better. You can edit these crops or upload new
          media for each placement by hovering over the image.
        </p>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleChange} />}
          label="Enable flexible media to deliver the media that you select below to additional placements when it's likely to improve performance"
        />
        <div className="flex gap-3 w-full items-start overflow-y-auto h-full">
          {ImageCards?.map((card) => {
            return <ImageCard card={card} />;
          })}
        </div>
      </div>
    </>
  );
};

function ImageCard({ card }) {
  const { height, width, options, label } = card;
  const [value, setValue] = React.useState("option1");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div className="border rounded-lg px-3 py-3 flex flex-col w-full ">
      <label htmlFor="" className="text-[14px] font-semibold mb-3">
        {label}
      </label>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          Select an Option
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {options?.map((option) => {
            return (
              <FormControlLabel
                value={option?.value}
                control={<Radio />}
                label={option?.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <div className="w-full flex flex-col h-fit"></div>
      <div className="w-full bg-gray-500 " style={{ height: height }}></div>
      {/* <div style={{ height: height, width: width, backgroundColor: "gray" }}>
        <img src="/assets/female_character.png" className="w-full" alt="" />
      </div> */}
      <div className="w-full flex gap-3">
        <button className="bg-[#F2F2F2] p-2 rounded-lg flex items-center gap-2">
          <IoIosCrop /> Crop
        </button>
        <button className="bg-[#F2F2F2] p-2 rounded-lg flex items-center gap-2">
          <LiaExchangeAltSolid />
          Replace
        </button>
      </div>
    </div>
  );
}
function ImageSources({ anchorEl, setAnchorEl }) {
  const open = Boolean(anchorEl);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    setSnapchatCredentials,
    fbCredentials,
  } = useStateContext();

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? "bg-dark text-white"
      : "bg-white text-black";
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log("This is called", anchorEl);

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": {
            padding: "0px !important", // Modify border radius
          },
          padding: "0px",
          zIndex: 9999999,
        }}
      >
        <div className={` ${getSummaryBgClass()} py-4`}>
          <div className="">
            <ul className="px-1">
              {imagesSources?.map((source) => {
                return (
                  <li
                    className={` flex items-center gap-2 w-full hover:bg-gray-200 py-3 ${
                      currentMode === "dark" ? "hover:text-black" : ""
                    } px-2 rounded-lg cursor-pointer flex  gap-4`}
                  >
                    {source?.icon} <span>{source?.label}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Menu>
    </div>
  );
}
