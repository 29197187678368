import React, { useState, useEffect } from "react";
import { Backdrop, Modal } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import Menu from "@mui/material/Menu";
import { IoSearchOutline } from "react-icons/io5";
import { TiArrowSortedDown } from "react-icons/ti";
import { IoCloseOutline } from "react-icons/io5";

const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const token = localStorage?.getItem("auth-token");

const BuildUrlParams = ({ urlParams, setUrlParams }) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
  } = useStateContext();
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState();

  const fields = [
    {
      label: "Campaign source",
      shortLabel:
        "To identify the source of traffic, e.g. Facebook, Instagram, a search engine or another source.",
    },
    {
      label: "Campaign medium",
      shortLabel: `To identify the advertising medium. For example, "banner", "email", "Facebook_Feed" or "Instagram_Story".`,
    },
    {
      label: "Campaign name",
      shortLabel: `To identify a specific promotion or strategic campaign. For example, "summer_sale".`,
    },
    {
      label: "Campaign content",
      shortLabel: `To differentiate ads or links that point to the same URL. For example, "white_logo", "black_logo".`,
    },
  ];

  const options = [
    {
      label: "{{campaign.id}}",
      value: "{{campaign.id}}",
    },
    {
      label: "{{adset.id}}",
      value: "{{adset.id}}",
    },
    {
      label: "{{ad.id}}",
      value: "{{ad.id}}",
    },
    {
      label: "{{campaign.name}}",
      value: "{{campaign.name}}",
    },
    {
      label: "{{adset.name}}",
      value: "{{adset.name}}",
    },
    {
      label: "{{ad.name}}",
      value: "{{ad.name}}",
    },
    {
      label: "{{placement}}",
      value: "{{placement}}",
    },
    {
      label: "{{site_source_name}}",
      value: "{{site_source_name}}",
    },
  ];
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? "bg-dark text-white"
      : "bg-white text-black";
  };

  return (
    <Modal
      //   keepMounted
      open={urlParams}
      onClose={() => setUrlParams(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1900,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[50%]  ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2 p-5 rounded-md flex flex-col items-start`}
      >
        <div className="mb-4">
          <h3 className="font-semibold text-[16px]">Build a URL parameter</h3>
        </div>
        <p>
          Fill in the fields in the form below to add parameters to your website
          URL. To automatically get information from your campaign, ad set or
          ad, click on each field and select a dynamic parameter, such as
          ID=&#123;&#123;ad.id&#125;&#125;
        </p>
        <div className="py-4">
          {fields?.map((field) => {
            return (
              <div className="flex gap-2 justify-between">
                <label htmlFor="" className="text-[14px] font-medium">
                  {field?.label}
                </label>
                <div className="w-[73%]">
                  <div>
                    <UrlField
                      options={options}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                    />
                  </div>
                  <p className=" text-gray-500">{field?.shortLabel}</p>
                </div>
              </div>
            );
          })}
        </div>
        <label htmlFor="" className="text-[14px] font-medium">
          Custom paramters
        </label>
        <div className="w-full py-4">
          <div className="grid grid-cols-2">
            <label className="text-[14px] font-medium">Paramter Name</label>
            <label className="text-[14px] font-medium">Paramter Value</label>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <input
              type="text"
              className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
            />
            {/* <div>
              <input
                type="text"
                className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
              />
            </div> */}
            <div className="flex gap-3 items-center w-full">
              <input
                type="text"
                className={`p-2 flex-1 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                //   value={campData?.name}
                placeholder=""
                //   onChange={(e) =>
                //     setCampData((pre) => ({
                //       ...pre,
                //       name: e?.target?.value,
                //     }))
                //   }
              />
              <div className="cursor-pointer">
                <IoCloseOutline size={16} />
              </div>
            </div>
          </div>
        </div>
        <button
          // onClick={(event) => setAnchorEl(event.currentTarget)}
          className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3   ${
            currentMode == "dark" ? "bg-dark text-white" : "bg-white"
          } `}
        >
          Add Parameter
        </button>
        <div className={`flex flex-col  ${getSummaryBgClass()} `}>
          <label className="text-[14px] font-medium">Paramter Value</label>
          <input
            type="text"
            className={`p-2 flex-1 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
            //   value={campData?.name}
            placeholder=""
            //   onChange={(e) =>
            //     setCampData((pre) => ({
            //       ...pre,
            //       name: e?.target?.value,
            //     }))
            //   }
            disabled={true}
          />
        </div>
        <div className="flex items-center justify-end gap-3 w-full pt-3 border-t-[2px]">
          <button
            onClick={() => setUrlParams(false)}
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
              currentMode == "dark" ? "bg-dark text-white" : "bg-white"
            } `}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setUrlParams(false);
            }}
            className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
            // disabled={!selectedCampaign}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BuildUrlParams;

function UrlField({
  options,
  selectedOption,
  setSelectedOption,
  placeholder,
  label,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    setSnapchatCredentials,
  } = useStateContext();

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? "bg-dark text-white"
      : "bg-white text-black";
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="w-full">
      <label htmlFor="" className="text-[12px] font-semibold mb-4">
        {label}
      </label>
      <div
        className="flex mt-2 items-center border px-2 py-1 w-full border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px]"
        onClick={(e) => setAnchorEl(e?.currentTarget)}
      >
        <input
          className={`p-2  ${getSummaryBgClass()} rounded-lg flex-1 focus:outline-none outline-none !outline-offset-0  `}
          value={selectedOption?.label || ""}
          onChange={(e) => setSelectedOption(e?.target?.value)}
        />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { width: anchorEl && anchorEl.offsetWidth }, // <-- The line that does all
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": {
            padding: "0px !important", // Modify border radius
          },
          padding: "0px",
          zIndex: 9999, // Remove any max width that might be applied by default
        }}
      >
        <div className={`${getSummaryBgClass()} py-4`}>
          <ul className="px-3">
            {options?.map((item) => {
              return (
                <li
                  className={`flex gap-4 items-center mb-4 group cursor-pointer px-2 py-2 rounded-lg select-none ${
                    selectedOption?.label === item?.label ? "bg-[#C3DCF5]" : ""
                  }`}
                  onClick={() => setSelectedOption(item)}
                >
                  <div className="flex-1">
                    <h3
                      className={`text-[14px] ${
                        currentMode === "dark" ? "text-white" : "text-black"
                      }`}
                    >
                      {item?.label}
                    </h3>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Menu>
    </div>
  );
}
