import {
  Backdrop,
  Button,
  CircularProgress,
  Modal,
  Slider,
  styled,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import MoodEmojiUI from "./MoodEmojiUI";
import NoiseBackground from "./NoiseBackground";
import modalLogo from "../../assets/modalLogo.png";
import { RxCross1 } from "react-icons/rx";
import axios from "../../axoisConfig";

import ModalBackground from "./ModalBackground";
import BackgroundWithBlurBalls from "./blurBackground";
import { toast } from "react-toastify";
import moment from "moment";
const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const DailyGoalsPopup = ({ goalPopup, setGoalPopup }) => {
  const { currentMode, User, isMobile, BACKEND_URL } = useStateContext();
  const token = localStorage.getItem("auth-token");
  //   const [goalPopup, setGoalPopup] = useState(true);
  console.log("USer: ", User);
  const [btnLoading, setBtnLoading] = useState(false);

  const modalContentRef = useRef(null);

  const [goalsData, setGoalData] = useState({
    manager_id: User?.isParent,
    agent_id: User?.id,
    mood: "",
    calls: "",
    meetings: "",
    date: moment().format("DD-MM-YYYY"),
  });

  console.log("goalsData: ", goalsData);

  const AddTask = () => {
    setBtnLoading(true);

    let url = `${BACKEND_URL}/agent-daily-goal`;

    axios
      .post(url, goalsData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log(" Added: ", result);

        if (result?.data?.status === false) {
          toast.error(result?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setBtnLoading(false);
          return;
        }

        toast.success(`Goal Set Successfully.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setGoalPopup(false);
        setGoalData({
          manager_id: "",
          agent_id: "",
          mood: "",
          calls: "",
          meetings: "",
          date: "",
        });
        setBtnLoading(false);
      })

      .catch((err) => {
        setBtnLoading(false);

        console.log(err);

        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          return;
        }

        toast.error("Something Went Wrong! Please Try Again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <Modal
      keepMounted
      open={goalPopup}
      // onClose={() => setGoalPopup(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <>
        {/* <ModalBackground> */}
        <div
          ref={modalContentRef}
          style={style}
          // className={`w-[calc(100%-20px)] md:w-[60%]  ${
          //   currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          // } relative top-1/2 left-1/2 p-5  rounded-md `}
          className={`w-[calc(100%-20px)] md:w-[60%] relative  top-1/2 left-1/2   rounded-md   inset-0  `}
        >
          <BackgroundWithBlurBalls>
            <div className="p-5 bg-[#0a1b15a8] ">
              {/* Decorative Circles */}
              {/* <div className="w-40 h-40 rounded-full bg-gray-200 opacity-5 absolute top-20 left-10"></div>
              <div className="w-[300px] h-[300px] rounded-full bg-white opacity-5 absolute top-32 right-8"></div> */}
              {/* <NoiseBackground containerRef={modalContentRef} /> */}
              <div className="absolute  top-2 md:top-0">
                <img
                  src={modalLogo}
                  alt="Do deals logo"
                  width={isMobile ? 50 : 100}
                  height={isMobile ? 50 : 100}
                />
              </div>
              <div className="absolute right-7">
                <RxCross1
                  size={28}
                  color={"#ffffff"}
                  className="p-1.5 rounded-full border border-gray-500 text-gray-500 z-50 cursor-pointer"
                  onClick={() => setGoalPopup(false)}
                />
              </div>
              <div className="flex flex-col items-center justify-center mt-7">
                <h1
                  className={`font-bold pt-3 text-2xl text-center  text-white
              `}
                >
                  Good Morning,{User?.userName}!
                </h1>
                <p className={`font-thin pt-3 text-sm text-center  text-white`}>
                  Small steps leads to big wins
                </p>

                <h1
                  className={`font-extrabold pt-3 text-xl md:text-4xl text-center text-white capitalize mt-12`}
                >
                  Set Your Goals for Today!
                </h1>

                <p
                  className={`font-normal pt-3 text-base text-center  text-white capitalize mt-8`}
                >
                  How are you feeling this morning?
                </p>
                <MoodEmojiUI goalsData={goalsData} setGoalData={setGoalData} />
              </div>
              <div className="flex flex-col md:flex-row  items-center justify-between  my-8 w-full px-8">
                <div className="flex flex-col items-start justify-start sm:justify-center sm:items-center mb-5 md:mb-0 ">
                  <p
                    className={`font-semibold pt-3 text-base text-center  text-white capitalize mb-4`}
                  >
                    What's your call goals for today?
                  </p>
                  <PrettoSlider
                    valueLabelDisplay="auto"
                    aria-label="pretto slider"
                    defaultValue={0}
                    value={goalsData?.calls}
                    onChange={(e) =>
                      setGoalData({
                        ...goalsData,
                        calls: String(e.target.value),
                      })
                    }
                  />{" "}
                  <p
                    className={`font-semibold pt-3 text-base text-center  text-white capitalize mt-4`}
                  >
                    {goalsData?.calls || 0}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-start">
                  <p
                    className={`font-semibold pt-3 text-base text-center md:text-start text-white capitalize mb-4`}
                  >
                    How many meetings will you aim to secure?
                  </p>

                  <PrettoSlider
                    valueLabelDisplay="auto"
                    aria-label="pretto slider"
                    defaultValue={0}
                    onChange={(e) =>
                      setGoalData({
                        ...goalsData,
                        meetings: String(e.target.value),
                      })
                    }
                  />
                  <p
                    className={`font-semibold pt-3 text-base text-center md:text-start text-white capitalize mt-4`}
                  >
                    {goalsData?.meetings || 0}
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center ">
                <button
                  className={`text-white text-center rounded-full  font-semibold disabled:opacity-70 disabled:cursor-not-allowed  hover:shadow-lg shadow-none px-11 py-4 bg-[#526860] `}
                  ripple="true"
                  size="large"
                  style={{
                    color: "white",
                    // backgroundColor: "rgba(0,175,107,0.2)",
                    //   borderRadius: "50px",
                  }}
                  onClick={AddTask}
                >
                  {btnLoading ? <CircularProgress /> : <span>Submit</span>}
                </button>
              </div>
            </div>
          </BackgroundWithBlurBalls>
        </div>
        {/* </ModalBackground> */}
      </>
    </Modal>
  );
};

export default DailyGoalsPopup;
