import React, { useContext, useEffect, useState, useRef } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MdStars, MdCurrencyExchange } from "react-icons/md";
import { Tooltip, Button, Badge } from "@mui/material";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";

import { useStateContext } from "../../context/ContextProvider";
import { ColorModeContext } from "../../context/theme";
import axios from "../../axoisConfig";
import BreadCrumb from "./BreadCrumb";
import UpcomingMeetingsMenu from "./UpcomingMeetingsMenu";
import Clock from "./Clock";
import NotificationsMenuUpdated from "./NotificationsMenuUpdated";
import { GrSecure } from "react-icons/gr";
import Switch from "@mui/material/Switch";

import { AiOutlineMenu } from "react-icons/ai";
import {
  BsCalendarEvent,
  BsCalendarEventFill,
  BsBell,
  BsClock,
  BsClockFill,
  BsApple,
  BsAndroid2,
} from "react-icons/bs";
import { GoCommentDiscussion } from "react-icons/go";
import {
  MdDarkMode,
  MdKeyboardArrowDown,
  MdOutlineLightMode,
} from "react-icons/md";
import {
  VscHistory,
  VscLock,
  VscShield,
  VscExclude,
  VscSignOut,
} from "react-icons/vsc";
import "../../styles/animation.css";
import { RxCross1 } from "react-icons/rx";

import ColorSchemeMenuItem from "./ColorSchemeMenuItem";
import SearchLeads from "./SearchLeads";
import CurrencyConvertor from "./CurrencyConvertor";
import CustomMenu from "./CustomMenu";
import Languages from "./Languages";
import ProfileDropdown from "./ProfileDropdown";
import ChatbotModal from "../Chatbot";
import { TbMessageChatbot } from "react-icons/tb";

const NavButton = ({
  title,
  customFunc,
  icon,
  color,
  handleClose,
  dotColor,
}) => (
  // <Tooltip title={title} arrow placement="bottom">
  <button
    type="button"
    onMouseEnter={customFunc}
    // onMouseLeave={handleClose}
    style={{ color }}
    className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    aria-label={title}
  >
    <span
      style={{ background: dotColor }}
      className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
    />
    {icon}
  </button>
  // </Tooltip>
);

const changeBodyDirection = (newDirection) => {
  document.body.style.direction = newDirection;
};

const changeBodyFont = (newFont) => {
  document.body.style.fontFamily = newFont;
};

const Navbar = () => {
  const token = localStorage.getItem("auth-token");

  const {
    currentMode,
    setCurrentMode,
    User,
    BACKEND_URL,
    isCollapsed,
    allRoutes,
    primaryColor,
    setIsCollapsed,
    themeBgImg,
    t,
    langs,
    isLangRTL,
    getLangDetails,
    darkModeColors,
    deviceType,
  } = useStateContext();
  const colorMode = useContext(ColorModeContext);
  const { collapseSidebar } = useProSidebar();
  const {
    activeMenu,
    setActiveMenu,
    setScreenSize,
    screenSize,
    setopenBackDrop,
    isUserSubscribed,
    unreadNotifsCount,
    notifIconAnimating,
    i18n,
  } = useStateContext();
  const closingTimeoutId = useRef(null);
  const [currNavBtn, setCurrNavBtn] = useState("");
  const [anchorElem, setAnchorElem] = useState("");

  const [langTitle, setLangTitle] = useState("");
  const [langFlag, setLangFlag] = useState("");

  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(true);
  const [isTwoFA, setIsTwoFA] = useState(
    User?.is_2FA_Verified == 0 || !User?.is_2FA_Verified ? false : true
  );
  const [currencyModal, setCurrencyModal] = useState(false);
  const [isChatboxActive, setIsChatboxActive] = useState(false);
  const navigate = useNavigate();

  console.log(User, "user");

  useEffect(() => {
    setIsTwoFA(
      User?.is_2FA_Verified == "0" || !User?.is_2FA_Verified ? false : true
    );
  }, [User]);

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.closest(".navbar-menu-backdrop")) {
        handleClose();
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const updateTwoFAStatus = async (isVerified) => {
    try {
      await axios.post(
        `${BACKEND_URL}/is_2FA_Verified`,
        JSON.stringify({
          is_2FA_Verified: isVerified ? 1 : 0,
          userEmail: User.userEmail,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      toast.success("Two FA Updated Successfull", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log("Background image updated in database successfully");
    } catch (error) {
      toast.error("Can't updated Two FA", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error updating background image in database:", error);
    }
  };

  const handleClick = (event, navBtn) => {
    setCurrNavBtn(navBtn);
    setOpen(true);
    setAnchorElem(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorElem(null);
    setCurrNavBtn("");
  };

  const LogoutUser = async () => {
    localStorage.removeItem("auth-token");
    localStorage.removeItem("user");
    localStorage.removeItem("leadsData");
    localStorage.removeItem("fb_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in");
    // localStorage.removeItem("hasVisited");

    try {
      const logout = await axios.post(`${BACKEND_URL}/logout`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("logout: ", logout);
      window.open("/", "_self");
    } catch (error) {
      console.log("error: ", error);
      toast.error("Unable to logout", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const UnsubscribeUser = async () => {
    try {
      await axios.post(
        `${BACKEND_URL}/cancel`,
        JSON.stringify({
          package_name: "unsubscribed",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      toast.success("User Unsubscribed Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      closingTimeoutId.current = setTimeout(() => {
        localStorage.removeItem("user");
        window.location.href = "/dashboard";
      }, 2000);
    } catch (error) {
      console.log(error);
      toast.error("Sorry, something went wrong", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    setopenBackDrop(false);
    setloading(false);
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      clearTimeout(closingTimeoutId.current);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
    // eslint-disable-next-line
  }, [screenSize]);

  // eslint-disable-next-line
  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  const changeMode = async () => {
    handleClose();
    colorMode.toggleColorMode();

    let newMode;
    const bgColor = currentMode === "dark" ? "#EEEEEE" : "#282B30";
    const token = localStorage.getItem("auth-token");

    if (currentMode === "dark") {
      newMode = "light";
    } else {
      newMode = "dark";
    }
    setCurrentMode(newMode);
    localStorage.setItem("currentMode", newMode);

    document.body.style.backgroundImage = "none";
    document.body.style.backgroundColor = bgColor;

    try {
      await axios.post(
        `${BACKEND_URL}/updateuser/${User.id}`,
        JSON.stringify({
          backgroundImg: "default",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("Background image updated in database successfully");
    } catch (error) {
      console.error("Error updating background image in database:", error);
    }
  };

  const openChat = () => {
    navigate("/chat");
  };

  useEffect(() => {
    const langDetails = getLangDetails(i18n.language);
    if (langDetails) {
      const { title, flag } = langDetails;
      setLangTitle(title);
      setLangFlag(flag);
      // console.log(`Language Title: ${title}`);
      // console.log(`Language Flag URL: ${flag}`);
    }
  }, [getLangDetails]);

  const saveLangInProfile = async (code) => {
    console.log(code, "language code");
    const token = localStorage.getItem("auth-token");
    try {
      await axios.post(
        `${BACKEND_URL}/updateuser/${User.id}`,
        JSON.stringify({
          language: code,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // DIPSLAY NAME
  const getDisplayName = (userName) => {
    if (!userName) return "";

    const words = userName.split(" ");
    if (words.length === 0) return "";

    if (words[0].length >= 4) {
      return words[0];
    } else if (words.length > 1) {
      return `${words[0]} ${words[1]}`;
    }
    return words[0];
  };

  return (
    <>
      {/* CHAT  */}
      <div
        className="fixed"
        style={{
          bottom: "20px",
          right: !isLangRTL(i18n.language) && "20px",
          left: isLangRTL(i18n.language) && "20px",
          zIndex: 999,
        }}
      >
        <Tooltip title="Chat">
          <button
            onClick={openChat}
            className="cursor-pointer bg-primary hover:bg-[#AAAAAA] hover:border-[#AAAAAA] text-white border-2 p-3 rounded-full"
          >
            <GoCommentDiscussion size={24} color={"#FFFFFF"} />
          </button>
        </Tooltip>
      </div>

      {/* AI CHATBOT */}
      <div className="chatbox">
        {/* Button to toggle the Chatbox */}
        <div
          className="fixed"
          style={{
            bottom: "20px",
            right: !isLangRTL(i18n.language) && "80px",
            left: isLangRTL(i18n.language) && "80px",
            zIndex: 999,
          }}
        >
          <Tooltip title="Ai ChatBot">
            <button
              onClick={() => setIsChatboxActive(!isChatboxActive)}
              className="cursor-pointer bg-primary hover:bg-[#AAAAAA] hover:border-[#AAAAAA] text-white border-2 p-3 rounded-full"
            >
              {isChatboxActive ? (
                <RxCross1 size={24} color={"#FFFFFF"} />
              ) : (
                <TbMessageChatbot size={24} color={"#FFFFFF"} />
              )}
            </button>
          </Tooltip>
        </div>

        {/* Chatbox Modal */}
        <div
          className={`bg-[#f9f9f9] w-[300px] rounded-tl-[20px] rounded-tr-[20px]  ${
            isChatboxActive ? "chatbox--active" : ""
          }`}
          style={{
            position: "fixed", // Ensure the modal is fixed to the viewport
            bottom: "100px", // Adjust position above the button
            right: !isLangRTL(i18n.language) && "20px",
            left: isLangRTL(i18n.language) && "20px",
            zIndex: 1000, // Ensure modal is on top of the button
          }}
        >
          {isChatboxActive && (
            <ChatbotModal
              isChatboxActive={isChatboxActive}
              setIsChatboxActive={setIsChatboxActive}
            />
          )}
        </div>
      </div>

      {(deviceType === "mobile" || deviceType === "tablet") && (
        <div
          className={` ${
            themeBgImg
              ? currentMode === "dark"
                ? "blur-bg-dark-nr"
                : "blur-bg-light-nr"
              : currentMode === "dark"
              ? "bg-dark-neu"
              : "bg-light-neu"
          } flex justify-end items-center p-2 relative w-full`}
          style={{
            position: "fixed",
            top: 0,
            zIndex: "20",
            boxShadow:
              currentMode !== "dark"
                ? "0 2px 4px rgba(0, 0, 0, 0.1)"
                : "0 2px 4px rgba(255, 255, 255, 0.1)",
          }}
        >
          <div className="flex items-center">
            {/* UPGRADE  */}
            {isUserSubscribed !== null && [
              isUserSubscribed === false ? (
                <Button
                  variant="contained"
                  className="bg-btn-primary"
                  sx={{
                    mx: 2,
                    "& svg": {
                      color: "white",
                    },
                  }}
                >
                  <Link
                    to="/marketing/payments"
                    className="flex gap-2 items-center"
                  >
                    <MdStars size={18} />
                    <span className="mt-[2px]">{t("upgrade")}</span>
                  </Link>
                </Button>
              ) : (
                <></>
              ),
            ]}
            {/* Currency Convertor */}

            <Tooltip title={"Currency Convertor"} arrow placement="bottom">
              <button
                type="button"
                onClick={() => setCurrencyModal(true)}
                className={`relative text-xl rounded-full p-3 hover:bg-light-gray ${
                  currentMode == "dark" ? "text-white" : "text-black"
                }`}
                aria-label={"Currency Convertor"}
              >
                <MdCurrencyExchange size={16} />
              </button>
            </Tooltip>

            {/* MEETINGS  */}
            <NavButton
              title="Meetings"
              handleClose={handleClose}
              dotColor={currentMode === "dark" ? "#ffffff" : primaryColor}
              customFunc={(event) => handleClick(event, "Meetings")}
              color={currentMode === "dark" ? "#ffffff" : "#333333"}
              icon={
                currNavBtn === "Meetings" ? (
                  <BsCalendarEventFill size={16} />
                ) : (
                  <BsCalendarEvent size={16} />
                )
              }
            />
            {/* NOTIFICATIONS  */}
            <NavButton
              handleClose={handleClose}
              title="Notification"
              // dotColor={currentMode === "dark" ? "#ffffff" : "#DA1F26"}
              customFunc={(event) => handleClick(event, "Notifications")}
              color={currentMode === "dark" ? "#ffffff" : "#333333"}
              aria-label="Notifications list"
              icon={
                <Badge
                  className={notifIconAnimating ? "animate-notif-icon" : ""}
                  badgeContent={unreadNotifsCount}
                  sx={{
                    "& .MuiBadge-badge": {
                      background: primaryColor,
                      color: "white",
                    },
                  }}
                >
                  <BsBell size={16} />
                </Badge>
              }
            />
            {/* CLOCK  */}
            <NavButton
              handleClose={handleClose}
              title="Clock"
              // color={currentMode === "dark" ? "#ffffff" : LightIconsColor}
              customFunc={(event) => handleClick(event, "Clock")}
              color={currentMode === "dark" ? "#ffffff" : "#333333"}
              icon={
                currNavBtn === "Clock" ? (
                  <BsClockFill size={16} />
                ) : (
                  <BsClock size={16} />
                )
              }
            />
            {/* THEME  */}
            {/* {!themeBgImg && */}
            <Tooltip
              title={currentMode === "dark" ? "Light mode" : "Dark mode"}
              arrow
              placement="bottom"
            >
              <button
                type="button"
                onClick={changeMode}
                className={`relative text-xl rounded-full p-3 hover:bg-light-gray ${
                  currentMode === "dark" ? "text-white" : " text-primary"
                }`}
              >
                {currentMode === "dark" ? (
                  <MdOutlineLightMode size={16} color="#dcb511" />
                ) : (
                  <MdDarkMode size={16} color={primaryColor} />
                )}
              </button>
            </Tooltip>
            {/* } */}
            {/* PROFILE  */}
            <Tooltip title="Profile" arrow placement="bottom">
              <div
                className={`bg-primary text-white mx-1 p-2 flex items-center gap-2 cursor-pointer hover:bg-light-gray rounded-xl shadow-sm card-hover`}
                onClick={(event) => {
                  if (currNavBtn === "Profile") {
                    handleClose();
                  } else {
                    handleClick(event, "Profile");
                  }
                }}
              >
                <img
                  height={40}
                  width={40}
                  className="rounded-full w-8 h-8 object-cover"
                  src={User?.displayImg ? User?.displayImg : "/assets/user.png"}
                  alt="user-profile"
                />
                {deviceType === "tablet" && (
                  <p className="display-block">
                    <span className={`font-bold`}>
                      {getDisplayName(User?.userName)}
                    </span>
                  </p>
                )}
                <MdKeyboardArrowDown size={14} className={``} />
              </div>
            </Tooltip>
            {/* LANG  */}
            <Tooltip title="Language" arrow placement="bottom">
              <div
                className="mx-2 flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                onClick={(event) => {
                  if (currNavBtn === "Language") {
                    handleClose();
                  } else {
                    handleClick(event, "Language");
                  }
                }}
                role="button"
              >
                <img
                  className="rounded-sm h-6 w-8 border"
                  src={langFlag}
                  alt={i18n?.language}
                />
                {/* <MdKeyboardArrowDown
                className={`${
                  currentMode === "dark" ? "text-white" : "text-black"
                }`}
              /> */}
              </div>
            </Tooltip>
            <Menu
              className="hide-scrollbar navbar-menu-backdrop"
              hideBackdrop={false}
              onClick={handleClose}
              onMouseLeave={handleClose}
              anchorEl={anchorElem}
              open={open}
              PaperProps={{
                elevation: 0,
                sx: {
                  "& .MuiList-root .clock-div": {
                    border: "none !important",
                    background: "transparent !important",
                  },
                  mt: 2,
                  pl: !isLangRTL(i18n.language) && 0.6,
                  pr: isLangRTL(i18n.language) && 0.6,
                  py: 0.4,
                  overflowY: "scroll",
                  filter:
                    currentMode === "dark"
                      ? "drop-shadow(1px 1px 6px rgb(238 238 238 / 0.3))"
                      : "drop-shadow(1px 1px 6px rgb(28 28 28 / 0.3))",
                  // background: currentMode === "dark" ? "#1C1C1C" : "#EEEEEE",
                  background:
                    currentMode === "dark"
                      ? "rgb(28 28 28 / 0.9)"
                      : "rgb(238 238 238 / 0.9)",
                  color: currentMode === "dark" ? "#ffffff" : "black",
                  minWidth: currNavBtn === "Language" ? 100 : 300,
                  maxWidth: currNavBtn === "Language" ? 180 : 350,
                  borderRadius: "10px",
                  // "& .MuiAvatar-root": {
                  //   width: 32,
                  //   height: 32,
                  //   // ml: -0.5,
                  //   mx: 1,
                  // },
                  "& .css-qwh1ly-MuiContainer-root, .css-khd9l5-MuiContainer-root":
                    {
                      padding: "0 !important",
                    },
                },
              }}
              transformOrigin={{ horizontal: "center", vertical: "top" }}
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            >
              {currNavBtn ? (
                currNavBtn === "Notifications" ? (
                  // <NotificationsMenu />
                  <NotificationsMenuUpdated
                    handleClose={handleClose}
                    setCurrNavBtn={setCurrNavBtn}
                  />
                ) : currNavBtn === "Clock" ? (
                  <Clock handleClose={handleClose} />
                ) : currNavBtn === "Meetings" ? (
                  <UpcomingMeetingsMenu handleClose={handleClose} />
                ) : currNavBtn === "Profile" ? (
                  <div className="px-2">
                    <div
                      className={`cursor-pointer card-hover ${
                        currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
                      } mb-3 p-4 rounded-xl shadow-sm w-full`}
                    >
                      <Link
                        to={"/profile"}
                        onClick={() => setopenBackDrop(true)}
                      >
                        <div className="flex items-center justify-start">
                          <Avatar
                            src={User?.displayImg}
                            className="inline-block"
                          />
                          <div className="flex justify-between items-center w-full h-full">
                            <div className="mx-1 space-y-1">
                              <p className="font-semibold">{User?.userName}</p>
                              <p className="text-xs capitalize">
                                {User?.position}
                              </p>
                            </div>
                            <div
                              style={{
                                borderColor: primaryColor,
                              }}
                              className={`text-sm rounded-full border px-2 py-1`}
                            >
                              {t("profile")}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <ColorSchemeMenuItem />

                    {/* DOWNLOAD MOBILE APP  */}
                    <div className="grid grid-cols-2 gap-2">
                      {/* iOS  */}
                      <div
                        className={`cursor-pointer card-hover ${
                          currentMode === "dark" ? "bg-primary" : "bg-primary"
                        } mb-3 p-3 rounded-xl shadow-sm w-full`}
                      >
                        {/* <Link to={"/profile"} onClick={() => setopenBackDrop(true)}> */}
                        <div className="flex items-center justify-start">
                          <div className={`p-1 rounded-full mr-2`}>
                            <BsApple size={18} color={"#FFFFFF"} />
                          </div>
                          <div className="flex justify-between items-center w-full h-full text-white">
                            <div className="flex items-center">
                              <p className="font-semibold mx-1 mr-2">
                                Hikal CRM
                                <br />
                                iOS
                              </p>
                            </div>
                            <VscLock
                              size={14}
                              color={"#FFFFFF"}
                              className="mr-2"
                            />
                          </div>
                        </div>
                        {/* </Link> */}
                      </div>

                      {/* ANDROID  */}
                      <div
                        className={`cursor-pointer card-hover ${
                          currentMode === "dark" ? "bg-primary" : "bg-primary"
                        } mb-3 p-3 rounded-xl shadow-sm w-full`}
                      >
                        {/* <Link to={"/profile"} onClick={() => setopenBackDrop(true)}> */}
                        <div className="flex items-center justify-start">
                          <div className={`p-1 rounded-full mr-2`}>
                            <BsAndroid2 size={18} color={"#FFFFFF"} />
                          </div>
                          <div className="flex justify-between items-center w-full h-full text-white">
                            <div className="flex items-center">
                              <p className="font-semibold mx-1 mr-2">
                                Hikal CRM
                                <br />
                                Android
                              </p>
                            </div>
                            <VscLock
                              size={14}
                              color={"#FFFFFF"}
                              className="mr-2"
                            />
                          </div>
                        </div>
                        {/* </Link> */}
                      </div>
                    </div>

                    {/* LOGIN HISTORY  */}
                    {/* <div
                    className={`cursor-pointer card-hover ${
                      currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
                    } mb-3 p-3 rounded-xl shadow-sm w-full`}
                  >
                    <div className="flex items-center justify-start">
                      <div
                        className={`${
                          currentMode === "dark"
                            ? "bg-[#1C1C1C]"
                            : "bg-[#EEEEEE]"
                        } p-2 rounded-full mr-2`}
                      >
                        <VscHistory size={18} color={"#AAAAAA"} />
                      </div>
                      <div className="flex justify-between items-center w-full h-full">
                        <div className="flex items-center">
                          <p className="font-semibold mx-1 mr-2">
                            {t("login_history")}
                          </p>
                          <VscLock
                            size={14}
                            color={primaryColor}
                            className="mr-2"
                          />
                        </div>
                        <div
                          style={{
                            background: primaryColor,
                            fontSize: "0.5rem",
                          }}
                          className="rounded-full text-white px-2 py-1 font-bold"
                        >
                          {t("soon")?.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  </div> */}

                    {/* OPEN DAILY GOALS MODAL  */}
                    <div
                      className={`cursor-pointer card-hover ${
                        currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
                      } mb-3 p-3 rounded-xl shadow-sm w-full`}
                    >
                      <Link
                        to={"/changepassword"}
                        onClick={() => setopenBackDrop(true)}
                      >
                        <div className="flex items-center justify-start">
                          <div
                            className={`${
                              currentMode === "dark"
                                ? "bg-[#1C1C1C]"
                                : "bg-[#EEEEEE]"
                            } p-2 rounded-full mr-2`}
                          >
                            <VscShield size={18} color={"#AAAAAA"} />
                          </div>
                          <p className="mx-1 mr-2 font-semibold">
                            {t("change_password")}
                          </p>
                        </div>
                      </Link>
                    </div>

                    {/*  for two step verfication */}
                    <div
                      className={`cursor-pointer card-hover ${
                        currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
                      } mb-3 p-3 rounded-xl shadow-sm w-full`}
                    >
                      <div className="flex items-center justify-start">
                        <div
                          className={`${
                            currentMode === "dark"
                              ? "bg-[#1C1C1C]"
                              : "bg-[#EEEEEE]"
                          } p-2 rounded-full mr-2`}
                        >
                          <GrSecure size={18} color={"#AAAAAA"} />
                        </div>
                        <div className="flex-1 flex items-center justify-between">
                          <p className="mx-1 mr-2 font-semibold">
                            {t("Two Step Verification")}
                          </p>
                          <div className="">
                            <Switch
                              sx={{
                                "& .MuiSwitch-track": {
                                  backgroundColor: "gray", // track color when unchecked
                                },
                              }}
                              onChange={(e) => {
                                setIsTwoFA(e?.target?.checked);
                                updateTwoFAStatus(e?.target?.checked);
                              }}
                              checked={isTwoFA}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* IF SUBSCRIBED, UNSUBCRIBE  */}
                    {User?.role !== 1 && isUserSubscribed && (
                      <div
                        className={`cursor-pointer card-hover ${
                          currentMode === "dark"
                            ? "bg-[#000000]"
                            : "bg-[#FFFFFF]"
                        } mb-3 p-3 rounded-xl shadow-sm w-full`}
                        onClick={UnsubscribeUser}
                      >
                        {/* <Link to={"/changepassword"} onClick={() => setopenBackDrop(true)}> */}
                        <div className="flex items-center justify-start">
                          <div
                            className={`${
                              currentMode === "dark"
                                ? "bg-[#1C1C1C]"
                                : "bg-[#EEEEEE]"
                            } p-2 rounded-full mr-2`}
                          >
                            <VscExclude size={18} color={"#AAAAAA"} />
                          </div>
                          <p className="mx-1 mr-2 font-semibold">
                            {t("unsubscribe_package")}
                          </p>
                          <VscLock
                            size={14}
                            color={primaryColor}
                            className="mr-2"
                          />
                        </div>
                        {/* </Link> */}
                      </div>
                    )}

                    {/* LOGOUT  */}
                    <div
                      className={`cursor-pointer card-hover ${
                        currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
                      } p-3 rounded-xl shadow-sm w-full`}
                      onClick={LogoutUser}
                    >
                      <div className="flex items-center justify-start">
                        <div
                          className={`${
                            currentMode === "dark"
                              ? "bg-[#1C1C1C]"
                              : "bg-[#EEEEEE]"
                          } p-2 rounded-full mr-2`}
                        >
                          <VscSignOut size={18} color={"#AAAAAA"} />
                        </div>
                        <p className="mx-1 mr-2 font-semibold">
                          {t("log_out")}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : currNavBtn === "Language" ? (
                  <div className="px-2">
                    {langs?.map((lang) => (
                      <button
                        className={`cursor-pointer card-hover ${
                          currentMode === "dark"
                            ? "bg-[#000000]"
                            : "bg-[#FFFFFF]"
                        } mb-3 p-3 rounded-xl shadow-sm w-full`}
                        onClick={(e) => {
                          i18n.changeLanguage(lang.code);
                          saveLangInProfile(lang.code);
                          if (isLangRTL(lang.code)) {
                            changeBodyDirection("rtl");
                          } else {
                            changeBodyDirection("ltr");
                          }
                        }}
                      >
                        <div className="grid grid-cols-2 gap-5">
                          <div className="text-start">
                            <img
                              className="rounded-sm h-6 w-8 border"
                              src={lang.flag}
                              alt=""
                            />
                          </div>
                          <div
                            className="text-end"
                            style={{
                              fontFamily: lang?.font,
                              // fontSize: lang?.size
                            }}
                          >
                            {lang.title}
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </Menu>
          </div>
        </div>
      )}

      {deviceType === "desktop" && (
        <div
          onMouseLeave={handleClose}
          className={` ${
            themeBgImg
              ? currentMode === "dark"
                ? "blur-bg-dark-nr"
                : "blur-bg-light-nr"
              : currentMode === "dark"
              ? "bg-dark-neu-nr"
              : "bg-light-neu-nr"
          } flex justify-between items-center p-2 relative`}
          style={{
            position: "fixed",
            top: 0,
            left: isLangRTL(i18n.language) ? "0" : !isCollapsed ? 80 : 200,
            right: isLangRTL(i18n.language) ? (!isCollapsed ? 80 : 200) : 0,
            zIndex: "20",
            // backgroundColor: !themeBgImg && (currentMode === "dark" ? "black" : "white"),
            boxShadow:
              currentMode !== "dark"
                ? "0 2px 4px rgba(0, 0, 0, 0.1)"
                : "0 2px 4px rgba(255, 255, 255, 0.1)",
          }}
        >
          {/* BREADCRUMB  */}
          <div className="flex items-center">
            <div
              onClick={() => {
                collapseSidebar();
                setIsCollapsed(!isCollapsed);
              }}
              className="flex items-center rounded-lg pl-1 cursor-pointer"
            >
              <button
                type="button"
                style={{
                  color: currentMode === "dark" ? "white" : primaryColor,
                }}
                // style={{ color: currentColor }}
                className={`relative text-xl rounded-full hover:bg-light-gray mr-4`}
                aria-label="menu-icon-toggle"
              >
                <span className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />
                <AiOutlineMenu />
              </button>
            </div>
            <BreadCrumb allroutes={allRoutes} currentMode={currentMode} />
          </div>

          <div className="flex items-center">
            {/* UPGRADE  */}
            {isUserSubscribed !== null && [
              isUserSubscribed === false ? (
                <Button
                  variant="contained"
                  className="bg-btn-primary"
                  sx={{
                    mx: 2,
                    "& svg": {
                      color: "white",
                    },
                  }}
                >
                  <Link to="/marketing/payments" className="flex items-center">
                    <MdStars className="mx-2" size={18} />
                    <span className="mt-[2px]">{t("upgrade")}</span>
                  </Link>
                </Button>
              ) : (
                <></>
              ),
            ]}

            {/* search */}
            <SearchLeads />

            {/* currency Convertor */}

            <Tooltip title={"Currency Convertor"} arrow placement="bottom">
              <button
                type="button"
                onClick={() => setCurrencyModal(true)}
                className={`relative text-xl rounded-full p-3 hover:bg-light-gray ${
                  currentMode == "dark" ? "text-white" : "text-black"
                }`}
                aria-label={"Currency Convertor"}
              >
                <MdCurrencyExchange size={16} />
              </button>
            </Tooltip>

            {/* MEETINGS  */}
            <NavButton
              title="Meetings"
              handleClose={handleClose}
              dotColor={currentMode === "dark" ? "#ffffff" : primaryColor}
              customFunc={(event) => handleClick(event, "Meetings")}
              color={currentMode === "dark" ? "#ffffff" : "#333333"}
              aria-label="upcoming Meetings list"
              icon={
                currNavBtn === "Meetings" ? (
                  <BsCalendarEventFill size={16} />
                ) : (
                  <BsCalendarEvent size={16} />
                )
              }
            />
            <CustomMenu name="Meetings" currNavBtn={currNavBtn}>
              <UpcomingMeetingsMenu />
            </CustomMenu>
            {/* NOTIFICATIONS  */}
            <NavButton
              handleClose={handleClose}
              title="Notification"
              // dotColor={currentMode === "dark" ? "#ffffff" : "#DA1F26"}
              customFunc={(event) => handleClick(event, "Notifications")}
              color={currentMode === "dark" ? "#ffffff" : "#333333"}
              aria-label="Notifications list"
              icon={
                <Badge
                  className={notifIconAnimating ? "animate-notif-icon" : ""}
                  badgeContent={unreadNotifsCount}
                  sx={{
                    "& .MuiBadge-badge": {
                      background: primaryColor,
                      color: "white",
                    },
                  }}
                >
                  <BsBell size={16} />
                </Badge>
              }
            />
            <CustomMenu name="Notifications" isHeight currNavBtn={currNavBtn}>
              <NotificationsMenuUpdated setCurrNavBtn={setCurrNavBtn} />
            </CustomMenu>
            {/* CLOCK  */}

            <NavButton
              handleClose={handleClose}
              title="Clock"
              // color={currentMode === "dark" ? "#ffffff" : LightIconsColor}
              customFunc={(event) => handleClick(event, "Clock")}
              color={currentMode === "dark" ? "#ffffff" : "#333333"}
              icon={
                currNavBtn === "Clock" ? (
                  <BsClockFill size={16} />
                ) : (
                  <BsClock size={16} />
                )
              }
            />

            <CustomMenu name="Clock" currNavBtn={currNavBtn}>
              <Clock />
            </CustomMenu>
            {/* THEME  */}
            {/* {!themeBgImg && */}
            <Tooltip
              title={currentMode === "dark" ? "Light mode" : "Dark mode"}
              arrow
              placement="bottom"
              className="relative"
            >
              <button
                type="button"
                onClick={changeMode}
                className={`relative text-xl rounded-full p-3 hover:bg-light-gray ${
                  currentMode === "dark" ? "text-white" : " text-primary"
                }`}
              >
                {currentMode === "dark" ? (
                  <MdOutlineLightMode size={16} color="#dcb511" />
                ) : (
                  <MdDarkMode size={16} color={primaryColor} />
                )}
              </button>
            </Tooltip>

            {/* } */}
            {/* PROFILE  */}
            <Tooltip
              title="Profile"
              arrow
              placement="bottom"
              className="relative"
            >
              <div
                className={`bg-primary text-white mx-1 p-2 flex items-center gap-2 cursor-pointer hover:bg-light-gray rounded-xl shadow-sm card-hover`}
                onClick={(event) => {
                  if (currNavBtn === "Profile") {
                    handleClose();
                  } else {
                    handleClick(event, "Profile");
                  }
                }}
              >
                <img
                  height={50}
                  width={50}
                  className="rounded-full w-10 h-10 object-cover"
                  src={User?.displayImg ? User?.displayImg : "/assets/user.png"}
                  alt="user-profile"
                />
                <p className="display-block">
                  <span className={`font-bold`}>
                    {getDisplayName(User?.userName)}
                  </span>
                </p>
                <MdKeyboardArrowDown size={14} className={``} />
              </div>
            </Tooltip>
            <CustomMenu name="Profile" currNavBtn={currNavBtn}>
              <ProfileDropdown
                setIsTwoFA={setIsTwoFA}
                isTwoFA={isTwoFA}
                updateTwoFAStatus={updateTwoFAStatus}
                setopenBackDrop={setopenBackDrop}
                UnsubscribeUser={UnsubscribeUser}
                LogoutUser={LogoutUser}
              />
            </CustomMenu>
            {/* LANG  */}
            <Tooltip
              title="Language"
              arrow
              placement="bottom"
              className="relative"
            >
              <div
                className="mx-2 flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                onClick={(event) => {
                  if (currNavBtn === "Language") {
                    handleClose();
                  } else {
                    handleClick(event, "Language");
                  }
                }}
                role="button"
              >
                <img
                  className="rounded-sm h-6 w-8 border"
                  src={langFlag}
                  alt={i18n?.language}
                />
                {/* <MdKeyboardArrowDown
                className={`${
                  currentMode === "dark" ? "text-white" : "text-black"
                }`}
              /> */}
              </div>
            </Tooltip>
            <CustomMenu name="Language" currNavBtn={currNavBtn}>
              <Languages
                saveLangInProfile={saveLangInProfile}
                langs={langs}
                changeBodyDirection={changeBodyDirection}
              />
            </CustomMenu>
          </div>
        </div>
      )}
      {currencyModal && (
        <CurrencyConvertor
          setCurrencyModal={setCurrencyModal}
          currencyModal={currencyModal}
        />
      )}
    </>
  );
};

export default Navbar;

// <Menu
// className="hide-scrollbar navbar-menu-backdrop"
// hideBackdrop={false}
// // onClick={handleClose}
// onMouseLeave={handleClose}
// anchorEl={anchorElem}
// open={open}
// PaperProps={{
//   elevation: 0,
//   sx: {
//     "& .MuiList-root .clock-div": {
//       border: "none !important",
//       background: "transparent !important",
//     },
//     mt: 2,
//     pl: !isLangRTL(i18n.language) && 0.6,
//     pr: isLangRTL(i18n.language) && 0.6,
//     py: 0.4,
//     overflowY: "scroll",
//     filter:
//       currentMode === "dark"
//         ? "drop-shadow(1px 1px 6px rgb(238 238 238 / 0.3))"
//         : "drop-shadow(1px 1px 6px rgb(28 28 28 / 0.3))",
//     // background: currentMode === "dark" ? "#1C1C1C" : "#EEEEEE",
//     background:
//       currentMode === "dark"
//         ? "rgb(28 28 28 / 0.9)"
//         : "rgb(238 238 238 / 0.9)",
//     color: currentMode === "dark" ? "#ffffff" : "black",
//     minWidth: currNavBtn === "Language" ? 100 : 300,
//     maxWidth: currNavBtn === "Language" ? 180 : 350,
//     borderRadius: "10px",
//     // "& .MuiAvatar-root": {
//     //   width: 32,
//     //   height: 32,
//     //   // ml: -0.5,
//     //   mx: 1,
//     // },
//     "& .css-qwh1ly-MuiContainer-root, .css-khd9l5-MuiContainer-root":
//       {
//         padding: "0 !important",
//       },
//   },
// }}
// transformOrigin={{ horizontal: "center", vertical: "top" }}
// anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
// >
// {currNavBtn ? (
//   currNavBtn === "Notifications" ? (
//     // <NotificationsMenu />
//     // <NotificationsMenuUpdated
//     //   handleClose={handleClose}
//     //   setCurrNavBtn={setCurrNavBtn}
//     // />
//     <></>
//   ) : currNavBtn === "Clok" ? (
//     <Clock handleClose={handleClose} />
//   ) : currNavBtn === "Metings" ? (
//     <UpcomingMeetingsMenu handleClose={handleClose} />
//   ) : currNavBtn === "Prfile" ? (
//     <div className="px-2">
//       <div
//         className={`cursor-pointer card-hover ${
//           currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
//         } mb-3 p-4 rounded-xl shadow-sm w-full`}
//       >
//         <Link
//           to={"/profile"}
//           onClick={() => setopenBackDrop(true)}
//         >
//           <div className="flex items-center justify-start">
//             <Avatar
//               src={User?.displayImg}
//               className="inline-block"
//             />
//             <div className="flex justify-between items-center w-full h-full">
//               <div className="mx-1 space-y-1">
//                 <p className="font-semibold">{User?.userName}</p>
//                 <p className="text-xs capitalize">
//                   {User?.position}
//                 </p>
//               </div>
//               <div
//                 style={{
//                   borderColor: primaryColor,
//                 }}
//                 className={`text-sm rounded-full border px-2 py-1`}
//               >
//                 {t("profile")}
//               </div>
//             </div>
//           </div>
//         </Link>
//       </div>

//       <ColorSchemeMenuItem />

//       {/* DOWNLOAD MOBILE APP  */}
//       <div className="grid grid-cols-2 gap-2">
//         {/* iOS  */}
//         <div
//           className={`cursor-pointer card-hover ${
//             currentMode === "dark" ? "bg-primary" : "bg-primary"
//           } mb-3 p-3 rounded-xl shadow-sm w-full`}
//         >
//           {/* <Link to={"/profile"} onClick={() => setopenBackDrop(true)}> */}
//           <div className="flex items-center justify-start">
//             <div className={`p-1 rounded-full mr-2`}>
//               <BsApple size={18} color={"#FFFFFF"} />
//             </div>
//             <div className="flex justify-between items-center w-full h-full text-white">
//               <div className="flex items-center">
//                 <p className="font-semibold mx-1 mr-2">
//                   Hikal CRM
//                   <br />
//                   iOS
//                 </p>
//               </div>
//               <VscLock
//                 size={14}
//                 color={"#FFFFFF"}
//                 className="mr-2"
//               />
//             </div>
//           </div>
//           {/* </Link> */}
//         </div>

//         {/* ANDROID  */}
//         <div
//           className={`cursor-pointer card-hover ${
//             currentMode === "dark" ? "bg-primary" : "bg-primary"
//           } mb-3 p-3 rounded-xl shadow-sm w-full`}
//         >
//           {/* <Link to={"/profile"} onClick={() => setopenBackDrop(true)}> */}
//           <div className="flex items-center justify-start">
//             <div className={`p-1 rounded-full mr-2`}>
//               <BsAndroid2 size={18} color={"#FFFFFF"} />
//             </div>
//             <div className="flex justify-between items-center w-full h-full text-white">
//               <div className="flex items-center">
//                 <p className="font-semibold mx-1 mr-2">
//                   Hikal CRM
//                   <br />
//                   Android
//                 </p>
//               </div>
//               <VscLock
//                 size={14}
//                 color={"#FFFFFF"}
//                 className="mr-2"
//               />
//             </div>
//           </div>
//           {/* </Link> */}
//         </div>
//       </div>

//       {/* LOGIN HISTORY  */}

//       {/* CHANGE PASSWORD  */}
//       <div
//         className={`cursor-pointer card-hover ${
//           currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
//         } mb-3 p-3 rounded-xl shadow-sm w-full`}
//       >
//         <Link
//           to={"/changepassword"}
//           onClick={() => setopenBackDrop(true)}
//         >
//           <div className="flex items-center justify-start">
//             <div
//               className={`${
//                 currentMode === "dark"
//                   ? "bg-[#1C1C1C]"
//                   : "bg-[#EEEEEE]"
//               } p-2 rounded-full mr-2`}
//             >
//               <VscShield size={18} color={"#AAAAAA"} />
//             </div>
//             <p className="mx-1 mr-2 font-semibold">
//               {t("change_password")}
//             </p>
//           </div>
//         </Link>
//       </div>
//       {/* for 2 step verification */}
//       <div
//         className={`cursor-pointer card-hover ${
//           currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
//         } mb-3 p-3 rounded-xl shadow-sm w-full`}
//       >
//         <div className="flex items-center justify-start">
//           <div
//             className={`${
//               currentMode === "dark"
//                 ? "bg-[#1C1C1C]"
//                 : "bg-[#EEEEEE]"
//             } p-2 rounded-full mr-2`}
//           >
//             <GrSecure size={18} color={"#AAAAAA"} />
//           </div>
//           <div className="flex-1 justify-between flex items-center">
//             <p className="mx-1 mr-2 font-semibold">
//               {t("Two Step Verification")}
//             </p>
//             <div className="">
//               <Switch
//                 sx={{
//                   "& .MuiSwitch-track": {
//                     backgroundColor: "gray", // track color when unchecked
//                   },
//                 }}
//                 onChange={(e) => {
//                   setIsTwoFA(e?.target?.checked);
//                   updateTwoFAStatus(e?.target?.checked);
//                 }}
//                 checked={isTwoFA}
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* IF SUBSCRIBED, UNSUBCRIBE  */}
//       {User?.role !== 1 && isUserSubscribed && (
//         <div
//           className={`cursor-pointer card-hover ${
//             currentMode === "dark"
//               ? "bg-[#000000]"
//               : "bg-[#FFFFFF]"
//           } mb-3 p-3 rounded-xl shadow-sm w-full`}
//           onClick={UnsubscribeUser}
//         >
//           {/* <Link to={"/changepassword"} onClick={() => setopenBackDrop(true)}> */}
//           <div className="flex items-center justify-start">
//             <div
//               className={`${
//                 currentMode === "dark"
//                   ? "bg-[#1C1C1C]"
//                   : "bg-[#EEEEEE]"
//               } p-2 rounded-full mr-2`}
//             >
//               <VscExclude size={18} color={"#AAAAAA"} />
//             </div>
//             <p className="mx-1 mr-2 font-semibold">
//               {t("unsubscribe_package")}
//             </p>
//             <VscLock
//               size={14}
//               color={primaryColor}
//               className="mr-2"
//             />
//           </div>
//           {/* </Link> */}
//         </div>
//       )}

//       {/* LOGOUT  */}
//       <div
//         className={`cursor-pointer card-hover ${
//           currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
//         } p-3 rounded-xl shadow-sm w-full`}
//         onClick={LogoutUser}
//       >
//         <div className="flex items-center justify-start">
//           <div
//             className={`${
//               currentMode === "dark"
//                 ? "bg-[#1C1C1C]"
//                 : "bg-[#EEEEEE]"
//             } p-2 rounded-full mr-2`}
//           >
//             <VscSignOut size={18} color={"#AAAAAA"} />
//           </div>
//           <p className="mx-1 mr-2 font-semibold">
//             {t("log_out")}
//           </p>
//         </div>
//       </div>
//     </div>
//   ) : currNavBtn === "Languae" ? (
//     <div className="px-2">
//       {langs?.map((lang) => (
//         <button
//           className={`cursor-pointer card-hover ${
//             currentMode === "dark"
//               ? "bg-[#000000]"
//               : "bg-[#FFFFFF]"
//           } mb-3 p-3 rounded-xl shadow-sm w-full`}
//           onClick={(e) => {
//             i18n.changeLanguage(lang.code);
//             saveLangInProfile(lang.code);
//             if (isLangRTL(lang.code)) {
//               changeBodyDirection("rtl");
//             } else {
//               changeBodyDirection("ltr");
//             }
//           }}
//         >
//           <div className="grid grid-cols-2 gap-5">
//             <div className="text-start">
//               <img
//                 className="rounded-sm h-6 w-8 border"
//                 src={lang.flag}
//                 alt=""
//               />
//             </div>
//             <div
//               className="text-end"
//               style={{
//                 fontFamily: lang?.font,
//                 // fontSize: lang?.size
//               }}
//             >
//               {lang.title}
//             </div>
//           </div>
//         </button>
//       ))}
//     </div>
//   ) : (
//     <></>
//   )
// ) : (
//   <></>
// )}
// </Menu>
